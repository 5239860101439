<template>
	<div class="x-main-box" style="min-width: 1024px;">
		<!--面包屑-->
		<search-form :data="searchData" ref="searchForm" @searchFun="searchFun"></search-form>
		<div class="x-toolbar x-tl">
			<el-button type="primary" @click="addbtn">新增用户</el-button>
			<el-button @click="exportInExcel">导入用户</el-button>
			<el-button @click="importInExcel">导出</el-button>
		</div>
		<table-page :total="total" :callback="getTableData" :currpage="currPage" :pagesize="pageSize">
			<el-table :data="tableData" border @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" fixed="left"></el-table-column>
				<el-table-column prop="name" label="姓名" show-overflow-tooltip>
					<template slot-scope="scope">
						{{ scope.row.surname + scope.row.name }}
					</template>
				</el-table-column>
				<el-table-column prop="mobile" label="手机号" width="100" show-overflow-tooltip></el-table-column>
				<el-table-column prop="email" label="邮箱" show-overflow-tooltip></el-table-column>
        <el-table-column prop="enterprise" label="公司" width="100"></el-table-column>
        <el-table-column prop="department" label="部门" width="100"></el-table-column>
        <el-table-column prop="post" label="职务" width="100"></el-table-column>
				<el-table-column prop="genderName" label="性别" width="100"></el-table-column>
				<el-table-column prop="birthday" label="出生" width="100">
					<template slot-scope="scope">
						{{ scope.row.birthday | formatDate('date') }}
					</template>
				</el-table-column>
				<el-table-column prop="area" show-overflow-tooltip label="所属区域"></el-table-column>
				<el-table-column prop="category" show-overflow-tooltip label="行业分类"></el-table-column>
				<el-table-column prop="domain" show-overflow-tooltip label="技术领域"></el-table-column>
				<el-table-column prop="keyword" show-overflow-tooltip label="技术关键词"></el-table-column>
				<el-table-column prop="typeName" label="用户类别" width="100"></el-table-column>
				<el-table-column prop="enabled" label="账号状态" width="100">
					<template slot-scope="scope">
						<span v-if="!scope.row.enabled">已停用</span>
						<span v-if="scope.row.enabled">已启用</span>
					</template>
				</el-table-column>
				<el-table-column prop="createDate" label="注册时间" width="140" show-overflow-tooltip>
					<template slot-scope="scope">
						{{ scope.row.createDate | formatDate('datetime') }}
					</template>
				</el-table-column>
				<el-table-column prop="id" label="保密协议签署" width="120" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-button type="text" @click="viewLy(scope.row)" v-if="scope.row.signProtocolTimes">{{ scope.row.signProtocolTimes + '次签署' }}</el-button>
						<!-- <el-button type="text" @click="viewLy(scope.row)">{{ 'X次签署' }}</el-button> -->
					</template>
				</el-table-column>
				<el-table-column prop="profile" label="报名状态" width="80">
					<template slot-scope="scope">
						<span v-if="scope.row.profile=='yes'">是</span>
						<span v-else>否</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="80">
					<template slot-scope="scope">
						<el-button type="text" @click="delClick(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</table-page>
		<div class="x-mt10">
			<span class="x-bold">批量操作所选</span>
			<el-button class="x-ml10" @click="sendMailAll" icon="el-icon-message">发送账号密码通知邮件</el-button>
		</div>
		<!--弹框-->
		<dialog-form ref="xdialog" width="900px" :title="title" @confirm="btnSave" @resetForm="resetForm" :editable="editable">
			<el-form :model="form" ref="form" :rules="rules" :label-width="labelWidth">
				<div class="u-set-box">
					<div class="u-set-form">
						<el-row>
							<el-col :span="24"><el-form-item label="身份">个人</el-form-item></el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<el-form-item label="姓名" prop="surnameNames">
									<el-input v-model="form.name" style="width: 100px;margin-right:10px;" placeholder="名"></el-input>
									<el-input v-model="form.surname" style="width: 100px;" placeholder="姓"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="性别" prop="gender">
									<el-radio-group v-model="form.gender">
										<el-radio-button v-for="item in genderOpt" :key="item.id" :label="item.id">{{ item.name }}</el-radio-button>
									</el-radio-group>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<el-form-item label="所在地" prop="area">
									<el-cascader v-model="form.area" :options="areaOpt" :props="{ value: 'name', label: 'name', children: 'children' }"></el-cascader>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="出生" prop="birthday">
									<el-date-picker v-model="form.birthday" type="date" format="yyyy年MM月dd日" placeholder="选择日期"></el-date-picker>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<el-form-item label="所属行业" prop="category">
									<el-cascader v-model="form.category" :options="categoryOpt" :props="{ value: 'name', label: 'name', children: 'children' }"></el-cascader>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="技术领域" prop="domain">
									<el-cascader v-model="form.domain" :options="domainOpt" :props="{ value: 'name', label: 'name', children: 'children' }"></el-cascader>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<el-form-item label="技术标签" prop="keyword">
									<demand-industry-input
										@setForm="setForm"
										@delEvent="delEvent"
										:selectOpt="keywordOpt"
										:isFilter="true"
										:allowCreate="true"
										:tagOpt="tagKeyword"
										ikey="keyword"
										placeholder="输入个人技术标签"
									>
										<div slot="des" class="x-color666">最多支持10个技术标签</div>
									</demand-industry-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<el-form-item label="手机号" prop="mobile"><el-input type="text" v-model="form.mobile"></el-input></el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="邮箱" prop="email"><el-input type="text" v-model="form.email"></el-input></el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="个人简介" prop="comment">
									<el-input type="textarea" :rows="5" v-model="form.comment" placeholder="输入个人简介"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-form>
		</dialog-form>
		<el-dialog title="协议签署列表" :visible.sync="dialogVisible" width="800px">
			<el-table :data="qianshuData" border>
				<el-table-column prop="tenant" label="签署协议">
					<template slot-scope="scope">
						<span :id="scope.row.id">{{$t('signUp8_1')}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="createDate" label="签署时间" show-overflow-tooltip>
					<template slot-scope="scope">
						{{ scope.row.createDate | formatDate('datetime') }}
					</template>
				</el-table-column>
			</el-table>

			<span slot="footer" class="dialog-footer" style="text-align: left;"><el-button @click="dialogVisible = false">关 闭</el-button></span>
		</el-dialog>
		<ImportExcel ref="dialogImportExcel" :data="excelData" @close="refreshTable"></ImportExcel>
	</div>
</template>

<script>
import TablePage from '@/components/comm/TablePage';
import SearchForm from '@/components/comm/SearchForm';
import DialogForm from '@/components/comm/DialogForm';
import ImportExcel from '@/components/comm/ImportExcel';
import mixins from '@/mixins';
import _ from 'lodash';
import { apiGetUser, apiPostUser, apiPostGeneratePwd, apiGetUsersFields, apiGetUserProtocolLog } from '@/api/UserManage/index';
import { apiTechnologyKeyword, apiPostTechnologyKeyword, apiPostAppUser } from '@/api/index';
import DemandIndustryInput from './comm/DemandIndustryInput';
import {downloadFileParms} from '../../../assets/comm';

export default {
	components: {
		TablePage,
		DialogForm,
		SearchForm,
		DemandIndustryInput,
		ImportExcel
	},
	mixins: [mixins],
	props: {
		nature: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			title: '',
			editable: true,
			details: {},
			searchValue: '',
			searchType: 'username',
			dialogVisible: false,
			searchData: [
				{ id: 'name', name: '姓名', type: 'text', ctrl: true },
				{ id: 'mobile', name: '手机号', type: 'text', ctrl: true },
				{ id: 'email', name: '邮箱', type: 'text', ctrl: true }
			],
			addDialog: false,
			rolesOpt: [],
			form: {
				surname: '',
				name: '',
				surnameNames: '',
				gender: 'nvl',
				area: [],
				birthday: '',
				category: [],
				domain: [],
				comment: '',
				email: '',
				mobile: '',
				headimg: '',
				keyword: ''
			},
			rules: {
				surnameNames: { required: true, message: '请输入姓名', trigger: 'change' },
				//gender: { required: true, message: '请选择性别', trigger: 'change' },
				area: { required: true, message: '请选择所在地', trigger: 'change' },
				//birthday: { required: true, message: '请选择出生', trigger: 'change' },
				category: { required: true, message: '请选择所属行业', trigger: 'change' },
				domain: { required: true, message: '请选择技术领域', trigger: 'change' },
				email: { required: true, message: '请填写邮箱', trigger: 'blur' },
				keyword: { required: true, message: '请添加技术标签', trigger: 'blur' }
			},
			addressOpt: [],
			labelWidth: '120px',
			keywordOpt: [],
			tagKeyword: [],
			mode: 0,
			multipleSelection: [],
			IDs: [],
			excelData: {},
			importTh: [],
			qianshuData: []
		};
	},
	created() {
		this.getRoles();
	},
	watch: {
		'form.surname'() {
			this.validName();
		},
		'form.name'() {
			this.validName();
		}
	},
	computed: {
		btnLoading() {
			return this.$store.getters.getBtnLoading;
		},
		genderOpt() {
			return this.$store.getters.getGender;
		},
		areaOpt() {
			let area = this.$store.getters.getArea;
			return this.getTreeData(area);
		},
		categoryOpt() {
			return this.getTreeData(this.$store.getters.getCategory);
		},
		domainOpt() {
			return this.getTreeData(this.$store.getters.getDomain);
		}
	},
	mounted() {
		this.getFields();
		this.init();
		this.getDict();
	},
	methods: {
    downloadFileParms,
		//查看留言
		viewLy(rows) {
			this.qianshuData = [];
			let parms = { userId: rows.id, desc: 'createDate' };
			apiGetUserProtocolLog(parms).then(res => {
				this.qianshuData = res.result;
				this.dialogVisible = true;
			});
		},
		init() {
			let param = this.$refs.searchForm.reParams();
			this.refreshTable(param);
		},
		validName() {
			let { surname, name } = this.form;
			if (surname && name) {
				this.$set(this.form, 'surnameNames', `${surname}_${name}`);
			}
		},
		//多选
		handleSelectionChange(val) {
			this.multipleSelection = val;
			let ids = [];
			this.multipleSelection.map(item => {
				ids.push(item.id);
			});
			this.IDs = ids;
		},
		getFields() {
			let arr = [];
			apiGetUsersFields().then(res => {
				let data = res.result;
				_.each(data, i => {
					_.each(i.fields, j => {
						if (!j.readonly) {
							arr.push({
								name: j.fieldName,
								field: j.field,
								required: j.required
							});
						}
					});
				});
				// console.log('arr',arr);
			});
			this.importTh = arr;
			console.log('importTh', this.importTh);
		},
		exportInExcel() {
			this.excelData = {
				tempUrl: '/cbmp/importUserTemplate',
				url1: '/cbmp/readExcel',
				url2: '/cbmp/importExcel',
				service: 'userImportService',
				title: '导入用户数据',
				step1: '下载用户数据导入模板',
				description1: '',
				step2: '上传用户数据导入表格',
				description2: '',
				importTh: this.importTh,
				type: 1
			};
			this.$refs.dialogImportExcel.openDialog();
		},
		initForm(val) {
			if (!_.isEmpty(val)) {
				let { surname, name, gender, area, birthday, category, domain, comment, email, mobile, headimg, keyword } = val;
				this.$set(this.form, 'surname', surname);
				this.$set(this.form, 'name', name);
				this.$set(this.form, 'gender', gender);
				this.$set(this.form, 'birthday', birthday);
				this.$set(this.form, 'area', area ? area.split('_') : []);
				this.$set(this.form, 'category', category ? category.split('_') : []);
				this.$set(this.form, 'domain', domain ? domain.split('_') : []);
				this.$set(this.form, 'comment', comment);
				this.$set(this.form, 'email', email);
				this.$set(this.form, 'mobile', mobile);
				this.$set(this.form, 'headimg', headimg);
				this.$set(this.form, 'keyword', keyword);
				this.tagKeyword = keyword.split('_');
			}
		},
		getDict() {
			if (_.isEmpty(this.areaOpt)) {
				this.$store.dispatch('setArea');
			}
			if (_.isEmpty(this.categoryOpt)) {
				this.$store.dispatch('setCategory');
			}
			if (_.isEmpty(this.domainOpt)) {
				this.$store.dispatch('setDomain');
			}
			apiTechnologyKeyword().then(res => {
				this.keywordOpt = res.result;
			});
		},
		validationPassword: function(rule, value, callback) {
			if (!value && this.mode === 0) {
				return callback(new Error('请输入密码'));
			}
			callback();
		},
		delEvent(index) {
			this.tagKeyword.splice(index, 1);
			this.$set(this.form, 'keyword', this.tagKeyword.join('_'));
		},
		//批量导入用户
		exportUser() {},
		btnSave() {
			this.$refs.form.validate(valid => {
				if (valid) {
					let { surname, name, gender, area, birthday, category, domain, comment, email, mobile, headimg, keyword } = this.form;
					let params = {
						surname,
						name,
						gender,
						birthday,
						area: area.join('_'),
						category: category.join('_'),
						domain: domain.join('_'),
						comment,
						email,
						mobile,
						headimg,
						keyword
					};
					params.roles = [10003]; //admin 管理人员
					params.nature = 'personal';
					console.log('params___', params);
					apiPostAppUser(params).then(res => {
						if (res.success) {
							this.init();
							this.closeDialog();
						}
					});
				}
			});
		},
		sendMailAll() {
			if (this.IDs.length == 0) {
				this.$message.info('请先选择记录！');
				return false;
			}
			this.$confirm('确定发送邮件吗', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false
			})
				.then(() => {
					apiPostGeneratePwd(this.IDs, 'post').then(res => {
						if (res.data.success) {
							this.$message.info(res.data.message);
							this.refreshTable();
						}
					});
				})
				.catch(() => {});
		},
		setForm(opt = {}) {
			let { key, value, isAdd, formValue } = opt;
			console.log('setForm--', key, value, isAdd, formValue);
			if (isAdd) {
				apiPostTechnologyKeyword(
					{
						keyword: value
					},
					{
						noMsg: true,
						noLoading: true
					}
				);
				this.keywordOpt.push({
					keyword: value
				});
			}
			this.tagKeyword.push(value);
			this.$set(this.form, key, formValue);
		},
		getTreeData(data = []) {
			for (let i = 0; i < data.length; i++) {
				if (!data[i].children) {
					data[i].children = undefined;
				} else if (data[i].children.length < 1) {
					data[i].children = undefined;
				} else {
					this.getTreeData(data[i].children);
				}
			}
			return data;
		},
		getRoles() {},

		refreshTable(param) {
      this.searchParams = {
        ...this.searchParams,
        name: '',
        mobile: '',
        email: '',
        nature: this.nature,
        includeList: true,
      };
			this.getTableData(param, apiGetUser, { nature: this.nature, includeList: true, exp: 'allLike,allLike,allLike,,' });
		},
		searchFun(params = {}) {
			params.pageSize = this.pageSize;
			params.currPage = 1;
			this.searchParams = params;
			this.refreshTable(this.searchParams);
		},
		openDialog() {
			this.$refs.xdialog.openDialog();
		},
		closeDialog() {
			this.$refs.xdialog.closeDialog();
		},
		addbtn() {
			this.mode = 0;
			this.title = '新增';
			this.editable = true;
			this.openDialog();
		},
		viewClick(rows) {
			this.title = '查看';
			this.editable = false;
			this.openDialog();
			apiPostUser(
				{
					params: { id: rows.id }
				},
				'get'
			).then(res => {
				this.details = res.result[0];
			});
		},
		editClick(rows) {
			this.mode = 1;
			this.title = '编辑';
			this.editable = true;
			this.openDialog();
			apiPostUser(
				{
					params: { id: rows.id }
				},
				'get'
			).then(res => {
				let data = res.result[0];
				let roles = [];
				_.each(data.roleNames, i => {
					_.each(this.rolesOpt, n => {
						if (i === n.name) {
							roles.push(n.id);
						}
					});
				});
				this.$nextTick(() => {
					this.form.username = data.username;
					this.form.password = '';
					this.form.sysRoles = roles;
					this.form.name = data.name;
					this.form.email = data.email;
					this.form.mobile = data.mobile;
					this.form.id = rows.id;
				});
			});
		},
		delClick(id) {
			this.del(id, apiPostUser);
		},
		enableClick(rows) {
			apiPostUser({ id: rows.id, enabled: !rows.enabled }, 'put').then(res => {
				if (res.success) {
					this.closeDialog();
					this.refreshTable();
				}
			});
		},
		resetForm() {
			this.$refs['form'].resetFields();
		},
    importInExcel(){
		  let nature = this.nature;
		  let {exp, includeList} = this.fixParams;
		  console.log(this.fixParams, nature, this.searchParams);
		  let params = {
        ...this.searchParams,
        nature,
        includeList,
        export: `${nature}.xlsx`,
        fields: "surname,name,mobile,email,enterprise,department,post,gender,birthday,area,category,domain,keyword,type,enabled,createDate,signProtocolTimes,joinActivity"
      };
		  if(!_.isEmpty(this.searchParams)){
		    params = {
		      ...params,
          exp
        }
      }
		  this.downloadFileParms('/cbmp/rest/AppUser/list', params);
    }
	}
};
</script>
<style></style>
