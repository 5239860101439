<template>
<div class="login-box">
  <div class="login-main-box">
    <div class="login-head">
      <img src="../../../assets/images/ICIM-LOGO.png" alt="ICIM LOGO">
      <slot name="title"></slot>
    </div>
    <div class="login-content">
      <slot></slot>
    </div>
  </div>
  <slot name="footer"></slot>
</div>
</template>
<script>
import "@/assets/style/login.css"
export default {}
</script>
