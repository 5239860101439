<template>
  <el-form ref="form" :model="form" :rules="rules">
    <el-form-item :label="$t('typeA1')" prop="description">
      <el-input v-model="form.description" type="textarea" :rows="5" :placeholder="$t('placeholder')" />
    </el-form-item>
    <el-form-item :label="$t('typeF1')" prop="mainProblemContent">
      <el-input v-model="form.mainProblemContent" type="textarea" :rows="5" :placeholder="$t('placeholder')" />
    </el-form-item>
    <el-form-item :label="$t('typeF2')" prop="mainProblemSupportContent">
      <el-input v-model="form.mainProblemSupportContent" type="textarea" :rows="5" :placeholder="$t('placeholder')" />
    </el-form-item>
    <step-btn @next="nextEvent" @save="saveEvent"></step-btn>
  </el-form>
</template>

<script>
  import StepBtn from "../StepBtn";
  import MixinsBase from "../../../mixins/base";
  export default {
    components: {
      StepBtn
    },
    mixins: [MixinsBase],
    data(){
      return {
        form: {
          description: '',
          mainProblemContent: '',
          mainProblemSupportContent: '',
        },
        rules: {
          description: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
          mainProblemContent: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
          mainProblemSupportContent: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
        }
      }
    },
    computed: {
      cacheDemand(){
        return this.$store.getters.getCacheDemand;
      }
    },
    watch: {
      cacheDemand: {
        immediate: true,
        handler: function(val){
          this.initForm(val);
        }
      }
    },
    methods: {
      initForm(val){
        if(!val){
          return ;
        }
        let { description, mainProblemContent, mainProblemSupportContent } = val;
        this.form = {
          description,
          mainProblemContent,
          mainProblemSupportContent
        };
      },
      nextEvent(step){
        this.$refs.form.validate(valid=>{
          if(valid){
            this.$store.dispatch('setCacheDemand', {
              ...this.cacheDemand,
              ...this.form
            });
            this.$store.dispatch('setStep', step);
          }
        })
      },
      saveEvent(){
        this.$refs.form.validate(valid=> {
          if (valid) {
            let {id} = this.cacheDemand;
            let params = {
              ...this.form,
              lang: this.lang
            };
            if(id){
              params.id = id;
            }
            let {langRelReqId} = this.$route.query;
            if(langRelReqId){
              params.langRelReqId = langRelReqId;
            }
            this.$store.dispatch('saveDemand', params);
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>


