<template>
  <div>
    <h3 class="x-tc" v-show="istitle">{{cacheDemand.name}}</h3>
    <detail-bar :details="cacheDemand"></detail-bar>
    <detail-info :details="cacheDemand"></detail-info>
    <div class="pa-box-bg" v-if="cacheDemand.supportContent&&cacheDemand.dealContent">
      <div v-if="cacheDemand.supportContent" class="x-color666 x-f16 x-mb10">{{$t('stepTwo20')}}</div>
      <div v-if="cacheDemand.supportContent">{{cacheDemand.supportContent}}</div>
      <div v-if="cacheDemand.dealContent" class="x-color666 x-f16 x-mt20 x-mb10">{{$t('stepTwo19')}}</div>
      <div v-if="cacheDemand.dealContent">{{cacheDemand.dealContent}}</div>
    </div>
    <div class="d-row x-mt20">
      <div class="pa-row-t">
        <div class="d-row-t x-mb10">{{$t('typeA1')}}</div>
        <div class="d-row-c">{{cacheDemand.description}}
        </div>
      </div>
    </div>

    <div class="d-row" v-for="(item, index) in cacheDemand.systemLinkList||[]" :key="item.id">
      <div class="d-row-t x-mb10"><span class="x-color666">{{$t('typeE2')}}{{index+1}} </span>{{item.name}}</div>
      <div class="d-row-status x-green" :class="{ 'x-yellow': item.status !=='developed' }">{{ reStatusName(item.status)}}</div>
      <div class="d-row-c x-color666">{{item.content}}</div>
      <div class="d-row-child" :class="{ 'd-row-child-none': isDetail }" v-for="(jtem, jndex) in item.funcList||[]"
           :key="jtem.id">
        <div class="d-row-2">
          <div class="d-row-2-t x-mb10"><span class="d-color555">{{$t('typeC3')}}{{jndex+1}}</span>{{jtem.name}}<span class="demand-list-tag">{{reFuncName(jtem.type)}}</span></div>
          <div class="d-row-c">{{jtem.content}}</div>
        </div>
      </div>
    </div>

    <!--<div class="d-row">
      <div class="pa-row-t">
        <div class="d-row-t x-mb10">本需求涉及的服务环节主要场景为</div>
        <div class="d-row-c">{{cacheDemand.scenariosContent}}
        </div>
      </div>
    </div>-->

  </div>
</template>

<script>
  import DetailBar from '../Comm/DetailBar';
  import DetailInfo from "../Comm/DetailInfo";
  import MixinsBase from "../../../mixins/base";
  export default {
    props: {
      istitle: {
        type: Boolean,
        default: true
      }
    },
    components: {DetailInfo, DetailBar },
    mixins: [MixinsBase],
    computed: {
      cacheDemand(){
        return this.$store.getters.getCacheDemand;
      },
      funcListOpt(){
        return this.$store.getters.getFuncListOpt;
      },
      systemLinkListOpt(){
        return this.$store.getters.getSystemLinkListOpt;
      },
      isDetail(){
        return this.$route.name === 'DemandDetail';
      }
    },
    methods: {
      reFuncName(type){
        let oo = this.funcListOpt.filter(i=>{
          return type === i.id;
        });
        return this.lang==='en'?oo[0]['nameEn']:oo[0]['name'];
      },
      reStatusName(type){
        let oo = this.systemLinkListOpt.filter(i=>{
          return type === i.id;
        });
        return this.lang==='en'?oo[0]['nameEn']:oo[0]['name'];
      },
    }
  }
</script>

<style scoped>
  .pa-box-bg{margin-top:20px;padding:20px;background:#f8f8f8;}
  .d-row-child-none{display:none;}
</style>
