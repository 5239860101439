<template>
  <div class="x-main">
    <x-header></x-header>
    <div class="x-content x-mt20">
      <x-card style="width:100%;">
        <div style="width:100%;height:100%;display:flex;align-items:center;justify-content:center">
          <div>
            <img :src="lang==='en'?srcEn:src" alt="" />
            <div class="x-tc x-f18 x-mt20 x-bold">{{$t('uc1')}}</div>
          </div>
        </div>
      </x-card>
    </div>
  </div>
</template>

<script>

  import XCard from "../components/XCard";
  import XHeader from "../components/XHeader";
  import ImgUC from "../assets/images/img-underConstruction.svg";
  import ImgUCEn from "../assets/images/img-underConstruction-en.svg";
  import MixinsBase from "../mixins/base";
  export default {
    name: "UnderConstruction",
    components: {XCard,XHeader},
    mixins: [MixinsBase],
    data(){
      return {
        src: ImgUC,
        srcEn: ImgUCEn,
      }
    }
  }
</script>

<style scoped>

</style>
