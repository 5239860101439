<template><!--demand-list-default/默认 demand-list-push/带状态-->
  <div :class="`demand-list ${cls}`">
    <div style="position:absolute;right:0;top:0;">
      <slot name="operation"></slot>
      <slot name="collection"></slot>
    </div>
    <div @click="toPageBlank('/DemandDetail', { id: item.id, langRelReqId: item.langRelReqId, lang: item.lang, idEn: item.idEn, idZh: item.idZh, uid: userInfo.id, uname: userInfo.username })">
      <div class="demand-list-title">
        <span class="x-1"></span>
        <span :class="`x-2 x-${item.status}`">{{item.statusName}}</span>
        <h3 class="demand-h3">{{item.name}}</h3>

      </div>
      <div class="demand-list-info x-mt10 x-color666">
        <span class="x-flex-1 x-f12">{{$t('userinfo10')}}：{{item.regionName}}</span>
        <span class="x-flex-1 x-f12">{{$t('userinfo12')}}：{{item.industryPath&&item.industryPath.substr(0,20)}}
          <el-tooltip placement="top" v-show="item.industryPath&&item.industryPath.split(';').length>1">
            <div slot="content" style="white-space:pre-wrap;">{{item.industryPath&&item.industryPath.replace(/;/g, '\n')}}
            </div>
            <span class="x-tag">{{item.industryPath&&item.industryPath.split(';').length}}</span>
          </el-tooltip>
        </span>
        <span class="x-flex-1 x-f12" v-if="item.isNoDate">{{$t('demandMy15')}}：{{$t('detailbar1')}}</span>
        <span class="x-flex-1 x-f12" v-else>{{$t('demandMy15')}}：{{item.beginDate}} {{$t('detailbar2')}} {{item.endDate}}</span>
        <span class="x-flex-1 x-f12" v-if="item.isFaceBudget">{{$t('stepTwo5')}}：{{$t('detailbar3')}}</span>
        <span class="x-flex-1 x-f12" v-else>{{$t('stepTwo5')}}：{{item.beginBudget}} {{$t('detailbar2')}} {{item.endBudget}}{{$t('detailbar4')}}</span>
        <span class="x-flex-1 x-f12">{{$t('demandMy9')}}：{{reTypeName(item.type)}}</span>

        <!--<span class="x-flex-1 x-f12">{{$t('userinfo13')}}：
          <el-tooltip placement="top">
            <div slot="content" style="white-space:pre-wrap;">{{reTechField(item.techFieldList)}}
            </div>
            <span>{{reTechField(item.techFieldList, 40)}}</span>
          </el-tooltip>
        </span>-->

        <span class="x-flex-1 x-f12">{{$t('userinfo13')}}：{{reTechField(item.techFieldList, 20)}}
          <el-tooltip placement="top" v-show="item.techFieldList&&reTechField(item.techFieldList).split('，').length>1">
            <div slot="content" style="white-space:pre-wrap;">{{item.techFieldList&&reTechField(item.techFieldList).replace(/，/g, '\n')}}
            </div>
            <span class="x-tag">{{item.techFieldList&&reTechField(item.techFieldList).split('，').length}}</span>
          </el-tooltip>
        </span>

      </div>
      <div class="demand-list-text x-mt10 x-color666">
        {{item.description|interceptStr}}
      </div>
    </div>
  </div>
</template>

<script>
  import MixinsBase from '../../../mixins/base';
  export default {
    props: {
      item: {
        type: Object,
        default: ()=>{}
      },
      cls: {
        type: String,
        default: 'demand-list-default'
      }
    },
    mixins: [MixinsBase],
    computed: {
      typeObj(){
        return this.$store.getters.getTypeObj;
      }
    },
    methods: {
      reTypeName(type){
        return this.typeObj[type]&&this.typeObj[type][this.lang==='en'?'nameEn':'name'];
      },
      reTechField(techFieldList, num){
        if(_.isArray(techFieldList)){
          let str = techFieldList.map(i=>{
            return this.lang === 'en'? `${i.pathNameEn}-${i.nameEn}`: `${i.pathName}-${i.name}`
          }).join('，');
          if(num){
            return str.substr(0, num);
          }
          return str;
        }else{
          return '';
        }

      }
    }
  }
</script>

<style scoped>
.demand-list-info span{margin-right:20px;}
.demand-list-info span:before{margin-right:12px;}
.demand-list-info{padding:10px;background:#f7fafc;display:flex;flex-wrap:wrap;}
  .x-flex-1{width:30%;display:block;}
  .x-tag{width:20px;height:20px;line-height:20px;text-align:center;display:inline-block;background:#fff;border-radius:50%;border:1px solid #e8e8e8;box-sizing:border-box;}
  .demand-list-default .x-1{width:4px;height:12px;display:inline-block;background:#c9d6dc;margin-right:10px;}
.demand-list-default .x-2{display:none;}
  .demand-list-title{display:flex;align-items: center;}
.demand-h3{color:#333;}
  .demand-list-push .x-2{padding:4px 20px;color:#fff;border-radius:20px;margin-right:10px;display:inline-block;background:#f37920;}
.demand-list-push .x-published{background:#01a023;}
.demand-list-push .x-draft{background:#c9d6dc;}
.demand-list{position:relative;}
</style>
