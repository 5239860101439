import { render, staticRenderFns } from "./TypeC.vue?vue&type=template&id=ba4dd6ba&scoped=true&"
import script from "./TypeC.vue?vue&type=script&lang=js&"
export * from "./TypeC.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba4dd6ba",
  null
  
)

export default component.exports