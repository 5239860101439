<template>
  <el-breadcrumb separator-class="el-icon-arrow-right" class="x-breadcrumb">
    <el-breadcrumb-item v-for="(item,index) in breadcrumb" :key="index">
      <span :class="{ 'on': !!item.path }" @click="toPage(item.path)">{{item.no?item.lang:$t(item.lang)}}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
  export default {
    name: "XBreadcrumb",
    computed: {
      breadcrumb(){
        return this.$store.getters.getBreadcrumb;
      }
    },
    methods: {
      toPage(path){
        if(path){
          this.$router.push(path);
        }
      }
    }
  }
</script>

<style scoped>
.on{font-weight:bold;color:#0268a1;}
</style>
