<template>
  <x-card style="width:100%;padding:0;">
    <div>
      <el-input :rows="2" class="x-dq-area" style="padding-top: 16px;font-size: 20px;" :placeholder="$t('dquick1')" maxlength="70" type="textarea" v-model="txt"></el-input>
    </div>
    <div class="x-dq-box">
      <div style="margin-left:10px;">{{$t('dquick2')}}：</div>
      <el-popover
        placement="bottom"
        trigger="manual" v-model="manualVis">
        <step-one-map></step-one-map>
        <div class="x-tc">
          <el-button type="primary" @click="manualSure">{{$t('delete3')}}</el-button>
          <el-button @click="manualVis=false">{{$t('delete4')}}</el-button>
        </div>
        <div slot="reference" style="position:relative;">
          <div @click="manualVis=true" style="position:absolute;z-index:9;opacity:0;left:0;top:0;right:0;bottom:0;background:#000;"></div>
          <el-select v-model="type" :placeholder="$t('einfo13')">
            <el-option :key="key" :label="value.name"
                       :value="key" v-for="(value, key) in typeObj"></el-option>
          </el-select>
        </div>
      </el-popover>
      <el-radio :label="true" v-model="aa" style="margin-right:0;line-height:20px;"><span style="color:#333;">{{$t('dquick3')}}</span></el-radio>
      <div @click="visibleRead2=true" class="x-blue x-cursor" style="flex:1;">{{$t('dquick4')}}</div>
      <div @click="sendDemand" class="el-icon-s-promotion x-dq-submit x-blue">{{$t('dquick5')}}</div>
    </div>
    <!--保密协议-->
    <el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="visibleRead2" append-to-body>
      <read-two></read-two>
      <div class="x-tc x-mt20">
        <span @click="visibleRead2=false" class="x-btn">{{$t('dquick6')}}</span>
      </div>
    </el-dialog>
  </x-card>
</template>

<script>
  import XCard from "../../../components/XCard";
  import ReadTwo from "../../Login/ReadTwo";
  import MixinsBase from "../../../mixins/base";
  import StepOneMap from "../StepOneMap";
  import _ from 'lodash';
  export default {
    components: {StepOneMap, ReadTwo, XCard},
    mixins: [MixinsBase],
    data(){
      return {
        txt: '',
        type: '',
        aa: true,
        visibleRead2: false,
        manualVis: false
      }
    },
    computed: {
      typeObj(){
        return this.$store.getters.getTypeObj;
      },
      demandType(){
        return this.$store.getters.getCacheDemand.type;
      }
    },
    methods: {
      sendDemand(){
        if(_.trim(this.txt)===''){
          this.$message.error(this.$t('dquick7'));
        }else if(this.type===''){
          this.$message.error(this.$t('dquick8'));
        }else{
          this.$store.dispatch('setStep', 1);
          this.$store.dispatch('setCacheDemand', {
            type: this.type,
            name: this.txt
          }).then(()=>{
            this.toPage('/DemandRelease');
          })
        }
      },
      manualSure(){
        this.manualVis = false;
        this.type = this.demandType;
      }
    }
  }
</script>

<style scoped>
.x-dq-box{width:100%;height:42px;display:flex;align-items:center;background:#f6fcff;border-bottom-left-radius:20px;}
  .x-dq-submit{height:42px;line-height:42px;padding:0 20px;background:#e6f6ff;border-bottom-right-radius: 20px;border:1px solid #cfe8e6;box-sizing: border-box;}
</style>
<style>
  .x-dq-area textarea{border-top-left-radius:20px;border-top-right-radius: 20px;border:none;}
  .x-dq-area .el-radio{margin-right:0;}
  .x-dq-box .el-select{margin-right:20px;}
  .x-dq-box .el-select .el-input__inner{height:42px;line-height:42px;border:none;box-sizing:border-box;background:#f6fcff;}
  .x-dq-box .el-select .el-input__inner::-webkit-input-placeholder{color:#333;font-size:14px;}
  .x-dq-area .el-textarea__inner::-webkit-input-placeholder{font-size:20px!important;}
</style>
