import $axios from '@/axios/index'

//获取用户头衔信息
export const apiGetExpertOrg = (params)=>{
  return $axios.get('/cbmp/rest/ExpertOrg/list', {params});
}
export const apiPostExpertOrg = (params)=>{
  return $axios.post('/cbmp/rest/ExpertOrg', params);
}

export default {
  apiGetExpertOrg,
  apiPostExpertOrg
}
