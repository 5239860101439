<template>
  <x-card style="padding:0;width:100%;">
    <release-step></release-step>
    <div style="padding:20px;">
      <component :is="currentView"></component>
    </div>
  </x-card>
</template>

<script>
  import XCard from "../../components/XCard";
  import ReleaseStep from "./ReleaseStep";
  import TypeA from "./DemandType/TypeA";
  import TypeB from "./DemandType/TypeB";
  import TypeC from "./DemandType/TypeC";
  import TypeD from "./DemandType/TypeD";
  import TypeE from "./DemandType/TypeE";
  import TypeF from "./DemandType/TypeF";
  export default {
    components: {TypeA, TypeB, TypeC, TypeD, TypeE, TypeF, XCard, ReleaseStep},
    computed: {
      currentView(){
        let { type } = this.$store.getters.getCacheDemand;
        return `Type${type[0]}`;
      }
    },
  }
</script>

<style scoped>

</style>
