<template>
  <el-menu
    :default-openeds="['1']"
    class="index-menu"
    default-active="1"
    router>
    <template v-for="item in menuDataF">
      <el-submenu v-if="item.children" :key="item.id" :index="item.id">
        <template slot="title">
          <span>{{lang==='en'?item.nameEn:item.name}}</span>
        </template>
        <template v-for="jtem in item.children">
          <el-menu-item :index="jtem.id" :key="jtem.id" :route="{ path: `${lang==='en'?'/en':''}/${jtem.url?jtem.url:'UnderConstruction'}`}">
            <span class="icon icim" :class="jtem.icon"></span>
            {{lang==='en'?jtem.nameEn:jtem.name}}
          </el-menu-item>
        </template>
      </el-submenu>
      <el-menu-item :index="item.id" :key="item.id" :route="{ path: `${lang==='en'?'/en':''}/${item.url?item.url:'UnderConstruction'}`}" v-else>
        <span slot="title">{{lang==='en'?item.nameEn:item.name}}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
  import MixinsBase from "../mixins/base";
  export default {
    name: "IndexMenu",
    props: {
      menuData: {
        type: Array,
        defualt: ()=>[]
      }
    },
    mixins: [MixinsBase],
    computed: {
      menuDataF(){
        let {nature, roleNames} = this.userInfo;
        let data = [];
        _.each(this.menuData, i=>{
          if(i.menuRole.indexOf(nature)<0||!i.status){
            return true;
          }
          let pa = {
            ...i,
            children: []
          };
          _.each(i.children, j=>{
            if((j.menuRole.indexOf(nature)<0||!j.status)&&!(j.sysRole&&roleNames.includes(j.sysRole))){
              return true;
            }

            pa.children.push(j);
          })
          data.push(pa);
        })
        return data;
      }
    },
  }
</script>

<style>
  .x-fr {
    float: right;
  }

  .index-menu {
    border: none;
  }

  .index-menu li {
    position: relative;
  }

  .index-menu .el-submenu__title,.index-menu > .el-menu-item {
    height: 40px;
    line-height: 40px;
    padding: 0 10px !important;
    color: #999;
  }

  .index-menu .el-submenu .el-menu-item {
    padding: 0 20px !important;
    height: 30px;
    line-height: 30px;
  }

  .index-menu .el-submenu:before,.index-menu > .el-menu-item:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 10px;
    left: 0;
    top: 16px;
    background: #c9d6dc;
  }

  .index-menu .icim {
    font-size: 12px;
  }
</style>
