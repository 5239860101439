<template>
	<div class="x-search-box">
		<el-form :inline="true" :model="form" ref="form" label-width="100px">
			<el-form-item v-for="item in $props.data" :prop="item.id" :label="item.type === 'singlechecked' ? '' : item.name" :key="item.id" v-show="item.ctrl || ismore">
				<!--日期-->
				<el-date-picker
					clearable
					v-if="item.type === 'date'"
					v-model="odate[item.id]"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="setDate(item.id, item.datakey)"
				></el-date-picker>
				<!--日期年月-->
				<el-date-picker clearable v-else-if="item.type === 'month'" v-model="form[item.id]" type="month" placeholder="选择月" value-format="yyyy-MM"></el-date-picker>
				<!--下拉-->
				<el-select clearable v-model="form[item.id]" :placeholder="'请选择' + item.name" v-else-if="item.type === 'select'">
					<el-option v-for="record in item.data" :label="record.label" :value="record.value" :key="record.value" :checked="record.checked"></el-option>
				</el-select>
				<!--下拉字典-->
				<el-select
					clearable
					v-model="form[item.id]"
					:placeholder="'请选择' + item.name"
					v-else-if="item.type === 'vselect'"
					@visible-change="setSelectData($event, item.id, item.name, item.url)"
				>
					<el-option v-for="record in dictData[item.id]" :label="record.ext" :value="record[item.key]" :key="record.id"></el-option>
				</el-select>
				<!--下拉lazy-->
				<select-lazy
					:formkey="item.id"
					v-else-if="item.type === 'selectLazy'"
					:func="item.func"
					:label="item.label"
					:value="item.value"
					@setValue="setSelectLazy"
				></select-lazy>
				<!--级联-->
				<el-cascader
					v-model="form[item.id]"
					v-else-if="item.type === 'cascader'"
					:options="item.data"
					:props="{ checkStrictly: item.checkStrictly }"
					clearable
				></el-cascader>
				<el-cascader
					v-model="form[item.id]"
					:props="{ checkStrictly: true }"
					v-else-if="item.type === 'vcascader'"
					:options="dictData[item.id]"
					@visible-change="setSelectData($event, item.id, item.name)"
					clearable
				></el-cascader>
				<!--级联lazy-->
				<!--<Cascader ref="cascaderLazy" :formkey="item.id" v-else-if="item.type === 'cascaderLazy'" @value="aaa"></Cascader>-->
				<template v-else-if="item.type === 'cascaderLazy'">
					<el-input @focus="showCascader" v-model="form[item.id]" placeholder="请选择"></el-input>
					<Cascader :formkey="item.id" ref="refCascader" v-on:ok="okCascader"></Cascader>
				</template>
				<!--单复选框-->
				<el-checkbox v-model="form[item.id]" v-else-if="item.type === 'singlechecked'">{{ item.name }}</el-checkbox>
				<!--输入-->
				<el-input clearable :placeholder="'请输入' + (item.placeholder ? item.placeholder : item.name)" v-model="form[item.id]" v-else></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit" plain>查询</el-button>
				<el-button @click="onReset">重置</el-button>
				<template v-if="isShow">
					<el-button v-show="!ismore" type="text" @click="toggleOption">
						更多筛选
						<el-icon class="el-icon-arrow-down"></el-icon>
					</el-button>
					<el-button v-show="ismore" type="text" @click="toggleOption">
						收起选项
						<el-icon class="el-icon-arrow-up"></el-icon>
					</el-button>
				</template>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
/*
 * ismore 组件/控制更多选项
 * --
 * ctrl   数据/是否默认显示
 * id     数据/查询字段名
 * name   数据/显示字段名称
 * type   数据/类型 默认文本框，date-日期，month-年月，select-下拉框，vselect-点击时查询选项的下拉框，cascader-级联，singlechecked-单复选框
 * data   数据/默认的数据选项，下拉框，级联等
 *
 * */
import Cascader from './Cascader';
import SelectLazy from './SelectLazy';

import _ from 'lodash';
export default {
	name: 'Search',
	emit: ['searchFun', 'refreshTable'],
	props: ['data', 'isShow'],
	components: {
		Cascader,
		SelectLazy
	},
	data() {
		return {
			form: {},
			odate: {},
			ismore: false,
			dictData: {}
		};
	},
	computed: {
		baseUrl() {
			return this.$store.state.baseUrl;
		}
	},
	created() {
		let a = {};
		_.each(this.data, i => {
			a[i.id] = i.value || '';
		});
		this.form = a;
		if (this._events.refreshTable) {
			this.$emit('refreshTable', a);
		}
	},
	methods: {
		toggleOption() {
			this.ismore = !this.ismore;
		},
		setSelectLazy(v, item, formkey) {
			//console.log(v, item, formkey);
			this.form[formkey] = v;
		},
		reParams() {
			let params = JSON.parse(JSON.stringify(this.form));
			params.currPage = 1;
			_.each(params, (i, n) => {
				if (_.isArray(i)) {
					params[n] = i.join('|');
				}
			});
			delete params.xdate1;
			delete params.xdate2;
			return params;
		},
		onSubmit() {
			let params = this.reParams();
			//console.log('params', params);
			this.$emit('searchFun', params);
		},
		onReset() {
			let resetParams = {};
			_.each(this.form, (value, key) => {
				resetParams[key] = '';
			});
			this.$refs.form.resetFields();
			this.odate = {};
			this.$refs.cascaderLazy && this.$refs.cascaderLazy[0].clear();
			this.$emit('searchFun', resetParams);
		},
		re(date, s) {
			if (!date) {
				return undefined;
			}
			let y = date.getFullYear(),
				m = ('00' + (date.getMonth() + 1)).slice(-2),
				d = ('00' + date.getDate()).slice(-2);
			let sdate = y + '-' + m + '-' + d;
			if (s) {
				sdate = sdate + ' ' + s;
			}
			return sdate;
		},
		setMonth(key, dateKey) {
			let dateArr = this.odate[key];
			console.log(key, dateKey, dateArr);
		},
		setDate(key, dateKey) {
			let dateArr = this.odate[key];
			let s1 = '',
				s2 = '';
			if (!dateArr) {
				this.form[key] = undefined;
				if (dateKey) {
					this.form[dateKey[0]] = undefined;
					this.form[dateKey[1]] = undefined;
				}
			} else {
				s1 = this.re(dateArr[0], '00:00:00');
				s2 = this.re(dateArr[1], '23:59:59');
				this.form[key] = s1 + '~' + s2;
				if (dateKey) {
					this.form[dateKey[0]] = s1;
					this.form[dateKey[1]] = s2;
				}
			}
		},
		aaa(v, k) {
			//console.log(v, k);
			this.form[k] = v.join('/');
		},
		okCascader(data) {
			//console.log(data.code, data.nameCn);
			let key = this.$refs.refCascader[0].formkey;
			//this.form[key] = "";
			this.form[key] = data.nameCn;
		},
		showCascader(e) {
			let x = e.target.getClientRects()[0].x,
				y = e.target.getClientRects()[0].y;
			//console.log(x,y);
			this.$refs.refCascader[0] && this.$refs.refCascader[0].show(x, y);
		},
		setForm(key, value) {
			this.form[key] = value;
		},
		setSelectData(v, key, name, url) {
			//console.log('setSelectData', v);
			if (!this[key + 'Dict']) {
				this.getDict(key, name);
			} else {
				this[key + 'Dict'](key, name, url);
			}
			/*if (v && !this.dictData[key]) {
				this[key + 'Dict'](key, name, url);
			}*/
		},
		// getDict(key, name) {
		// 	apiGetDict({
		// 		name: name,
		// 		enable: true
		// 	}).then(res => {
		// 		this.dictData = {
		// 			...this.dictData,
		// 			[key]: res.result
		// 		};
		// 	});
		// }
	}
};
</script>

<style scoped>
.x-search-box {
	padding: 10px 10px 0 10px;
}
.x-search-box .el-form--inline .el-form-item {
	margin-bottom: 10px;
}
</style>
