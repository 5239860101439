<template>
  <div>
    <x-card style="width:100%;" v-show="activityData.length>0">
      <index-carousel :listData="activityData"></index-carousel>
    </x-card>
    <x-card style="width:100%;">
      <div class="x-f16 x-bold">{{$t('zhuyeshuoming1')}}</div>
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('tab1')" name="first">
          <div class="b">
            <div class="b-l">{{$t('zhuyeshuoming3')}}</div>
            <div class="b-r">
              <img :src="lang==='en'?src3en:src3" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('tab2')" name="second">
          <div class="b">
            <div class="b-l">{{$t('zhuyeshuoming5')}}
            </div>
            <div class="b-r">
              <img :src="lang==='en'?src4en:src4" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('tab3')" name="third">
          <div class="b">
            <div class="b-l">{{$t('zhuyeshuoming7')}}
            </div>
            <div class="b-r">
              <img :src="lang==='en'?src5en:src5" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('tab4')" name="fourth">
          <div class="b">
            <div class="b-l">{{$t('zhuyeshuoming9')}}
            </div>
            <div class="b-r">
              <img :src="lang==='en'?src6en:src6" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('tab5')" name="five">
          <div class="b">
            <div class="b-l">{{$t('zhuyeshuoming11')}}
            </div>
            <div class="b-r">
              <img :src="lang==='en'?src7en:src7" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('tab6')" name="six">
          <div class="b">
            <div class="b-l">{{$t('zhuyeshuoming13')}}
            </div>
            <div class="b-r">
              <img :src="lang==='en'?src8en:src8" alt="" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </x-card>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" alt="" :src="src9" />
    </el-dialog>
  </div>
</template>

<script>
  import XCard from "../components/XCard";
  import Img1 from "../assets/images/img-index-1.png";
  import Img3 from "../assets/images/img-index-3.png";
  import Img3en from "../assets/images/img-index-3en.png";
  import Img4 from "../assets/images/img-index-4.png";
  import Img4en from "../assets/images/img-index-4en.png";
  import Img5 from "../assets/images/img-index-5.png";
  import Img5en from "../assets/images/img-index-5en.png";
  import Img6 from "../assets/images/img-index-6.png";
  import Img6en from "../assets/images/img-index-6en.png";
  import Img7 from "../assets/images/img-index-7.png";
  import Img7en from "../assets/images/img-index-7en.png";
  import Img8 from "../assets/images/img-index-8.png";
  import Img8en from "../assets/images/img-index-8en.png";
  import Img9 from "../assets/images/event-poster-3.jpeg";
  import MixinsBase from "../mixins/base";
  import {apiPutAppUser} from "../api";
  import {apiGetActivity} from "../api/Activities";
  import IndexCarousel from "./IndexCarousel";
  export default {
    name: "IndexCenter",
    components: {IndexCarousel, XCard},
    mixins: [MixinsBase],
    data(){
      return {
        src1: Img1,
        src3: Img3,
        src3en: Img3en,
        src4: Img4,
        src4en: Img4en,
        src5: Img5,
        src5en: Img5en,
        src6: Img6,
        src6en: Img6en,
        src7: Img7,
        src7en: Img7en,
        src8: Img8,
        src8en: Img8en,
        src9: Img9,
        activeName: 'first',
        dialogVisible: false,
        activityData: []
      }
    },
    mounted() {
      this.getActivity();
    },
    methods: {
      getActivity(){
        apiGetActivity({
          disabled: false,
          draft: false
        }).then(res=>{
          if(res.success){
            this.activityData = res.result;
          }
        })
      },
      toTarget(){
        //window.open('https://icim.glueup.cn/event/37737/');
        let params = {
          id: this.userInfo.id,
          profile: 'yes'
        };
        apiPutAppUser(params).then(res=>{
          if(res.success){
            this.$store.dispatch('setUserInfo');
          }
        })
      }
    }
  }
</script>

<style scoped>
  .event-title{
    display: flex;
    align-items: center;
  }
.b{display:flex;}
  .b-l{flex:1;}
  .b-r{width:440px;margin-left:40px;}
.x-btn{
  font-size: 14px;
  padding: 10px 15px;
}
  .el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{
    color: rgba(2, 164, 36, 0.4);
    border-color: rgba(2, 164, 36, 0.4);
  }
</style>
