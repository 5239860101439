<template>
  <div class="x-main">
    <x-header></x-header>
    <x-breadcrumb></x-breadcrumb>
    <div class="x-content">
      <div class="x-left x-mr20">
        <x-card>
          <ul class="x-demand-left">
            <li @click="toPage('/UserInfoSet')">{{$t('baseInfoSet')}}</li>
            <li class="x-blue">{{$t('AccountSettings')}}</li>
          </ul>
        </x-card>
      </div>
      <div class="x-center">
        <x-card width="100%">
          <el-form ref="form" :model="form" :rules="rules" :label-width="labelWidth" style="width:400px;">
            <el-form-item :label="$t('userpwd1')">
              {{userInfo.email}}
            </el-form-item>
            <el-form-item :label="$t('userpwd2')">
              {{userInfo.area}}
            </el-form-item>
            <el-form-item :label="$t('userinfo1')"></el-form-item>
            <el-form-item :label="$t('userpwd3')" prop="srcPassord">
              <el-input type="password" v-model="form.srcPassord" :placeholder="$t('userpwd4')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('userpwd5')" prop="newPassword">
              <el-input type="password" v-model="form.newPassword" :placeholder="$t('userpwd6')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('userpwd7')" prop="newPassword2">
              <el-input type="password" v-model="form.newPassword2" :placeholder="$t('userpwd8')"></el-input>
            </el-form-item>
          </el-form>
          <el-button class="x-btn x-btn-save" :style="`margin-left:${labelWidth};`" @click="btnSave" :loading="btnLoading">{{$t('resetPwd4')}}</el-button>
        </x-card>

      </div>
    </div>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import XBreadcrumb from "../../components/XBreadcrumb";
  import XCard from "../../components/XCard";
  import { apiChangePassword } from '../../api';
  import { JSEncrypt } from "jsencrypt";
  import MixinsBase from '../../mixins/base';
  export default {
    components: {
      XCard,
      XBreadcrumb,
      XHeader,
    },
    mixins: [MixinsBase],
    data () {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.form.newPassword2 !== '') {
            this.$refs.form.validateField('newPassword2');
          }
          callback();
        }
      };
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.form.newPassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        form: {
          srcPassord: '',
          newPassword: '',
          newPassword2: ''
        },
        labelWidth: '160px',
        rules: {
          srcPassord: [
            { required: true, message: this.$t('userpwd4'), trigger: 'blur' },
            /*{ min: 8, message: '请输入至少8位密码', trigger: 'blur' }*/
            ],
          newPassword: [
            { required: true, message: this.$t('userpwd6'), trigger: 'blur' },
            { validator: validatePass, trigger: 'blur' },
            /*{ min: 8, message: '请输入至少8位密码', trigger: 'blur' }*/
          ],
          newPassword2: [
            { required: true, message: this.$t('userpwd8'), trigger: 'blur' },
            { validator: validatePass2, trigger: 'blur' },
            /*{ min: 8, message: '请输入至少8位密码', trigger: 'blur' }*/
          ]
        }
      }
    },
    computed: {
      btnLoading(){
        return this.$store.getters.getBtnLoading;
      },
      userInfo(){
        return this.$store.getters.getUserInfo;
      },
      publicKey(){
        return this.$store.getters.getPublicKey;
      },
    },
    methods: {
      encryptedData(data) {
        let encryptor = new JSEncrypt();
        encryptor.setPublicKey(this.publicKey);
        return encryptor.encrypt(data);
      },
      btnSave(){
        this.$refs.form.validate(valid=>{
          if(valid){
            let { srcPassord, newPassword } = this.form;
            let p1 = this.encryptedData(srcPassord);
            let p2 = this.encryptedData(newPassword);
            let fd = new FormData();
            fd.append('srcPassord', p1);
            fd.append('newPassword', p2);
            apiChangePassword(fd).then(res=>{
              if(res.success){
                //this.$refs.form.resetFields();
                this.$router.push('/SignIn');
              }
            });
          }
        })
      }
    }
  }
</script>

<style scoped>
.x-demand-left li{margin-bottom:20px;cursor:pointer;}
.x-demand-left li:last-child{margin-bottom:0;}

</style>
