import {mapGetters} from 'vuex';
export default {
  computed: {
    ...mapGetters({
      lang: 'getLanguage',
      userInfo: 'getUserInfo',
    })
  },
  watch: {
    lang(){
      //this.$router.go(0);
      this.init&&this.init();
    }
  },
  methods: {
    toPage(path='', query={}, lang=false){
      //console.log(path, query);
      this.$router.push({
        path: this.lang === 'en'&&!lang?`/en${path}`:path,
        query
      });
    },
    toPageBlank(path='', query={}, lang=false){
      const routeUrl = this.$router.resolve({
        path: this.lang === 'en'&&!lang?`/en${path}`:path,
        query,
      });
      window.open(routeUrl.href, "_blank");
    },
    setLanguage (command) {
      let { name, query } = this.$route;
      let newName = '';
      if(command === 'zh'){
        newName = name.substr(2);
      }
      if(command === 'en'){
        newName = `EN${name}`;
      }
      this.$router.push({ name: newName, query });
    }
  }
}
