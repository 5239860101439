<template>
  <div>
    <template v-for="item in dataSource">
      <div class="x-hd" :key="item.id">
        <!--<div class="x-hd-1">
          <el-checkbox></el-checkbox>
        </div>-->
        <!--<div class="x-hd-2">
          <span class="x-read-true">已读</span>
        </div>-->
        <div class="x-hd-3">
          <div style="display:flex;">
            <div style="width:40px;color:#ccc;">回复</div>
            <div>{{item.content}}</div>
          </div>
          <div style="padding-left:40px;">
            <span class="x-fr">{{item.createDate|formatDate('datetime')}}</span>
            <el-button type="text" @click="nameClick(item)">{{item.fullname}}</el-button>
            <span class="x-ml20">对接专家</span>
            <span class="x-ml20">{{item.mobile}}</span>
            <span class="x-ml20">{{item.email}}</span>
            <el-button type="text" class="x-ml20" @click="sendBefore(item)">发消息</el-button>
          </div>
        </div>
        <div class="x-hd-4 x-red" @click="del(item.id)">
          删除
        </div>
      </div>
      <for-message v-if="item.children" :data-source="item.children"></for-message>
    </template>
    <!--发送通知-->
    <send-message ref="sendMessage"></send-message>
  </div>
</template>

<script>

  import SendMessage from "../../MessageManage/comm/SendMessage";
  export default {
    name: "ForMessage",
    components: {SendMessage},
    props: {
      dataSource: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      del(id){
        this.$emit('refresh', id);
      },
      sendBefore(item){
        this.$refs.sendMessage.open(item);
      },
      nameClick(item){
        let name = item.nature==='expert'?'ExpertInfo':'EnterpriseInfo'
        this.$router.push({ name, query: { id:item.userId }})
      }
    }
  }
</script>

<style scoped>

</style>
