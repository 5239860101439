<template>
  <!--发送通知-->
  <dialog-form ref="msgDialog" @confirm="msgConfirm" @resetForm="restMsgForm">
    <el-form ref="msgForm" :model="msgForm" :rules="msgRules" label-width="120px">
      <el-form-item label="通知标题" prop="title">
        <el-input v-model="msgForm.title"></el-input>
      </el-form-item>
      <el-form-item label="通知内容" prop="content">
        <el-input type="textarea" :rows="5" v-model="msgForm.content"></el-input>
      </el-form-item>
      <el-form-item label="通知范围">
        {{msgItem.fullname}}  {{msgItem.mobile||msgItem.email}}
      </el-form-item>
      <el-form-item label="" prop="mode">
        <el-checkbox-group v-model="msgForm.mode">
          <el-checkbox label="inside">站内通知</el-checkbox>
          <el-checkbox label="email">邮件通知</el-checkbox>
          <!--<el-checkbox label="sms">短信通知</el-checkbox>-->
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="通知附件" prop="attachmentCode">
        <upload-file v-model="msgForm.attachmentCode" @setAttachment="setAttachment"></upload-file>
      </el-form-item>
    </el-form>
  </dialog-form>
</template>

<script>
  import UploadFile from "../../../../components/comm/UploadFile";
  import DialogForm from "../../../../components/comm/DialogForm";
  import { apiNotice } from '@/api/RequirementManage';
  export default {
    components: {
      UploadFile,
      DialogForm
    },
    data(){
      return {
        msgForm: {
          title: '',
          userId: '',
          username: '',
          mobile: '',
          content: '',
          mode: ['inside'],
          attachmentCode: '',
          setAttachment: ''
        },
        msgRules: {
          title: { required: true, message: '请输入', trigger: 'blur' },
          content: { required: true, message: '请输入', trigger: 'blur' },
          mode: { required: true, message: '请选择', trigger: 'change' },
        },
        msgItem: {}
      }
    },
    methods: {
      msgConfirm(){
        this.$refs.msgForm.validate((valid)=>{
          if(valid){
            let params = {
              ...this.msgForm,
              mode: this.msgForm.mode.join(','),
              userId: this.msgItem.userId||this.msgItem.user_id,
              username: this.msgItem.fullname,
            };
            apiNotice(params).then(res=>{
              if(res.success){
                this.$refs.msgDialog.closeDialog();
              }
            })
          }
        });
      },
      setAttachment(name){
        this.$set(this.msgForm, 'attachment', name);
      },
      restMsgForm(){
        this.$refs.msgForm.resetFields();
      },
      open(item){
        this.msgItem = item;
        this.$refs.msgDialog.openDialog();
      }
    }
  }
</script>

<style scoped>

</style>
