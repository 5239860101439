<template>
  <div class="x-main">
    <x-header></x-header>
    <div class="x-content x-mt20">
      <div class="x-left x-mr20">
        <index-left></index-left>
      </div>
      <x-card style="width:100%;padding:0;">
        <div class="x-notice-t">{{$t('notice1')}}</div>
        <div class="x-notice-box">
          <ul class="x-notice-l">
            <li :class="{ 'x-blue': leftIndex===0 }" @click="leftIndex=0">{{$t('notice2')}}</li>
            <li :class="{ 'x-blue': leftIndex===1 }" @click="leftIndex=1">{{$t('notice3')}}</li>
            <li :class="{ 'x-blue': leftIndex===2 }" @click="leftIndex=2">{{$t('notice4')}}</li>
          </ul>
          <div class="x-notice-r">
            <div class="x-notice-tab">
              <span :class="{ 'x-curr': topIndex===0 }" @click="topIndex=0">{{$t('notice5')}}</span>
              <span :class="{ 'x-curr': topIndex===1 }" @click="topIndex=1">{{$t('notice6')}}</span>
              <span :class="{ 'x-curr': topIndex===2 }" @click="topIndex=2">{{$t('notice7')}}</span>
              <span :class="{ 'x-curr': topIndex===3 }" @click="topIndex=3">{{$t('notice8')}}</span>
            </div>
            <div class="x-notice-con">
              <div class="x-tr">
                <el-input style="width:200px;" v-model="queryStr">
                  <el-button slot="append" @click="refresh">{{$t('notice9')}}</el-button>
                </el-input>
              </div>
              <el-checkbox-group v-model="checkedArr" @change="handleChecked">
                <ul class="x-notice-list">
                  <li v-for="item in listData" :key="item.id">
                    <div>
                      <el-checkbox :label="item.id"></el-checkbox>
                      <span class="x-circle" v-show="!item.isViewed"></span>
                      <span class="x-f14" @click="toDetails(item)">{{item.title}}</span>
                    </div>
                    <div class="x-f12">{{item.create_date|formatDate}}</div>
                  </li>
                </ul>
              </el-checkbox-group>
              <div class="x-notice-fun x-mt20">
                <div>
                  <el-checkbox v-model="isAll"></el-checkbox>
                  <el-button class="x-ml10" @click="del">{{$t('notice10')}}</el-button>
                  <el-button v-show="leftIndex!==2" @click="read">{{$t('notice11')}}</el-button>
                  <!--<el-button v-show="leftIndex!==2" @click="readAll">全部已读</el-button>
                  <el-button @click="delAll">全部删除</el-button>-->
                </div>
                <div>
                  <x-pagination :total="total" :currpage="currPage" @pageChange="pageChange"></x-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </x-card>
    </div>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import IndexLeft from "../IndexLeft";
  import XCard from "../../components/XCard";
  import {apiGetNotice, apiNotice} from "../../api/Notice";
  import XPagination from "../../components/comm/XPagination";
  import MixinsBase from "../../mixins/base";
  export default {
    components: {
      XPagination,
      XHeader,
      IndexLeft,
      XCard
    },
    mixins: [MixinsBase],
    data(){
      return {
        leftIndex: 0,
        topIndex: 0,
        //null全部消息,inside("站内信息"), invite("邀请信息"), interact("互动信息")
        categoryType: [null, 'inside', 'invite', 'interact'],
        //true已读，否则未读
        isViewed: [null, false, true],
        queryStr: '',
        listData: [],
        isAll: false,
        checkedArr: [],
        checkedAllArr: [],
        currPage: 1,
        pageSize: 10,
        total: 1
      }
    },
    watch: {
      leftIndex(){
        this.currPage = 1;
        this.refresh();
      },
      topIndex(){
        this.currPage = 1;
        this.refresh();
      },
      isAll(val){
        if(val){
          this.checkedArr = this.checkedAllArr;
        }else if(this.checkedArr.length === this.checkedAllArr.length){
          this.checkedArr = [];
        }else{
          //
        }
      }
    },
    mounted() {
      this.getTableData();
    },
    methods: {
      handleChecked(value){
        this.isAll = value.length === this.checkedAllArr.length;
      },
      getTableData(params={}, pages={
        currPage: this.currPage,
        pageSize: this.pageSize
      }){
        apiGetNotice({
          ...pages,
          ...params
        }).then(res=>{
          if(res.success){
            this.listData = res.result;
            this.total = res.size;
            this.checkedAllArr = res.result.map(i=>{
              return i.id;
            })
          }
        });
      },
      pageChange(pages){
        this.currPage = pages.currPage;
        this.$nextTick(()=>{
          this.refresh();
        })
      },
      refresh(){
        let categoryType = this.categoryType[this.topIndex];
        let isViewed = this.isViewed[this.leftIndex];
        let queryStr = this.queryStr;
        this.checkedArr = [];
        this.getTableData({
          categoryType,
          isViewed,
          queryStr
        });
      },
      readAxios(params={}){
        apiNotice(params).then(res=>{
          if(res.success){
            this.refresh();
            this.$store.dispatch('setNoticeSize');
            this.checkedArr = [];
            this.isAll = false;
          }
        })
      },
      read(){
        let params = {
          ids: this.checkedArr.join(','),
          actionType: 'read'
        }
        this.readAxios(params);
      },
      del(){
        let params = {
          ids: this.checkedArr.join(','),
          actionType: 'delete'
        }
        this.readAxios(params);
      },
      toDetails(item){
        this.readAxios({
          ids: `${item.id}`,
          actionType: 'read'
        })
        this.$store.dispatch('setDetails', item);
        this.toPage('/NoticeDetail', { id: item.id })
      }
    }
  }
</script>

<style scoped>
.x-notice-t{
  padding:20px;background:#F9FBFC;
}
  .x-notice-box{
    padding:20px;display:flex;
  }
  .x-notice-l{
    width:90px;padding:40px 0;background:#F5FAFF;text-align:center;
  }
  .x-notice-l li{
    padding:10px 0;
  }
  .x-notice-r{
    flex:1;padding:0 20px;
  }
  .x-notice-tab{
    border-bottom:2px solid #F0F9FF;
  }
  .x-notice-tab span{
    display:inline-block;padding-bottom:10px;position:relative;margin-right:20px;
  }
  .x-notice-tab span:after{
    content:'';position:absolute;left:0;bottom:-2px;right:0;height:2px;
  }
.x-notice-tab .x-curr{
  color:#5FA1C6;
}
  .x-notice-tab .x-curr:after{
    background:#5FA1C6;
  }
  .x-notice-con{
    padding-top:10px;
  }
  .x-notice-list{
    min-height:400px;
  }
  .x-notice-list li{
    display:flex;padding:10px 0;justify-content: space-between;border-bottom:1px solid #EDF2F4;
  }
  .x-circle{
    width:10px;height:10px;margin-left:10px;display:inline-block;border-radius:50%;background:#FF7C8F;
  }
  .x-notice-fun{
    display:flex;padding:10px;border:1px solid #E8EEF1;justify-content: space-between;align-items: center;
  }
</style>
<style>
  .x-notice-list .el-checkbox__label{
    display:none;
  }
</style>
