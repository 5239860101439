<template>
  <div class="di-box">
    <div class="di-col">
      <div class="x-color666 x-f16 x-mb10">{{$t('stepTwo15')}}</div>
      <div>{{rePartnerTypeName(details.partnerType)}}</div>
    </div>
    <div class="di-col">
      <div class="x-color666 x-f16 x-mb10">{{$t('stepTwo16')}}</div>
      <div>{{rePartnerRegionTypeName(details.partnerRegionType)}}</div>
    </div>
    <div class="di-col">
      <div class="x-color666 x-f16 x-mb10">{{$t('stepTwo12')}}</div>
      <div>{{reBool(details.hasOwnership)}}</div>
    </div>
    <div class="di-col">
      <div class="x-color666 x-f16 x-mb10">{{$t('stepTwo13')}}</div>
      <div>{{reBool(details.isAllowVisit)}}</div>
    </div>
    <div class="di-col" style="width:100%;">
      <div class="x-color666 x-f16 x-mb10">{{$t('stepTwo14')}}</div>
      <div>{{reBool(details.isTechFreeze)}}</div>
    </div>
    <div class="di-col" v-if="isDetails">
      <div class="x-color666 x-f16 x-mb10">{{$t('stepTwo18')}}</div>
      <div>{{reNames(details.avoidExpert)}}</div>
    </div>
    <div class="di-col" v-if="isDetails">
      <div class="x-color666 x-f16 x-mb10">{{$t('stepTwo17')}}</div>
      <div>{{reNames(details.avoidEnterprise)}}</div>
    </div>
  </div>
</template>

<script>
  import _ from "lodash";
  import MixinsBase from '../../../mixins/base';
  export default {
    props: {
      details: {
        type: Object,
        default: ()=> {}
      }
    },
    mixins: [MixinsBase],
    computed: {
      partnerTypeOpt(){
        return this.$store.getters.getPartnerTypeOpt;
      },
      partnerRegionTypeOpt(){
        return this.$store.getters.getPartnerRegionTypeOpt;
      },
      isDetails(){
        return !(this.$route.name === 'DemandDetail'||this.$route.name === 'ENDemandDetail');
      }
    },
    methods: {
      rePartnerRegionTypeName(id){
        let oo = this.partnerRegionTypeOpt.filter(i=>{
          return id === i.id;
        });
        if(_.isEmpty(oo)){
          return '--';
        }else{
          return this.lang==='en'?oo[0]['nameEn']:oo[0]['name'];
        }
      },
      rePartnerTypeName(id){
        let oo = this.partnerTypeOpt.filter(i=>{
          return id === i.id;
        });
        if(_.isEmpty(oo)){
          return '--';
        }else{
          return this.lang==='en'?oo[0]['nameEn']:oo[0]['name'];
        }
      },
      reBool(val){
        if(val === true){
          return this.$t('true');
        }else if(val===false){
          return this.$t('false');
        }else{
          return '--';
        }
      },
      reNames(arr){
        if(_.isEmpty(arr)){
          return '--'
        }else{
          let names = [];
          _.each(arr, i=>{
            names.push(i.name);
          });
          return names.join(',');
        }
      }
    }
  }
</script>

<style scoped>
.di-box{display:flex;flex-wrap:wrap;justify-content: space-between;}
  .di-col{width:50%;position:relative;padding-left:20px;box-sizing: border-box;margin-top:20px;}
  .di-col:before{content:'';width:2px;position:absolute;left:0;top:0;bottom:0;background:#e3eff6;}
</style>
