<template><!--规避专家，规避企业-->
  <div>
    <div class="i-box">
      <el-select v-model="i1"
                 :filterable="isFilter"
                 :allow-create="allowCreate"
                 :placeholder="placeholder"  value-key="id"
      @change="change" default-first-option>
        <el-option
          v-for="item in selectOpt"
          :key="item.id"
          :label="item.name"
          :value="item">
        </el-option>
      </el-select>
      <el-button class="x-green x-ml10 x-mr20" @click="addEvent">+ {{$t('userinfo17')}}</el-button>
      <span class="x-color666">{{$t('stepTwo24')}}。</span>
      <slot name="des"></slot>
    </div>
    <div>
      <el-tag class="x-mr10" closable v-for="(item, index) in tagOpt"
              :key="index" @close="delEvent(index)">{{item.name}}</el-tag>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  export default {
    props: {
      isFilter: {
        type: Boolean,
        default: false
      },
      allowCreate: {
        type: Boolean,
        default: false
      },
      selectOpt: {
        type: Array,
        default: ()=> []
      },
      placeholder: {
        type: String,
        default: '请选择'
      },
      tagOpt: {
        type: Array,
        default: ()=> []
      },
      ikey: {
        type: String,
        default: ''
      },
      max: {
        type: Number,
        default: 10
      }
    },
    emits: ['setForm', 'delEvent'],
    data(){
      return {
        i1: ''
      }
    },
    methods: {
      isAdd(val){
        let k = this.selectOpt.filter(i=>{
          return i.name === val;
        });
        return _.isEmpty(k);
      },
      addEvent(){
        let i1_val = this.i1;
        if(typeof this.i1 === 'string'){
          i1_val = {
            name: this.i1
          }
        }
        let has = this.tagOpt.filter(i=>{
          return i.name === i1_val.name;
        })
        if(_.isEmpty(i1_val)||(i1_val&&!_.isEmpty(has))||this.tagOpt.length >= this.max){
          return ;
        }
        let value = _.concat([], this.tagOpt, [i1_val]);
        this.$emit('setForm', {
          key: this.$props.ikey,
          value: this.i1,
          formValue: value,
          tagOpt: this.tagOpt,
          isAdd: this.isAdd(i1_val.name)
        });
      },
      delEvent(index){
        this.$emit('delEvent', index);
      },
      change(val){
        console.log('change---', val);
      }
    }
  }
</script>

<style scoped>
.i-box{display:flex;}
</style>
