<template>
  <div>
    <template v-for="item in dataSource">
      <div class="x-msg" :key="item.id" :class="{ 'x-msg-top': item.isTop }">
        <div class="x-msg-l">
          <div class="x-msg-pic">
            <!--<el-image v-if="item.headimg"
                      style="width: 100%; height: 100%"
                      :src="`/cbmp/file/${item.headimg}`"
                      :preview-src-list="[`/cbmp/file/${item.headimg}`]">
            </el-image>-->
            <el-image v-if="item.headimg"
                      style="width: 100%; height: 100%"
                      :src="`/cbmp/file/${item.headimg}`"
                      @click="toPageBlank(`${item.nature==='expert'?'/ExpertInfo':'/EnterpriseInfo'}`, { id: item.userId })">
            </el-image>
          </div>
          <div class="x-msg-name">
            <div>{{item.fullname}}</div>
            <div>{{item.createDate|longAgo}}</div>
          </div>
        </div>
        <div v-if="creatorId===item.userId||item.isReqExpert" class="x-msg-mark x-msg-m" :class="{ 'x-msg-mark-blue': item.nature==='expert' }">
          {{item.nature==='expert'?'意向专家':'需求企业'}}
        </div>
        <div class="x-msg-r" :style="`margin-left:${creatorId===item.userId||item.isReqExpert?'200px':'120px'}`">
          <div>
            {{item.content}}
          </div>
          <el-button v-if="item.attachmentCode" type="text" @click="downFile(item.attachmentCode)">{{item.attachment}}</el-button>
          <div class="x-re-box">
            <el-button type="text" class="x-re-btn" @click="reClick($event)" v-if="uid!=item.userId">回复</el-button>
            <el-button type="text" class="x-red" @click="deleteClick(item.id)" v-if="uid==creatorId||uid==item.userId">删除</el-button>
          </div>
          <div class="x-re-msg-box x-dsn" style="clear:both;">
            <el-input :rows="5" type="textarea" v-model="reMsg.content"></el-input>
            <div style="padding:4px;display:flex;align-items: center;justify-content: flex-end;">
              <div style="position:relative;display:inline-block;">
                <upload-file v-model="reMsg.attachmentCode" :loading="true" @setAttachment="setAttachment">
                  <span class="el-icon-paperclip x-ml10">附件</span>
                </upload-file>
              </div>
              <span class="x-send-btn x-ml10 x-mr10 x-blue" @click="sendChild($event, item.id)">发送</span>
              <span class="x-cancel-btn" @click="cancelClick($event)">取消</span>
            </div>
          </div>
        </div>
        <demand-message v-if="item.children" :data-source="item.children" :details-id="detailsId" :creator-id="creatorId" @refresh="refresh"></demand-message>
      </div>

    </template>
  </div>
</template>

<script>
  import {apiLeaveMessage} from "../../api/LeaveMessage";
  import UploadFile from "../../components/comm/UploadFile";
  import {downFile} from "../../filters/utils";

  import MixinsBase from '../../mixins/base';
  export default {
    name: "DemandMessage",
    components: {UploadFile},
    props: {
      dataSource: {
        type: Array,
        default: () => []
      },
      detailsId: {
        type: Number,
        default: 0
      },
      creatorId: {
        type: Number,
        default: 0
      }
    },
    mixins: [MixinsBase],
    data(){
      return {
        reMsg: {
          content: '',
          attachmentCode: '',
          attachment: '',
        }
      }
    },
    computed: {
      userId(){
        return this.$store.getters.getUserInfo.id;
      },
      uid(){
        //return this.$route.query.uid;
        return this.$store.getters.getUserInfo.id;
      }
    },
    methods: {
      downFile,
      sendChild(ev, id){
        if(this.reMsg.content===''){
          this.$message.error(this.$t('ddetail11'));
        }else{
          apiLeaveMessage({
            ...this.reMsg,
            parent: id,
            requirementId: this.detailsId
          }).then(res=>{
            if(res.success){
              this.refresh();
              this.reMsg = {
                content: '',
                attachmentCode: '',
                attachment: '',
              };
              let _parent = ev.target.parentNode.parentNode;
              _parent.className = 'x-re-msg-box x-dsn';
              _parent.previousSibling.className = 'x-re-box';
            }
          })
        }
      },
      reClick(ev){
        let _parent = ev.target.parentNode.parentNode;
        let _dom1 = document.getElementsByClassName('x-re-msg-box');
        let _dom2 = document.getElementsByClassName('x-re-box');
        for(let i=0;i<_dom1.length;i++){
          _dom1[i].className = 'x-re-msg-box x-dsn';
          _dom2[i].className = 'x-re-box';
        }
        _parent.className = 'x-re-box x-dsn';
        _parent.nextSibling.className = 'x-re-msg-box';
      },
      cancelClick(ev){
        let _parent = ev.target.parentNode.parentNode;
        _parent.className = 'x-re-msg-box x-dsn';
        _parent.previousSibling.className = 'x-re-box';
      },
      deleteClick(id){
        apiLeaveMessage({
          isDeleted: true,
          id
        }, 'put').then(res=>{
          if(res.success){
            this.refresh();
          }
        })
      },
      refresh(){
        this.$emit('refresh');
      },
      setAttachment(filename){
        this.$set(this.reMsg, 'attachment', filename);
      },
    }
  }
</script>

<style scoped>
.x-msg-mark{
  margin-right:6px;
}
</style>
