<template>
  <sign-layout>
    <h1 slot="title">{{$t('resetPwd1')}}</h1>
    <el-form ref="ruleForm" :model="ruleForm" class="demo-dynamic" :rules="rules">
      <el-form-item prop="pass">
        <el-input show-password type="password" v-model="ruleForm.pass" prefix-icon="el-icon-lock" :placeholder="$t('resetPwd2')"></el-input>
      </el-form-item>
      <el-form-item prop="checkPass">
        <el-input show-password type="password" v-model="ruleForm.checkPass" prefix-icon="el-icon-lock" :placeholder="$t('resetPwd3')"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button style="width:100%" type="primary" round @click="btnSave">{{$t('resetPwd4')}}</el-button>
      </el-form-item>
    </el-form>
  </sign-layout>
</template>

<script>
  //import "@/assets/style/login.css"
  import SignLayout from "./SignLayout";
  import {apiResetPwd} from "../../api/index";
  import { JSEncrypt } from "jsencrypt";
  import MixinsBase from '../../mixins/base';
  export default {
    components: {SignLayout},
    mixins: [MixinsBase],
    data(){
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        ruleForm: {
          pass: '',
          checkPass: '',
        },
        rules: {
          pass: [
            { validator: validatePass, trigger: 'blur' },
            /*{ min: 8, message: '请输入至少8位密码', trigger: 'blur' }*/
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' },
            /*{ min: 8, message: '请输入至少8位密码', trigger: 'blur' }*/
          ]
        }
      }
    },
    computed: {
      checkCode(){
        return this.$route.query.checkcode;
      },
      publicKey(){
        return this.$store.getters.getPublicKey;
      },
    },
    methods: {
      encryptedData(data) {
        let encryptor = new JSEncrypt();
        encryptor.setPublicKey(this.publicKey);
        return encryptor.encrypt(data);
      },
      btnSave(){
        this.$refs.ruleForm.validate(valid=>{
          if(valid){
            let password = this.ruleForm.pass;
            let encryptionPwd = this.encryptedData(password);
            apiResetPwd({
              "checkcode": this.checkCode,
              "password": encryptionPwd
            }).then(res=>{
              if(res.success){
                this.toPage('/SignIn');
              }
            })
          }
        })
      }
    }
  };
</script>

<style lang="css" scoped>

</style>
