<template>
  <el-carousel :interval="5000" arrow="always" height="240px" indicator-position="none">
    <el-carousel-item v-for="item in listData" :key="item.id">
      <div style="display:flex;">
        <el-image
          style="width:240px;height:240px;border-radius:20px;overflow:hidden;"
          :src="`/cbmp/file/${item.thumbs}`"
          fit="cover" @click="toPage('/ActivityDetails', { id: item.id, uid: userInfo.id })"></el-image>
        <div style="flex:1;margin-left:20px;">
          <div class="event-title">
            <div class="x-t1" @click="toPage('/ActivityDetails', { id: item.id, uid: userInfo.id })">{{item.title}}</div>
            <div class="x-t2">-- {{item.atitle}}</div>
          </div>
          <div style="overflow:hidden;max-height:60px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;">{{item.content&&item.content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ').substr(0, 300)}}</div>
          <div class="x-mt10">
            {{$t('iCenter4')}}：<span class="x-yellow">{{item.start|formatDate('datetime')}}~{{item.end|formatDate('datetime')}}</span> <span class="x-gray">|</span> <span v-show="item.online">线上</span> <span v-show="item.offline">线下</span>
          </div>
          <el-button style="border-radius:20px;padding:8px 40px;" type="primary" class="x-mt10" @click="toPage('/ActivityDetails', { id: item.id, uid: userInfo.id })">
            {{$t('iCenter10')}}
          </el-button>
        </div>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
  import MixinsBase from "../mixins/base";
  export default {
    props: {
      listData: {
        type: Array,
        default: ()=>[]
      }
    },
    mixins: [MixinsBase]
  }
</script>

<style scoped>
  .event-title{
    font-weight:bold;
  }
.x-t1{
  font-size:20px;overflow:hidden;line-height:30px;max-height:60px;
}
  .x-t2{
    font-size:14px;line-height:20px;margin:5px 0;max-height:40px;overflow:hidden;
  }
</style>
