<template>
  <div class="x-main-box" style="min-width: 1024px;">
    <!--面包屑-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <el-icon class="el-icon-s-home"></el-icon>
        首页
      </el-breadcrumb-item>
      <el-breadcrumb-item>需求管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-select v-model="lang" @change="init" style="float: right; position:relative; right:0px;top: -20px;"
               placeholder="请选择">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
    <div class="x-mt20" style="background:#fff;">
      <search-form ref="searchForm" :data="searchData" @searchFun="searchFun"></search-form>
      <div style="display:flex;align-items: center;padding:10px;">
        <div>排序方式</div>
        <div style="margin:0 10px;">
          <el-radio-group v-model="paixu">
            <el-radio-button :label="false">最新</el-radio-button>
            <el-radio-button :label="true">对接数</el-radio-button>
          </el-radio-group>
        </div>
        <div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="noViewDocked">不展示对接完成的</el-checkbox>
            <el-checkbox label="noViewExpire">不展示已过期的</el-checkbox>
            <el-checkbox label="noViewDraft">不展示草稿</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>

    <table-page :total="total" :callback="init" :currpage="currPage" :pagesize="pageSize" style="margin-top:0;">
      <el-table :data="tableData" row-key="id" header-row-class-name="x-table-th" border>
        <el-table-column prop="statusName" :label="langData.demandMy1" width="90"></el-table-column>
        <el-table-column prop="name" :label="langData.demandMy2" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" :label="langData.demandMy17" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" @click="getFavorite(scope.row)">{{scope.row.favoriteCnt}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="" label="对接互动信息" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" @click="getHudong(scope.row)">{{scope.row.leaveMsgCnt}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="typeName" :label="langData.demandMy9" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="enterpriseName" :label="langData.userinfo22" width="150"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="regionName" :label="langData.demandMy10" width="150"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="industryPath" :label="langData.demandMy14" width="80"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="beginDate" :label="langData.demandMy15" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
						<span v-if="scope.row.beginDate && scope.row.endDate">
							{{ formatDateTime(scope.row.beginDate, 'date') + '~' + formatDateTime(scope.row.endDate, 'date') }}
						</span>
            <span v-else>面议</span>
          </template>
        </el-table-column>
        <el-table-column prop="key8" :label="langData.demandMy4" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.beginBudget && scope.row.endBudget">{{ scope.row.beginBudget + '~' + scope.row.endBudget }}</span>
            <span v-else>面议</span>
          </template>
        </el-table-column>
        <el-table-column prop="creator" :label="langData.demandMy13" width="120"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="updateDate" :label="langData.demandMy11" width="140" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formatDateTime(scope.row.updateDate, 'datetime') }}
          </template>
        </el-table-column>
        <el-table-column prop="createDate" :label="langData.demandMy16" width="140" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formatDateTime(scope.row.createDate, 'datetime') }}
          </template>
        </el-table-column>
        <el-table-column prop="id" :label="langData.demandMy12" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" @click="viewLy(scope.row)" v-if="scope.row.consultionCnt">{{ scope.row.consultionCnt
              + '条留言' }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column :label="langData.demandMy5" width="60" fixed="right" align="center">
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link"><i class="el-icon-more" style="transform:rotate(90deg);"></i></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span @click="viewClick(scope.row.id, 'zh')">查看</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.idEn">
                  <span @click="viewClick(scope.row.idEn, 'en')">查看英文</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span @click="delClick(scope.row.id)">删除</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span @click="duijie(scope.row.id)">已对接</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <a target="_blank" :href="'http://wwww.baidu.com?id=' + scope.row.id">查看</a> -->
            <!--<el-button type="text" @click="viewClick(scope.row.id, 'zh')">查看</el-button>
            <el-button type="text" v-if="scope.row.idEn" @click="viewClick(scope.row.idEn, 'en')">查看英文</el-button>
            <el-button type="text" @click="delClick(scope.row.id)">删除</el-button>-->
            <!-- <span class="x-funbtn" @click="viewClick(scope.row.id)">查看</span>
            <span class="x-funbtn" @click="delClick(scope.row.id)">删除</span> -->
          </template>
        </el-table-column>
      </el-table>
    </table-page>

    <el-dialog :title="langData.title1" :visible.sync="viewVisible" class="z-req-detail" width="85%">
      <DemandDetail v-if="viewVisible" :lang="this.lang" :isShow="false" :id="viewId"></DemandDetail>
    </el-dialog>

    <el-dialog title="留言" :visible.sync="dialogVisible" width="800px" :before-close="handleClose">
      <el-row v-for="(item, idx) in lyData" :key="'lyData' + item.id">
        <el-col :span="1">
          <el-checkbox v-model="item.checked"></el-checkbox>
        </el-col>
        <el-col :span="23" class="z-ly">
          <el-row>
            <el-col :span="3" style="padding-top: 3px; text-align: center;">
              <span v-if="item.status == 'processed'"
                    style="border:solid 1px #01a023; color: #01a023; padding: 1px 6px; ">{{ item.statusName }}</span>
              <span v-if="item.status == 'untreated'" style="border:solid 1px gray; color: gray; padding: 1px 6px; ">{{ item.statusName }}</span>
            </el-col>
            <el-col :span="21" class="z-ly">
              <span>{{ item.content }}</span>
            </el-col>
          </el-row>
          <el-row class="x-mt20">
            <el-col :span="24">
              <span>{{ item.userName }}</span>
              <span>{{ item.mobile }}</span>
              <span>{{ item.email }}</span>
              <!-- <span>{{ item.statusName }}</span> -->
              <span style="margin-right: 10px"
                    class="x-fr x-mr0">{{ formatDateTime(item.createDate, 'datetime') }}</span>
            </el-col>
          </el-row>
          <!-- <p><span>{{ item.content }}</span></p> -->

          <!-- <br /> -->
          <el-divider v-if="idx < lyData.length - 1"></el-divider>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer" style="text-align: left;">
				<el-dropdown style="margin-right: 20px;" @command="handleCommand">
					<el-button type="primary">批量操作所选</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="a">标记为已处理</el-dropdown-item>
						<el-dropdown-item command="all">删除到回收站</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-button @click="dialogVisible = false">关 闭</el-button>
			</span>
    </el-dialog>
    <!--收藏人员列表-->
    <el-dialog title="对接专家查看" :visible.sync="fVisible" width="800px">
      <el-table :data="favoriteData">
        <el-table-column label="姓名" prop="name">
          <template slot-scope="scope">
            {{scope.row.surname}}{{scope.row.name}}
          </template>
        </el-table-column>
        <el-table-column label="公司" prop="enterprise"></el-table-column>
        <el-table-column label="职务" prop="post"></el-table-column>
        <el-table-column label="手机" prop="mobile"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="行业" prop="category"></el-table-column>
        <el-table-column label="技术领域" prop="domain"></el-table-column>
        <el-table-column label="技术关键词" prop="keyword"></el-table-column>
        <el-table-column label="对该互动咨询">
          <template slot-scope="scope">
            <el-button type="text" @click="getHudong2(scope.row)">{{scope.row.leaveMsgCnt}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button @click="fVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!--留言互动-->
    <el-dialog :title="`${demandTitle}的互动咨询信息`" :visible.sync="liuyanVisible" width="800px">
      <template v-for="item in liuyanData">
        <div class="x-hd" :key="item.id">
          <!--<div class="x-hd-1">
            <el-checkbox></el-checkbox>
          </div>-->
          <!--<div class="x-hd-2">
            <span class="x-read-true">已读</span>
          </div>-->
          <div class="x-hd-3">
            <div>
              {{item.content}}
            </div>
            <div>
              <span class="x-fr">{{item.createDate|formatDate('datetime')}}</span>
              <el-button type="text">{{item.fullname}}</el-button>
              <span class="x-ml20">{{reUserType(item.user_type)}}</span>
              <span class="x-ml20">{{item.mobile}}</span>
              <span class="x-ml20">{{item.email}}</span>
              <el-button type="text" class="x-ml20" @click="sendBefore(item)">发消息</el-button>
            </div>
          </div>
          <div class="x-hd-4 x-red" @click="liuyanDel(item.id)">
            删除
          </div>
        </div>
        <for-message :dataSource="item.children" @refresh="liuyanDel"></for-message>
      </template>
      <!--<el-button class="x-mt20">批量删除所选</el-button>-->
      <div class="x-tc x-mt20">
        <el-button type="primary" @click="liuyanVisible=false">关闭</el-button>
      </div>
    </el-dialog>
    <!--发送通知-->
    <send-message ref="sendMessage"></send-message>
  </div>
</template>

<script>
  import TablePage from '@/components/comm/TablePage';
  import SearchForm from '@/components/comm/SearchForm';
  import DemandDetail from '@/views/Demand/DemandDetail';
  import mixins from '@/mixins';
  import {formatDateTime} from '@/assets/comm';
  import {
    apiGetRequirementList,
    apiGetConsultion,
    apiPostConsultion,
    apiGetFavoriteUser,
    apiGetListLeaveMessage
  } from '@/api/RequirementManage';
  import {apiPutTRequirement} from '@/api/demand';
  //import { en } from '@/lang/en';
  import ForMessage from "./comm/ForMessage";
  import {getZhData} from '@/lang/zh';
  import {getEnData} from '@/lang/en';
  import SendMessage from "../MessageManage/comm/SendMessage";
  import _ from 'lodash';
  import {apiLeaveMessage} from "../../../api/LeaveMessage";

  export default {
    components: {
      TablePage,
      SearchForm,
      DemandDetail,
      ForMessage,
      SendMessage
    },
    mixins: [mixins],
    data() {
      return {
        //apiPost: apiPostWorkOrder,
        viewId: 0,
        viewVisible: false,
        dialogVisible: false,
        apiGet: apiGetRequirementList,
        apiPutTRequirement: apiPutTRequirement,
        options: [{label: '中文版需求', value: 'zh'}, {label: '英文版需求', value: 'en'}],
        searchData: [
          {id: 'queryStr', name: '需求名称', ctrl: true},
          // { id: 'queryStr2', name: '企业名称', ctrl: true },
          {
            id: 'reqType',
            name: '需求类型',
            ctrl: true,
            type: 'select',
            data: [
              {label: '产品/设备使用场景-软硬件', value: 'A1'},
              {label: '产品/设备使用场景-纯软件', value: 'A2'},
              {label: '产品/设备使用场景-纯硬件', value: 'A3'},
              {label: '企业管理-管理咨询', value: 'A4'},
              {label: '工艺设计或生产载体设计或改造-软硬件', value: 'B1'},
              {label: '工艺设计或生产载体设计或改造-纯软件', value: 'B2'},
              {label: '工艺设计或生产载体设计或改造-纯硬件', value: 'B3'},
              {label: '生产环节业务场景A-软硬件', value: 'C1'},
              {label: '生产环节业务场景A-纯软件', value: 'C2'},
              {label: '生产环节业务场景A-纯硬件', value: 'C3'},
              {label: '服务环节业务场景A-软硬件', value: 'D1'},
              {label: '服务环节业务场景A-纯软件', value: 'D2'},
              {label: '服务环节业务场景A-纯硬件', value: 'D3'},
              {label: '多环节或复杂系统', value: 'E1'},
              {label: '人员协调/数字化转型项目推进', value: 'F1'}
            ]
          },
          // { id: 'queryStr3', name: '需求发布人', ctrl: true },
          // { id: 'queryStr4', name: '地区', ctrl: true },
          // { id: 'queryStr5', name: '行业', ctrl: true },
          {
            id: 'reqStatus',
            name: '状态',
            ctrl: true,
            type: 'select',
            data: [
              {
                label: '草稿',
                value: 'draft'
              },
              {
                label: '已发布',
                value: 'published'
              }
            ]
          }
        ],
        typeMapList: {},
        lyData: [],
        zhData: this.getZhData(),
        enData: this.getEnData(),
        lang: 'zh',
        favoriteData: [],
        fVisible: false,
        liuyanVisible: false,
        hdItem: {},
        liuyanData: [],
        paixu: false,
        checkList: [],
      };
    },
    created() {
      //console.log(this.$store.state.typeObj)
      console.log('this.$store.state', this.$store.state);
      this.typeMapList = this.$store.state.demand.typeObj;
      console.log('zhData', this.zhData);
    },
    mounted() {
      this.init();
    },
    watch: {
      'paixu'(){
        this.init()
      },
      'checkList'(){
        this.init()
      }
    },
    computed: {
      langData: function () {
        return this.lang == 'zh' ? this.zhData : this.enData;
      },
      demandTitle() {
        return this.hdItem.name;
      }
    },
    methods: {
      getZhData,
      getEnData,
      init(_parm = {}) {
        let param = this.$refs.searchForm.reParams();
        let params = _.assign(param, _parm, {
          isFavoriteCntSort: this.paixu,
          noViewType: this.checkList.join(",")
        });
        this.searchParams = {
          ...params
        };
        this.refreshTable(params);
      },
      formatDateTime,
      handleClose() {
        this.dialogVisible = false;
      },
      handleCommand2(type) {
        //
      },
      sendBefore(item){
        this.$refs.sendMessage.open(item);
      },
      handleCommand(command) {
        let _r = this.lyData.filter(i => {
          return i.checked == true;
        });
        if (_r.length == 0) {
          this.$message({
            message: '请先选择留言！',
            type: 'warning'
          });
          return false;
        }
        let _data = [];

        //console.log('ids', ids);
        let _status = '';
        if (command === 'a') {
          _status = 'processed';
        }
        if (command === 'all') {
          _status = 'recycle';
        }
        _r.forEach(item => {
          _data.push({id: item.id, status: _status});
        });
        //apiPostConsultion(_data).then(res)
        apiPostConsultion(_data, 'put').then(res => {
          if (res.success) {
            this.handleClose();
            this.init();
          }
        });
      },
      exportFile() {
        let param = this.$refs.searchForm.reParams();
        delete param.pageSize;
        delete param.currPage;
        this.downloadFileBlob('/req/export', param);
      },
      refreshTable(param) {
        this.getTableData(param, this.apiGet, {isStatConsultion: true, lang: this.lang, isStatLeaveMsg: true});
      },
      searchFun(params = {}) {
        params.pageSize = this.pageSize;
        params.currPage = 1;
        this.searchParams = {
          ...this.searchParams,
          ...params
        };
        this.refreshTable(this.searchParams);
      },
      openDialog() {
        this.$refs.dialogForm.openDialog();
      },
      closeDialog() {
        this.$refs.dialogForm.closeDialog();
      },
      //查看留言
      viewLy(rows) {
        this.lyData = [];
        let parms = {reqId: rows.id, isRecycle: false};
        apiGetConsultion(parms).then(res => {
          this.lyData = res.result;
          this.dialogVisible = true;
        });
      },
      duijie(id) {
        this.apiPutTRequirement({
          id,
          status: "docked"
        })
      },
      delClick(id) {
        this.del({id: id, isDelete: true}, this.apiPutTRequirement);
      },
      //查看
      viewClick(id, lang) {
        this.$store.dispatch('setLanguage', lang);
        this.$i18n.locale = lang;
        this.viewVisible = false;
        this.viewVisible = true;
        this.viewId = id;
        // this.$router.push({
        // 	path: '/DemandDetail',
        // 	query: { id: id }
        // });
      },
      del(parms, func) {
        this.$confirm('确定删除这条记录吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        })
          .then(() => {
            func(parms).then(res => {
              if (res.success) {
                this.init();
              }
            });
          })
          .catch(() => {
          });
      },
      getFavorite(item) {
        this.fVisible = true;
        this.favoriteData = [];
        this.hdItem = item;
        apiGetFavoriteUser({
          reqId: item.id,
          isStatLeaveMsgCnt: true
        }).then(res => {
          this.favoriteData = res.result
        })
      },
      getHudong2(item){
        this.getHudong({
          ...this.hdItem,
          userId: item.id
        });
      },
      getHudong(item) {
        let params = {
          reqId: item.id,
        };
        if(item.userId){
          params.userId = item.userId;
        }
        this.hdItem = item;
        apiGetListLeaveMessage(params).then(res => {
          if (res.success) {
            this.liuyanData = res.result;
          }
        })
        this.$nextTick(() => {
          this.liuyanVisible = true;
        })
      },
      liuyanDel(id){
        apiLeaveMessage({
          isDeleted: true,
          id
        }, 'put').then(res=>{
          if(res.success){
            this.getHudong();
          }
        })
      },
      reUserType(stype){
        return stype === 'reqCreator'?'需求发布企业':(stype==='reqExpert'?'对接专家':'')
      }
    }
  };
</script>
<style lang="less">
  .x-hd {
    display: flex;margin:10px 0;
  }

  .x-hd-1 {
    width: 20px;
  }

  .x-hd-2 {
    width: 60px;
    text-align: center;
  }

  .x-hd-2 span {
    color: #C9D6DC;
  }

  .x-hd-2 span:before {
    content: '●';
    display: block;
  }

  .x-hd-2 .x-read-true {
    color: #01A023;
  }

  .x-hd-3 {
    flex: 1;
    border-bottom: 1px solid #f4f4f4;padding-bottom:10px;
  }

  .x-hd-4 {
    width: 80px;text-align:right;cursor:pointer;
  }
  .x-hd-4:hover{
    text-decoration:underline;
  }
</style>
