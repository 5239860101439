<template>
	<div class="x-imgbox1" style="position:relative;">
    <div v-if="code === '' || code === null">
      <slot>
        <el-button type="primary" class="el-icon-plus1">{{$t('ufile1')}}</el-button>
      </slot>
    </div>

		<input :style="`width:${width};opacity:0;position: absolute;left:0;top:0;height:30px;`" type="file" class="z-upload-btn" v-if="fileList.length == 0" @change="uploadChange" />
		<!-- <img width="100" height="100" v-if="code !== '' && code !== null" :src="imgsrc" alt="" /> -->
		<ul class="el-upload-list el-upload-list--text" v-if="fileList.length != 0&&code">
			<li tabindex="0" v-for="item in fileList" :key="item.code" class="el-upload-list__item is-success" style="margin-top:0;">
				<a class="el-upload-list__item-name">
					<i class="el-icon-document"></i>
					{{ item.filename }}
				</a>
				<label class="el-upload-list__item-status-label"><i class="el-icon-upload-success el-icon-circle-check"></i></label>
				<i class="el-icon-close" @click="clearFile()"></i>
				<i class="el-icon-close-tip">按 delete 键可删除</i>
			</li>
		</ul>

	</div>
</template>

<script>
import { apiPostService } from '@/api';

export default {
	props: {
		//此处一定要用value
		value: {
			type: String
		},
    filename: {
		  type: String,
      default: ''
    },
    loading: {
		  type: Boolean,
      default: false
    },
    width: {
		  type: String,
      default: '80px'
    }
	},
	data() {
		return {
			code: '',
			fileList: []
		};
	},
	watch: {
		value: {
			handler(val){
        this.code = val;
      },
      immediate: true
		},
    filename: {
		  handler(val){
        if(val){
          this.fileList = [
            {
              filename: val
            }
          ];
        }else{
          this.fileList = [];
          this.code = '';
        }
      },
      immediate: true
    }
	},
	computed: {
		imgsrc() {
			return this.$store.state.baseUrl + '/file/' + this.code;
		}
	},
	methods: {
		uploadChange(ev) {
			let files = ev.target.files;
			if (files[0]) {
				let name = files[0]['name'];
				let ext = '';
				ext = name.substr(name.lastIndexOf('.'));
				this.reBase64(files[0]);
			}
		},
		clearFile() {
			this.fileList = [];
      this.$emit('input', '');
      this.$emit('setAttachment', '');
		},
		reBase64(file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = e => {
				let base64 = e.target['result'];
				base64 = base64.split('base64,')[1];
				let params = {
					data: base64,
					ext: file.name.substr(file.name.lastIndexOf('.')),
					filename: file.name,
					type: file.type
				};
				let loading;
				if(this.loading){
          loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
        }
				apiPostService('/cbmp/rest/SysFile', params).then(res => {
				  if(res.success){
            this.fileList = res.result||[];
            this.code = res.result?res.result[0]['code']:'';
            this.$emit('input', this.code);
            if(this.$listeners.setAttachment){
              this.$emit('setAttachment', file.name);
            }
          }
				}).catch(()=>{

        }).finally(()=>{
          loading&&loading.close();
        });
			};
		}
	}
};
</script>

<style scoped>
.x-imgbox {
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
	position: relative;
	background-color: #ddd;
}
.x-upImg[type='file'] {
	position: absolute;
	z-index: 3;
	left: 0;
	top: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
}
.z-upload-btn{
	/* color: #0000EE; */
}
</style>
