<template>
  <div>
    <h3 class="x-tc">{{$t('baomixieyi1')}}</h3>
    <div style="height:400px;overflow-y:auto;margin-top:20px;">
      <pre style="white-space: pre-wrap;font-family:inherit;">
        {{$t('baomixieyi2')}}
        </pre>
      <div style="text-align:right;">
        {{$t('baomixieyi3')}}<br />
        20220901
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ReadTwo"
  }
</script>

<style scoped>

</style>
