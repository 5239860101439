<template>
  <el-form :model="jcForm" ref="jcForm">
  <div style="position:relative;clear:both;">
    <el-form-item class="a-loop-box" v-for="(item, index) in jcForm.arrList"
     :key="index" :prop="'arrList.' + index + '.content'"
                  :rules="{
      required: true, message: '不能为空', trigger: 'blur'
    }">
      <div class="x-color666">{{$t('typeC3')}}{{index+1}}</div>
      <el-input v-model="item.content" type="textarea" :rows="5" :placeholder="$t('typeC4')"></el-input>
      <div class="a-del" v-if="index!==0" @click="delClick(index)">
        <el-button type="danger" icon="el-icon-delete" circle></el-button>
      </div>
    </el-form-item>
    <div class="a-add" @click="addClick">
      <el-button type="success" icon="el-icon-plus" circle></el-button>
    </div>
  </div>
  </el-form>
</template>

<script>
  import _ from 'lodash';

  export default {
    props: ['value'],
    data(){
      return {
        jcForm: {
          arrList:[
            {
              content: '',
            }
          ]
        }
      }
    },
    watch: {
      'value': {
        immediate: true,
        handler: function(val){
          this.$set(this.jcForm, 'arrList', _.isEmpty(val) ? [{
            content: '',
          }] : val);
        }
      }
    },
    methods: {
      addClick(){
        let {arrList} = this.jcForm;
        this.$set(this.jcForm, 'arrList', [
          ...arrList,
          {
            content: ''
          }
        ])
      },
      delClick(index){
        let {arrList} = this.jcForm;
        arrList.splice(index, 1);
        this.$set(this.jcForm, 'arrList', [
          ...arrList
        ])
      },
      reParams(){
        let params = [];
        this.$refs.jcForm.validate(valid=>{
          if(valid){
            params = this.jcForm.arrList;
          }
        })
        return params;
      },
      setValue(val){
        let params = [];
        _.each(val, i=>{
          if(i.content){
            params.push(i);
          }
        })
        this.$emit('getFunc', params);
      }
    }
  }
</script>

<style scoped>
  .a-loop-box{width:100%;padding-right:80px;margin-bottom:30px;box-sizing:border-box;position:relative;}
  .a-del,.a-add{position:absolute;right:0;bottom:0;}
  .a-del{bottom:60px;right:-78px;}
</style>
