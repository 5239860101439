import $axios from '@/axios/index'

//咨询列表
export const apiGetConsultion = (params)=>{
  return $axios.get('/cbmp/req/getConsultionList', {params});
}

//修改咨询
export const apiPostConsultion = (params, type="post")=>{
  return $axios[type]('/cbmp/rest/Consultion', params);
}

//需求列表
export const apiGetRequirementList = (params)=>{
  return $axios.get('/cbmp/req/query', {params});
}
//需求留言
export const apiGetListLeaveMessage = (params)=>{
  return $axios.get('/cbmp/req/listLeaveMessage', {params});
}

//获取个人信息
export const apiGetCurruser = (params)=>{
  return $axios.get('/cbmp/cbmpuser', {params});
}
//获取收藏人员信息
export const apiGetFavoriteUser = (params)=>{
  return $axios.get('/cbmp/req/getFavoriteUserInfosByReq', {params});
}
//发送通知
export const apiNotice = (params, type="post")=>{
  return $axios[type]('/cbmp/rest/Notice', params);
}
