import { render, staticRenderFns } from "./DetailInfo.vue?vue&type=template&id=948a2adc&scoped=true&"
import script from "./DetailInfo.vue?vue&type=script&lang=js&"
export * from "./DetailInfo.vue?vue&type=script&lang=js&"
import style0 from "./DetailInfo.vue?vue&type=style&index=0&id=948a2adc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "948a2adc",
  null
  
)

export default component.exports