<template>
  <div class="x-main">
    <x-header></x-header>
    <x-breadcrumb></x-breadcrumb>
    <div class="x-content">
      <component :is="currentView"></component>
    </div>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import XBreadcrumb from "../../components/XBreadcrumb";
  import StepOne from "./StepOne";
  import StepTwo from "./StepTwo";
  import StepThree from "./StepThree";
  import StepFour from "./StepFour";
  import {apiDemandDetail} from '../../api';
  export default {
    components: {
      StepOne,
      StepTwo,
      StepThree,
      StepFour,
      XBreadcrumb,
      XHeader
    },
    data(){
      return {
        view: [StepOne, StepTwo, StepThree, StepFour],
      }
    },
    computed: {
      currentView() {
        let step = this.$store.getters.getStep;
        return this.view[step];
      },
      query(){
        return this.$route.query;
      },
      cacheDemand(){
        return this.$store.getters.getCacheDemand;
      }
    },
    mounted(){
      this.init();
    },
    methods: {
      init(){
        let {id} = this.query;
        let {type, name} = this.cacheDemand;
        let params = {}
        if(!type&&!name){
          this.$store.dispatch('setStep', 0);
        }else{
          params = {
            type, name
          }
        }
        if(id){
          apiDemandDetail({
            reqId: id
          }).then(res=>{
            this.$store.dispatch('setCacheDemand', res.ext);
          })
        }else{
          this.$store.dispatch('setCacheDemand', params);
        }
      }
    }
  }
</script>

<style scoped>

</style>
