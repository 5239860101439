import $axios from '@/axios/index';
//活动
export const apiGetActivity = (params)=>{
  return $axios.get('/cbmp/rest/Activity/list', {params});
}
export const apiActivity = (params, type="post")=>{
  return $axios[type]('/cbmp/rest/Activity', params);
}
//问卷
export const apiGetQuestionnaire = (params)=>{
  return $axios.get('/cbmp/rest/Questionnaire/list', {params});
}
//查询答案
export const apiGetQestionnaire = (params)=>{
  return $axios.get('/cbmp/rest/QuestionnaireParticipant/list', {params});
}
//提交答案
export const apiQuestionnaireParticipant = (params, type="post")=>{
  return $axios[type]('/cbmp/rest/QuestionnaireParticipant', params);
}
