<template>
  <div class="x-main">
    <x-header></x-header>
    <div class="x-content x-mt20">
      <div class="x-center">
        <x-card style="width:100%;padding:0;">
          <div class="x-e-bg">
            <el-button type="primary" @click="interest" v-if="noSelf&&!isGuanzhu">{{$t('einfo2')}}</el-button>
            <el-button type="primary" @click="interestDel" v-if="noSelf&&isGuanzhu">{{$t('einfo3')}}</el-button>
          </div>
          <div class="x-e-box">
            <div class="x-e-l">
              <el-image v-if="details.logo"
                        style="width: 100%; height: 100%"
                        :src="`/cbmp/file/${details.logo}`"
                        :preview-src-list="[`/cbmp/file/${details.logo}`]"
                        fit="contain">
              </el-image>
            </div>
            <div class="x-e-r">
              <div class="x-e-1">
                <div class="x-e-name">
                  <strong class="x-f18">{{details.enterpriseName}}</strong>
                  <span class="x-ml10 x-gray">{{reEnterprise(details.enterpriseType)}}</span>
                  <span class="x-ml10 x-gray">{{reInsize(details.insize)}}</span>
                </div>
                <x-tab :tab-data="tabData" @reIndex="reIndex"></x-tab>
              </div>
              <div class="x-e-2">
                <span class="el-icon-location-outline">{{details.area|re_str}}</span>
                <span class="el-icon-coordinate x-ml20">{{details.category|re_str}}</span>
                <span class="el-icon-rank x-ml20">{{details.domain|re_str}}</span>
              </div>
              <!--介绍-->
              <div v-if="curr===0" v-html="details.enterpriseDesc" style="min-height:300px;padding:30px 0;"></div>
              <!--需求列表-->
              <div v-if="curr===1" style="min-height:300px;padding:30px 0;">
                <demand-list-temp v-for="item in tableData" :key="item.id" :item="item"></demand-list-temp>
                <x-pagination :total="total" @pageChange="refreshTable" :currpage="currPage"></x-pagination>
              </div>
            </div>
          </div>
        </x-card>
      </div>
      <div class="x-right x-ml20">
        <e-right :details="details" ref="eright"></e-right>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiGetAppUser} from '../../api/login';
  import XHeader from "../../components/XHeader";
  import XCard from "../../components/XCard";
  import XTab from "../../components/comm/XTab";
  import ERight from "./ERight";
  import _ from 'lodash';
  import {apiDemandList} from "../../api";
  import mixinsBase from "../../mixins/base";
  import Mixins from "../../mixins";
  import DemandListTemp from "../Demand/DemandComm/DemandListTemp";
  import XPagination from "../../components/comm/XPagination";
  import {apiDelInterest, apiGetInterest, apiPostInterest} from "../../api/Notice";
  export default {
    components: {
      XPagination,
      DemandListTemp,
      XHeader,
      XCard,
      XTab,
      ERight
    },
    mixins: [Mixins, mixinsBase],
    data(){
      return {
        curr: 0,
        details: {},
        tableData: [],
        tabData: [],
        isGuanzhu: false,
        isGuanzhuId: -1,
        pageSize: 4
      }
    },
    watch: {
      tableData(val){
        if(val.length === 0){
          this.tabData = [this.$t('einfo15')];
        }else{
          this.tabData = [this.$t('einfo15'), this.$t('demand7')];
        }
      },
      '$route.query.id'(){
        this.refreshId();
      },
    },
    computed: {
      id(){
        return this.$route.query.id;
      },
      userInfo(){
        return this.$store.getters.getUserInfo;
      },
      noSelf(){
        return this.id != this.userInfo.id;
      }
    },
    mounted() {
      this.refreshId();
    },
    methods: {
      refreshId(){
        apiGetAppUser({
          id: this.id
        }).then(res=>{
          if(res.success){
            this.details = res.result[0];
            this.getInterest();
          }
          this.refreshTable();
        })
      },
      reIndex(index){
        this.curr = index;
      },
      reEnterprise(id) {
        let opt = this.$store.getters.getEnterprise;
        let cur = _.find(opt, { id });
        return cur?cur.name:'';
      },
      reInsize(id) {
        let opt = this.$store.getters.getInsize;
        let cur = _.find(opt, { id });
        return cur?cur.name:'';
      },
      refreshTable(params={}){
        this.getTableData(params, apiDemandList, {
          lang: this.lang,
          //isSelf: true,
          creator: this.id,
          reqStatus: 'published'
        });
      },
      getInterest(){
        apiGetInterest({
          creatorId: this.userInfo.id,
          userId: this.details.id
        }).then(res=>{
          if(res.success){
            this.isGuanzhu = res.result.length !== 0;
            this.isGuanzhuId = res.result[0]&&res.result[0]['id'];
          }
        })
      },
      interest(){
        let { id, username, nature, post, headimg, logo, enterpriseName, name, surname } = this.details;
        let userId = this.userInfo.id;
        let userNature = this.userInfo.nature;
        let creatorHeadimg = this.userInfo.headimg;
        let creatorLogo = this.userInfo.logo;
        let creatorPost = this.userInfo.post;
        let creatorName = this.userInfo.name;
        let creatorSurname = this.userInfo.surname;
        let creatorEnterpriseName = this.userInfo.enterpriseName;
        apiPostInterest({
          creatorId: userId,
          creatorHeadimg: userNature==='expert'?creatorHeadimg:creatorLogo,
          creatorPost,
          creatorName,
          creatorSurname,
          creatorEnterpriseName,
          userId: id,
          username,
          nature,
          enterpriseName,
          headimg: nature==='expert'?headimg:logo,
          post,
          name,
          surname
        }).then(res=>{
          if(res.success){
            this.getInterest();
            this.$refs.eright.refresh();
          }
        })
      },
      interestDel(){
        if(this.isGuanzhuId > 0){
          apiDelInterest({
            data: [this.isGuanzhuId]
          }).then(res=>{
            if(res.success){
              this.getInterest();
              this.$refs.eright.refresh();
            }
          })
        }
      }
    }
  }
</script>

<style scoped>
.x-e-bg{
  padding:20px;text-align:right;background:#f7fcff;
}
  .x-e-box{
    display:flex;padding:0 20px;
  }
  .x-e-l{
    width: 80px;height:80px;background:#f1f1f1;position:relative;top:-40px;
  }
  .x-e-r{
    flex:1;padding-left:10px;
  }
  .x-e-1{
    padding-top:20px;display:flex;justify-content: space-between;
  }
  .x-e-2{padding:10px 20px;background:#f7fcff;}
</style>
