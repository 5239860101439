<template>
  <type-c></type-c>
</template>

<script>
  import TypeC from "./TypeC";
  export default {
    components: {
      TypeC
    }
  }
</script>

<style scoped>

</style>
