<template>
  <div class="x-main">
    <x-header></x-header>
    <div class="x-content x-mt20">
      <div class="x-center">
        <x-card style="width:100%;padding:0;">
          <div class="x-e-bg">
            <el-button @click="yaoqin" v-if="noSelf">{{$t('einfo1')}}</el-button>
            <el-button type="primary" @click="interest" v-if="noSelf&&!isGuanzhu">{{$t('einfo2')}}</el-button>
            <el-button type="primary" @click="interestDel" v-if="noSelf&&isGuanzhu">{{$t('einfo3')}}</el-button>
          </div>
          <div class="x-e-box">
            <div class="x-e-l">
              <el-image v-if="details.headimg"
                        style="width: 100%; height: 100%"
                        :src="`/cbmp/file/${details.headimg}`"
                        :preview-src-list="[`/cbmp/file/${details.headimg}`]" fit="cover">
              </el-image>
            </div>
            <div class="x-e-r">
              <div class="x-e-1">
                <div class="x-e-name">
                  <strong class="x-f18">{{details.surname}} {{details.name}}</strong>
                  <span class="x-ml10 x-gray">{{orgName||details.department}}</span>
                  <span class="x-ml10 x-gray">{{orgTitle||details.post}}</span>
                  <span class="x-ml10 x-gray">{{details.genderName}}</span>
                  <span class="x-ml10 x-gray">{{age}}</span>
                </div>
                <x-tab :tab-data="tabData" @reIndex="reIndex"></x-tab>
              </div>
              <div class="x-e-2">
                <span class="el-icon-location-outline">{{details.area|re_str}}</span>
                <span class="el-icon-coordinate x-ml20">{{details.category|re_str}}</span>
                <span class="el-icon-rank x-ml20">{{details.domain|re_str}}</span>
              </div>
              <!--介绍-->
              <div v-if="curr===0" class="minH300">
                <div v-if="bol1" style="margin-bottom:32px;">
                  <p style="margin-bottom:16px;"><strong>{{$t('einfo4')}}</strong></p>
                  <div v-html="details.comment"></div>
                </div>
                <div v-if="bol2">
                  <p style="margin-bottom:16px;"><strong>{{$t('einfo5')}}</strong></p>
                  <div v-html="details.researchDirection"></div>
                </div>
                <div v-if="!bol1&&!bol2">
                  暂无内容
                </div>
              </div>
              <!--案例-->
              <div v-if="tabDataIndex[curr]===1" class="minH300">
                <el-collapse accordion>
                  <el-collapse-item v-for="item in tab1Data" :key="item.id">
                    <template slot="title">
                      {{item.year}}{{$t('einfo6')}} <strong class="x-ml20">{{item.name}}</strong>
                    </template>
                    <div v-html="item.description"></div>
                    <el-button v-show="item.attachment&&item.attachmentCode" type="text" class="el-icon-document" @click="downFile(item.attachmentCode)"> {{item.attachment}}</el-button>
                  </el-collapse-item>
                </el-collapse>
              </div>
              <!--成果&荣誉-->
              <div v-if="tabDataIndex[curr]===2" class="minH300">
                <div class="x-bold" style="margin-bottom:12px;">{{$t('einfo7')}}</div>
                <el-collapse accordion>
                  <el-collapse-item v-for="item in tab2Data" :key="item.id">
                    <template slot="title">
                      {{item.year}}{{$t('einfo6')}} <strong class="x-ml20">{{item.name}}</strong>
                    </template>
                    <div style="display:flex;">
                      <div style="width:60px;height:60px;margin-right:20px;" v-if="item.images">
                        <el-image
                                  style="width: 100%; height: 100%"
                                  :src="`/cbmp/file/${item.images}`"
                                  :preview-src-list="[`/cbmp/file/${item.images}`]">
                        </el-image>
                      </div>
                      <div style="flex:1;" v-html="item.description"></div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
                <div class="x-bold" style="margin:32px 0 12px 0;">{{$t('einfo8')}}</div>
                <el-collapse accordion>
                  <el-collapse-item v-for="item in tab3Data" :key="item.id">
                    <template slot="title">
                      {{item.year}}{{$t('einfo6')}} <strong class="x-ml20">{{item.name}}</strong>
                    </template>
                    <div style="display:flex;">
                      <div style="width:60px;height:60px;margin-right:20px;" v-if="item.images">
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="`/cbmp/file/${item.images}`"
                          :preview-src-list="[`/cbmp/file/${item.images}`]">
                        </el-image>
                      </div>
                      <div style="flex:1;" v-html="item.description"></div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
              <!--媒体报道-->
              <div v-if="tabDataIndex[curr]===3" class="minH300">
                <el-collapse accordion>
                  <el-collapse-item v-for="item in tab4Data" :key="item.id">
                    <template slot="title">
                      <strong>{{item.title}}</strong>
                    </template>
                    <div v-html="item.description"></div>
                    <el-link v-show="item.url" :href="item.url" target="_blank" type="primary">{{$t('einfo9')}}</el-link>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
        </x-card>
      </div>
      <div class="x-right x-ml20">
        <e-right :details="details" ref="eright"></e-right>
      </div>
    </div>
    <dialog-form ref="yqDialog" :title="`${$t('einfo10')}${details.surname+details.name} ${$t('einfo11')}`" @confirm="confirm">
      <div>
        <el-input v-model="form.remarks" :rows="5" type="textarea" :placeholder="$t('einfo12')"></el-input>
      </div>
      <div class="x-mt10">
        <el-select class="x-w100" v-model="form.requirementId" filterable
                   :placeholder="$t('einfo13')" value-key="id">
          <el-option
            v-for="item in demandList"
            :key="item.id"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
      </div>
    </dialog-form>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import XCard from "../../components/XCard";
  import XTab from "../../components/comm/XTab";
  import DialogForm from "../../components/comm/DialogForm";
  import ERight from "./ERight";
  import {apiGetAppUser} from "../../api/login";
  import _ from "lodash";
  import $axios from '../../axios';
  import {apiDemandList} from "../../api";
  import {apiDelInterest, apiGetInterest, apiPostInterest, apiPostRequirementInvite} from "../../api/Notice";
  import {downFile} from '../../filters/utils';
  import {apiGetExpertOrg} from "../../api/PersonalData";
  import MixinsBase from '../../mixins/base';
  export default {
    components: {
      XHeader,
      XCard,
      XTab,
      ERight,
      DialogForm
    },
    mixins: [MixinsBase],
    data(){
      return {
        curr: 0,
        details: {},
        tabData: [this.$t('einfo15')],
        tabDataIndex: [0],
        tab1Data: [],
        tab2Data: [],
        tab3Data: [],
        tab4Data: [],
        demandList: [],
        form: {
          requirementId: '',
          remarks: '',
        },
        isGuanzhu: false,
        isGuanzhuId: -1,
        orgName: '',
        orgTitle: '',
      }
    },
    watch: {
      /*curr(val){
        this[`tab${val}`]&&this[`tab${val}`]();
      },*/
      '$route.query.id'(){
        this.refreshId();
      },
    },
    computed: {
      id(){
        return this.$route.query.id;
      },
      age(){
        let date1 = new Date().getFullYear();
        let date2 = 0;
        let birthday = this.details.birthday;
        if(birthday){
          date2 = new Date(birthday).getFullYear();
        }
        return date2===0?'':`${date1-date2}岁`
      },
      userInfo(){
        return this.$store.getters.getUserInfo;
      },
      noSelf(){
        return this.id != this.userInfo.id;
      },
      bol1(){
        return this.details&&this.details.comment&&this.details.comment.replace(/<[^>]+>/g, '');
      },
      bol2(){
        return this.details&&this.details.researchDirection&&this.details.researchDirection.replace(/<[^>]+>/g, '');
      },
    },
    mounted() {
      this.refreshId();
    },
    methods: {
      downFile,
      init(){
        this.refreshId();
        this.tabData = [this.$t('einfo15')];
      },
      refreshId(){
        apiGetAppUser({
          id: this.id
        }).then(res=>{
          if(res.success){
            let _data = res.result[0];
            this.details = _data;
            apiDemandList({
              //creatorId: data.id
              isSelf: true,
              lang: this.lang,
              reqStatus: 'published'
            }).then(res=>{
              if(res.success){
                this.demandList = res.result;
              }
            })
            apiGetExpertOrg({
              userId: _data.id,
              asc: 'createDate',
              pageSize: 1,
              currPage: 1
            }).then(res=>{
              if(res.success){
                let orgData = res.result[0]||[];
                this.orgName = orgData['orgName'];
                this.orgTitle = orgData['title'];
              }
            })
            this.getInterest();
            this.tab1();
            this.tab2();
            this.tab3();
          }
        });
      },
      getInterest(){
        apiGetInterest({
          creatorId: this.userInfo.id,
          userId: this.details.id
        }).then(res=>{
          if(res.success){
            this.isGuanzhu = res.result.length !== 0;
            this.isGuanzhuId = res.result[0]&&res.result[0]['id'];
          }
        })
      },
      interest(){
        let { id, username, nature, post, headimg, logo, enterpriseName, name, surname } = this.details;
        let userId = this.userInfo.id;
        let userNature = this.userInfo.nature;
        let creatorHeadimg = this.userInfo.headimg;
        let creatorLogo = this.userInfo.logo;
        let creatorPost = this.userInfo.post;
        let creatorName = this.userInfo.name;
        let creatorSurname = this.userInfo.surname;
        let creatorEnterpriseName = this.userInfo.enterpriseName;
        apiPostInterest({
          creatorId: userId,
          creatorHeadimg: userNature==='expert'?creatorHeadimg:creatorLogo,
          creatorPost,
          creatorName,
          creatorSurname,
          creatorEnterpriseName,
          userId: id,
          username,
          nature,
          creatorNature: userNature,
          enterpriseName,
          headimg: nature==='expert'?headimg:logo,
          post,
          name,
          surname
        }).then(res=>{
          if(res.success){
            this.getInterest();
            this.$refs.eright.refresh();
          }
        })
      },
      confirm(){
        let {requirementId, remarks } = this.form;
        if(!requirementId||!remarks){
          this.$message.error(this.$t('einfo14'));
        }else{
          apiPostRequirementInvite({
            requirementId: requirementId.id,
            remarks: `${remarks} <a href='/#/DemandDetail?id=${requirementId.id}&langRelReqId&lang=${this.lang}&idEn=${requirementId.idEn}&idZh=${requirementId.idZh}&uid=${this.userInfo.id}&uname=${this.userInfo.username}'>${requirementId.name}</a>`,
            userId: this.details.id,
            username: this.details.username
          }).then(res=>{
            if(res.success){
              this.$refs.yqDialog.closeDialog();
              this.form = {
                requirementId: '',
                remarks: ''
              };
            }
          })
        }

      },
      reIndex(index){
        this.curr = index;
      },
      yaoqin(){
        this.$refs.yqDialog.openDialog();
      },
      reEnterprise(id) {
        let opt = this.$store.getters.getEnterprise;
        let cur = _.find(opt, { id });
        return cur?cur.name:'';
      },
      reInsize(id) {
        let opt = this.$store.getters.getInsize;
        let cur = _.find(opt, { id });
        return cur?cur.name:'';
      },
      getList(entity, callback){
        $axios.get(`/cbmp/rest/${entity}/list`, {
          params: {
            userId: this.details.id
          }
        }).then(res=>{
          if(res.success){
            callback&&callback(res.result);
          }
        })
      },
      tab1(){
        this.getList('ExampleOfCase', (data)=>{
          this.tab1Data = data;
          if(!_.isEmpty(data)&&!this.tabData.includes(this.$t('einfo16'))){
            this.tabData.push(this.$t('einfo16'));
            this.tabDataIndex.push(1);
          }
        })
      },
      tab2(){
        this.getList('ScientificPayoffs', (data)=>{
          this.tab2Data = data;
          if(!_.isEmpty(data)&&!this.tabData.includes(this.$t('einfo17'))){
            this.tabData.push(this.$t('einfo17'));
            this.tabDataIndex.push(2);
          }
        })
        this.getList('HonorAward', (data)=>{
          this.tab3Data = data;
          if(!_.isEmpty(data)&&!this.tabData.includes(this.$t('einfo17'))){
            this.tabData.push(this.$t('einfo17'));
            this.tabDataIndex.push(2);
          }
        })
      },
      tab3(){
        this.getList('MediaCoverage', (data)=>{
          this.tab4Data = data;
          if(!_.isEmpty(data)&&!this.tabData.includes(this.$t('einfo18'))){
            this.tabData.push(this.$t('einfo18'));
            this.tabDataIndex.push(3);
          }
        })
      },
      interestDel(){
        if(this.isGuanzhuId > 0){
          apiDelInterest({
            data: [this.isGuanzhuId]
          }).then(res=>{
            if(res.success){
              this.getInterest();
              this.$refs.eright.refresh();
            }
          })
        }
      }
    }
  }
</script>

<style scoped>
.x-e-bg{
  height:28px;padding:20px;text-align:right;background:#f7fcff;
}
  .x-e-box{
    display:flex;padding:0 20px;
  }
  .x-e-l{
    width: 80px;height:80px;border-radius:50%;overflow:hidden;background:#f1f1f1;position:relative;top:-40px;
  }
  .x-e-r{
    flex:1;padding-left:10px;
  }
  .x-e-1{
    padding-top:20px;display:flex;justify-content: space-between;
  }
  .x-e-2{padding:10px 20px;background:#f7fcff;}
  .minH300{
    min-height:590px;padding:30px 0;
  }
</style>
