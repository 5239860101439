<template>
  <div class="x-main-box" style="min-width: 1024px;">
    <!--面包屑-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <el-icon class="el-icon-s-home"></el-icon>
        首页
      </el-breadcrumb-item>
      <el-breadcrumb-item>通知管理</el-breadcrumb-item>
      <el-breadcrumb-item>发布</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="background:#fff;margin-top:20px;padding:20px;">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="通知标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="通知内容" prop="content">
          <wang-editor v-model="form.content"></wang-editor>
        </el-form-item>
        <el-form-item label="发送到站外邮箱" prop="email">
          <el-input v-model="form.email" placeholder="输入多个邮箱用半角逗号分隔"></el-input>
        </el-form-item>
        <el-form-item label="通知范围">
          <el-select v-model="fanwei" multiple placeholder="请选择">
            <el-option
              v-for="item in fanweiOpt"
              :key="item.id"
              :label="`${item.nature==='enterprise'?item.enterpriseName:item.surname+item.name} ${item.mobile||item.email}`"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="mode">
          <el-checkbox-group v-model="form.mode">
            <el-checkbox label="inside">站内通知</el-checkbox>
            <el-checkbox label="email">邮件通知</el-checkbox>
            <!--<el-checkbox label="sms">短信通知</el-checkbox>-->
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="通知附件" prop="attachmentCode">
          <upload-file v-model="form.attachmentCode" @setAttachment="setAttachment">
            <el-button type="primary" plain>上传附件</el-button>
          </upload-file>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="confirm" size="large">发布</el-button>
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
  import { apiNotice } from '@/api/RequirementManage';
  import {apiGetUser} from "../../../api/UserManage";
  import UploadFile from "../../../components/comm/UploadFile";
  import WangEditor from "../../../components/comm/WangEditor";
  export default {
    components: {UploadFile,WangEditor},
    data() {
      return {
        form: {
          title: '',
          content: '',
          email: '',
          userId: '',
          username: '',
          mobile: '',
          mode: ['inside'],
          attachmentCode: '',
          setAttachment: ''
        },
        rules: {
          title: { required: true, message: '请输入', trigger: 'blur' },
          content: { required: true, message: '请输入', trigger: 'blur' },
          mode: { required: true, message: '请选择', trigger: 'change' },
        },
        fanwei: [],
        fanweiOpt: []
      };
    },
    watch: {
      fanwei(val){
        this.setUsers(val);
      }
    },
    mounted() {
      apiGetUser().then(res=>{
        if(res.success){
          this.fanweiOpt = res.result;
        }
      })
    },
    methods: {
      setAttachment(name){
        this.$set(this.form, 'attachment', name);
      },
      restForm(){
        this.$refs.form.resetFields();
      },
      confirm(){
        //console.log(this.form);
        this.$refs.form.validate((valid)=>{
          if(valid){
            let params = {
              ...this.form,
              mode: this.form.mode.join(','),
              sendAll: this.form.userId===""
            };
            apiNotice(params).then(res=>{
              if(res.success){
                this.$router.push('/System/Notice');
              }
            })
          }
        });
      },
      setUsers(val){
        let selectFanwei = this.fanweiOpt.filter(i=>{
          return val.includes(i.id);
        });
        let userId = [], username = [], mobile = [];
        _.each(selectFanwei, i=>{
          userId.push(i.id);
          username.push(i.username);
          mobile.push(i.mobile);
        });
        this.$set(this.form, 'userId', userId.join(','));
        this.$set(this.form, 'username', username.join(','));
        this.$set(this.form, 'mobile', mobile.join(','));
      }
    }
  };
</script>
<style></style>
