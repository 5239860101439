const validateReg = {
  "zh-en": /^[a-zA-Z\u4e00-\u9fa5]+$/,
  "zh": /[\u4E00-\u9FA5\uF900-\uFA2D]{1,}/,
  "mobile": /^1[3-9](\d{9})$/,
  "email": /^[^@]+@[^@]+$/,
  "number": /^[0-9]*[1-9][0-9]*$/,
};
export const reValidateReg = (str='')=>{
  return validateReg[str];
};
export const validateFun = (value, reg = "")=>{
  if(!reg){
    return false;
  }
  return validateReg[reg].test(value);
}
