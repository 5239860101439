import store from '@/store'
let baseUrl = '';

function checkRoleByType() {
	// let userInfo = this.$store.state.userInfo;
	// let type = 0;
	// if (userInfo.roleNames.indexOf('管理人员') >= 0) {
	// 	type = 1;
	// } else if (
	// 	userInfo.roleNames.indexOf('管理人员') < 0 &&
	// 	(userInfo.roleNames.indexOf('事业部需求对接人') >= 0)
	// ) {
	// 	type = 2;
	// } else {
	// 	type = 0;
	// }
	// return {
	// 	userInfo: userInfo,
	// 	type: type
	// }
}

function formatDateTime(value, type) {
	if (value == null) {
		return '';
	}
	let dates = new Date(value);
	let year = dates.getFullYear();
	let month = dates.getMonth() + 1;
	if (month < 10) month = '0' + month;
	let day = dates.getDate();
	if (day < 10) day = '0' + day;
	let hours = dates.getHours();
	if (hours < 10) hours = '0' + hours;
	let minutes = dates.getMinutes();
	if (minutes < 10) minutes = '0' + minutes;
	let seconds = dates.getSeconds();
	if (seconds < 10) seconds = '0' + seconds;
	if (type === 'datetime') {
		return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
	} else if (type === 'month') {
		return year + '-' + month;
	} else if (type === 'sfm') {
		return hours + ':' + minutes;
	} else if (type === 'dateZh') {
		return year + '年' + month + '月' + day+'日';
	} else {
		return year + '-' + month + '-' + day;
	}
}

function formatMoney(value, mode) {
	if (value == null) {
		return '-';
	}
	if (mode == 1) {
		return value.toFixed(2)
	} else if (mode == 2) {
		value = value.toString()
		var intPart = Number(value).toFixed(0); //获取整数部分
		var intPartFormat = intPart
			.toString()
			.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断
		var floatPart = ".00"; //预定义小数部分
		var value2Array = value.split(".");
		//=2表示数据有小数位
		if (value2Array.length == 2) {
			floatPart = value2Array[1].toString(); //拿到小数部分
			if (floatPart.length == 1) {
				//补0,
				return intPartFormat + "." + floatPart + "0";
			} else {
				return intPartFormat + "." + floatPart;
			}
		} else {
			return intPartFormat + floatPart;
		}
	} else {
		return value
	}
}

//取年月日
function getDateByType(type) {
	var date = new Date();
	var myyear = date.getFullYear();
	var mymonth = date.getMonth() + 1;
	var myweekday = date.getDate();
	if (mymonth < 10) {
		mymonth = '0' + mymonth;
	}
	if (myweekday < 10) {
		myweekday = '0' + myweekday;
	}
	if (type === "year") {
		return myyear;
	} else if (type === "month") {
		return myyear + '-' + mymonth;
	}
	return myyear + '-' + mymonth + '-' + myweekday;
}

function formatDataCN(val) {
	return val.replace('-', '年') + "月";
}

function getCycle(val, type) {
	if (type === 2) {
		val = new Date(val + '-01');
	}
	var now = val;
	var nowMonth = now.getMonth(); //当前月
	var nowYear = now.getFullYear(); //当前年
	//本月的开始时间
	var monthStartDate = new Date(nowYear, nowMonth, 1);
	//本月的结束时间
	var monthEndDate = new Date(nowYear, nowMonth + 1, 0);
	return this.formatDateTime(monthStartDate) + '至' + this.formatDateTime(monthEndDate);
}

function getCycleList(val, type) {
	if (type === 2) {
		val = new Date(val + '-01');
	}
	var now = val;
	var nowMonth = now.getMonth(); //当前月
	var nowYear = now.getFullYear(); //当前年
	//本月的开始时间
	var monthStartDate = new Date(nowYear, nowMonth, 1);
	//本月的结束时间
	var monthEndDate = new Date(nowYear, nowMonth + 1, 0);
	var list = [];
	list.push(this.formatDateTime(monthStartDate));
	list.push(this.formatDateTime(monthEndDate));
	return list;
}

function downloadFileParms(url, _param = null) {
	let param = {};
	if (_param != null) {
		param = _param;
	} else {
		param = this.$refs.searchForm.reParams();
	}

	param.isExport = 1;
	delete param.currPage;
	delete param.pageSize;
	param = JSON.stringify(param)
		.replace('{', '')
		.replace('}', '')
		.replace(/","/g, '&')
		.replace(/,"/g, '&')
		.replace(/:/g, '=')
		.replace(/":"/g, '=')
		.replace(/"/g, '');
	let _url = url + "?" + param;
	downloadFile(_url)
}

function downloadFile(_url) {
	const link = document.createElement('a');
	link.href = baseUrl + _url;
	link.click();
	window.URL.revokeObjectURL(link.href);
}

function getDayNo(val) {
	let now = new Date(val)
	var _day = now.getDate(); //当前月
	if (_day < 10) _day = '0' + _day;
	return _day;
}

/*function getDayNo2(value) {
	let dates = new Date(value);
	//let year = dates.getFullYear();
	let month = dates.getMonth() + 1;
	if (month < 10) month = '0' + month;
	let day = dates.getDate();
	if (day < 10) day = '0' + day;
}*/

function trimToShort(val, len) {
	if (val == undefined || val == null) {
		return '';
	}
	if (val.length <= len) {
		return val;
	} else {
		return val.substring(0, len - 1) + '...';
	}
}

function returnYYYYMM(year, month) {
	if (month < 10) month = '0' + month;
	return year + '-' + month;
}

function returnYYYYMMDD(year, month, day) {
	if (month < 10) month = '0' + month;
	if (day < 10) day = '0' + day;
	return year + '-' + month + '-' + day;
}

function getMonthDayCN(value) {
	if (value == null) {
		return '';
	}
	let dates = new Date(value);
	let month = dates.getMonth() + 1;
	if (month < 10) month = '0' + month;
	let day = dates.getDate();
	if (day < 10) day = '0' + day;

	return month + '月' + day + '日';
}

function getWeekDay(value) {
	if (value == null) {
		return '';
	}
	let dates = new Date(value);
	var str = "星期";
	var week = dates.getDay();
	switch (week) {
		case 0:
			str += "日";
			break;
		case 1:
			str += "一";
			break;
		case 2:
			str += "二";
			break;
		case 3:
			str += "三";
			break;
		case 4:
			str += "四";
			break;
		case 5:
			str += "五";
			break;
		case 6:
			str += "六";
			break;
	}

	return str;
}

function redirectUrl(module, url, y = '') {
	//window.sessionStorage.setItem('x', module);
	history.pushState(null, url, url);
	//window.location.reload();
	window.menuManage(module, y, url);
}

import $axios from '@/axios/index'

function apiGetDownload(_url, param) {
	let _param = [];
	for (var key in param) {
		_param.push(key + '=' + param[key]);
	}
	let _p = _param.join('&')
	return $axios({
		url: _url + '?' + _p,
		method: 'get',
		data: {},
		responseType: 'blob'
	})
}

function downloadFileBlob(_url, param) {
	apiGetDownload(_url, param).then(res => {
		exportFileByBlob(res);
	});
}

export function exportFileByBlob(res) {
	//console.log(res)
	const blob = new Blob([res], {
		type: "application/vnd.ms-excel,charset=gbk"
	});
	const fileName = '导出数据.xlsx';
	if (window.navigator.msSaveOrOpenBlob) {
		navigator.msSaveBlob(blob, fileName);
	} else {
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
		window.URL.revokeObjectURL(link.href);
	}
}

function returnAllPageFunc(pageSize, arr) {
	let pageNum = 1
	let pageObj = {
		pageNum: 1,
		list: []
	}
	let pageResult = []

	let newArr = JSON.parse(JSON.stringify(arr))
	let totalPage = newArr.length ? Math.ceil(arr.length / pageSize) : 0 // 计算总页数

	for (let i = 1; i <= totalPage; i++) {
		if (totalPage == 1) {
			pageNum += 1
			pageObj.list = newArr.splice(0, arr.length)
		} else if (i <= totalPage) {
			pageNum += 1
			pageObj.list = newArr.splice(0, pageSize)
		} else {
			pageObj.list = newArr.splice(0, arr.length % pageSize)
		}
		pageResult.push(pageObj)
		pageObj = {
			pageNum: pageNum,
			list: []
		}
	}
	//console.log(`分页：${JSON.stringify(pageResult)}`)
	return pageResult
}

export {
	formatDateTime,
	formatMoney,
	getDateByType,
	formatDataCN,
	getCycle,
	getCycleList,
	downloadFile,
	downloadFileParms,
	trimToShort,
	getDayNo,
	returnYYYYMM,
	returnYYYYMMDD,
	getMonthDayCN,
	getWeekDay,
	redirectUrl,
	downloadFileBlob,
	apiGetDownload,
	checkRoleByType,
	returnAllPageFunc
}
