<template><!--所属行业-->
  <div>
    <div class="i-box">
      <el-select v-model="i1"
                 :filterable="isFilter"
                 :allow-create="allowCreate"
                 :placeholder="placeholder"
      @change="change">
        <el-option
          v-for="item in selectOpt"
          :key="item.id"
          :label="item.keyword"
          :value="item.keyword">
        </el-option>
      </el-select>
      <el-button class="x-green x-ml10 x-mr20" @click="addEvent">+ 添加</el-button>
      <slot name="des"></slot>
    </div>
    <div>
      <el-tag class="x-mr10" closable v-for="(item, index) in tagOpt"
      :key="index" @close="delEvent(index)">{{item}}</el-tag>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  export default {
    props: {
      isFilter: {
        type: Boolean,
        default: false
      },
      allowCreate: {
        type: Boolean,
        default: false
      },
      selectOpt: {
        type: Array,
        default: ()=> []
      },
      placeholder: {
        type: String,
        default: '请选择'
      },
      tagOpt: {
        type: Array,
        default: ()=> []
      },
      ikey: {
        type: String,
        default: ''
      },
      max: {
        type: Number,
        default: 10
      }
    },
    emits: ['setForm', 'delEvent'],
    data(){
      return {
        i1: ''
      }
    },
    methods: {
      isAdd(val){
        let k = this.selectOpt.filter(i=>{
          return i.keyword === val;
        });
        return _.isEmpty(k);
      },
      addEvent(){
        if((this.i1&&this.tagOpt.includes(this.i1))||this.tagOpt.length >= this.max){
          return ;
        }
        let value = _.concat([], this.tagOpt, [this.i1]);
        this.$emit('setForm', {
          key: this.$props.ikey,
          value: this.i1,
          formValue: value.join('_'),
          isAdd: this.isAdd(this.i1)
        });
      },
      delEvent(index){
        this.$emit('delEvent', index);
      },
      change(val){
        console.log('change---', val);
      }
    }
  }
</script>

<style scoped>
.i-box{display:flex;}
</style>
