<template>
  <div class="du-box">
    <div class="du-user"></div>
    <div class="du-user-info">
      <div>{{cacheDemand.linkerSecondName}}{{cacheDemand.linkerFirstName}}</div>
      <div class="du-span x-color666">
        <span>{{cacheDemand.linkerPosition}}</span>
        <span>{{cacheDemand.linkerPhone}}</span>
        <span>{{cacheDemand.linkerEmail}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  /*
  * "linkerSecondName":"需求联系人姓氏",
  "linkerFirstName":"需求联系人名字",
  "linkerDetp":"需求联系人所在部门",
  "linkerPosition":"需求联系人职务",
  "linkerPhone":"需求联系人手机号",
  "linkerEmail":"需求联系人邮箱",*/
  export default {
    computed: {
      cacheDemand(){
        return this.$store.getters.getCacheDemand;
      }
    }
  }
</script>

<style scoped>
.du-box{display:flex;align-items:center;padding:20px;background: #F8F9FA;border-radius: 0 0 20px 20px;}
  .du-user{width:32px;height:32px;border-radius:20px;margin-right:20px;background:#000;}
  .du-span span{margin-right:30px;}
</style>
