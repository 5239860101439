<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 300px; overflow-y: hidden;"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
    />
  </div>
</template>

<script>
  import Vue from 'vue'
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  export default Vue.extend({
    components: { Editor, Toolbar },
    props: {
      //此处一定要用value
      value: {
        type: String
      }
    },
    data() {
      return {
        editor: null,
        html: '',
        toolbarConfig: {
          toolbarKeys: ['bold', 'color', 'fontSize', 'justifyLeft', 'justifyRight',
            'justifyCenter', 'insertImage', 'header1',
            'insertTable',
            //'insertVideo', 'uploadVideo',
            'uploadImage']
        },
        editorConfig: {
          placeholder: '请输入内容...',
          height: '100px',

          MENU_CONF: {
            // 配置上传图片
            uploadImage: {
              base64LimitSize: 1000 * 1024,
              server: "/cbmp/rest/SysFile"
            }
          }
        },
        mode: 'default', // or 'simple'
      }
    },
    watch: {
      "value":{
        immediate:true,//首次加载的时候执行函数
        handler(val){
          this.html = val||'';
        }
      }
    },
    methods: {
      onCreated(editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      },
      onChange(editor) {
        let _html = editor.getHtml();
        this.$emit("input", _html);
      },
    },
    mounted() {
      //console.log(this.value)
      // 模拟 ajax 请求，异步渲染编辑器
      /*setTimeout(() => {
        this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
      }, 1500)*/
    },
    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    }
  })
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
