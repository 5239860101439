<template>
  <div>
    <x-card style="padding:0;position:relative;">
      <span @click="toPage('/UserInfoSet')" class="x-btn x-blue"
            style="padding:0 20px;font-size:12px;position:absolute;right:0;top:20px;cursor:pointer;border:none;">{{$t('operationEdit')}}</span>
      <img alt="" src="../assets/images/user-card-bg.png" style="border-radius:20px 20px 0 0;"/>
      <div>
        <div style="position:relative;width:68px;height:68px;margin:-38px auto 0;">
          <el-image :preview-src-list="[`/cbmp/file/${userInfo.headimg}`]"
                    :src="`/cbmp/file/${userInfo.headimg}`"
                    style="width: 100%; height: 100%;border-radius:50%;"
                    v-if="userInfo.headimg">
          </el-image>
        </div>
        <div class="index-name x-tc" style="width:90%;margin:10px auto;">
          <div><span class="x-f16">{{isZh(`${userInfo.surname}${userInfo.name}`)?`${userInfo.surname||''}${userInfo.name||''}`:`${userInfo.name||''} ${userInfo.surname||''}`}}</span>
            <span class="x-gray x-f12">{{reNature(userInfo.nature)}}</span></div>
        </div>
      </div>
      <div style="margin:0 20px;padding:20px 0;border-top:1px solid #ebf4f8;">
        <div class="x-bold">{{$t('l3_1')}}</div>
        <div class="index-tag-box x-mt10">
          <span :key="index" v-for="(item, index) in tagOpt">{{item}}</span>
        </div>
      </div>

    </x-card>
    <x-card>
      <div class="index-tag-t" style="display:flex;justify-content: space-between;">
        <div class="x-bold">{{$t('l2_1')}}</div>
        <el-popover
          placement="right"
          :width="lang==='en'?300:200"
          trigger="manual"
          v-model="visible">
          <div>
            <div class="el-icon-close x-fr x-cursor" @click="visible=false"></div>
            <span class="x-bold">{{$t('leftMenu6')}}</span>
          </div>
          <ul class="x-left-menu">
            <li v-for="item in menuData" :key="item.id">
              <div style="display:flex;justify-content: space-between;">
                <div>{{lang==='en'?item.nameEn:item.name}}</div>
                <div>
                  <el-switch v-model="item.status" @change="changeParent(item)"></el-switch>
                </div>
              </div>
              <ul>
                <li v-for="jtem in item.children" :key="jtem.id">
                  <div style="display:flex;justify-content: space-between;"
                       v-if="jtem.menuRole.indexOf(userInfo.nature)>=0||(jtem.sysRole&&userInfo.roleNames&&userInfo.roleNames.includes(jtem.sysRole))">
                    <div>{{lang==='en'?jtem.nameEn:jtem.name}}</div>
                    <div>
                      <el-switch v-model="jtem.status" @change="changeChildren(jtem)"></el-switch>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
          <div slot="reference" class="el-icon-setting x-cursor" @click="visible=!visible"></div>
        </el-popover>

      </div>
      <index-menu :menu-data="dynamicMenuData"></index-menu>
    </x-card>
  </div>
</template>

<script>
  import MixinsBase from "../mixins/base";
  import XCard from "../components/XCard";
  import IndexMenu from "./IndexMenu";
  import {validateFun} from '../filters/formValidate';
  import {apiPutAppUser} from "../api";

  export default {
    name: "IndexLeft",
    components: {IndexMenu, XCard},
    mixins: [MixinsBase],
    data() {
      return {
        menuData: [{
          "id": "1",
          "name": "需求",
          "nameEn": "Demand",
          "icon": "",
          "url": null,
          "status": true,
          "menuRole": "enterprise,expert",
          "children": [{
            "id": "1_1",
            "name": "我发布的需求",
            "nameEn": "My published requirements",
            "icon": "icim-handshake-angle-2",
            "url": "DemandMy",
            "status": true,
            "menuRole": "enterprise,expert"
          }, {
            "id": "1_2",
            "name": "推荐给我的需求",
            "nameEn": "Recommended to my needs",
            "icon": "icim-heart-2",
            "url": "RecommendToMe",
            "status": true,
            "menuRole": "expert"
          }, {
            "id": "1_3",
            "name": "我对接的需求",
            "nameEn": "My collection needs",
            "icon": "icim-bookmark-2",
            "url": "DemandCollection",
            "status": true,
            "menuRole": "enterprise,expert"
          }]
        }, {
          "id": "2",
          "name": "活动",
          "nameEn": "Events",
          "icon": "",
          "url": null,
          "status": true,
          "menuRole": "enterprise,expert",
          "children": [{
            "id": "2_1",
            "name": "发布的活动",
            "nameEn": "Published Activities",
            "icon": "icim-handshake-angle-2",
            "url": "ActivityList",
            "status": true,
            //"menuRole": "enterprise,expert",
            "menuRole": "",
            "sysRole": "ADMIN"
          }]
        }, {
          "id": "3",
          "name": "课程",
          "nameEn": "Courses",
          "icon": "",
          "url": null,
          "status": true,
          "menuRole": "enterprise,expert"
        }, {
          "id": "4",
          "name": "方案",
          "nameEn": "Solutions",
          "icon": "",
          "url": null,
          "status": true,
          "menuRole": "enterprise,expert"
        }],
        menuDataStatus: {},
        visible: false,
        timer: null,

      }
    },
    computed: {
      tagOpt() {
        let {keyword} = this.$store.getters.getUserInfo;
        if (keyword) {
          return keyword.split('_');
        } else {
          return [];
        }
      },
      natureOpt() {
        return this.$store.getters.getNature;
      },
      profile() {
        return this.$store.getters.getProfile;
      },
      dynamicMenuData() {
        let { menuDataStatus } = this.$store.getters.getProfile;
        if(!_.isEmpty(menuDataStatus)){
          _.each(this.menuData, i=>{
            i.status = menuDataStatus[i.id];
            if(i.children){
              _.each(i.children, j=>{
                j.status = menuDataStatus[j.id];
              })
            }
          })
        }
        return this.menuData;
      }
    },
    methods: {
      validateFun,
      reNature(val) {
        let oo = this.natureOpt.filter(i => {
          return val === i.id;
        });
        if (this.lang === 'en') {
          return oo[0] && oo[0]['nameEn'];
        } else {
          return oo[0] && oo[0]['name'];
        }
      },
      isZh(value) {
        return this.validateFun(value, 'zh');
      },
      changeParent(item) {
        this.menuDataStatus[item.id] = item.status;
        _.each(item.children, i => {
          i.status = item.status;
          this.menuDataStatus[i.id] = item.status;
        });
        this.changeSave();
      },
      changeChildren(item) {
        this.menuDataStatus[item.id] = item.status;
        this.changeSave();
      },
      save() {
        let params = {
          ...this.profile,
          menuDataStatus: {
            ...this.profile.menuDataStatus,
            ...this.menuDataStatus
          }
        };
        apiPutAppUser({
          id: this.userInfo.id,
          profile: JSON.stringify(params)
        }, {
          noLoading: false
        }).then(res => {
          if (res.success) {
            this.$store.dispatch('setProfile', params);
          }
        })
      },
      changeSave() {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(this.save, 1000);
      }
    }
  }
</script>

<style scoped>
  .index-tag-box span {
    display: inline-block;
    margin: 6px 6px 0 0;
    padding: 4px 10px;
    background: rgba(2, 104, 161, 0.05);
    border-radius: 8px;
  }

  .x-left-menu > li {
    margin: 10px 0;
  }

  .x-left-menu > li li {
    margin-top: 10px;
    padding: 0 0 0 20px;
  }
</style>
