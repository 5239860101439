<template>
  <div class="x-imgbox">
    <template v-if="code!==''&&code!==null&&isTemp">
      <i v-if="btnDelete" class="el-icon-error"
         style="background-color:#fff;border-radius:12px;position:absolute;right:-6px;top:-6px; cursor:pointer;font-size:24px;z-index:9;"
         @click="deleteUpload"></i>
      <el-image
        style="width: 100%; height: 100%;"
        :src="imgsrc"
        :preview-src-list="[imgsrc]" fit="contain">
      </el-image>
    </template>
    <input :style="upStyle" class="x-upImg" type="file" @change="uploadChange" accept=".jpg,.jpeg,.png,.gif"/>
    <slot>
      <span class="el-icon-plus" v-if="code===''||code===null"></span>
    </slot>
  </div>
</template>

<script>
  import {apiPostService} from '@/api';

  export default {
    props: {
      //此处一定要用value
      value: {
        type: String
      },
      maximage: {
        type: Number,
        default: 2
      },
      url: {
        type: String,
        default: '/cbmp/rest/SysFile',
      },
      surl: {
        type: String,
        default: '/cbmp/file'
      },
      btnDelete: {
        type: Boolean,
        default: false
      },
      isTemp: {
        type: Boolean,
        default: false
      },
      upStyle: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        code: ''
      }
    },
    watch: {
      value(val) {
        this.code = val;
      }
    },
    computed: {
      imgsrc() {
        return this.surl + '/' + this.code;
      },
    },
    created() {
      if (this.value) {
        this.code = this.value;
      }
    },
    methods: {
      deleteUpload() {
        this.code = '';
        this.$emit("input", this.code);
      },
      uploadChange(ev) {
        let files = ev.target.files;
        if (files[0]) {
          let max = this.$props.maximage;
          if (max) {
            const isLt2M = files[0].size / 1024 / 1024 < max;
            if (!isLt2M) {
              this.$message.error(`上传图片大小不能超过 ${max}MB!`);
              return false;
            }
          }
          let name = files[0]['name'];
          let ext = '';
          ext = name.substr(name.lastIndexOf('.'));
          if (ext === '.png' || ext === '.jpg' || ext === '.jpeg') {
            this.reBase64(files[0]);
          } else {
            this.$message.info('只能上传png、jpg、jpeg格式图片！');
          }
        }
      },
      reBase64(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          let base64 = e.target['result'];
          base64 = base64.split('base64,')[1];
          let params = {
            data: base64,
            ext: file.name.substr(file.name.lastIndexOf('.')),
            filename: file.name,
            type: file.type,
          };
          apiPostService(this.url, params).then(res => {
            if (res.success) {
              this.code = res.result[0]['code'];
              if (this.$listeners['reBase64']) {
                this.$emit("reBase64", base64);
              }
              this.$emit("input", this.code);
            }
          });
        };
      },
    }
  }
</script>

<style scoped>
  .x-imgbox {
    width: 100px;
    height: 100px;
    /*line-height: 100px;*/
    text-align: center;
    position: relative;
    background-color: #ddd;
  }

  .x-upImg[type='file'] {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
</style>
