<template>
  <sign-layout>
    <div>
    <h1 slot="title">{{$t('signIn2')}}</h1>
    <el-form ref="form" :model="form" class="demo-dynamic">
      <el-form-item prop="email" :rules="{ required: true, message: $t('forgotPwd1'), trigger: 'blur' }">
        <el-input v-model="form.email" prefix-icon="el-icon-message" :placeholder="$t('forgotPwd1')"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button style="width:100%" type="primary" round @click="sendEmail" :loading="btnLoading">{{$t('forgotPwd2')}}</el-button>
      </el-form-item>
    </el-form>
    </div>

    <div class="login-footer" slot="footer">
      <el-dropdown @command="setLanguage">
        <span class="el-dropdown-link">
          {{$t("name")}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="zh" v-if="lang==='en'">简体中文</el-dropdown-item>
          <el-dropdown-item command="en" v-if="lang==='zh'">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div>
        <el-button type="text" @click="toPage('/SignIn')" class="x-f14">{{$t('signIn3')}}</el-button>
      </div>
    </div>
  </sign-layout>
</template>

<script>
  //import "@/assets/style/login.css"
  import SignLayout from "./SignLayout";
  import {apiResetPwdByEmail} from '../../api/index';
  import MixinsBase from '../../mixins/base';
  export default {
    components: {SignLayout},
    mixins: [MixinsBase],
    data(){
      return {
        form: {
          email: ''
        }
      }
    },
    computed: {
      btnLoading(){
        return this.$store.getters.getBtnLoading;
      }
    },
    methods: {
      sendEmail(){
        this.$refs.form.validate(valid=>{
          if(valid){
            let {email} = this.form;
            apiResetPwdByEmail({
              email,
              lang: this.lang
            }).then(res=>{
              if(res.success){
                //this.$router.push({ name: 'Tips', query: { type: 'ForgotPwd', email } });
                this.toPage('/Tips', { type: 'ForgotPwd', email })
              }
            })
          }
        })

      }
    }
  };
</script>

<style lang="css" scoped>

</style>
