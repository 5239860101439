<template>
  <div class="x-main">
    <x-header></x-header>
    <div class="x-content x-mt20">
      <div class="x-left x-mr20">
        <index-left></index-left>
      </div>
      <div class="x-center">
        <index-center></index-center>
      </div>
      <div class="x-right x-ml20">
        <index-right></index-right>
      </div>
    </div>
  </div>
</template>

<script>
  import XHeader from "../components/XHeader";
  import MixinsBase from '../mixins/base';
  import IndexMenu from "./IndexMenu";
  import IndexCenter from "./IndexCenter";
  import IndexLeft from "./IndexLeft";
  import IndexRight from "./IndexRight";
  export default {
    components: {
      IndexRight,
      IndexLeft,
      IndexCenter,
      IndexMenu,
      XHeader,
    },
    mixins: [MixinsBase],

  }
</script>

<style lang="scss" scoped>

</style>

