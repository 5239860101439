<template>
  <el-form :model="zbForm" ref="zbForm">
  <div style="position:relative;clear:both;">
    <el-form-item class="a-loop-box"
         v-for="(item, index) in zbForm.funcList"
    :key="item.key">
      <div class="a-name-box x-mb20">
        <el-form-item :prop="'funcList.' + index + '.name'"
                      :rules="{required: true, message: $t('placeholder'), trigger: 'blur'}" style="flex:1;margin-right:20px;">
          <el-input v-model="item.name" type="text" class="a-name" :placeholder="$t('typeA3')">
            <template slot="prepend">No.{{index+1}}</template>
          </el-input>
        </el-form-item>

        <el-form-item :prop="'funcList.' + index + '.type'"
                      :rules="{
      required: true, message: $t('placeholder'), trigger: 'change'
    }">
          <el-radio-group v-model="item.type" class="a-radio">
            <el-radio-button v-for="item in funcListOpt"
                             :key="item.id"
                             :label="item.id">{{lang==='en'?item.nameEn:item.name}}</el-radio-button>
          </el-radio-group>
        </el-form-item>

      </div>
      <el-form-item :prop="'funcList.' + index + '.content'"
                    :rules="{
      required: true, message: $t('placeholder'), trigger: 'blur'
    }">
        <el-input v-model="item.content" type="textarea" :rows="5" :placeholder="$t('loopzb1')"></el-input>
      </el-form-item>
      <div class="a-del" v-if="index!==0" @click="delClick(index)">
        <el-button type="danger" icon="el-icon-delete" circle></el-button>
      </div>
    </el-form-item>
    <div class="a-add" @click="addClick">
      <el-button type="success" icon="el-icon-plus" circle></el-button>
    </div>
  </div>
  </el-form>
</template>

<script>
  import _ from 'lodash';
  import MixinsBase from '../../../mixins/base';
  import {reValidateReg} from "../../../filters/formValidate";
  export default {
    props: ['value'],
    mixins: [MixinsBase],
    data(){
      return {
        zbForm: {
          funcList:[
            {
              name: '',
              type: '',
              content: '',
            }
          ]
        }

      }
    },
    computed: {
      funcListOpt(){
        return this.$store.getters.getFuncListOpt;
      },
    },
    watch: {
      'value': {
        immediate: true,
        handler: function(val){
          this.$set(this.zbForm, 'funcList', _.isEmpty(val) ? [{
            name: '',
            type: '',
            content: ''
          }] : val);
        }
      }
    },
    methods: {
      reValidateReg,
      addClick(){
        let {funcList} = this.zbForm;
        this.$set(this.zbForm, 'funcList', [
          ...funcList,
          {
            name: '',
            type: '',
            content: '',
          }
        ])
      },
      delClick(index){
        let {funcList} = this.zbForm;
        funcList.splice(index, 1);
        this.$set(this.zbForm, 'funcList', [
          ...funcList
        ])
      },
      reParams(){
        let params = [];
        this.$refs.zbForm.validate(valid=>{
          if(valid){
            params = this.zbForm.funcList;
          }
        })
        return params;
      },
      setValue(val){
        let params = [];
        _.each(val, i=>{
          if(i.name&&i.type&&i.content){
            params.push(i);
          }
        })
        this.$emit('getFunc', params);
      }
    }
  }
</script>

<style scoped>
  .a-loop-box{width:100%;padding-right:80px;margin-bottom:30px;box-sizing:border-box;position:relative;}
  .a-name-box{display:flex;justify-content:space-between;}
  .a-name{flex:1;margin-right:20px;}
  .a-del,.a-add{position:absolute;right:0;bottom:0;}
  .a-del{bottom:60px;right:-78px;}
</style>
