<template>
	<div class="x-main-box" style="min-width: 1024px;">
		<!--面包屑-->
		<search-form :data="searchData" ref="searchForm" @searchFun="searchFun"></search-form>
		<table-page :total="total" :callback="init" :currpage="currPage" :pagesize="pageSize">
			<el-table :data="tableData" border @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" fixed="left"></el-table-column>
				<el-table-column prop="fullname" :label="lableData[0]" width="130" show-overflow-tooltip>
					<template slot-scope="scope">
						{{ scope.row.fullname }}
					</template>
				</el-table-column>
				<el-table-column prop="email" :label="lableData[1]" width="160" show-overflow-tooltip></el-table-column>
				<el-table-column prop="mobile" :label="lableData[2]" width="120" show-overflow-tooltip></el-table-column>
				<el-table-column prop="content" :label="lableData[3]" show-overflow-tooltip></el-table-column>
				<el-table-column prop="type" :label="lableData[4]">
					<template slot-scope="scope">
						<!--<span class="x-blue x-pointer" @click="expertViewClick(scope.row)">{{ scope.row.name }}</span>-->
            <span class="x-blue x-pointer" v-if="type==='req'" @click="viewClick(scope.row)">{{ scope.row.name||scope.row.expertName }}</span>
            <span v-else>{{ scope.row.name||scope.row.expertName||scope.row.toWhom }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="createDate" label="时间" width="140" show-overflow-tooltip>
					<template slot-scope="scope">
						{{ (scope.row.create_date||scope.row.createDate||scope.row.updateDate) | formatDate('datetime') }}
					</template>
				</el-table-column>
				<!--<el-table-column prop="statusName" label="状态" width="100"></el-table-column>-->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="sendMsgBefore(scope.row)">发送通知</el-button>
          </template>
        </el-table-column>
			</el-table>
		</table-page>
		<div class="x-mt10">
			<el-button v-if="type == 'recycle'" type="primary" class="x-mt10" @click="delClick">删除</el-button>
			<el-button v-else type="primary" class="x-mt10" @click="handleCommand('all')">批量删除到回收站</el-button>
			<!--<el-dropdown v-else class="x-mt10" @command="handleCommand">
				<el-button type="primary">批量操作所选</el-button>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="a">标记为已处理</el-dropdown-item>
					<el-dropdown-item command="all">删除到回收站</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>-->
		</div>

		<el-dialog :title="reqViewData.title" :visible.sync="viewVisible" class="z-req-detail" width="85%">
			<DemandDetail v-if="viewVisible" lang="zh" :isShow="false" :id="reqViewData.id"></DemandDetail>
		</el-dialog>

		<el-dialog title="专家信息" :visible.sync="expertVisible" class="z-req-detail" width="85%">
			<UserDetail v-if="expertVisible" lang="zh" :id="expertData.id"></UserDetail>
		</el-dialog>
    <!--发送通知-->
    <send-message ref="sendMessage"></send-message>
	</div>
</template>

<script>
import TablePage from '@/components/comm/TablePage';
import SearchForm from '@/components/comm/SearchForm';
import DialogForm from '@/components/comm/DialogForm';
import UserDetail from '@/views/System/UserManage/UserDetail';
import mixins from '@/mixins';
import _ from 'lodash';

//import { apiTechnologyKeyword, apiPostTechnologyKeyword, apiPostAppUser } from '@/api/index';
import { apiPostConsultion,apiRequirementLeaveMessage, apiGetRecycleList, apiCleanRecycle } from '@/api/MessageManage';
import { apiNotice, apiGetListLeaveMessage } from '@/api/RequirementManage';
import DemandDetail from '@/views/Demand/DemandDetail';
import UploadFile from "../../../components/comm/UploadFile";
import SendMessage from "./comm/SendMessage";
//import DemandIndustryInput from './comm/DemandIndustryInput';
import { apiGetConsultion } from '@/api/RequirementManage';
export default {
	components: {
    SendMessage,
    UploadFile,
    DialogForm,
		TablePage,
		SearchForm,
		DemandDetail,
		UserDetail
	},
	mixins: [mixins],
	props: {
		type: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			title: '',
			apiPost: apiPostConsultion,
			searchData: [{ id: 'queryStr', name: '关键字', type: 'text', ctrl: true }],
			multipleSelection: [],
			IDs: [],
      IDsRecords: [],
			viewId: 0,
			reqViewData: {
				id: 0,
				title: ''
			},
			expertData: {
				id: 0,
				title: ''
			},
			viewVisible: false,
			lableData: this.setlable(),
			expertVisible: false,
      msgForm: {
			  title: '',
        userId: '',
        username: '',
        mobile: '',
        mode: ['inside'],
        attachmentCode: '',
        setAttachment: ''
      },
      msgRules: {
			  title: { required: true, message: '请输入', trigger: 'blur' },
			  content: { required: true, message: '请输入', trigger: 'blur' },
			  mode: { required: true, message: '请选择', trigger: 'change' },
      },
      msgItem: {},
		};
	},
	created() {},
	computed: {},
	mounted() {
		this.init();
	},
	methods: {
		setlable() {
			let lable = {
				recycle: ['发布人姓名', '发布人邮箱', '发布人手机号', '留言信息', '留言到'],
				req: ['留言人姓名', '留言人邮箱', '留言人手机号', '留言信息', '至需求'],
				expert: ['留言人姓名', '留言人邮箱', '留言人手机号', '留言信息', '至专家']
			};
			return lable[this.type];
		},
		init(p = {}) {
			let _param = this.$refs.searchForm.reParams();
			let param = Object.assign(_param, p);
			this.refreshTable(param);
		},

		//多选
		handleSelectionChange(val) {
			this.multipleSelection = val;
			let ids = [];
			let IDsRecords = {
        reqIds: [],
        conIds: []
      };
			this.multipleSelection.map(item => {
				ids.push(item.id);
				if(item.reqId){
          IDsRecords.reqIds.push(item.reqId);
        }
        if(item.consultionId){
          IDsRecords.conIds.push(item.consultionId);
        }
			});
			this.IDs = ids;
			this.IDsRecords = IDsRecords;
		},

		handleCommand(command) {
			if (this.IDs.length === 0) {
				this.$message.info('请先选择留言！');
				return false;
			}
			let _data = [];
			let _status = '';
			if (command === 'a') {
				_status = 'processed';
			}
			if (command === 'all') {
				_status = 'recycle';
			}
			this.IDs.forEach(item => {
				_data.push({ id: item, status: _status });
			});
			let _proxy;
			if(this.type === 'req'){
        _proxy = apiRequirementLeaveMessage(_data, 'put');
      }else{
			  _proxy = apiPostConsultion(_data, 'put');
      }
      _proxy.then(res => {
				if (res.success) {
					this.init();
				}
			});
		},
		refreshTable(param) {
			let fixData = {};
			let _api = apiGetConsultion;
			if(this.$props.type === 'req'){
			  _api = apiGetListLeaveMessage;
      }
			if(this.$props.type === 'recycle'){
			  _api = apiGetRecycleList;
      }
			if (this.$props.type === 'expert') {
        fixData.type = this.type;
			}
			this.getTableData(param, _api, fixData);
		},
		searchFun(params = {}) {
			params.pageSize = this.pageSize;
			params.currPage = 1;
			this.searchParams = params;
			this.refreshTable(this.searchParams);
		},
		viewClick(row) {
			this.viewVisible = false;
			this.viewVisible = true;
			//this.reqViewData.id = row.id;
			this.reqViewData.id = row.requirement_id;
			this.reqViewData.title = row.name;
			console.log('this.reqViewData', this.reqViewData);
		},
		expertViewClick(row) {
			this.expertVisible = false;
			this.expertVisible = true;
			//this.expertData.id = row.id;
			//this.expertData.id = row.expertId;
			//this.expertData.title = row.expertName;
      this.expertData.id = row.user_id;
      this.expertData.title = row.name;
		},

		delClick() {
			if (this.IDs.length == 0) {
				this.$message.info('请先选择留言！');
				return false;
			}
			console.log(this.IDsRecords);
			this.del(this.IDsRecords, apiCleanRecycle);
		},
		enableClick(rows) {
			this.apiPost({ id: rows.id, enabled: !rows.enabled }, 'put').then(res => {
				if (res.success) {
					this.closeDialog();
					this.refreshTable();
				}
			});
		},
    sendMsgBefore(item){
		  this.msgItem = item;
      this.$refs.sendMessage.open(item);
    },
	}
};
</script>
<style></style>
