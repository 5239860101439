import { render, staticRenderFns } from "./PreviewD.vue?vue&type=template&id=37768832&scoped=true&"
import script from "./PreviewD.vue?vue&type=script&lang=js&"
export * from "./PreviewD.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37768832",
  null
  
)

export default component.exports