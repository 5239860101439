<template>
  <div class="x-main">
    <x-header></x-header>
    <x-breadcrumb></x-breadcrumb>
    <div class="x-content">
      <x-card style="width:100%;padding:20px;">
        <div class="x-flex">
          <h3 class="x-l-t">发布活动</h3>
          <div class="x-r-btn">
            <el-button @click="save(true)">保存草稿</el-button>
            <el-button type="primary" @click="save(false)">确定发布</el-button>
          </div>
        </div>
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" style="padding:30px 100px;">
          <el-form-item label="活动标题" prop="title">
            <el-input v-model="form.title" placeholder="请输入活动标题"></el-input>
          </el-form-item>
          <el-form-item label="副标题" prop="atitle">
            <el-input v-model="form.atitle" placeholder="请输入活动副标题"></el-input>
          </el-form-item>
          <el-form-item label="活动日期" prop="adate">
            <el-date-picker
              v-model="form.adate"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="活动方式" prop="offline">
            <el-checkbox v-model="form.offline">线下</el-checkbox>
            <el-checkbox v-model="form.online">线上</el-checkbox>
          </el-form-item>
          <el-form-item label="线上活动地址" prop="outsideUrl">
            <el-input v-model="form.outsideUrl" placeholder="HTTP://"></el-input>
          </el-form-item>
          <el-form-item label="活动地点" prop="area">
            <el-cascader
                         v-model="form.area"
                         :options="areaOpt"
                         :props="{ value: lang==='en'?'nameEn':'name',label: lang==='en'?'nameEn':'name',children: 'children'}" :placeholder="$t('placeholder')"></el-cascader>
          </el-form-item>
          <el-form-item label="活动详细地点" prop="address">
            <el-input v-model="form.address" placeholder="具体街道大楼或酒店"></el-input>
          </el-form-item>
          <el-form-item label="活动发布者" prop="publisher">
            <el-input v-model="form.publisher" placeholder="输入智能匹配或者添加自定义名称"></el-input>
          </el-form-item>
          <el-form-item label="活动宣传海报" prop="poster">
            <upload-file v-model="form.poster" width="230px" :filename="form.poster">
              <el-button>上传PNG/JPG/GIF,用于活动详情展示</el-button>
            </upload-file>
          </el-form-item>
          <el-form-item label="列表缩略图" prop="thumbs">
            <upload-image v-model="form.thumbs" style="width:240px;height:240px;display:flex;align-items: center;justify-content: center;" :isTemp="true" :btnDelete="true">
              <div style="position:absolute;" v-show="!form.thumbs">点击添加</div>
            </upload-image>
            <div class="x-gray">
              推荐尺寸240*240px JPG/GIF/PNG
            </div>
          </el-form-item>
          <el-form-item label="活动横幅图" prop="image">
            <upload-image v-model="form.image" style="width:100%;min-height:100px;height:auto;display:flex;align-items: center;justify-content: center;" :isTemp="true" :btnDelete="true">
              <div style="position:absolute;" v-show="!form.image">点击添加</div>
            </upload-image>
            <div class="x-gray">
              推荐尺寸1400*300px JPG/GIF/PNG
            </div>
          </el-form-item>
          <el-form-item label="活动简介" prop="content">
            <wang-editor v-model="form.content"></wang-editor>
          </el-form-item>
        </el-form>
      </x-card>
    </div>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import XBreadcrumb from "../../components/XBreadcrumb";
  import XCard from "../../components/XCard";
  import {getTreeData} from '../../filters/utils';

  import MixinsBase from "../../mixins/base";
  import UploadImage from "../../components/comm/UploadImage";
  import WangEditor from "../../components/comm/WangEditor";
  import UploadFile from "../../components/comm/UploadFile";
  import {apiActivity, apiGetActivity} from '../../api/Activities';
  export default {
    components: {
      UploadFile,
      UploadImage,
      XHeader, XBreadcrumb, XCard, WangEditor
    },
    mixins: [MixinsBase],
    data(){
      let validateAType = (rule, value, callback) => {
        if (!value&&!this.form.online) {
          callback(new Error('请选择活动方式'));
        } else {
          //this.$refs.form.validateField('offline');
          callback();
        }
      };
      return {
        form: {
          title: '',
          atitle: '',
          adate: [],
          online: false,
          offline: false,
          area: '',
          address: '',
          publisher: '',
          poster: '',
          image: '',
          content: '',
        },
        rules: {
          title: { required: true, message: '请输入活动标题', trigger: 'blur' },
          //start , end
          adate: { required: true, message: '请输入活动日期', trigger: 'change' },
          //online , offline
          offline: [
            { required: true, message: '请选择活动方式', trigger: 'change' },
            { validator: validateAType, trigger: 'change' }
          ],
          thumbs: { required: true, message: '请上传列表缩略图', trigger: 'change' },
          image: { required: true, message: '请上传活动横幅图', trigger: 'change' },
          content: { required: true, message: '请输入活动简介', trigger: 'change' },
        }
      }
    },
    computed: {
      getActivity(){
        return this.$store.getters.getActivity;
      },
      query(){
        return this.$route.query;
      },
      areaOpt() {
        let area = this.$store.getters.getArea;
        if(_.isEmpty(area)){
          this.$store.dispatch('setArea');
        }
        return this.getTreeData(area);
      }
    },
    mounted() {
      let { type, id } = this.query;
      if(type === 'quick'){
        let {title, adate}=this.getActivity;
        if(title&&adate){
          this.$set(this.form, 'title', title);
          this.$set(this.form, 'adate', adate);
        }
      }else if( type === 'edit' ){
        apiGetActivity({
          id
        }).then(res=>{
          if(res.success&&res.result){
            let {title,atitle,start,end,online,offline,area, address,publisher, poster, image, content, thumbs} = res.result[0];
            this.form = {
              title,
              atitle,
              adate: [start,end],
              online,
              offline,
              area: area&&area.split(' '),
              address,
              publisher,
              poster,
              image,
              content,
              thumbs
            };
          }
        })
      }else{
        //
      }
    },
    methods: {
      getTreeData,
      save(draft=false){
        this.$refs.form.validate(valid=>{
          if(valid){
            let {adate, area} = this.form;
            let params = {
              ...this.form,
              area: area&&area.join(' '),
              start: adate[0],
              end: adate[1],
              draft
            };
            delete params.adate;
            let {type, id} = this.query;
            let proxy;
            if(type==='edit'){
              proxy = apiActivity({
                ...params,
                id
              }, 'put');
            }else{
              proxy = apiActivity(params);
            }
            proxy.then(res=>{
              if(res.success){
                this.toPage('/Index');
              }
            })
          }
        })

      }
    }
  }
</script>

<style scoped>
.x-flex{
  display:flex;padding:0 0 20px 0;align-items: center;justify-content: space-between;border-bottom:1px solid #f4f4f4;
}
  .x-l-t{
    font-size:20px;
  }
  .x-r-btn{

  }

</style>
