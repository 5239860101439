<template>
  <div class="x-main">
    <x-header></x-header>
    <x-breadcrumb></x-breadcrumb>
    <div class="x-content">
      <x-card style="width:100%;padding:0;">
        <img alt="" v-if="details.image" style="width:100%;border-top-left-radius: 20px;border-top-right-radius: 20px;" :src="`/cbmp/file/${details.image}`" />
        <div style="padding:20px;">
          <!--<div class="x-red x-bold">今天下午4:00</div>-->
          <h3 class="x-t1">{{details.title}}</h3>
          <h4 class="x-t2">--{{details.atitle}}</h4>
          <div class="x-t3" v-if="details.publisher">举办者：{{details.publisher}}</div>
          <div class="x-box1">
            <div class="x-gray el-icon-date"> {{details.start|formatDate('datetime')}}~{{details.end|formatDate('datetime')}}</div>
            <div v-if="details.outsideUrl"><span class="x-gray el-icon-video-camera"> 线上活动链接</span> <a :href="details.outsideUrl" target="_blank" class="x-blue">{{details.outsideUrl}}</a></div>
            <div v-if="details.area"><span class="x-gray el-icon-location-outline"> 线下活动地址</span> <span class="x-bold">{{details.area}}{{details.address}}</span></div>
          </div>
          <div style="display:flex;align-items: center;justify-content: space-between;margin-top:20px;">
            <div class="x-btn-box">
              <div class="x-btn x-btn-save" @click="weWant" v-if="baoming">我要参与</div>
              <div class="x-btn x-btn-disabled" v-if="!baoming">您已报名</div>
              <div class="x-btn x-btn-1" v-show="details.poster" @click="dvisible=true">活动详情海报</div>
            </div>
            <!--<div class="x-btn">分享</div>-->
          </div>
          <div class="x-mt20 x-bold">活动介绍</div>
          <div v-html="details.content"></div>

        </div>
      </x-card>
    </div>
    <dialog-form ref="dialogForm" title="报名表单" @confirm="confirm" @resetForm="resetForm">
      <el-form :model="form" ref="form" class="x-form1">
        <el-form-item v-for="(item, index) in form.formData" :key="item.id"
                      :label="`${index+1}、${item.name}`"
                      :prop="'formData.' + index + '.value'"
        :rules="item.rules">
          <div class="x-gray">{{item.description}}</div>
          <!--复选框-->
          <el-checkbox-group v-model="item.value" v-if="item.type==='checkbox'">
            <el-checkbox style="display:block;" v-for="(jtem, jndex) in item.item" :label="jtem" :key="jndex"></el-checkbox>
          </el-checkbox-group>
          <!--单选框-->
          <el-radio-group v-model="item.value" v-else-if="item.type==='radio'">
            <el-radio style="display:block;" v-for="(jtem, jndex) in item.item" :label="jtem" :key="jndex"></el-radio>
          </el-radio-group>
          <!--文本域-->
          <el-input type="textarea" v-else-if="item.type==='textarea'" v-model="item.value" :placeholder="`请输入${item.name}`"></el-input>
          <!--上传文件-->
          <upload-file v-else-if="item.type==='file'" v-model="item.value"></upload-file>
          <!--输入框-->
          <el-input v-else v-model="item.value" :placeholder="`请输入${item.name}`"></el-input>
        </el-form-item>
      </el-form>
    </dialog-form>
    <el-dialog :visible.sync="dvisible">
      <img v-if="details.poster" width="100%" alt="" :src="`/cbmp/file/${details.poster}`" />
    </el-dialog>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import XBreadcrumb from "../../components/XBreadcrumb";
  import XCard from "../../components/XCard";
  import {apiGetActivity, apiGetQuestionnaire, apiQuestionnaireParticipant, apiGetQestionnaire} from "../../api/Activities";
  import DialogForm from "../../components/comm/DialogForm";
  import UploadFile from "../../components/comm/UploadFile";
  import { reValidateReg } from "../../filters/formValidate";
  export default {
    components: {
      UploadFile,
      DialogForm,
      XHeader,
      XBreadcrumb,
      XCard
    },
    data(){
      return {
        details: {},
        dvisible: false,
        form: {
          formData: [],
        },
        baoming: false
      }
    },
    computed: {
      query(){
        return this.$route.query
      },
      breadcrumb() {
        return this.$store.getters.getBreadcrumb;
      },
      userInfo(){
        return this.$store.getters.getUserInfo;
      }
    },
    mounted() {
      let {id} = this.query;
      apiGetActivity({
        id
      }).then(res=>{
        if(res.success){
          this.details = {
            ...res.result[0]
          }

          let {breadcrumb} = this;
          this.$store.dispatch('setBreadcrumb', [
            ...breadcrumb,
            {
              name: this.details.title,
              lang: this.details.title,
              no: true,
              path: null
            }
          ]);
        }
      })
      apiGetQuestionnaire({
        relevanceId: id,
        relevance: 'Activity',
        includeList: true
      }).then(res=>{
        if(res.success&&res.result[0]){
          let fdata = [];
          _.each(res.result[0]['items'], i=>{
            fdata.push({
              id: i.id,
              description: i.description,
              name: i.name,
              type: i.type,
              item: i.item&&JSON.parse(i.item),
              required: i.required,
              value: i.item&&i.type==='checkbox'?[]:'',
              rules: i.type === 'mobile'?
                ([
                  { required: i.required, message: '必填项', trigger: 'change' },
                  { pattern: reValidateReg('mobile'), message: '格式错误', trigger: 'change' }
                ]): (i.type === 'email'?
                  ([
                    { required: i.required, message: '必填项', trigger: 'change' },
                    { pattern: reValidateReg('email'), message: '格式错误', trigger: 'change' }
                  ]): { required: i.required, message: '必填项', trigger: 'change' })
            })
          })
          this.$set(this.form, 'formData', fdata);
        }
      })
      //是否报名参与
      this.getBaoming();
    },
    methods: {
      reValidateReg,
      getBaoming(){
        let {id, uid} = this.query;
        apiGetQestionnaire({
          relevanceId: id,
          userId: uid
        }).then(res=>{
          if(res.success){
            this.baoming = res.result&&res.result.length===0;
          }
        })
      },
      weWant(){
        this.$refs.dialogForm.openDialog();
      },
      closeDialog(){
        this.$refs.dialogForm.closeDialog();
      },
      confirm(){
        console.log(this.form);
        this.$refs.form.validate(valid=>{
          if(valid){
            let params = {
              userId: this.userInfo.id,
              username: this.userInfo.username,
              questionnaireId: this.id,
              relevance: 'Activity',
              relevanceId: this.details.id,
              items: []
            };
            let items = [];
            _.each(this.form.formData, i=>{
              items.push({
                answer: i.type==='checkbox'?`${JSON.stringify(i.value)}`:i.value,
                itemId: i.id,
              });
            })
            params.items = items;
            apiQuestionnaireParticipant(params).then(res=>{
              if(res.success){
                this.closeDialog();
                this.baoming = false;
              }
            })
          }

        })

      },
      resetForm(){
        this.$refs.form.resetFields();
      }
    }
  }
</script>

<style scoped>
.x-t1, .x-t2{
  line-height:40px;
}
  .x-t1{
    font-size:30px;
  }
  .x-t2{
    font-size:20px;font-weight:normal;color:#666;
  }
  .x-t3{
    color:#666;margin:20px 0 10px 0;font-weight:bold;
  }
  .x-box1{
    border-left:2px solid #cedae0;padding-left:30px;line-height:30px;
  }
  .x-btn-box .x-btn-save{
    min-width:100px;
  }
  .x-btn-box .x-btn{
    margin-left:0;
  }
  .x-btn-box .x-btn-1{
    color:#0069A2;border-color:#0069A2;
  }
.x-btn-box .x-btn-1:hover{
  color:#fff;
}
  .x-btn-disabled{opacity:.5;cursor:not-allowed;}
  .x-btn-disabled:hover{
    background:#fff;color:#021021;
  }
</style>
<style>
  .x-form1 .el-form-item__label{
    text-align:left;display:block;float:none;
  }
  .x-form1 .el-checkbox{
    display:flex!important;align-items:center;margin:5px 0;
  }
  .x-form1 .el-checkbox__label{
    white-space: pre-wrap
  }
  .x-form1 .el-radio{
    margin:5px 0;
  }
</style>
