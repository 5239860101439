<template>
  <div class="step-one-img">
    <img alt="" :src="lang==='en'?srcEn:src" />
    <!--产品设备-->
    <div class="box box-1">
      <div class="box-radio-box">
        <el-radio label="A1" v-model="cacheDemand.type">{{$t('stepOne1')}}</el-radio>
      </div>
      <div class="box-radio-box">
        <el-radio label="A2" v-model="cacheDemand.type">{{$t('stepOne2')}}</el-radio>
      </div>
      <div class="box-radio-box">
        <el-radio label="A3" v-model="cacheDemand.type">{{$t('stepOne3')}}</el-radio>
      </div>
    </div>
    <!--工艺设计-->
    <div class="box box-2">
      <div class="box-radio-box">
        <el-radio label="B1" v-model="cacheDemand.type">{{$t('stepOne1')}}</el-radio>
      </div>
      <div class="box-radio-box">
        <el-radio label="B2" v-model="cacheDemand.type">{{$t('stepOne2')}}</el-radio>
      </div>
      <div class="box-radio-box">
        <el-radio label="B3" v-model="cacheDemand.type">{{$t('stepOne3')}}</el-radio>
      </div>
    </div>
    <!--生产环节-->
    <div class="box box-3">
      <div class="box-radio-box">
        <el-radio label="C1" v-model="cacheDemand.type">{{$t('stepOne1')}}</el-radio>
      </div>
      <div class="box-radio-box">
        <el-radio label="C2" v-model="cacheDemand.type">{{$t('stepOne2')}}</el-radio>
      </div>
      <div class="box-radio-box">
        <el-radio label="C3" v-model="cacheDemand.type">{{$t('stepOne3')}}</el-radio>
      </div>
    </div>
    <!--服务环节-->
    <div class="box box-4">
      <div class="box-radio-box">
        <el-radio label="D1" v-model="cacheDemand.type">{{$t('stepOne1')}}</el-radio>
      </div>
      <div class="box-radio-box">
        <el-radio label="D2" v-model="cacheDemand.type">{{$t('stepOne2')}}</el-radio>
      </div>
      <div class="box-radio-box">
        <el-radio label="D3" v-model="cacheDemand.type">{{$t('stepOne3')}}</el-radio>
      </div>
    </div>
    <!--集成控制-->
    <div class="box box-5 box-radio-box">
      <el-radio label="E1" v-model="cacheDemand.type">{{$t('stepOne4')}}</el-radio>
    </div>
    <!--企业管理-->
    <div class="box box-6 box-radio-box">
      <el-radio label="F2" v-model="cacheDemand.type">{{$t('stepOne5')}}</el-radio>
    </div>
    <!--其他-->
    <div class="box box-7 box-radio-box">
      <el-radio label="F1" v-model="cacheDemand.type">{{$t('stepOne6')}}</el-radio>
    </div>
  </div>
</template>

<script>
  import imgStepOne from '../../assets/images/img-step-one.png';
  import imgStepOneEn from '../../assets/images/img-step-one-en.png';
  import MixinsBase from '../../mixins/base';
  export default {
    name: "StepOneMap",
    data(){
      return {
        src: imgStepOne,
        srcEn: imgStepOneEn,
      }
    },
    mixins: [MixinsBase],
    computed: {
      cacheDemand(){
        return this.$store.getters.getCacheDemand;
      }
    },
  }
</script>

<style scoped>
  .step-one{}
  .step-one-info{width:100%;box-sizing:border-box;padding:0 24px;height:36px;line-height:36px;color:#f37920;background:#fbf8f4;}
  .step-one-img{width:1300px;height:432px;margin:0 auto;position:relative;}
  .box{position:absolute;}
  .box-1,.box-3,.box-4,.box-2{left:37px;top:225px;background: #fdfbf6;border: 1px solid rgba(246,249,247,1);border-radius: 16px;padding:10px;width:134px;}
  .box-2{left:204px;top:225px;}
  .box-3{left:398px;top:225px;}
  .box-4{left:611px;top:225px;}
  .box-5{left:776px;top:240px;width: 100px;}
  .box-6{left:909px;top:240px;width:116px;}
  .box-7{left:1057px;top:240px;}
  .box-radio-box{background: #fff;padding:6px 10px;
    border: 1px solid rgba(227,239,246,1);
    border-radius: 16px;}
  .box .box-radio-box{margin-top:20px;}
  .box .box-radio-box:first-child{margin-top:0;}
</style>
<style>
  .box .el-radio{display:flex;white-space:pre-wrap;}
</style>
