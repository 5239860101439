import { render, staticRenderFns } from "./TypeB.vue?vue&type=template&id=7e496864&scoped=true&"
import script from "./TypeB.vue?vue&type=script&lang=js&"
export * from "./TypeB.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e496864",
  null
  
)

export default component.exports