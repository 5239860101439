<template>
  <div>
    <el-button @click="open" style="margin-bottom:24px;">{{$t('mboadr1')}}</el-button>
    <div v-if="lists.length===0">{{$t('mboadr2')}}</div>
    <ul class="x-lists-ul" v-else>
      <li v-for="item in lists" :key="item.id">
        <div>{{entity==='ExpertOrg'?item.orgName:''}}{{item.title||item.name}}</div>
        <div>
          <el-button type="text" @click="edit(item)">{{$t('mboadr3')}}</el-button>
          <el-button type="text" class="x-red" @click="del(item.id)">{{$t('mboadr4')}}</el-button>
        </div>
      </li>
    </ul>
    <dialog-form ref="dialogForm" :title="title" @confirm="confirm" @resetForm="resetForm">
      <slot></slot>
    </dialog-form>
  </div>
</template>

<script>
  import $axios from '@/axios/index'
  import DialogForm from "../../../components/comm/DialogForm";
  export default {
    props: ['formData', 'entity', 'title'],
    components: {DialogForm},
    data(){
      return {
        lists: [],
        id: -1
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters.getUserInfo;
      }
    },
    mounted() {
      this.getLists();
    },
    methods: {
      getLists(){
        console.log('---', this.userInfo.id);
        $axios.get(`/cbmp/rest/${this.$props.entity}/list`, {
          params: {
            userId: this.userInfo.id
          }
        }).then(res=>{
          this.lists = res.result;
        })
      },
      open(){
        this.$refs.dialogForm.openDialog();
      },
      save(){
        $axios.post(`/cbmp/rest/${this.$props.entity}`, {
          ...this.$props.formData,
          userId: this.userInfo.id
        })
          .then(res=>{
            if(res.success){
              this.resetForm();
              this.$refs.dialogForm.closeDialog();
              this.getLists();
            }
          })
      },
      update(){
        $axios.put(`/cbmp/rest/${this.$props.entity}`, {
          ...this.$props.formData,
          id: this.id
        })
          .then(res=>{
            if(res.success){
              this.resetForm();
              this.$refs.dialogForm.closeDialog();
              this.getLists();
            }
          })
      },
      confirm(){
        this.$emit('formValid', ()=>{
          if(this.id === -1){
            this.save();
          }else{
            this.update();
          }
        });
      },
      edit(item){
        this.id = item.id;
        this.$emit('edit', item);
        this.open();
      },
      del(id){
        this.$confirm('确定删除这条记录吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          $axios.delete(`/cbmp/rest/${this.$props.entity}`, {
            data: [id]
          })
            .then(res=>{
              if(res.success){
                this.getLists();
              }
            })
        }).catch(() => {

        });
      },
      resetForm(){
        this.id = -1;
        this.$emit('resetForm');
      }
    }
  }
</script>

<style scoped>
  .x-lists-ul li{display:flex;align-items: center;justify-content: space-between;}
</style>
