<template>
	<el-dialog :visible.sync="dialogVisible" :title="data.title" append-to-body>
		<div style="height: 230px;">
			<el-steps direction="vertical" :space="100">
				<el-step title="">
					<template slot="description">
						<span class="colorBlack">{{ data.step1 }}</span>
						<p class="x-mt10">{{ data.description1 }}</p>
						<el-button @click="download" class="x-mt10">下载模板</el-button>
						<br />
						<br />
					</template>
				</el-step>
				<el-step title="" :description="data.step2">
					<template slot="description">
						<span class="colorBlack">{{ data.step2 }}</span>
						<p class="x-mt10">{{ data.description2 }}</p>
						<ImportExcelBtn title="点击上传" :systh="data.importTh" :url1="data.url1" :url2="data.url2" :service="data.service" @succ="close"></ImportExcelBtn>
						<!-- <import-excel title="点击上传" class="x-mt10" :type="data.type"  @close="close" :systh="data.importTh" :service="data.service" :url1="data.url1" :url2="data.url2"></import-excel> -->
					</template>
				</el-step>
			</el-steps>
		</div>
	</el-dialog>
</template>
<script>
import $axios from '@/axios/index';
import { apiPostService } from '@/api';
import ImportExcelBtn from '@/components/comm/ImportExcelBtn';
import { downloadFile } from '@/assets/comm';
export default {
	components: {
		ImportExcelBtn
	},
	name: 'DialogRefuse',
	emit: ['ImportExcelconfirm', 'close'],
	props: ['data'],
	data() {
		return {
			fileList: [],
			dialogVisible: false,
			loading: false,
			//apiGet: apiGetEmployerList,
			editData: {},
			editable: true,
			visible: false,
			post: apiPostService,
			rows: {},
			defaultProps: {
				children: 'children',
				label: 'label'
			}
		};
	},
	created() {
		//debugger;
		//console.log('zzz', this.data);
	},
	computed: {
		baseUrl: function() {
			return this.$store.state.baseUrl;
		}
	},
	methods: {
		downloadFile,
		close(parm) {
			this.closeDialog();
			this.$emit('close', parm);
		},
		apiPostService(params, type = 'post') {
			return $axios[type](this.data.url2, params);
		},
		closeDialog() {
			this.loading = false;
			this.dialogVisible = false;
		},
		openDialog() {
			this.dialogVisible = true;
		},
		isEmptyObject(a) {
			var b;
			for (b in a) {
				return !1;
			}
			return !0;
		},
		ImportExcelconfirm() {
			// const formData = new FormData();
			// this.fileList.forEach((file, index) => {
			// 	formData.append('file', file);
			// 	files.push(file);
			// });
			// this.$emit('ImportExcelconfirm', formData);
		},
		closeLoading() {
			this.loading = false;
		},
		openLoading() {
			this.loading = true;
		},
		Choose(d) {
			console.log(d);
			this.rows = d;
		},
		//下载
		download() {
			this.downloadFile(this.data.tempUrl);
		},
		handleSuccess(res) {
			let data = res;
			if (data.success) {
				this.openLoading();
				let fileName = data.message;
				let _data = {
					fileName: fileName,
					service: 'clockImportExcelService',
					mapping: {
						name: '姓名',
						mobile: '手机号',
						employeNumber: '员工号',
						org: '部门',
						post: '岗位',
						clockTime: '打卡时间',
						comment: '备注'
					}
				};
				this.post(_data, 'post').then(res => {
					this.$message.info(res.data.message);
					this.closeLoading();
					if (res.data.success) {
						this.closeDialog();
					}
				});
			}
		},
		clearFiles() {
			this.$refs.upload.clearFiles();
		},
		handleError(err) {
			console.log('error', err);
		},
		beforeUpload(file) {
			let filename = file.name;
			let fileext = filename.substr(filename.lastIndexOf('.'));
			const isExcel = fileext === '.xls' || fileext === '.xlsx';
			if (!isExcel) {
				this.$message.error('只能上传excel文件!');
			}
			return isExcel;
		}
	}
};
</script>

<style scoped>
.colorBlack {
	color: black;
	font-size: 16px;
	font-weight: bold;
}
</style>
