<template>
  <el-dialog :visible.sync="dialogVisible"
             :append-to-body="true" :title="title" @close="resetForm"
  :close-on-click-modal="false" :width="width">
    <slot></slot>
    <div slot="footer" class="dialog-footer x-tc" v-if="nobtn">
      <el-button v-if="editable" type="primary" @click="confirm" :loading="loading">{{$t('delete3')}}</el-button>
      <el-button @click="closeDialog">{{$t('delete4')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    emit: ['confirm', 'resetForm'],
    props: {
      title: {
        type: String,
        default: "提示"
      },
      editable: {
        type: Boolean,
        default: true
      },
      nobtn: {
        type: Boolean,
        default: true
      },
      width: {
        type: String,
        default: '50%'
      }
    },
    data(){
      return {
        dialogVisible: false,
      }
    },
    computed: {
      loading(){
        return this.$store.state.base.btnLoading;
      }
    },
    methods: {
      closeDialog(){
        this.dialogVisible = false;
      },
      openDialog(){
        this.dialogVisible = true;
      },
      confirm(){
        this.$emit("confirm");
      },
      resetForm(){
        this.$emit("resetForm")
      },
    }
  }
</script>

<style scoped>

</style>
