<template>
  <div>
    <h3 class="x-tc" v-show="istitle">{{cacheDemand.name}}</h3>
    <detail-bar :details="cacheDemand"></detail-bar>
    <detail-info :details="cacheDemand"></detail-info>
    <div class="pa-box-bg" v-if="cacheDemand.supportContent&&cacheDemand.dealContent">
      <div v-if="cacheDemand.supportContent" class="x-color666 x-f16 x-mb10">{{$t('stepTwo20')}}</div>
      <div v-if="cacheDemand.supportContent">{{cacheDemand.supportContent}}</div>
      <div v-if="cacheDemand.dealContent" class="x-color666 x-f16 x-mt20 x-mb10">{{$t('stepTwo19')}}</div>
      <div v-if="cacheDemand.dealContent">{{cacheDemand.dealContent}}</div>
    </div>
    <div class="d-row x-mt20">
      <div class="pa-row-t">
        <div class="d-row-t x-mb10">{{$t('typeA1')}}</div>
        <div class="d-row-c">{{cacheDemand.description}}
        </div>
      </div>
    </div>
    <div class="d-row">
      <div class="pa-row-t">
        <div class="d-row-t x-mb10">{{$t('typeB1')}}</div>
        <div class="d-row-c">{{cacheDemand.expectContent}}
        </div>
      </div>
    </div>
    <div class="d-row">
      <div class="pa-row-t">
        <div class="d-row-t x-mb10">{{$t('typeB3')}}</div>
        <div class="d-row-c">{{cacheDemand.expectAimContent}}
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import DetailBar from '../Comm/DetailBar';
  import DetailInfo from "../Comm/DetailInfo";
  export default {
    components: {DetailInfo, DetailBar },
    props: {
      istitle: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      cacheDemand(){
        return this.$store.getters.getCacheDemand;
      }
    },
  }
</script>

<style scoped>
.pa-box-bg{margin-top:20px;padding:20px;background:#f8f8f8;}
</style>
