import $axios from '@/axios/index'

//咨询列表
export const apiGetConsultionRest = (params)=>{
  return $axios.get('/cbmp/rest/Consultion/list', {params});
}


//修改咨询
export const apiPostConsultion = (params, type="post")=>{
  return $axios[type]('/cbmp/rest/Consultion', params);
}

//修改需求留言
export const apiRequirementLeaveMessage = (params, type="post")=>{
  return $axios[type]('/cbmp/rest/RequirementLeaveMessage', params);
}
//回收站查询
export const apiGetRecycleList = (params)=>{
  return $axios.get('/cbmp/req/getRecycleList', {params});
}
//删除回收站留言
export const apiCleanRecycle = (params)=>{
  return $axios.post('/cbmp/req/cleanRecycle', params.data);
}
