<template>
  <div>
    <x-card>
      <div style="width:100%" class="x-bold">{{$t('eright1')}}</div>
      <div class="e-tab">
        <span :class="{'curr':index1===0}" @click="index1=0">{{$t('eright2')}}</span>
        <span :class="{'curr':index1===1}" @click="index1=1">{{$t('eright3')}}</span>
      </div>
      <div :key="item.id"
           class="index-user pl0"
           :class="{'radius0':index1===0}"
            v-for="item in reData1" @click="toPage(index1===0?'/EnterpriseInfo':'/ExpertInfo', { id: item.creatorId})">
        <div class="index-pic">
          <el-image
                    style="width: 100%; height: 100%"
                    :src="`/cbmp/file/${item.creatorHeadimg}`"
                    fit="contain">
          </el-image>
        </div>
        <div class="index-name">
          <div>{{reTitle(item)}} <span class="x-f12 x-gray">{{item.creatorPost}}</span></div>
          <div class="x-h30">
            <div class="x-gray x-f12 x-r0-1">{{item.creatorEnterpriseName}}</div>
          </div>
        </div>
      </div>
      <el-empty :description="$t('eright4')" v-show="reData1.length===0"></el-empty>
    </x-card>
    <x-card>
      <div style="width:100%" class="x-bold">{{$t('eright5')}}</div>
      <div class="e-tab">
        <span :class="{'curr':index2===0}" @click="index2=0">{{$t('eright2')}}</span>
        <span :class="{'curr':index2===1}" @click="index2=1">{{$t('eright3')}}</span>
      </div>
      <div :key="item.id"
           class="index-user pl0"
           :class="{'radius0':index2===0}"
           v-for="item in reData2" @click="toPage(index2===0?'/EnterpriseInfo':'/ExpertInfo', { id: item.userId})">
        <div class="index-pic">
          <el-image
            style="width: 100%; height: 100%"
            :src="`/cbmp/file/${item.headimg}`"
            fit="contain">
          </el-image>
        </div>
        <div class="index-name">
          <div>{{item.surname+item.name}} <span class="x-f12 x-gray">{{item.post}}</span></div>
          <div class="x-h30">
            <div class="x-gray x-f12 x-r0-1">{{item.enterprise}}</div>
          </div>
        </div>
      </div>
      <el-empty :description="$t('eright4')" v-show="reData2.length===0"></el-empty>
    </x-card>
  </div>
</template>

<script>
  import {apiGetInterest} from "../../api/Notice";
import XCard from "../../components/XCard";
import mixinsBase from "../../mixins/base";
  export default {
    components: {
      XCard
    },
    mixins: [mixinsBase],
    props: ['details'],
    data() {
      return {
        data10: [],
        data11: [],
        data20: [],
        data21: [],
        index1: 0,
        index2: 0,
      }
    },
    computed: {
      userInfo(){
        return this.$store.getters.getUserInfo;
      },
      reData1(){
        return this[`data1${this.index1}`];
      },
      reData2(){
        return this[`data2${this.index2}`];
      }
    },
    watch: {
      details(){
        //console.log(val);
        this.getExports();
      }
    },
    mounted() {
      //this.getExports();
    },
    methods: {
      refresh(){
        this.getExports();
      },
      getExports(){
        let {id}=this.$props.details;
        apiGetInterest({
          currPage: 1,
          pageSize: 3,
          creatorNature: 'expert',
          userId: id
        }).then(res=>{
          if(res.success){
            this.data11 = res.result;
          }
        });
        apiGetInterest({
          currPage: 1,
          pageSize: 3,
          creatorNature: 'enterprise',
          userId: id
        }).then(res=>{
          if(res.success){
            this.data10 = res.result;
          }
        });

        apiGetInterest({
          currPage: 1,
          pageSize: 3,
          nature: 'expert',
          creatorId: id
        }).then(res=>{
          if(res.success){
            this.data21 = res.result;
          }
        });
        apiGetInterest({
          currPage: 1,
          pageSize: 3,
          nature: 'enterprise',
          creatorId: id
        }).then(res=>{
          if(res.success){
            this.data20 = res.result;
          }
        });
      },
      reTitle(item){
        //lang==='en'?`${item.creatorSurname}${item.creatorName}`:`${item.creatorName}${item.creatorSurname}`
        let surname = item.creatorSurname||'';
        let name = item.creatorName||'';
        let nameArr = [name, surname];
        if(this.lang === 'en'){
          return nameArr.reverse().join(' ');
        }else{
          return nameArr.join('');
        }
      }
    }
  }
</script>

<style scoped>
  .index-user {
    display: flex;
    align-items: flex-start;
    padding: 10px 16px 10px;
  }

  .index-pic {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
    background: #f4f4f4;
    position: relative;
    z-index: 2;
  }

  .index-name {
    margin-left: 10px;
    flex: 1;
    position: relative;
  }
  .e-tab span{display:inline-block;margin-right:20px;padding:10px 0;border-bottom:2px solid transparent;}
  .e-tab .curr{border-color:#0069a2;}
  .pl0{padding-left:0;}
</style>
<style>
  .radius0 .index-pic{border-radius:0;}
</style>
