<template>
  <el-form ref="form" :model="form" :rules="rules">
    <el-form-item :label="$t('typeA1')" prop="description">
      <el-input v-model="form.description" type="textarea" :rows="5" :placeholder="$t('placeholder')"/>
    </el-form-item>
    <el-form-item :label="$t('typeE1')" prop="systemLinkList">
      <div class="e-loop-box" v-for="(item, index) in form.systemLinkList" :key="index">
        <div class="e-title-box">
          <div>{{$t('typeE2')}} {{index+1}}</div>
          <div>
            <el-button @click="delParent(index)" circle icon="el-icon-delete" size="small" type="danger" v-if="form.systemLinkList.length>1"></el-button>
            <!--<span @click="toggleEvent" class="el-icon-arrow-up x-ml10 "></span>-->
          </div>
        </div>
        <div class="e-pd20">
          <div class="e-name-box x-mb20">
            <el-form-item :prop="'systemLinkList.' + index + '.name'"
            :rules="{
            required: true, message: $t('placeholder'), trigger: 'blur'
            }" style="flex:1;margin-right:20px;">
            <el-input v-model="item.name" type="text" class="e-name" :placeholder="`${$t('typeE2')} ${index+1} `"></el-input>
            </el-form-item>
            <div class="e-radio">
              <span class="x-mr10 x-color666">{{$t('typeE3')}}</span>
              <el-form-item :prop="'systemLinkList.' + index + '.status'"
                            :rules="{
            required: true, message: $t('placeholder'), trigger: 'change'
            }" style="display:inline-block;">
              <el-radio-group v-model="item.status">
                <el-radio-button v-for="item in systemLinkListOpt"
                                 :key="item.id" :label="item.id">{{lang==='en'?item.nameEn:item.name}}
                </el-radio-button>
              </el-radio-group>
              </el-form-item>
            </div>
          </div>
          <el-form-item :prop="'systemLinkList.' + index + '.content'"
                        :rules="{
            required: true, message: $t('placeholder'), trigger: 'blur'
            }">
          <el-input v-model="item.content" type="textarea" :rows="3"
                    :placeholder="$t('typeE4')"></el-input>
          </el-form-item>
          <div class="e-child-box">
            <el-form-item :label="$t('typeE5')" prop="funcList">
              <loop-zb ref="func" :value="item.funcList"></loop-zb>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form-item>
    <div class="x-tc x-mb20" @click="addParent">
      <el-button type="success" icon="el-icon-plus" plain></el-button>
    </div>
    <step-btn @next="nextEvent" @save="saveEvent"></step-btn>
  </el-form>
</template>

<script>
  import LoopZb from "./LoopZb";
  import _ from 'lodash';
  import StepBtn from "../StepBtn";
  import MixinsBase from '../../../mixins/base';
  export default {
    components: {StepBtn, LoopZb},
    mixins: [MixinsBase],
    data() {
      return {
        singleSystem: {
          "name": "",
          "status": "",
          "content": "",
          "funcList": [
            {
              "name": "",
              "type": "",
              "content": ""
            }
          ]
        },
        form: {
          description: '',
          systemLinkList: [{
            "name": "",
            "status": "",
            "content": "",
            "funcList": [
              {
                "name": "",
                "type": "",
                "content": ""
              }
            ]
          }]
        },
        rules: {
          description: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
          systemLinkList: {required: true, message: this.$t('placeholder'), trigger: 'change'},
        }
      }
    },
    computed: {
      cacheDemand() {
        return this.$store.getters.getCacheDemand;
      },
      systemLinkListOpt() {
        return this.$store.getters.getSystemLinkListOpt;
      },
    },
    watch: {
      cacheDemand: {
        immediate: true,
        handler: function (val) {
          this.initForm(val);
        }
      }
    },
    methods: {
      initForm(val) {
        if (!val) {
          return;
        }
        let {description, systemLinkList} = val;
        if(_.isEmpty(systemLinkList)){
          systemLinkList = [{
            "name": "",
            "status": "",
            "content": "",
            "funcList": [
              {
                "name": "",
                "type": "",
                "content": ""
              }
            ]
          }];
        }
        this.form = {
          description,
          systemLinkList
        };
      },
      setFormList(){
        let aa = [];
        let bol = true;
        let {systemLinkList} = this.form;
        _.each(systemLinkList, (i,n)=>{
          let vv = this.$refs.func[n].reParams();
          if(_.isEmpty(vv)){
            bol = false;
          }
          aa.push({
            ...i,
            funcList: vv
          });
        });
        if(bol){
          this.$set(this.form, 'systemLinkList', aa);
        }
        return bol;
      },
      nextEvent(step) {
        let bol = this.setFormList();
        let bol2 = false;
        this.$refs.form.validate(valid => {
          if (valid) {
            bol2 = true;
          }
        })
        if(bol&&bol2){
          this.$store.dispatch('setCacheDemand', {
            ...this.cacheDemand,
            ...this.form
          });
          this.$store.dispatch('setStep', step);
        }

      },
      saveEvent() {
        let bol = this.setFormList();
        let bol2 = false;
        this.$refs.form.validate(valid => {
          if (valid) {
            bol2 = true;
          }
        })
        let params = {
          ...this.form,
          lang: this.lang
        };
        let {langRelReqId} = this.$route.query;
        if(langRelReqId){
          params.langRelReqId = langRelReqId;
        }
        if(bol&&bol2){
          this.$store.dispatch('saveDemand', params);
        }
      },
      addParent(){
        let { systemLinkList } = this.form;
        this.$set(this.form, 'systemLinkList', [
          ...systemLinkList,
          {
            "name": "",
            "status": "",
            "content": "",
            "funcList": [
              {
                "name": "",
                "type": "",
                "content": ""
              }
            ]
          }
        ]);
      },
      delParent(index){
        let { systemLinkList } = this.form;
        systemLinkList.splice(index, 1)
        this.$set(this.form, 'systemLinkList', systemLinkList);
      },
      toggleEvent(ev){
        let ochild = ev.target;
        let oclass= ochild.className;
        let pchild = ochild.parentNode.parentNode.parentNode;
        let ph = pchild.scrollHeight;
        if(oclass.indexOf("el-icon-arrow-down") < 0){
          pchild.style.height = "42px";
          ochild.className = "el-icon-arrow-up el-icon-arrow-down x-ml10";
        }else{
          pchild.style.height = ph + "px";
          ochild.className = "el-icon-arrow-up x-ml10";
        }

      }
    }
  }
</script>

<style scoped>
  .e-loop-box {
    background: #fff;
    clear: both;overflow:hidden;transition:height .2s ease;
    border: 1px solid rgba(245, 246, 255, 1);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    border-radius: 8px;margin-bottom:20px;
  }

  .e-title-box {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    background: #fff;
    border: 1px solid rgba(236, 236, 236, 1);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 0 0;
  }

  .e-pd20 {
    padding: 20px;
  }

  .e-name-box {
    display: flex;
    justify-content:space-between;
  }

  .e-name {
    flex: 1;
    margin-right: 20px;
  }

  .e-child-box {
    background: #fff;
    border: 1px solid rgba(236, 238, 250, 1);
    border-radius: 8px;
    margin-top: 20px;
    padding: 20px;
  }
</style>
