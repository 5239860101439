<template>
  <div>
    <x-card v-if="userInfo.nature === 'enterprise'">
      <div style="width:100%" class="x-bold">{{$t('r0_1')}} <span class="x-pointer x-blue" v-if="isMore"
                                                                  style="float:right;font-size:14px"
                                                                  @click="ChangeExpert">{{$t('r0_0')}}</span></div>
      <div :key="item.id"
           class="index-user index-user-r index-user-hover"
           style="align-items: flex-start;" v-for="item in expertsData">
        <div class="index-pic" @click="toUser(item)">
          <el-image  v-if="item.headimg"
            style="width: 100%; height: 100%"
            :src="`/cbmp/file/${item.headimg}`"
            fit="contain">
          </el-image>
        </div>
        <div class="index-name">
          <div>{{isZh(`${item.surname}${item.name}`)?`${item.surname}${item.name}`:`${item.name} ${item.surname}`}}
            <span class="x-f12 x-gray">{{item.post}}</span></div>
          <div class="x-h30">
            <div class="x-gray x-f12 x-r0-1">{{item.enterprise}}</div>
            <div @click="liuyan(item)" class="icon icim icim-comments x-f12 x-border x-r0-2"> {{$t('r1_2')}}</div>
          </div>
        </div>
      </div>
    </x-card>
    <x-card>
      <div class="x-bold">{{$t('r1_1')}}</div>
      <div class="index-user index-user-r" style="align-items: flex-start;">
        <div class="index-pic">
          <img alt="" src="../assets/images/persion-pic.png"/>
        </div>
        <div class="index-name">
          <div>{{$t('r3')}} <span class="x-f12 x-gray">{{$t('r4')}}</span></div>
          <div class="x-gray x-f12 x-r0-1">{{$t('r5')}}</div>
          <div class="x-border x-tc" style="display:flex;margin-top:8px;">
            <div class="icon icim icim-comments x-f12 x-wx" style="flex:1;position:relative;"> {{$t('r6')}}
              <div class="person-qr">
                <img alt="" src="../assets/images/persion-qr.png"/>
              </div>
            </div>
            <div @click="sendMail" class="icon icim icim-envelope x-f12" style="flex:1;"> {{$t('r7')}}</div>
          </div>
        </div>
      </div>
      <div class="x-bold">{{$t('r2_1')}}</div>
      <div class="x-tc x-mt20">
        <img alt="" src="../assets/images/icim-qr.png"/>
      </div>
    </x-card>
    <el-dialog :visible.sync="lyVisible" class="ly-dialog">
      <div class="index-user index-user-r" slot="title">
        <div class="index-pic">
          <img :src="`/cbmp/file/${expertRecord.headimg}`" alt="" v-if="expertRecord.headimg"/>
        </div>
        <div class="index-name">
          <div>
            {{isZh(`${expertRecord.surname}${expertRecord.name}`)?`${expertRecord.surname}${expertRecord.name}`:`${expertRecord.name}
            ${expertRecord.surname}`}} <span class="x-f12 x-gray">{{expertRecord.post}}</span></div>
          <div class="x-gray x-f12 x-r0-1">{{expertRecord.enterprise}}</div>
        </div>
      </div>
      <div class="x-bold x-f16 x-mb10 x-black">{{$t('r1_3')}}</div>
      <el-input :placeholder="$t('placeholder')" :rows="5" type="textarea" v-model="ly"></el-input>
      <div class="x-tc x-mt20">
        <el-button :loading="btnLoading" @click="saveLy" type="primary">{{$t('delete3')}}</el-button>
        <el-button @click="lyVisible=false">{{$t('delete4')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import MixinsBase from "../mixins/base";
  import {apiExperts, apiPostConsultion} from "../api";
  import XCard from "../components/XCard";
  import {validateFun} from '../filters/formValidate';
  import {returnAllPageFunc} from '@/assets/comm';

  export default {
    name: "IndexRight",
    components: {XCard},
    mixins: [MixinsBase],
    data() {
      return {
        expertsData: [],
        expertsDataList: [],
        lyVisible: false,
        expertRecord: {},
        ly: '',
        isMore: false,
        expertsDataLength: 0,
        defaultSore: 0
      }
    },
    computed: {
      btnLoading() {
        return this.$store.getters.getBtnLoading;
      }
    },
    watch: {
      userInfo: {
        handler(){
          this.init();
        },
        immediate: true
      }
    },
    methods: {
      validateFun,
      returnAllPageFunc,
      init() {
        let {nature} = this.userInfo;
        if (nature === 'enterprise') {
          this.getExports();
        }
      },
      getExports() {
        apiExperts().then(res => {
          if (res.success) {
            let _d = res.result;
            this.expertsDataList = this.returnAllPageFunc(3, _d);
            let _l = this.expertsDataList.length;
            this.expertsDataLength = _l;
            if (_l >= 1) {
              if (_l > 1) {
                this.isMore = true;
              }
              this.expertsData = this.expertsDataList[0].list;
            }
          }
        });
      },
      ChangeExpert() {
        //debugger;
        if ((this.defaultSore + 1) == this.expertsDataLength) {
          this.defaultSore = 0;
          this.expertsData = this.expertsDataList[0].list;
        } else {
          this.defaultSore++;
          this.expertsData = this.expertsDataList[this.defaultSore].list;
        }
      },
      saveLy() {
        if (_.isEmpty(this.ly)) {
          this.$message.error(this.$t('placeholder'));
        } else {
          apiPostConsultion({
            "expertId": this.expertRecord.id,
            "type": "expert",
            "content": this.ly
          }).then(res => {
            if (res.success) {
              this.lyVisible = false;
              this.ly = '';
            }
          })
        }
      },
      sendMail() {
        window.open('mailTo:songhx@jitri.cn');
      },
      liuyan(item) {
        this.lyVisible = true;
        this.expertRecord = item;
      },
      isZh(value) {
        return this.validateFun(value, 'zh');
      },
      toUser(item){
        //let _route = item.enterprise?'EnterpriseInfo':'ExpertInfo';
        this.toPage('/ExpertInfo', {
          id: item.id
        })
      }
    }
  }
</script>

<style scoped>
  .index-user {
    display: flex;
    align-items: flex-start;
    padding: 10px 16px 30px;
  }

  .index-pic {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
    background: #f4f4f4;
    position: relative;
    z-index: 2;
    top: -32px;
  }

  .index-name {
    margin-left: 10px;
    flex: 1;
    position: relative;
  }

  .index-left-box2 li {
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
  }

  .index-left-box2 li span:first-child {
    width: 24px;
  }

  .index-li {
    flex: 1;
  }

  .index-tag-t {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .index-tag-box span {
    display: inline-block;
    margin: 6px 6px 0 0;
    padding: 4px 10px;
    background: rgba(2, 104, 161, 0.05);
    border-radius: 8px;
  }

  .index-user-c, .index-user-r {
    padding: 0;
  }

  .index-user-c .index-pic, .index-user-r .index-pic {
    top: 0;
  }

  .index-user-r {
    margin: 10px 0;
    align-items: center;
  }

  .icim-border {
    background: #fff;
    border: 1px solid rgba(219, 227, 233, 1);
    border-radius: 8px;
    color: #0268a1;
    padding: 4px 8px;
  }

  .x-comments-bg {
    padding: 4px 0;
    background: #f2f7fb;
  }

  .person-qr {
    width: 140px;
    position: absolute;
    left: 0;
    top: 22px;
    display: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  }

  .x-wx:hover .person-qr {
    display: block;
  }

  .x-border {
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 20px;
    display: inline-block;
    padding: 0 10px;
  }

  .index-user-hover .x-r0-2 {
    display: none;
  }

  .index-user-hover:hover .x-r0-2 {
    display: inline-block;
  }

  .index-user-hover:hover .x-r0-1 {
    display: none;
  }

  .x-h30 {
    height: 30px;
  }
</style>
<style>
  .ly-dialog .el-dialog__body {
    padding-top: 0;
  }

  .ly-dialog textarea {
    border-radius: 10px;
  }
</style>
