<template>
  <div class="x-tab">
    <span
      v-for="(item, index) in tabData"
      :key="index"
      :class="{ 'x-curr': index===curr }"
      @click="reData(index)"
    >{{item}}</span>
  </div>
</template>

<script>
  export default {
    props: ['tabData'],
    emits: ['reIndex'],
    data(){
      return {
        curr: 0
      }
    },
    methods: {
      reData(index=0){
        this.curr = index;
        this.$emit('reIndex', index);
      }
    }
  }
</script>

<style scoped>
  .x-tab span{
    display:inline-block;margin:0 10px;padding-bottom:10px;border-bottom:2px solid transparent;cursor:pointer;
  }
  .x-tab .x-curr{
    border-color:#0069a2;
  }
</style>
