<template>
  <div>
  <h3 class="x-tc">{{$t('yinsizhengce2')}}</h3>
  <div style="height:400px;overflow-y:auto;margin-top:20px;">
    <p>{{$t('yinsizhengce3')}}</p>
    <p>{{$t('yinsizhengce4')}}</p>
    <pre style="white-space: pre-wrap;">
          {{$t('yinsizhengce5')}}
    </pre>
  </div>
  </div>
</template>

<script>
  export default {
    name: "ReadOne"
  }
</script>

<style scoped>

</style>
