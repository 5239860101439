<template>
  <div class="login-box" style="width:60rem">
    <div class="login-main-box">
      <div class="login-head">
        <img src="../../assets/images/ICIM-LOGO.png" alt="ICIM LOGO">
        <h1 style="font-size:1.6rem;color:rgba(2,16,33, 0.6);">温馨提示</h1>
      </div>
      <div class="login-content">
        <div v-if="type==='SignUp'" class="x-tc">
          <h1>验证你的电子邮件</h1>
          <p>一封验证电子邮件信息已经发到你的 {{email}} 邮箱，请查收并根据信息提示验证！</p>
          <el-button style="margin-top: 2rem;" type="primary" round @click="reSend">再次发送邮件</el-button>
        </div>
        <div v-else-if="type==='activateSuccess'" class="x-tc">
          <h1>激活成功，欢迎加入国际智能制造联盟</h1>
          <el-button style="margin-top: 2rem;" type="primary" round @click="toPage('/')">前往登录</el-button>
        </div>
        <div v-else-if="type==='ForgotPwd'" class="x-tc">
          <p>一封包含重置密码的邮件已经发送到 {{email}} </p>
          <p>请到邮箱点击链接以后，输入新密码</p>
          <el-button style="margin-top: 2rem;" type="primary" round @click="reSendPwd" :disabled="count!==0" :loading="btnLoading">
            再次发送 <span v-show="count!==0">({{count}}s)</span>
          </el-button>
        </div>
        <div v-else>
          ^_^
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import "@/assets/style/login.css";
  import { apiRegister, apiResetPwdByEmail } from '../../api/index';
  export default {
    data() {
      return {
        type: '',//signup-注册发送邮件   activation-激活成功
        email: '',
        count: 0
      }
    },
    computed: {
      query(){
        return this.$route.query;
      },
      btnLoading(){
        return this.$store.getters.getBtnLoading;
      }
    },
    mounted(){
      let { type, email } = this.query;
      this.type = type;
      this.email = email;
    },
    methods: {
      countDown(){
        let _count = 60, timer=null, speed = 1000;
        let fn = ()=>{
          if(_count <= 0){
            this.count = 0;
            clearTimeout(timer);
          }else{
            _count-=1;
            this.count = _count;
            timer = setTimeout(fn, speed);
          }
        };
        fn();
        timer = setTimeout(fn, speed);
      },
      reSend() {
        let { email } = this.query;
        apiRegister({
          email
        })
      },
      reSendPwd() {
        let { email } = this.query;
        apiResetPwdByEmail({
          email
        }).then(res=>{
          if(res.success){
            this.countDown();
          }
        })
      },
      toPage(path){
        this.$router.push(path);
      }
    }
  };
</script>

<style lang="css" scoped>

</style>
