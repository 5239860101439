<template>
  <mother-boadr :formData="form" entity="MediaCoverage"
                @resetForm="resetForm"
                @edit="edit"
                @formValid="formValid" :title="$t('userinfo34')">
    <el-form :model="form" ref="form6" :rules="rules" label-width="120px">
      <el-form-item :label="$t('haward3')" prop="title">
        <el-input v-model="form.title" :placeholder="$t('placeholder')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('haward7')" prop="url">
        <el-input v-model="form.url" :placeholder="$t('mcoverage1')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('haward5')" prop="description">
        <wang-editor v-model="form.description"></wang-editor>
      </el-form-item>
    </el-form>
  </mother-boadr>
</template>

<script>
  import MotherBoadr from "./MotherBoadr";
  import WangEditor from "../../../components/comm/WangEditor";
  export default {
    components: {MotherBoadr, WangEditor},
    data(){
      return {
        form: {
          title: '',
          url: '',
          description: ''
        },
        rules: {
          title: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
          //url: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
        },
      }
    },
    methods: {
      resetForm(){
        this.$refs.form6.resetFields();
      },
      edit(item){
        let { title, url } = item;
        this.$set(this.form, 'title', title);
        this.$set(this.form, 'url', url);
      },
      formValid(cb){
        this.$refs.form6.validate(valid=>{
          if(valid){
            cb&&cb();
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>
