<template>
  <div class="x-main">
    <x-header></x-header>
    <x-breadcrumb></x-breadcrumb>
    <div class="x-content">
      <div class="x-center">
        <x-card style="border-top-left-radius:0;" width="100%">
            <div v-if="tableData.length === 0">
              <no-data></no-data>
            </div>
            <div v-else>
              <demand-list-temp v-for="item in tableData" :key="item.id" :item="item"></demand-list-temp>
            </div>
            <x-pagination :total="total" @pageChange="refreshTable" :currpage="currPage"></x-pagination>
        </x-card>
      </div>
    </div>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import XBreadcrumb from "../../components/XBreadcrumb";
  import XCard from "../../components/XCard";
  import DemandListTemp from "./DemandComm/DemandListTemp";
  import NoData from "./Comm/NoData";
  import Mixins from "../../mixins";
  import _ from "lodash";
  import {apiDemandList} from "../../api";
  import XPagination from "../../components/comm/XPagination";
  export default {
    components: {
      XPagination,
      NoData,
      DemandListTemp,
      XCard,
      XBreadcrumb,
      XHeader,
    },
    mixins: [Mixins],
    data () {
      return {
      }
    },
    computed: {
      search(){
        return this.$route.query.search;
      }
    },
    watch: {
      search(){
        this.refreshTable();
      }
    },
    mounted(){
      this.refreshTable();
    },
    methods: {
      refreshTable(params={}){
        this.getTableData({
          ...params,
          name: this.search
        }, apiDemandList);
      },
      toPage(path){
        this.$router.push(path);
      },
    }
  }
</script>

<style scoped>
.x-demand-left li{margin-bottom:20px;cursor:pointer;}
.x-demand-left li:last-child{margin-bottom:0;}
  .x-demand-tab{width:100%;height:40px;position:relative;top:-1px;}
  .x-demand-tab li{width:100px;line-height:40px;height:40px;text-align:center;display:inline-block;background:#f6f3f5;border-radius: 20px 20px 0 0;position:relative;border:1px solid #e4eaf0;}
  .x-demand-tab li:after{content: '';width:30px;height:40px;display:inline-block;background:url('../../assets/images/bg-tab-demand.png') no-repeat 0 -42px;position:absolute;right:-14px;top:0;border-bottom:1px solid #e4eaf0;}
  .x-demand-tab .on{z-index:3;background-color:#fff;border-bottom-color:#fff;}
  .x-demand-tab .on:after{height:42px;background-position-y:0;border-bottom:none;}
  .demand-seach{margin-right:10px;flex:1;}
</style>
