<template>
  <div class="nodata">

  </div>
</template>

<script>
  export default {

  }
</script>

<style scoped>
.nodata{width:100%;height:400px;background:url('../../../assets/images/no-data.png') no-repeat center;background-size:160px;}
</style>
