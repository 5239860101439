<template>
  <div class="x-main">
    <x-header v-if="isShow"></x-header>
    <x-breadcrumb v-if="isShow"></x-breadcrumb>
    <div class="x-content" style="display:block;">
      <x-card v-if="isShow" width="100%">
        <div style="position:relative;">
          <x-consulting :id="details.id" :userId="userInfo.id" v-if="userInfo.nature==='expert'&&details.status!=='draft'" style="position:absolute;right:0;top:0;" @refresh="tab2"></x-consulting>
          <h3 class="x-tc">{{details.name}}</h3>
          <div class="x-d-tab">
            <span :class="{'curr': tabindex===0}" @click="tabindex=0">{{$t('ddetail1')}}</span>
            <span :class="{'curr': tabindex===1}" @click="tabindex=1" v-show="userInfo.id === details.creatorId&&details.status!=='draft'">{{$t('ddetail2')}} {{tab1Data.length}}</span>
            <span style="color:#f37920;" v-show="details.status!=='draft'" :class="{'curr': tabindex===2}" @click="tabindex=2">{{$t('ddetail3')}} {{tab2Data.length}}</span>
          </div>
        </div>
        <div v-show="tabindex===0">
          <component :is="currentView" :istitle="false"></component>
        </div>
        <div v-show="tabindex===1" class="x-zj-box">
          <div class="x-zj" v-for="item in tab1Data" :key="item.id">
            <div class="x-zj-l">
              <!--<el-image v-if="item.headimg"
                        style="width: 100%; height: 100%"
                        :src="`/cbmp/file/${item.headimg}`"
                        :preview-src-list="[`/cbmp/file/${item.headimg}`]">
              </el-image>-->
              <el-image v-if="item.headimg"
                        style="width: 100%; height: 100%"
                        :src="`/cbmp/file/${item.headimg}`"
                        @click="toPageBlank('/ExpertInfo', { id: item.id })">
              </el-image>
            </div>
            <div class="x-zj-r">
              <div>{{item.surname}}{{item.name}}</div>
              <div>{{item.post}}</div>
              <div>
                <span class="el-icon-location-outline x-mr20">{{item.area|re_str}}</span>
                <span class="el-icon-coordinate">{{item.category|re_str}}</span>
              </div>
            </div>
          </div>
          <el-empty v-show="tab1Data&&tab1Data.length==0" :description="$t('nodata')"></el-empty>
        </div>
        <div v-show="tabindex===2">
          <div>{{$t('ddetail4')}}</div>
          <div class="x-bgbar">
            <el-input type="textarea" :rows="5" :placeholder="$t('ddetail5')" v-model="content"></el-input>
            <div style="padding:4px;display:flex;align-items: center;justify-content: flex-end;">
              <el-checkbox v-model="isTop" v-if="isQuanxian">{{$t('ddetail6')}}</el-checkbox>
              <span class="el-icon-view x-mr20 x-ml20" v-if="isQuanxian"> {{$t('ddetail7')}}</span>
              <el-select v-model="messageRight" v-if="isQuanxian">
                <el-option :label="lang==='en'?'All':'全网公开'" value="all"></el-option>
                <el-option :label="lang==='en'?'Expert':'所有对接需求的专家'" value="reqExpert"></el-option>
              </el-select>

              <div style="position:relative;display:inline-block;">
                <upload-file v-model="attachmentCode" @setAttachment="setAttachment">
                  <span class="el-icon-paperclip x-ml10">{{$t('ddetail8')}}</span>
                </upload-file>
              </div>
              <el-button class="x-ml20" type="primary" plain @click="send">{{$t('ddetail9')}}</el-button>
            </div>
          </div>
          <div class="x-mt20">{{$t('ddetail10')}}</div>
          <demand-message :dataSource="tab2Data" @refresh="tab2" :detailsId="details.id" :creatorId="details.creatorId"></demand-message>
          <el-empty v-show="tab2Data&&tab2Data.length==0" :description="$t('nodata')"></el-empty>
        </div>
      </x-card>
      <component v-else :is="currentView" :istitle="false"></component>
    </div>

  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import XBreadcrumb from "../../components/XBreadcrumb";
  import XCard from "../../components/XCard";
  //import DemandListTemp from "./DemandListTemp";
  import XConsulting from "../../components/XConsulting";
  //import DetailBar from "./Comm/DetailBar";
  import {apiDemandDetail} from "../../api";
  import {apiGetFavoriteUser} from "../../api/RequirementManage";
  import {apiGetLeaveMessage, apiLeaveMessage} from "../../api/LeaveMessage";
  import PreviewA from "./DemandType/PreviewA";
  import PreviewB from "./DemandType/PreviewB";
  import PreviewC from "./DemandType/PreviewC";
  import PreviewD from "./DemandType/PreviewD";
  import PreviewE from "./DemandType/PreviewE";
  import PreviewF from "./DemandType/PreviewF";
  import MixinsBase from '../../mixins/base';
  import UploadFile from "../../components/comm/UploadFile";
  import DemandMessage from "./DemandMessage";
  import {downFile} from '../../filters/utils';
  export default {
    props: {
      isShow: {
        type: Boolean,
        default: true
      },
      id: {
        type: Number,
        default: 0
      },
      xlang: {
        type: String,
        default: 'zh'
      },
    },
    mixins: [MixinsBase],
    components: {
      UploadFile,
      PreviewA,
      PreviewB,
      PreviewC,
      PreviewD,
      PreviewE,
      PreviewF,
      XConsulting,
      //DemandListTemp,
      XCard,
      XBreadcrumb,
      XHeader,
      //DetailBar
      DemandMessage
    },
    data() {
      return {
        details: {},
        currentView: '',
        tabindex: 0,
        tab1Data: [],
        tab2Data: [],
        messageRight: null,
        attachmentCode: '',
        content: '',
        isTop: false,
        reMsg: {
          content: '',
          attachmentCode: '',
          attachment: '',
        },
        isQuanxian: false
      }
    },
    watch: {
      /*tabindex (val){
        this[`tab${val}`]&&this[`tab${val}`]();
      }*/
      userInfo: {
        handler(val){
          if(!_.isEmpty(val)){
            this.init();
          }
        },
        immediate: true
      }
    },
    computed: {
      breadcrumb() {
        return this.$store.getters.getBreadcrumb;
      },
      cacheDemand() {
        return this.$store.getters.getCacheDemand;
      },
      query() {
        return this.$route.query;
      },
    },
    mounted() {
      //this.init();
    },
    methods: {
      downFile,
      init(){
        this.getDetails();
      },
      send(){
        let {messageRight, attachmentCode, content, isTop} = this;
        let {attachment} = this.reMsg;
        if(content===''){
          this.$message.error(this.$t('ddetail11'));
        }else{
          apiLeaveMessage({
            messageRight,
            attachmentCode,
            attachment,
            content,
            isTop,
            requirementId: this.details.id
          }).then(res=>{
            if(res.success){
              this.tab2(this.details.id);
              this.messageRight = null;
              this.attachmentCode = '';
              this.content = '';
              this.$set(this.reMsg, 'attachment', '');
              this.isTop = false;
            }
          })
        }
      },
      sendChild(ev, id){
        apiLeaveMessage({
          ...this.reMsg,
          parent: id,
          requirementId: this.details.id
        }).then(res=>{
          if(res.success){
            this.tab2(this.details.id);
            this.reMsg = {
              content: '',
              attachmentCode: '',
              attachment: '',
            };
            this.cancelClick(ev);
          }
        })
      },
      topClick(id, isTop){
        apiLeaveMessage({
          isTop: !isTop,
          id
        }, 'put').then(res=>{
          if(res.success){
            this.tab2(this.details.id);
          }
        })
      },
      deleteClick(id){
        apiLeaveMessage({
          isDeleted: true,
          id
        }, 'put').then(res=>{
          if(res.success){
            this.tab2(this.details.id);
          }
        })
      },
      tab1(id){
        apiGetFavoriteUser({
          reqId: id,
          userNatureType: 'expert'
        }).then(res=>{
          if(res.success){
            this.tab1Data = res.result;
          }
        })
      },
      tab2(id=this.details.id){
        apiGetLeaveMessage({
          reqId: id
        }).then(res=>{
          if(res.success){
            this.tab2Data = res.result||[];
          }
        })
      },
      reClick(ev){
        let _parent = ev.target.parentNode.parentNode;
        let _dom1 = document.getElementsByClassName('x-re-msg-box');
        let _dom2 = document.getElementsByClassName('x-re-box');
        for(let i=0;i<_dom1.length;i++){
          _dom1[i].className = 'x-re-msg-box x-dsn';
          _dom2[i].className = 'x-re-box';
        }
        _parent.className = 'x-re-box x-dsn';
        _parent.nextSibling.className = 'x-re-msg-box';
      },
      cancelClick(ev){
        let _parent = ev.target.parentNode.parentNode;
        _parent.className = 'x-re-msg-box x-dsn';
        _parent.previousSibling.className = 'x-re-box';
      },
      setAttachment(filename){
        this.$set(this.reMsg, 'attachment', filename);
      },
      getDetails() {
        let { id, idEn, idZh, creatorId } = this.query;
        let uid = this.userInfo.id;
        let uname = this.userInfo.username;
        let params = {};
        if(this.id !== 0){
          params.reqId = this.id;
        }else if(this.lang === 'en'&&idEn){
          params.reqId = idEn;
        }else if(this.lang === 'zh'&&idZh){
          params.reqId = idZh;
        }else{
          if(creatorId === uid){
            this.$confirm(this.$t('delete5'), this.$t('delete2'), {
              confirmButtonText: this.$t('delete3'),
              cancelButtonText: this.$t('delete4'),
              type: 'warning',
              closeOnClickModal: false
            }).then(() => {
              this.toPage('/DemandRelease', { langRelReqId: id });
            }).catch(() => {

            });
          }else{
            this.$confirm(this.$t('delete6'), this.$t('delete2'), {
              confirmButtonText: this.$t('delete3'),
              showCancelButton: false,
              type: 'warning',
              closeOnClickModal: false
            }).then(() => {
              this.toPage('/Demand');
            }).catch(() => {

            });
          }

        }

        if(!_.isEmpty(params)){
          apiDemandDetail(params).then(res => {
            let _ext = res.ext;
            this.details = _ext;
            let detailsId = _ext.id;
            let {breadcrumb} = this;
            console.log(breadcrumb);
            this.currentView = `Preview${_ext.type[0]}`;
            this.$store.dispatch('setCacheDemand', _ext);
            this.$store.dispatch('setBreadcrumb', [
              ...breadcrumb,
              {
                name: _ext.name,
                lang: _ext.name,
                no: true,
                path: null
              }
            ]);
            this.tab1(detailsId);
            this.tab2(detailsId);
            if(uname==='admin'||uid==_ext.creatorId){
              this.isQuanxian = true;
              this.messageRight = "reqExpert";
            }
          })
        }
      }
    }
  }
</script>

<style>
.x-d-tab{
  border-bottom:1px solid #ECECEC;margin-bottom:20px;
}
  .x-d-tab span{
    display:inline-block;padding:10px 20px;border-bottom:1px solid transparent;cursor:pointer;
  }
  .x-d-tab .curr{
    border-color:#0069A2;color:#0069A2;
  }
  .x-zj-box{
    display:flex;flex-wrap: wrap;justify-content: space-between;
  }
  .x-zj{
    width:49%;margin:10px 0;border:2px solid #E4EBED;box-sizing:border-box;border-radius:10px;padding:20px;display:flex;
  }
  .x-zj-l{
    width:72px;height:72px;border-radius:50%;overflow:hidden;margin-right:10px;
  }
  .x-zj-r{
    flex:1;
  }
  .x-msg{
    position:relative;padding:20px;overflow:hidden;border-radius:5px;border:1px solid #E7EEF2;margin-top:20px;
  }
  .x-msg .x-msg{
    margin-left:50px;
  }
  .x-msg-top:before{
    content:'';width:40px;height:40px;background:#0069A2;position:absolute;left:-20px;top:-20px;
    transform: skew(135deg);
  }
  .x-msg:hover{
    background:#F7FBFF;
  }
  .x-msg:after{
    content: '';clear:both;display:block;
  }
  .x-msg-l{
    width:120px;float:left;display:flex;
  }
  .x-msg-m{
    float:left;
  }
  .x-msg-pic{
    width:42px;height:42px;border-radius:50%;background:#f1f1f1;
  }
  .x-msg-name{
    flex:1;margin-left:10px;
  }
  .x-msg-mark{
    height:30px;line-height:30px;padding:0 10px;border-radius:10px;color:#fff;background:#F37920;
  }
  .x-msg-mark-blue{
    background:#0069A2;
  }
  .x-mr200{
    margin-right:220px;
  }
  .x-ml200{
    margin-left:220px;
  }
  .x-msg-flip .x-msg-l{
    float:right;
  }
  .x-ml200 .x-msg-r{
    margin:0 220px 0 0;
  }
  .x-msg-r{
    margin-left:120px;
  }
  .x-bgbar, .x-re-msg-box{
    background:#F5FBFF;border:1px solid #E7EEF2;border-radius:5px;margin-top:16px;
  }
.x-dsn{
  display:none;
}
</style>
<style>
  .x-bgbar textarea, .x-re-msg-box textarea{
    border:none;
  }
  .x-send-btn{
    position:relative;z-index:10;display:inline-block;color: #0069A2;cursor:pointer;border-radius:5px;
    background: rgb(230, 240, 246);padding:3px 12px;border: 1px solid rgb(153, 195, 218);
  }
  .x-send-btn:hover{
    background-color:#0069A2;
    border-color:#0069A2;color:#fff;
  }
</style>
