//import $axios from '../axios';
//下载
export const downFile = (filecode)=>{
  if(!filecode){
    return false;
  }
  /*$axios.get(`/cbmp/file/${filecode}`).then(res=>{

  })*/
  const link = document.createElement('a');
  link.href = `/cbmp/file/${filecode}`;
  link.target = '_blank';
  link.click();
}
//树结构子节点置空
export const getTreeData = (data = [])=> {
  for (let i = 0; i < data.length; i++) {
    if (!data[i].children) {
      data[i].children = undefined;
    } else if (data[i].children.length < 1) {
      data[i].children = undefined;
    } else {
      getTreeData(data[i].children);
    }
  }
  return data;
}
