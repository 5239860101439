<template>
  <preview-c :istitle="false"></preview-c>
</template>

<script>
  import PreviewC from "./PreviewC";
  export default {
    components: {PreviewC}
  }
</script>

<style scoped>

</style>



