<template>
  <div class="x-main">
    <x-header></x-header>
    <x-breadcrumb></x-breadcrumb>
    <div class="x-content">
      <div class="x-left x-mr20">
        <index-left></index-left>
      </div>
      <div class="x-center">
        <x-card style="width:100%;padding:0;">
          <div class="x-demand-title">推荐给我的需求</div>
          <div class="x-demand-list">
            <div v-if="tableData.length === 0">
              <no-data></no-data>
            </div>
            <div v-else>
              <demand-list-temp :item="item" :key="item.id" v-for="item in tableData">
              </demand-list-temp>
            </div>
            <x-pagination :currpage="currPage" :total="total" @pageChange="refreshTable"></x-pagination>
          </div>
        </x-card>
      </div>
      <div class="x-right x-ml20">
        <index-right></index-right>
      </div>
    </div>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import XBreadcrumb from "../../components/XBreadcrumb";
  import XCard from "../../components/XCard";
  import DemandListTemp from "./DemandComm/DemandListTemp";
  import XPagination from "../../components/comm/XPagination";
  import NoData from "./Comm/NoData";
  import Mixins from "../../mixins";
  import MixinsBase from "../../mixins/base";
  import {apiFavorite, apiDelRequirement} from "../../api";
  import IndexLeft from "../IndexLeft";
  import IndexRight from "../IndexRight";
  export default {
    components: {
      IndexRight,
      IndexLeft,
      XPagination,
      DemandListTemp,
      XCard,
      XBreadcrumb,
      XHeader,
      NoData
    },
    mixins: [Mixins, MixinsBase],
    data () {
      return {
        params: {
          name: ''
        }
      }
    },
    mounted(){
      this.init();
    },
    methods: {
      init(){
        this.refreshTable();
      },
      refreshTable(params={}){
        this.getTableData(params, apiFavorite, {
          lang: this.lang
        });
      },
      search(){
        this.refreshTable({
          ...this.params,
          currPage: 1
        });
      },
      pageChange(v){
        console.log('vvv-', v);
      },
      deleteRecord(id){
        apiDelRequirement([id]).then(res=>{
          if(res.success){
            this.refreshTable();
          }
        })
      }
    }
  }
</script>

<style scoped>
.x-demand-left li{margin-bottom:20px;cursor:pointer;}
.x-demand-left li:last-child{margin-bottom:0;}
  .x-demand-tab{width:100%;height:40px;position:relative;top:-1px;}
  .x-demand-tab li{width:100px;line-height:40px;height:40px;text-align:center;display:inline-block;background:#f6f3f5;border-radius: 20px 20px 0 0;position:relative;border:1px solid #e4eaf0;}
  .x-demand-tab li:after{content: '';width:30px;height:40px;display:inline-block;background:url('../../assets/images/bg-tab-demand.png') no-repeat 0 -42px;position:absolute;right:-14px;top:0;border-bottom:1px solid #e4eaf0;}
  .x-demand-tab .on{z-index:3;background-color:#fff;border-bottom-color:#fff;}
  .x-demand-tab .on:after{height:42px;background-position-y:0;border-bottom:none;}
  .demand-seach{margin-right:10px;flex:1;}
</style>
