<template><!--所属行业-->
  <div>
    <div class="i-box">
      <el-cascader ref="cascader" :placeholder="$t('placeholder')"
        v-model="i1"
        :options="selectOpt"
        :props="{ value: 'id', label: lang==='en'?'nameEn':'name',children: 'children'}" @change="change"></el-cascader>
      <el-button class="x-green x-ml10 x-mr20" @click="addEvent">+ {{$t('userinfo17')}}</el-button>
      <span class="x-color666">{{$t('stepTwo24')}}。</span>
      <slot name="des"></slot>
    </div>
    <div>
      <el-tag class="x-mr10" closable v-for="(item, index) in tagOpt"
              :key="index" @close="delEvent(index)">{{item.pathName||item.name}}</el-tag>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import MixinsBase from '../../../mixins/base';
  export default {
    props: {
      selectOpt: {
        type: Array,
        default: ()=> []
      },
      placeholder: {
        type: String,
        default: '请选择'
      },
      tagOpt: {
        type: Array,
        default: ()=> []
      },
      ikey: {
        type: String,
        default: ''
      },
      max: {
        type: Number,
        default: 5
      }
    },
    emits: ['setForm', 'delEvent'],
    mixins: [MixinsBase],
    data(){
      return {
        i1: '',
        formValue: {}
      }
    },
    methods: {
      addEvent(){
        let _is = _.filter(this.tagOpt, i=>{
          return this.i1.includes(i.id);
        });
        if(_.isEmpty(this.i1)||!_.isEmpty(_is)||this.tagOpt.length >= this.max){
          return ;
        }
        let value = _.concat([], this.tagOpt, [this.formValue]);
        this.$emit('setForm', {
          key: this.$props.ikey,
          value: this.formValue,
          formValue: value
        });
      },
      delEvent(index){
        this.$emit('delEvent', index);
      },
      change(){
        let selectNode = this.$refs.cascader.getCheckedNodes();
        if(!_.isEmpty(selectNode)){
          let name1 = selectNode[0]['label'];
          let id1 = selectNode[0]['value'];
          let name2 = selectNode[0]['parent']['label'];
          this.formValue = {
            id: id1,
            name: `${name2}/${name1}`,
            pathName: `${name2}/${name1}`,
          }
        }
      }
    }
  }
</script>

<style scoped>
  .i-box{display:flex;}
</style>
