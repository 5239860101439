<template>
	<div class="x-header">
		<div class="x-logo">
			<div class="x-logo-img"><img src="../assets/images/ICIM-LOGO.png" alt="" /></div>
			<el-dropdown @command="setLanguage">
				<span class="el-dropdown-link">
					{{ $t('name') }}
					<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="zh" v-show="lang === 'en'">简体中文</el-dropdown-item>
					<el-dropdown-item command="en" v-show="lang === 'zh'">English</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<div class="x-menu">
			<ul class="x-menu-ul x-tc">
				<li :class="{ on: menuIndex === 1 }" @click="toPage('/Index')">
					<div class="icon icim icim-home"></div>
					{{ $t('m1') }}
				</li>
				<li :class="{ on: menuIndex === 2 }" @click="toPage('/Demand')">
					<div class="icon icim icim-handshake-angle"></div>
					{{ $t('m2') }}
				</li>
				 <li :class="{ 'on': menuIndex===3 }" @click="toPage('/UnderConstruction')">
          <div class="icon icim icim-circle-exclamation-check"></div>
          {{$t("m3")}}
        </li>
        <li :class="{ 'on': menuIndex===4 }" @click="toActivity">
          <div class="icon icim icim-calendar-days"></div>
          {{$t("m4")}}
        </li>
        <li :class="{ 'on': menuIndex===5 }" @click="toPage('/UnderConstruction')">
          <div class="icon icim icim-graduation-cap-2"></div>
          {{$t("m5")}}
        </li>
			</ul>
		</div>
		<div class="x-search">
			<el-input :placeholder="$t('placeholder')" v-model="search" class="input-with-select" @keyup.enter.native="searchResult">
				<el-button slot="append" icon="el-icon-search" @click="searchResult"></el-button>
			</el-input>
		</div>
    <div style="display:flex;align-items: center;margin-left:10px;position:relative;">
      <el-badge :value="noticeSize" class="item">
        <el-button type="danger" icon="el-icon-message-solid" circle @click="isBadge=!isBadge"></el-button>
      </el-badge>
    </div>
		<div class="x-exit user-box">
			<div class="head-img">
				<el-image v-if="userInfo.headimg" style="width: 40px; height: 40px; border-radius:30px" :src="`/cbmp/file/${userInfo.headimg}`"></el-image>
				<div v-else class="head-img"><el-icon class="el-icon-user-solid" /></div>
			</div>
			<div class="user-info x-ml10" >
				<el-dropdown @command="logout">
					<span class="el-dropdown-link">
						{{isZh(`${userInfo.surname}${userInfo.name}`)?`${userInfo.surname||''}${userInfo.name||''}`:`${userInfo.name||''}${userInfo.surname||''}`}}
						<i class="el-icon-arrow-down el-icon--right" style="font-size:14px;"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="UserInfoSet">{{$t('xheader1')}}</el-dropdown-item>
						<el-dropdown-item command="Index">{{$t('xheader2')}}</el-dropdown-item>
						<el-dropdown-item command="SignIn">{{ $t('logout') }}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
    <div class="x-badge-pupup" v-show="isBadge">
      <div class="x-badge-col" v-for="item in noticeData" :key="item.id" @click="toDetails(item)">
        {{item.title}}
        <div class="x-gray">{{item.create_date|formatDate('datetime')}}</div>
      </div>
      <div style="padding:10px;" class="x-blue x-tc" @click="toPage('/Notice')">
        {{$t('xheader3')}}
      </div>
    </div>
	</div>
</template>

<script>
import mixinsBase from '../mixins/base';
import { apiExit } from '../api';
import {validateFun} from '../filters/formValidate';
import {apiGetNotice, apiNotice} from "../api/Notice";
export default {
	name: 'Header',
	mixins: [mixinsBase],
	data() {
		return {
			language: 'zh',
			search: '',
      isBadge: false,
		};
	},
	computed: {
		menuIndex() {
			return this.$store.getters.getMenuIndex;
		},
		query() {
			return this.$route.query;
		},
    noticeSize(){
		  return this.$store.getters.getNoticeSize;
    },
    noticeData(){
		  return this.$store.getters.getNoticeData;
    },
    isAdmin(){
		  let {roleNames} = this.$store.getters.getUserInfo;
      return roleNames&&roleNames.includes('ADMIN');
    }
	},
	mounted() {
		let { search } = this.query;
		if (search) {
			this.search = search;
		}
    this.$store.dispatch('setNoticeSize');
	},
	methods: {
    validateFun,
		toPage(path, query = {}) {
			this.$router.push({
				path: this.lang === 'en' ? `/en${path}` : path,
				query
			});
		},
    toActivity(){
      this.toPage(this.isAdmin?'/PublishingActivities':'UnderConstruction');
    },
		searchResult() {
			this.toPage('/SearchResult', { search: this.search });
		},
		logout(command='') {
      let _route = command;
      if(command === 'SignIn'){
        apiExit();
      }
      if(command === 'Index'){
        let {nature, id}= this.userInfo;
        _route = nature === 'expert'?`ExpertInfo?id=${id}`:`EnterpriseInfo?id=${id}`;
      }
			this.toPage(`/${_route}`);
		},
    isZh(value){
      return this.validateFun(value, 'zh');
    },
    toDetails(item){
      this.$store.dispatch('setDetails', item);
      apiNotice({
        ids: item.id,
        actionType: 'read'
      }).then(res=>{
        if(res.success){
          this.$store.dispatch('setNoticeSize');
        }
      })
      this.toPage('/NoticeDetail', { id: item.id });
    }
	}
};
</script>

<style scoped lang="scss">
.x-header {
	display: flex;
}
.x-logo,
.x-search {
	width: 260px;
	display: flex;
	align-items: center;
}
.x-menu {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.x-exit {
	margin-left: 20px;
	display: flex;
	align-items: center;
	font-size: 12px;

	i {
		font-size: 24px;
	}

	.head-img {
		cursor: pointer;
		width: 40px;
		height: 40px;
		//margin-left: 25px;
		text-align: center;
		line-height: 40px;
		border-radius: 50%;
		background-color: rgba(88, 28, 202, 0.1);
		color: #581cca;
		display: inline-block;

		i {
			line-height: 50px;
			font-size: 24px;
		}
	}

	.user-info {
		font-size: 15px;
		margin-left: 20px;
		display: inline-block;
		color: #101010;
		cursor: pointer;

		.name,
		.dpt {
			display: block;

			&.dpt {
				font-size: 12px;
				color: #101010;
				opacity: 0.5;
			}

			&.name {
				i {
					margin-left: 18px;
					font-size: 13px;
				}
			}
		}
	}
}
.x-menu-ul li {
	display: inline-block;
	margin: 0 20px;
}

.x-logo-img {
	width: 180px;
}
.x-logo-img img {
	display: block;
}
.x-menu-ul li {
	cursor: pointer;
}
  .x-badge-pupup{
    width:240px;position:absolute;right:0;top:60px;z-index:9;background:#fff;box-shadow: -3px 3px 3px rgba(0,0,0,.1);
  }
  .x-badge-col{
    border-bottom:1px solid #F7F7F7;padding:10px;
  }
  .x-badge-col:hover{
    background:#F7F7F7;
  }
</style>
