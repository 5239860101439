<template><!--需求预算-->
  <div style="display:flex;">
    <div :class="{ 'bggray': cc }" class="i-box" style="flex:1;">
      <span>￥</span>
      <input :disabled="cc" :style="`width:${width}`" v-model="i1" type="text" class="i-input" :placeholder="$t('placeholder')" />
      <span>-</span>
      <input :disabled="cc" :style="`width:${width}`" v-model="i2" type="text" class="i-input" :placeholder="$t('placeholder')" />
      <span>{{$t('detailbar4')}} </span>
    </div>
    <el-checkbox v-if="!ischeck" v-model="cc" class="x-ml10">{{$t('detailbar3')}}</el-checkbox>
  </div>
</template>

<script>
  import {validateFun} from "../../../filters/formValidate";
  export default {
    props: ['value', 'ikey', 'width', 'ischeck'],
    data(){
      return {
        i1: '',
        i2: '',
        cc: false
      }
    },
    watch: {
      'cc'(val){
        this.reIndefinite(val);
      },
      'value': {
        immediate: true,
        handler: function(val){
          this.setValue(val);
        }
      },
      'i1'(nv, ov){
        this.change('i1', nv, ov);
      },
      'i2'(nv, ov){
        this.change('i2', nv, ov);
      }
    },
    methods: {
      validateFun,
      reIndefinite(val){
        if(val === true){
          this.reset();
          this.$emit('setValue', val, this.$props.ikey);
        }

      },
      change(o, nv, ov) {
        let bol = this.validateFun(nv, 'number');
        console.log(o, nv, ov);
        if(!bol&&nv!==""){
          this[o] = ov||"";
          return ;
        }
        if(this.cc === true){
          return ;
        }
        let {i1, i2} = this;
        let val = '';
        if(i1&&i2){
          val = `${i1}_${i2}`;
          this.$emit('setValue', val, this.$props.ikey);
        }
      },
      setValue(val){
        if(val === true){
          this.cc = true;
        }
        if(typeof val === 'string'){
          let vv = val.split('_');
          this.i1 = vv[0];
          this.i2 = vv[1];
        }
      },
      reset(){
        this.i1 = '';
        this.i2 = '';
      }
    }
  }
</script>

<style scoped>
.i-box{display:flex;-webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  font-size: inherit;
  height: 28px;
  line-height: 28px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);}
  .i-input{border:none;}
  .bggray{background:#fafafa;}
</style>
<style>
  input::-webkit-input-placeholder { /* WebKit browsers */
    color: #ccc;font-size:12px;
  }
</style>
