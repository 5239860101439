import $axios from '@/axios/index'
//字典查询
export const apiGetDict = (params)=>{
  return $axios.get(`/cbmp/dict/${params.name}`);
}
//获取用户信息
export const apiGetCurruser = (params)=>{
  return $axios.get('/cbmp/cbmpuser', {params});
}

//随机生成密码
export const apiPostGeneratePwd = (params)=>{
  return $axios.post('/cbmp/generatePwd', params);
}

//用户管理
export const apiGetSysUser = (params)=>{
  return $axios.get('/cbmp/rest/SysUser/list', {params});
}

//用户管理
export const apiGetUser = (params)=>{
  return $axios.get('/cbmp/rest/AppUser/list', {params});
}

export const apiPostUser = (params, type="post")=>{
  return $axios[type]('/cbmp/rest/AppUser', params);
}

//获取用户字段信息
export const apiGetUsersFields = (params)=>{
  return $axios.get('/cbmp/importUserFields', {params});
}

//签署信息
export const apiGetUserProtocolLog = (params)=>{
  return $axios.get('/cbmp/rest/UserProtocolLog/list', {params});
}