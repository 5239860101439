<template>
  <div>
    <el-checkbox v-model="checked"></el-checkbox> {{$t('signUp8_1')}}
    <span @click="visibleRead=true" class="x-f14 x-blue x-cursor">{{$t('signUp8_2')}}</span>
    <!--隐私政策-->
    <el-dialog :visible.sync="visibleRead" append-to-body :close-on-click-modal="false" :show-close="false">
      <read-one></read-one>
      <div class="x-tc x-mt20">
        <span class="x-btn x-submit" @click="agreen('chekced')">{{$t('signUp8_1')}}</span>
        <span class="x-btn" @click="visibleRead=false">{{$t('xconsulting5')}}</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import ReadOne from "./ReadOne";
  export default {
    props: {
      value: {
        type: Boolean
      }
    },
    components: { ReadOne },
    data(){
      return {
        visibleRead: false,
        checked: false
      }
    },
    watch: {
      checked(val){
        this.$emit('input', val);
      }
    },
    methods: {
      agreen(){
        this.checked=true;
        this.visibleRead=false;
      },
    }
  }
</script>

<style scoped>

</style>
