<template>
  <div class="x-main">
    <x-header></x-header>
    <x-breadcrumb></x-breadcrumb>
    <div class="x-content">
      <div class="x-left x-mr20">
        <index-left></index-left>
      </div>
      <div class="x-center">
        <demand-quick></demand-quick>
        <x-card style="width:100%;padding:0;">
          <div class="x-demand-title">我发布的需求</div>
          <div class="x-demand-list">
            <div v-if="tableData.length === 0">
              <no-data></no-data>
            </div>
            <div v-else>
              <demand-list-temp :item="item" :key="item.id" cls="demand-list-push" v-for="item in tableData">
                <el-dropdown slot="operation">
                  <div class="icon icim icim-ellipsis-vertical"></div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item><span
                      @click="toPageBlank('/DemandDetail', { id: item.id, langRelReqId: item.langRelReqId, lang: item.lang, idEn: item.idEn, idZh: item.idZh })"
                      class="icon icim icim-eye x-f12 x-block"> {{$t('operationView')}}</span></el-dropdown-item>
                    <el-dropdown-item><span
                      @click="toPage('/DemandRelease', { id: item.id, langRelReqId: item.langRelReqId, lang: item.lang })"
                      class="icon icim icim-pen-2 x-f12  x-block"> {{$t('operationEdit')}}</span></el-dropdown-item>
                    <el-dropdown-item v-if="lang==='zh'&&!item.langRelReqId"><span @click="toPageBlank('/en/DemandRelease', { langRelReqId: item.id, idEn: item.idEn, idZh: item.idZh }, true)"
                                                                                        class="icon icim icim-grid-2-plus x-f12 x-block"> Add English</span>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="lang==='zh'&&item.langRelReqId"><span @click="toPageBlank('/en/DemandDetail', { langRelReqId: item.id, idEn: item.idEn, idZh: item.idZh }, true)"
                                                                                       class="icon icim icim-grid-2-plus x-f12 x-block"> View English</span>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="lang==='en'&&!item.langRelReqId"><span @click="toPageBlank('/DemandRelease', { langRelReqId: item.id, idEn: item.idEn, idZh: item.idZh }, true)"
                                                                                        class="icon icim icim-grid-2-plus x-f12 x-block"> 添加中文</span>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="lang==='en'&&item.langRelReqId"><span @click="toPageBlank('/DemandDetail', { id: item.id, langRelReqId: item.langRelReqId, idEn: item.idEn, idZh: item.idZh }, true)"
                                                                                       class="icon icim icim-grid-2-plus x-f12 x-block"> 查看中文</span>
                    </el-dropdown-item>
                    <el-dropdown-item><span @click="delClick(item.id)"
                                            class="icon icim icim-trash-alt x-f12 x-block"> {{$t('operationDel')}}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </demand-list-temp>
            </div>
            <x-pagination :currpage="currPage" :total="total"  :pageSize="pageSize" @pageChange="refreshTable"></x-pagination>
          </div>
        </x-card>
      </div>
      <div class="x-right x-ml20">
        <index-right></index-right>
      </div>
    </div>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import XBreadcrumb from "../../components/XBreadcrumb";
  import XCard from "../../components/XCard";
  import Mixins from "../../mixins";
  import MixinsBase from "../../mixins/base";
  import {apiDemandList, apiPutTRequirement} from "../../api";
  import XPagination from "../../components/comm/XPagination";
  import IndexLeft from "../IndexLeft";
  import IndexRight from "../IndexRight";
  import DemandQuick from "./Comm/DemandQuick";
  import NoData from "./Comm/NoData";
  import DemandListTemp from "./DemandComm/DemandListTemp";

  export default {
    components: {
      DemandListTemp,
      DemandQuick,
      IndexRight,
      IndexLeft,
      XPagination,
      XCard,
      XBreadcrumb,
      XHeader,
      NoData,
    },
    mixins: [Mixins, MixinsBase],
    data() {
      return {
        params: {
          name: '',
        }
      }
    },
    computed: {
      typeObj() {
        return this.$store.getters.getTypeObj;
      }
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.refreshTable();
      },
      refreshTable(params = {}) {
        this.getTableData(params, apiDemandList, {
          isSelf: true,
          lang: this.lang
        });
      },
      search() {
        this.refreshTable({
          ...this.params,
          currPage: 1
        });
      },
      delClick(id) {
        this.$confirm(this.$t('delete1'), this.$t('delete2'), {
          confirmButtonText: this.$t('delete3'),
          cancelButtonText: this.$t('delete4'),
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          apiPutTRequirement({
            id,
            "isDelete": true
          }).then(res => {
            if (res.success) {
              this.refreshTable();
            }
          })
        }).catch(() => {

        });
      },
    }
  }
</script>

<style scoped>
  .x-demand-left li {
    margin-bottom: 20px;
    cursor: pointer;
  }

  .x-demand-left li:last-child {
    margin-bottom: 0;
  }

  .x-demand-tab {
    width: 100%;
    height: 40px;
    position: relative;
    top: -1px;
  }

  .x-demand-tab li {
    width: 100px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    display: inline-block;
    background: #f6f3f5;
    border-radius: 20px 20px 0 0;
    position: relative;
    border: 1px solid #e4eaf0;
  }

  .x-demand-tab li:after {
    content: '';
    width: 30px;
    height: 40px;
    display: inline-block;
    background: url('../../assets/images/bg-tab-demand.png') no-repeat 0 -42px;
    position: absolute;
    right: -14px;
    top: 0;
    border-bottom: 1px solid #e4eaf0;
  }

  .x-demand-tab .on {
    z-index: 3;
    background-color: #fff;
    border-bottom-color: #fff;
  }

  .x-demand-tab .on:after {
    height: 42px;
    background-position-y: 0;
    border-bottom: none;
  }

  .demand-seach {
    margin-right: 10px;
    flex: 1;
  }
</style>
<style>
  .x-demand-table {
    margin-bottom: 20px;
  }

  .x-demand-table th {
    background: #F9FBFC !important;
  }

  .x-demand-table tr:nth-child(even) td {
    background: #fcfcfc;
  }

  .x-block {
    display: block;
  }
</style>
