<template>
  <div class="x-container">
    <top-bar></top-bar>
    <div class="x-main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import TopBar from '../comm/TopBar';
  import {apiGetCurruser} from '@/api/RequirementManage';

  export default {
    components: {TopBar},
    data() {
      return {};
    },
    computed: {
      userInfo() {
        return this.$store.getters.getUserInfo;
      },
    },
    created() {
      apiGetCurruser().then(res => {
        this.$store.dispatch('setUserInfo', res);
      });
    }
  };
</script>

<style scoped>
  .x-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
</style>
