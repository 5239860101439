<template>
  <div class="x-main">
    <x-header></x-header>
    <x-breadcrumb></x-breadcrumb>
    <div class="x-content">
      <div class="x-left x-mr20">
        <index-left></index-left>
      </div>
      <div class="x-center">
        <activity-quick></activity-quick>
        <x-card style="width:100%;padding:0;">
          <div class="x-demand-title">活动列表</div>
          <div class="x-demand-list">
            <div v-if="tableData.length === 0">
              <no-data></no-data>
            </div>
            <div v-else>
              <div v-for="item in tableData" :key="item.id" style="display:flex;border-bottom:1px solid #e8edef;padding-bottom:20px;margin-bottom:20px;">
                <el-image
                  style="width:120px;height:80px;border-radius:10px;overflow:hidden;"
                  :src="`/cbmp/file/${item.image}`"></el-image>
                <div style="flex:1;margin:0 20px;">
                  <div>{{item.start|formatDate('datetime')}}~{{item.end|formatDate('datetime')}}</div>
                  <div class="x-bold">{{item.title}}</div>
                  <div>活动进行中</div>
                </div>
                <ul>
                  <li><el-button type="text" class="el-icon-edit" @click="editClick(item)">编辑活动</el-button></li>
                  <!--<li><el-button type="text" class="el-icon-view" @click="toPage('/EnrollmentView', { id: item.id })">报名查看</el-button></li>-->
                  <li v-if="item.disabled"><el-button type="text" class="el-icon-delete x-gray" @click="startClick(item.id, false)">活动启用</el-button></li>
                  <li v-else><el-button type="text" class="el-icon-delete x-gray" @click="startClick(item.id, true)">活动停用</el-button></li>

                </ul>
              </div>
            </div>
            <x-pagination :total="total" @pageChange="refreshTable" :pageSize="pageSize" :currpage="currPage"></x-pagination>
          </div>
        </x-card>
      </div>
      <div class="x-right x-ml20">
        <index-right></index-right>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import XHeader from "../../components/XHeader";
  import XBreadcrumb from "../../components/XBreadcrumb";
  import XCard from "../../components/XCard";
  import NoData from "../Demand/Comm/NoData";
  import Mixins from "../../mixins";
  import MixinsBase from "../../mixins/base";
  import {apiGetActivity, apiActivity} from "../../api/Activities";
  import XPagination from "../../components/comm/XPagination";
  import IndexLeft from "../IndexLeft";
  import IndexRight from "../IndexRight";
  import ActivityQuick from "./ActivityQuick";
  export default {
    components: {
      ActivityQuick,
      IndexRight,
      IndexLeft,
      XPagination,
      NoData,
      XCard,
      XBreadcrumb,
      XHeader,
    },
    mixins: [Mixins, MixinsBase],
    data () {
      return {
        pageSize: 4
      }
    },
    computed: {
      getUserInfo(){
        return this.$store.getters.getUserInfo;
      }
    },
    watch: {
      getUserInfo: {
        handler(val){
          if(!_.isEmpty(val)){
            this.refreshTable();
          }
        },
        immediate:true
      }
    },
    methods: {
      init(){
        this.refreshTable();
      },
      refreshTable(params={}){
        this.searchParams = {
          ...this.searchParams,
          ...params
        };
        this.getTableData(params, apiGetActivity, {
          creator: this.getUserInfo.username,
          //disabled: false
        });
      },
      editClick(item){
        this.toPage('/PublishingActivities', { type: 'edit', id: item.id });
      },
      startClick(id, bol){
        apiActivity({
          id,
          disabled: bol
        }, 'put').then(res=>{
          if(res.success){
            this.refreshTable();
          }
        })
      }
    }
  }
</script>

<style scoped>
  .x-demand-search{
    border-bottom:1px solid #e5f0f6;position:relative;
  }
</style>
