<template><!--需求有效期-->
  <div style="display:flex;">
    <el-date-picker style="flex:1;"
      v-model="value1"
      type="daterange"
      value-format="yyyy-MM-dd"
      :range-separator="$t('detailbar2')"
      :start-placeholder="$t('startDate')"
      :end-placeholder="$t('endDate')" :disabled="cc" @change="changeDate">
    </el-date-picker>
    <el-checkbox v-model="cc" class="x-ml10">{{$t('detailbar1')}}</el-checkbox>
  </div>
</template>

<script>
  export default {
    props: ['value','ikey'],
    data(){
      return {
        value1: [],
        cc: false
      }
    },
    watch: {
      'cc'(val){
        this.reIndefinite(val);
      },
      'value': {
        immediate: true,
        handler: function(val){
          this.setValue(val);
        }
      }
    },
    methods: {
      reIndefinite(val){
        this.$emit('setValue', val, this.$props.ikey);
      },
      changeDate(val) {
        this.$emit('setValue', val, this.$props.ikey);
      },
      setValue(val){
        if(val === true){
          this.cc = true;
        }else{
          this.value1 = val;
        }
      }
    }
  }
</script>

<style scoped>

</style>
