<template>
  <div style="width:100%;">
    <x-card style="padding:0;width:100%;">
      <release-step></release-step>
      <div style="padding:20px;line-height:24px;">
        <component :is="currentView"></component>
      </div>
      <detail-user></detail-user>
    </x-card>
    <step-btn @submit="submit"></step-btn>
  </div>

</template>

<script>
  import StepBtn from "./StepBtn";
  import XCard from "../../components/XCard";
  import ReleaseStep from "./ReleaseStep";
  import PreviewA from "./DemandType/PreviewA";
  import PreviewB from "./DemandType/PreviewB";
  import PreviewC from "./DemandType/PreviewC";
  import PreviewD from "./DemandType/PreviewD";
  import PreviewE from "./DemandType/PreviewE";
  import PreviewF from "./DemandType/PreviewF";
  import DetailUser from "./Comm/DetailUser";
  import {apiSaveOrUpdate} from '../../api';
  import MixinsBase from "../../mixins/base";
  export default {
    components: {DetailUser, XCard, StepBtn, ReleaseStep, PreviewA, PreviewB, PreviewC, PreviewD, PreviewE, PreviewF},
    mixins: [MixinsBase],
    computed: {
      currentView(){
        let { type } = this.$store.getters.getCacheDemand;
        return `Preview${type[0]}`;
      },
      cacheDemand(){
        return this.$store.getters.getCacheDemand;
      },
    },
    methods: {
      submit(){
        let params = {
          ...this.cacheDemand,
          status: 'published',
          lang: this.lang
        };
        let {langRelReqId} = this.$route.query;
        if(langRelReqId){
          params.langRelReqId = langRelReqId;
        }
        if(params.isNoDate === false){
          delete params.isNoDate;
        }
        if(params.isFaceBudget === false){
          delete params.isFaceBudget;
        }
        apiSaveOrUpdate(params).then(res=>{
          if(res.success){
            this.toPage('/DemandMy');
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>
