<template>
	<sign-layout>
		<h1 slot="title">{{ $t('signIn1') }}</h1>
		<el-tabs v-model="activeName" class="demo-tabs">
			<el-tab-pane :label="$t('signIn6')" name="first">
				<el-form :model="dynamicValidateForm" ref="dynamicValidateForm" :label-position="labelPosition" class="demo-dynamic">
					<el-form-item prop="mobile" :rules="{ required: true, message: $t('signIn8'), trigger: 'blur' }">
						<el-input prefix-icon="el-icon-mobile-phone" v-model="dynamicValidateForm.mobile" :placeholder="$t('signIn8')">
							<el-select
								filterable
								v-model="dynamicValidateForm.areaCodeId"
								slot="prepend"
								:placeholder="$t('placeholder')"
								style="width:120px;"

								:rules="{ required: true, message: '', trigger: 'blur' }"
							>
								<el-option
									v-for="item in mobilePrefixOpt"
									:key="item.id"
									:label="`+${item.mobilePrefix}(${lang === 'en' ? item.countryEn : item.country})`"
									:value="item.id"
								></el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<el-form-item v-if="isCode" prop="checkcode" :rules="{ required: true, message: $t('signIn9'), trigger: 'blur' }">
						<el-col :span="14"><el-input :placeholder="$t('signIn9')" v-model="dynamicValidateForm.checkcode"></el-input></el-col>
						<el-col :span="9" :offset="1">
							<el-button plain style="width:100%" @click="getCheckCode">{{ countStr }}</el-button>
						</el-col>
					</el-form-item>
					<el-form-item v-else prop="password" :rules="{ required: true, message: $t('signUp13'), trigger: 'blur' }">
            <el-input type="password" :placeholder="$t('signUp13')" v-model="dynamicValidateForm.password"></el-input>
					</el-form-item>
          <div class="x-blue">
            <span class="x-cursor" v-if="isCode" @click="isCode=false">{{ $t('signIn11') }}</span>
            <span class="x-cursor" @click="isCode=true" v-else>{{ $t('signIn12') }}</span>
          </div>
          <el-form-item :rules="{ validator: validChecked, trigger: 'change' }" class="x-f12" prop="checked">
            <el-checkbox v-model="dynamicValidateForm.checked"></el-checkbox>
            {{ $t('signUp8_1') }}
            <span @click="visibleRead = true" class="x-f14 x-blue x-cursor">{{ $t('signUp8_2') }}</span>
          </el-form-item>
          <el-form-item :rules="{ validator: validChecked2, trigger: 'change' }" class="x-f12" prop="checked2">
            <el-checkbox v-model="dynamicValidateForm.checked2"></el-checkbox>
            {{ $t('signUp8_1') }}
            <span @click="visibleRead2 = true" class="x-f14 x-blue x-cursor">{{ $t('signUp8_5') }}</span>
          </el-form-item>
					<el-form-item><el-button style="width:100%" type="primary" round :loading="btnLoading" @click="phoneSignIn">{{$t('signIn3')}}</el-button></el-form-item>
				</el-form>
			</el-tab-pane>
			<el-tab-pane :label="$t('signIn7')" name="second">
				<el-form :label-position="labelPosition" :model="emailForm" class="demo-dynamic" ref="emailForm">
					<el-form-item :rules="[{ required: true, message: $t('signUp15'), trigger: 'blur' }]" prop="email">
						<el-input :placeholder="$t('signUp15')" prefix-icon="el-icon-message" v-model="emailForm.email"></el-input>
					</el-form-item>
					<el-form-item :rules="{ required: true, message: $t('signUp13'), trigger: 'blur' }" prop="password">
						<el-input
							:placeholder="$t('signUp13')"
							@keyup.enter.native="emailFormSubmit"
							autocomplete="off"
							prefix-icon="el-icon-lock"
							show-password
							type="password"
							v-model="emailForm.password"
						></el-input>
					</el-form-item>
					<el-button @click="toPage('/ForgotPwd')" type="text">{{ $t('signIn2') }}?</el-button>
					<el-form-item :rules="{ validator: validChecked, trigger: 'change' }" class="x-f12" prop="checked">
						<el-checkbox v-model="emailForm.checked"></el-checkbox>
						{{ $t('signUp8_1') }}
						<span @click="visibleRead = true" class="x-f14 x-blue x-cursor">{{ $t('signUp8_2') }}</span>
					</el-form-item>
					<el-form-item :rules="{ validator: validChecked2, trigger: 'change' }" class="x-f12" prop="checked2">
						<el-checkbox v-model="emailForm.checked2"></el-checkbox>
						{{ $t('signUp8_1') }}
						<span @click="visibleRead2 = true" class="x-f14 x-blue x-cursor">{{ $t('signUp8_5') }}</span>
					</el-form-item>
					<el-form-item>
						<el-button :loading="btnLoading" @click="emailFormSubmit" round style="width:100%" type="primary">{{ $t('signIn3') }}</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
		</el-tabs>
		<div class="login-footer" slot="footer">
			<el-dropdown @command="setLanguage">
				<span class="el-dropdown-link">
					{{ $t('name') }}
					<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="zh" v-if="lang === 'en'">简体中文</el-dropdown-item>
					<el-dropdown-item command="en" v-if="lang === 'zh'">English</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<div>
				{{ $t('signIn4') }}
				<el-button type="text" @click="toPage('/SignUp')" class="x-f14">{{ $t('signIn5') }}</el-button>
			</div>
		</div>
		<!--隐私政策-->
		<el-dialog :visible.sync="visibleRead" append-to-body :close-on-click-modal="false" :show-close="false">
			<read-one></read-one>
			<div class="x-tc x-mt20">
				<span class="x-btn x-submit" @click="agreen('checked')">{{ $t('signUp8_1') }}</span>
				<span class="x-btn" @click="visibleRead = false">{{ $t('xconsulting5') }}</span>
			</div>
		</el-dialog>
		<!--保密协议-->
		<el-dialog :visible.sync="visibleRead2" append-to-body :close-on-click-modal="false" :show-close="false">
			<read-two></read-two>
			<div class="x-tc x-mt20">
				<span class="x-btn x-submit" @click="agreen('checked2')">{{ $t('signUp8_1') }}</span>
				<span class="x-btn" @click="visibleRead2 = false">{{ $t('xconsulting5') }}</span>
			</div>
		</el-dialog>
	</sign-layout>
</template>

<script>
import '@/assets/style/login.css';
import SignLayout from './SignLayout';
import { JSEncrypt } from 'jsencrypt';
import { apiLogin, apiSendSms, apiMobilePrefix } from '../../api';
import MixinsBase from '../../mixins/base';
import ReadTwo from './ReadTwo';
import ReadOne from './ReadOne';

export default {
	components: { ReadOne, ReadTwo, SignLayout },
	mixins: [MixinsBase],
	data() {
		let validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error(this.$t('signUp13')));
			} else {
				if (this.ruleForm.pass !== '') {
					this.$refs.ruleForm.validateField('pass');
				}
				callback();
			}
		};

		let validChecked = (rule, value, callback) => {
			if (!value) {
				callback(new Error(this.$t('signUp14') + this.$t('signUp8_3')));
			} else {
				callback();
			}
		};

		let validChecked2 = (rule, value, callback) => {
			if (!value) {
				callback(new Error(this.$t('signUp14') + this.$t('signUp8_5')));
			} else {
				callback();
			}
		};
		return {
			labelPosition: 'top',
			formLabelAlign: {
				name: '',
				region: '',
				type: ''
			},
			activeName: 'first',
			dynamicValidateForm: {
				mobile: '',
				password: '',
				checkcode: '',
				areaCode: '86',
				areaCodeId: 1,
        checked: true,
        checked2: true
			},
			ruleForm: {
				pass: ''
			},
			rules: {
				pass: [{ validator: validatePass, trigger: 'blur' }]
			},
			emailForm: {
				email: '',
				password: '',
				//email: 'xuzhi31672@billjc.com',
				//password: '888888',
				checked: true,
				checked2: true
			},
			count: 0,
			speed: 1000,
			visibleRead: false,
			visibleRead2: false,
			validChecked,
			validChecked2,
      isCode: true
		};
	},
  watch: {
	  'dynamicValidateForm.areaCodeId'(areaId){
	    let selectItem = this.mobilePrefixOpt.filter(i=>{
	      return i.id === areaId;
      });
	    this.$set(this.dynamicValidateForm, 'areaCode', selectItem&&selectItem[0]['mobilePrefix']);
    }
  },
	computed: {
		publicKey() {
			return this.$store.getters.getPublicKey;
		},
		btnLoading() {
			return this.$store.getters.getBtnLoading;
		},
		countStr() {
			return this.count === 0 ? this.$t('signIn10') : `${this.count}s`;
		},
    mobilePrefixOpt(){
		  return this.$store.getters.getMobilePrefix;
    }
	},
	mounted() {
		this.$store.dispatch('setUserInfo', {});
		this.$store.dispatch('setMobilePrefix');
	},
	methods: {
		countryMobileChange(val) {
			let oo = this.mobilePrefixOpt.filter(i => {
				return val === i.id;
			});
			this.$set(this.dynamicValidateForm, 'areaCode', oo[0]['mobilePrefix']);
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					alert('submit!');
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		encryptedData(data) {
			let encryptor = new JSEncrypt();
			encryptor.setPublicKey(this.publicKey);
			return encryptor.encrypt(data);
		},
		emailFormSubmit() {
			this.$refs.emailForm.validate(valid => {
				if (valid) {
					let { email, password } = this.emailForm;
					let encryptionPwd = this.encryptedData(password);
					let params = {
						username: email,
						password: encryptionPwd
					};
					//console.log(params);
					apiLogin(params).then(res => {
						if (res.success) {
							this.$store.dispatch('setUserInfo', res.ext);
							this.toPage('/Index');
						}
					});
				}
			});
		},

		getCheckCode() {
			this.$refs.dynamicValidateForm.validateField('mobile', valid => {
				if (!valid) {
					let { speed } = this,
						timer = null;
					let count = 60;
					let fn = () => {
						count -= 1;
						if (count < 0) {
							clearTimeout(timer);
						} else {
							this.count = count;
							timer = setTimeout(fn, speed);
						}
					};
					let {mobile, areaCode} = this.dynamicValidateForm;
					apiSendSms({
						mobile,
            areaCode,
						type: 'login'
					});
					fn();
				}
			});
		},
		phoneSignIn() {
			this.$refs.dynamicValidateForm.validate(valid => {
				if (valid) {
					let { mobile, checkcode, areaCode, password } = this.dynamicValidateForm;
					let params = {
						username: mobile,
						checkcode,
            areaCode,
					};
					if(password){
					  params.password = this.encryptedData(password);
          }
					if(this.isCode){
					  params.type = 'checkcode'
          }
					apiLogin(params).then(res => {
						if (res.success) {
							this.toPage('/Index');
						}
					});
				}
			});
		},
		agreen(key = 'checked') {
      this.visibleRead = false;
      this.visibleRead2 = false;
			if(this.activeName === 'first'){
        this.$set(this.dynamicValidateForm, key, true);
      }
      if(this.activeName === 'second'){
        this.$set(this.emailForm, key, true);
      }

		}
	}
};
</script>

<style lang="css" scoped></style>
