<template>
  <x-card style="padding:0;width:100%;">
    <release-step></release-step>
    <div class="step-one">
      <div class="step-one-info">
        <span class="el-icon-info"></span>
        {{$t('stepOne7')}}
      </div>
      <step-one-map></step-one-map>
      <div style="padding:20px;height:220px;overflow-y:auto;">
        <h3 class="x-tc">{{$t('baomixieyi1')}}</h3>
        <pre style="white-space: pre-wrap;">
          {{$t('baomixieyi2')}}
          </pre>
        <div style="text-align:right;">
          {{$t('baomixieyi3')}}<br />
          20220901
        </div>
        <div class="x-tc x-mt20">
          <el-radio v-model="radio" :label="1">{{$t('signUp8_1')}}</el-radio>
        </div>
      </div>
      <step-btn @next="nextEvent" @save="saveEvent"></step-btn>
    </div>
  </x-card>

</template>

<script>

  import StepBtn from "./StepBtn";
  import XCard from "../../components/XCard";
  import ReleaseStep from "./ReleaseStep";
  import MixinsBase from '../../mixins/base';
  import StepOneMap from "./StepOneMap";
  export default {
    components: {StepOneMap, StepBtn,XCard,ReleaseStep},
    data(){
      return {
        radio: 0
      }
    },
    mixins: [MixinsBase],
    computed: {
      cacheDemand(){
        return this.$store.getters.getCacheDemand;
      }
    },
    methods: {
      nextEvent(step){
        if(!this.cacheDemand.type){
          this.$message.error(this.$t('stepOne7'));
        }else if(this.radio===0){
          this.$message.error(this.$t('stepOne8'));
        }else{
          this.$store.dispatch('setStep', step);
        }
      },
      saveEvent(){
        let {type} = this.cacheDemand;
        if(!type){
          this.$message.error(this.$t('stepOne7'));
        }else if(this.radio===0){
          this.$message.error(this.$t('stepOne8'));
        }else{
          let params = {
            status: "draft",
            lang: this.lang
          };
          let {langRelReqId} = this.$route.query;
          if(langRelReqId){
            params.langRelReqId = langRelReqId;
          }
          this.$store.dispatch('saveDemand', params);
        }
      },
    }
  }
</script>

<style scoped>
  .step-one-info{width:100%;box-sizing:border-box;padding:0 24px;height:36px;line-height:36px;color:#f37920;background:#fbf8f4;}
</style>
