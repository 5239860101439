<template>
	<div class="top-bar" style="min-width: 1350px;">
		<div class="left-part">
			<div class="x-logo-img"><img src="../../assets/images/ICIM-LOGO.png" alt="" /></div>
			<ul class="nav-list x-tc x-ml x-ml20">
				<li v-for="(item, index) in menu1" :key="index" :class="current === item.index ? 'active' : ''" @click="toPage(item.url, index)">
					<div><el-icon :class="item.icon" /></div>
					<span class="text">{{ item.name }}</span>
				</li>
				<!-- <li @click="openDialog">
          <div><el-icon class="el-icon-s-grid" /></div>
          <span class="text">更多</span>
        </li> -->
			</ul>
		</div>
		<div class="right-part">
			<!-- <el-dropdown @command="handleCommand">
        <el-button icon="el-icon-setting" circle style="width:40px;height:40px;border:1px solid rgba(227, 231, 236, .5)!important"></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in menu2.children" :key="index" :command="item.url">{{ item.name }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
			<div class="user-box">
				<div class="head-img"><el-icon class="el-icon-user-solid" /></div>
				<div class="user-info">
					<el-dropdown @command="handleCommand">
						<span class="el-dropdown-link">
							{{ userInfo.name }}

							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown"><el-dropdown-item command="logout">退出登录</el-dropdown-item></el-dropdown-menu>
					</el-dropdown>

					<!-- <span class="dpt" v-if="userInfo.roleNames!==undefined&& userInfo.roleNames.length === 1">{{ userInfo.roleNames[0] }}</span>
          <span class="dpt" v-if="userInfo.roleNames!==undefined&&userInfo.roleNames.length > 1"> -->

					<!-- <el-dropdown >
            <span class="el-dropdown-link">
              {{ userInfo.roleNames[0] }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="logout" v-for="(item,idx) in userInfo.roleNames" :key="666+idx" >{{item}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span> -->
				</div>
			</div>
		</div>
		<dialog-form ref="dialogForm" title="产品导航" :nobtn="false" width="60%">
			<el-divider content-position="left">工作菜单</el-divider>
			<ul class="x-moreMenu">
				<li v-for="(item, index) in menu1" :key="index" @click="toPage(item.url, index)">
					<div><el-icon :class="item.icon" /></div>
					<span class="text">{{ item.name }}</span>
				</li>
			</ul>

			<el-divider content-position="left">后台菜单</el-divider>
			<ul class="x-moreMenu">
				<li v-for="(item, index) in menu2.children" :key="index" @click="toPage(item.url, index)">
					<div><el-icon :class="item.icon || 'el-icon-paperclip'" /></div>
					<span class="text">{{ item.name }}</span>
				</li>
			</ul>
		</dialog-form>
	</div>
</template>

<script>
import { apiPostService } from '@/api/index';
import DialogForm from './DialogForm';
import _ from 'lodash';
export default {
	components: {
		DialogForm
	},
	data() {
		return {
			current: 0,
			index: 0,
			currentArr: [],
			menu1: [
				{ url: 'System/index', name: '需求管理', icon: 'el-icon-s-management', index: 0 },
				{ url: 'System/SysUser', name: '用户管理', icon: 'el-icon-s-management', index: 1 },
				{ url: 'System/Message', name: '互动咨询管理', icon: 'el-icon-s-management', index: 2 },
				{ url: 'System/Notice', name: '通知管理', icon: 'el-icon-s-management', index: 3 }
			],
			menu2: {
				children: []
			}
		};
	},
	computed: {
		userInfo() {
			return this.$store.getters.getUserInfo;
		},
		isCurrent() {
			return this.current === this.index;
		}
	},
	watch: {
		userInfo(newVal, oldVal) {
			let type = newVal.type;
			if (type == 'front') {
				this.$router.push('/index');
				//this.logout();
				setTimeout(() => {
					this.$message({
						message: '无权限访问此页面！',
						type: 'warning'
					});
				}, 1000);
			}
		}
	},
	mounted() {
		let path = this.$route.path && this.$route.path.substr(1);
		let aa = this.menu1.filter(i => {
			return _.startsWith(i.url, path, 0);
		});
		if(path==='System/NoticeSend'){
      this.toPage("System/NoticeSend", 3);
    } else if (aa.length > 0) {
			this.toPage(aa[0].url, aa[0].index);
		}else{
		  //
    }
	},
	methods: {
		openDialog() {
			this.$refs.dialogForm.openDialog();
		},
		closeDialog() {
			this.$refs.dialogForm.closeDialog();
		},
		toPage(name, index) {
			this.current = index;
      if (name.indexOf('http') === 0) {
        let aaa = document.createElement('a');
        aaa.href = name;
        aaa.target = '_blank';
        aaa.click();
      } else {
        this.closeDialog();
        //let _t = new Date().getTime();
        this.$router.push({ path: '/' + name });
        //this.$router.push({ path: '/'+name, query: { t: _t } });
      }
		},
		getAction(idx) {
			return this.current == idx;
		},
		logout() {
			apiPostService(`/cbmp/exit`, {}, 'get').then(() => {
				this.$router.push('/System/Login');
			});
		},
		handleCommand(command) {
			if (command === 'notice') {
				let data = { id: 0, mode: 0 };
				this.$refs.noticeDialog.initForm(data);
			} else {
				if (this[command]) {
					this[command]();
				} else {
					this.toPage(command, -1);
				}
			}
		}
	}
};
</script>

<style lang="less">
.x-logo-img {
	width: 180px;
}

.x-logo-img img {
	display: block;
}

.top-bar {
	margin: 15px 20px 30px 20px;
	display: flex;
	justify-content: space-between;

	.left-part {
		display: flex;
		align-items: center;

		i {
			font-size: 16px;
		}

		h2 {
			font-size: 26px;
			margin-right: 40px;
			font-weight: bold;
		}

		.nav-list {
			vertical-align: middle;
			display: flex;

			li {
				margin-right: 30px;
				cursor: pointer;

				&.active {
					border-radius: 10px;
					background-color: rgba(0, 38, 255, 0.1);
					color: #0268a2;
					font-weight: bold;

					i {
						font-size: 20px;
					}
				}

				display: block;
				width: 80px;
				padding: 10px 0 12px 0;
				text-align: center;
				font-size: 12px;
				color: rgb(0, 4, 26);

				i {
					display: block;
					margin-bottom: 3px;
				}
			}
		}
	}

	.right-part {
		display: flex;
		align-items: center;

		i {
			font-size: 16px;
		}

		.setting,
		.bell {
			cursor: pointer;
			width: 40px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			border-radius: 50%;
			border: 1px solid rgba(227, 231, 236);

			&.setting {
				margin-right: 20px;
				color: rgb(0, 4, 26, 100);
			}

			&.bell {
				color: rgba(234, 87, 87, 100);
			}
		}

		.user-box {
			display: flex;
			align-items: center;
			font-size: 12px;

			i {
				font-size: 12px;
			}

			.head-img {
				cursor: pointer;
				width: 50px;
				height: 50px;
				margin-left: 25px;
				text-align: center;
				line-height: 50px;
				border-radius: 50%;
				background-color: rgba(88, 28, 202, 0.1);
				color: #581cca;
				display: inline-block;

				i {
					line-height: 50px;
					font-size: 24px;
				}
			}

			.user-info {
				font-size: 15px;
				margin-left: 10px;
				display: inline-block;
				color: #101010;
				cursor: pointer;

				.name,
				.dpt {
					display: block;

					&.dpt {
						font-size: 12px;
						color: #101010;
						opacity: 0.5;
					}

					&.name {
						i {
							margin-left: 18px;
							font-size: 13px;
						}
					}
				}
			}
		}
	}
}

.x-moreMenu {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px;

	li {
		width: 80px;
		text-align: center;
		padding: 5px 10px;
		cursor: pointer;
		border-radius: 5px;
		margin-bottom: 20px;

		&:hover {
			color: #0268a2;
		}
	}
}
</style>
