<template>
  <div class="x-flex x-search-box">
    <div style="position:absolute;right:100px;top:-38px;">
      <el-radio-group v-model="params.isWeightSort" @change="sortChange">
        <el-radio-button :label="true">{{$t('dsearch1')}}</el-radio-button>
        <el-radio-button :label="false">{{$t('dsearch2')}}</el-radio-button>
      </el-radio-group>
      <el-input :placeholder="$t('dsearch3')" v-model="params.name" style="width:200px;margin-left:10px;">
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-input>
    </div>
    <div @click="isgaoji=!isgaoji" class="x-gaoji x-cursor">
      <span class="x-f12 x-blue">{{$t('dsearch4')}}</span>
      <span :class="isgaoji?'el-icon-arrow-up':'el-icon-arrow-down'" class="x-arrow"></span>
    </div>
    <div style="padding:10px;" v-show="searchParams.name||searchParams.reqType||searchParams.beginDate||searchParams.beginBudget||searchParams.industryIds||searchParams.regionId||searchParams.techFieldIds">
      <span class="x-red">
        {{$t('dsearch5')}}
      </span>
      <span class="x-search-tag" v-show="searchParams.name">
        {{$t('dsearch6')}}：<span class="x-red">{{searchParams.name}}</span> <span class="el-icon-error x-red x-cursor" @click="delSearch('name')"></span>
      </span>
      <span class="x-search-tag" v-show="searchParams.reqType">
        {{$t('demandMy9')}}：<span class="x-red">{{typeObj[searchParams.reqType]&&typeObj[searchParams.reqType][lang==='en'?'nameEn':'name']}}</span> <span class="el-icon-error x-red x-cursor"  @click="delSearch('reqType')"></span>
      </span>
      <span class="x-search-tag" v-show="searchParams.beginDate">
        {{$t('demandMy3')}}：<span class="x-red">{{searchBeginDate}}</span> <span class="el-icon-error x-red x-cursor"  @click="delSearch('beginDate')"></span>
      </span>
      <span class="x-search-tag" v-show="searchParams.beginBudget">
        {{$t('demandMy4')}}：<span class="x-red">{{searchParams.beginBudget+'~'+searchParams.endBudget}}</span> <span class="el-icon-error x-red x-cursor"  @click="delSearch('beginBudget')"></span>
      </span>
      <span class="x-search-tag" v-show="searchParams.industryIds">
        {{$t('userinfo12')}}：<span class="x-red">{{searchParams.industryIds}}</span> <span class="el-icon-error x-red x-cursor"  @click="delSearch('industryIds')"></span>
      </span>
      <span class="x-search-tag" v-show="searchParams.regionId">
        {{$t('userinfo10')}}：<span class="x-red">{{searchParams.regionId}}</span> <span class="el-icon-error x-red x-cursor"  @click="delSearch('regionId')"></span>
      </span>
      <span class="x-search-tag" v-show="searchParams.techFieldIds">
        {{$t('userinfo13')}}：<span class="x-red">{{searchParams.techFieldIds}}</span> <span class="el-icon-error x-red x-cursor"  @click="delSearch('techFieldIds')"></span>
      </span>
    </div>
    <!--高级搜索-->
    <div v-show="isgaoji" class="x-gaoji-box">
      <div class="x-flex">
        <div class="x-flex-1 x-flex-2">
          <div class="x-gray x-mb8">{{$t('demandMy9')}}</div>
          <el-select clearable :placeholder="$t('demandMy9')" style="width: 100%;" v-model="params.reqType">
            <el-option :key="key" :label="lang==='en'?value.nameEn:value.name"
                       :value="key" v-for="(value, key) in typeObj"></el-option>
          </el-select>
        </div>
        <div class="x-flex-1">
          <div class="x-gray x-mb8">{{$t('demandMy3')}}</div>
          <el-date-picker :end-placeholder="$t('endDate')"  :start-placeholder="$t('startDate')"
                          class="demand-seach"
                          range-separator="-"
                          style="width:100%;"
                          type="daterange"
                          v-model="demandDate">
          </el-date-picker>

        </div>
        <div class="x-flex-1">
          <div class="x-gray x-mb8">{{$t('demandMy4')}}</div>
          <demand-budget :ischeck="true" @setValue="setBudget" class="x-mr10" ref="budget" width="50px"></demand-budget>

        </div>
        <div class="x-flex-1">
          <div class="x-gray x-mb8">{{$t('userinfo12')}}</div>
          <el-cascader :key="indstryKey" ref="industryRef" :options="categoryOpt" :props="{ value: 'id', label: lang==='en'?'nameEn':'name', children: 'children'}" clearable
                       :placeholder="$t('userinfo12')"
                       style="width: 100%;"
                       v-model="params.industryIds" @change="industryChange"></el-cascader>
        </div>
        <div class="x-flex-1">
          <div class="x-gray x-mb8">{{$t('userinfo10')}}</div>
          <el-cascader :key="areaKey" ref="areaRef" :options="areaOpt" :props="{ value: 'id',label: lang==='zh'?'name':'nameEn',children: 'children'}" clearable
                       :placeholder="$t('userinfo10')"
                       style="width: 100%;"
                       v-model="params.regionId" @change="areaChange"></el-cascader>
        </div>
        <div class="x-flex-1">
          <div class="x-gray x-mb8">{{$t('userinfo13')}}</div>
          <el-cascader :key="domainKey" ref="domainRef" :options="domainOpt" :props="{ value: 'id', label: lang==='en'?'nameEn':'name', children: 'children', multiple: true }" collapse-tags
                       :placeholder="$t('userinfo13')"
                       style="width: 100%;"
                       v-model="params.techFieldIds" @change="domainChange"></el-cascader>
        </div>
        <div class="x-flex-1 x-search-btn">
          <div class="x-gray x-mb8">{{$t('dsearch6')}}</div>
          <el-input :placeholder="$t('demand5')" type="text" v-model="params.name"></el-input>
        </div>
      </div>
      <div class="x-tc" style="margin-top:24px;">
        <el-button @click="search" class="demand-seach-btn" type="primary">{{$t('search')}}</el-button>
        <el-button @click="reset" class="demand-seach-btn">{{$t('reset')}}</el-button>
      </div>
    </div>

  </div>
</template>

<script>
  import _ from "lodash";
  import DemandBudget from "../FormComm/DemandBudget";
  import MixinsBase from "../../../mixins/base";
  export default {
    props: {
      minHeight: {
        type: String,
        default: '24px'
      },
      maxHeight: {
        type: String,
        default: '58px'
      }
    },
    components: {DemandBudget},
    mixins: [MixinsBase],
    data(){
      return {
        params: {
          isWeightSort: true,
          reqType: '',
          beginDate: '',
          endDate: '',
          regionId: '',
          industryIds: '',
          techFieldIds: [],
          name: '',
          beginBudget: '',
          endBudget: '',
        },
        demandDate: [],
        isdate: false,
        isbudget: false,
        isgaoji: false,
        searchParams: {},
        areaCache: "",
        domainCache: "",
        industryCache: "",
        indstryKey: 1,
        areaKey: 1,
        domainKey: 1,
		oldTechFieldIds:[]
      }
    },
    watch: {
      demandDate(val){
        if(_.isEmpty(val)){
          this.params.beginDate = "";
          this.params.endDate = "";
        }else{
          this.params.beginDate = val[0];
          this.params.endDate = val[1];
        }
      }
    },
    computed: {
      typeObj(){
        return this.$store.getters.getTypeObj;
      },
      areaOpt() {
        let area = this.$store.getters.getArea;
        return this.getTreeData(area);
      },
      categoryOpt() {
        return this.getTreeData(this.$store.getters.getCategory);
      },
      domainOpt() {
        return this.getTreeData(this.$store.getters.getDomain);
      },
      searchBeginDate(){
        let {beginDate,endDate} = this.searchParams;
        if(beginDate){
          return `${beginDate.getFullYear()}-${beginDate.getMonth()+1}-${beginDate.getDate()}至${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`;
        }else{
          return '';
        }
      }
    },
    mounted() {
      this.getDict();
    },
    methods: {
      init(){
        this.indstryKey = new Date().getTime();
        this.areaKey = new Date().getTime();
        this.domainKey = new Date().getTime();
        this.$nextTick(()=>{
          this.areaChange();
          this.domainChange();
          this.industryChange();
          this.search();
        })


      },
      getDict() {
        if (_.isEmpty(this.areaOpt)) {
          this.$store.dispatch('setArea');
        }
        if (_.isEmpty(this.categoryOpt)) {
          this.$store.dispatch('setCategory');
        }
        if (_.isEmpty(this.domainOpt)) {
          this.$store.dispatch('setDomain');
        }
      },
      setBudget(val){
        if(_.isEmpty(val)){
          this.params.beginBudget = '';
          this.params.endBudget = '';
        }else{
          let arr = val.split('_');
          this.params.beginBudget = arr[0];
          this.params.endBudget = arr[1];
        }
      },
      getTreeData(data = []) {
        for (let i = 0; i < data.length; i++) {
          if (!data[i].children) {
            data[i].children = undefined;
          } else if (data[i].children.length < 1) {
            data[i].children = undefined;
          } else {
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      delSearch(key=''){
        if(key === 'beginDate'){
          this.demandDate = [];
        } else if(key === 'beginBudget'){
          this.params['beginBudget'] = "";
          this.params['endBudget'] = "";
        }else{
          this.params[key] = "";
		  this.searchParams[key] = "";
        }
        this.search();
      },
      search(){
        let { regionId, techFieldIds, industryIds } = this.params;

        let params = {
          ...this.params,
          regionId: regionId?[...regionId].pop():"",
          industryIds: industryIds?[...industryIds].pop():""
        };
		if(techFieldIds){
			params.techFieldIds=techFieldIds.map(i=>{
            return i[1];
          }).join(',')
		}
        let sparams = {}
        if(regionId){
          sparams.regionId = this.areaCache
        }

        if(techFieldIds){
          sparams.techFieldIds = this.domainCache;
        }
        if(industryIds){
          sparams.industryIds = this.industryCache;
        }
        this.searchParams = {
          ...params,
          ...sparams
        };
        this.isgaoji = false;
        this.$emit('refreshTable', {
          ...params,
          //...sparams,
          currPage: 1
        });
      },
      reset(){
        this.params = {
          name: '',
          regionId: '',
          beginBudget: '',
          endBudget: '',
          beginDate: '',
          endDate: '',
        };
        this.demandDate = [];
        this.$refs.budget&&this.$refs.budget.reset();
        this.$emit('refreshTable', {});
      },
      areaChange(){
        let labels = this.$refs.areaRef.getCheckedNodes()[0]?this.$refs.areaRef.getCheckedNodes()[0]['pathLabels']:[];
        this.areaCache = labels.join("-");
      },
      domainChange(val){
		if(val.length > 3) {
			this.$message.error(this.$t('checkReqTechFieldIds'));
			this.params.techFieldIds = [...this.oldTechFieldIds];
			return false;
		}else{
			this.params.techFieldIds = val
			this.oldTechFieldIds = [...this.params.techFieldIds];
		}
        let labels = this.$refs.domainRef.getCheckedNodes();
        let arrs = labels.map(i=>{
          return i['pathLabels'].join('-');
        })
        this.domainCache = arrs.join(",");
      },
      industryChange(){
        let labels = this.$refs.industryRef.getCheckedNodes()[0]?this.$refs.industryRef.getCheckedNodes()[0]['pathLabels']:[];
        this.industryCache = labels.join("-");
      },
      sortChange(){
        this.search();
      }
    }
  }
</script>

<style scoped>
.x-flex{display:flex;flex-wrap: wrap;}
  .x-flex-1{width:25%;margin-bottom:6px;padding-right:10px;box-sizing: border-box;position:relative;}
.x-flex-2{width:50%;}
  .x-pos{position:absolute;left:0;top:30px;z-index:9;}
  .x-arrow{font-size:14px;margin:4px 10px 0 8px;}
  .x-search-box{position:relative;transition: height .2s ease;}
  .x-gaoji{position:absolute;z-index:10;right:0;top:-36px;}
</style>
<style>
  .x-ds-select .el-select .el-input__inner,
  .x-ds-select .el-cascader .el-input__inner
  {border:none;}
  .x-search-btn .el-button{padding:0 4px;height:28px;margin-left:4px;}
  .x-search-box input::placeholder{color:#000;}
  .x-gaoji-box{
    position:absolute;z-index:99;left:0;top:0;right:0;background:#fff;box-shadow:1px 5px 5px rgba(0,0,0,.3);border-radius: 0 0 10px 10px;padding:10px;
  }
  .x-search-tag{
    border:1px solid #ccc;border-radius:5px;padding:5px 10px;display:inline-block;margin:0 10px 10px 0;
  }
  .x-mb8{
    margin-bottom:8px;
  }
</style>
<style>
  .demand-seach input::placeholder{color:#000!important;}
</style>
