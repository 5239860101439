<template>
  <div class="x-card" :style="`width:${width};padding:${padding}`">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "XCard",
    props: {
      width: {
        type: String,
        default: '260px'
      },
      padding: {
        type: String,
        default: '20px'
      },
    }
  }
</script>

<style scoped>
.x-card{margin-bottom:16px;background:#fff;border: 1px solid rgba(228,233,239,1);border-radius: 20px;box-sizing:border-box;}
</style>
