<template>
  <div class="step-btn-box x-tc">
    <el-button class="x-btn x-prev" v-show="step!==0" @click="preStep">{{$t('stepBtn1')}}</el-button>
    <el-button class="x-btn x-next" v-show="step!==3" @click="nextStep">{{$t('stepBtn2')}}</el-button>
    <el-button class="x-btn x-save" v-show="step!==3" :loading="btnLoading" @click="save">{{$t('stepBtn3')}}</el-button>
    <el-button class="x-btn x-submit" v-show="step===3" :loading="btnLoading" @click="submit">{{$t('stepBtn4')}}</el-button>
  </div>
</template>

<script>
  export default {
    emits: ['save', 'next'],
    computed: {
      step() {
        return this.$store.getters.getStep;
      },
      btnLoading(){
        return this.$store.getters.getBtnLoading;
      },
      query(){
        return this.$route.query;
      }
    },
    methods: {
      preStep(){
        let step = this.step - 1;
        this.$store.dispatch('setStep', step);
      },
      nextStep(){
        if(this.$listeners.next){
          this.$emit('next', this.step + 1);
        }else{
          let step = this.step + 1;
          this.$store.dispatch('setStep', step);
        }
      },
      save(){
        if(this.$listeners.save){
          this.$emit('save');
        }
      },
      submit(){
        if(this.$listeners.submit){
          this.$emit('submit');
        }
      }
    }
  }
</script>

<style scoped>
.step-btn-box{padding-bottom:20px;}
</style>
