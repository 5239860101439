<template>
	<sign-layout>
		<h1 slot="title">后台登录</h1>

		<el-form :model="form" ref="form" :label-position="labelPosition" :rules="rules" class="demo-dynamic">
			<el-form-item prop="username" :rules="[{ required: true, message: '请输入账号', trigger: 'blur' }]">
				<el-input prefix-icon="el-icon-message" v-model="form.username"></el-input>
			</el-form-item>
			<el-form-item><el-input prefix-icon="el-icon-lock" suffix-icon="el-icon-view" type="password" v-model="form.password" autocomplete="off"></el-input></el-form-item>

			<el-form-item><el-button style="width:100%" type="primary"  :loading="loading" round @click="login">登录</el-button></el-form-item>
		</el-form>
	</sign-layout>
</template>

<script>
import '@/assets/style/login.css';
import SignLayout from './SignLayout';
import { JSEncrypt } from 'jsencrypt';
//import $axios from '@/axios/index';
import { apiLogin } from '@/api';
export default {
	components: { SignLayout },
	data() {
		// var validatePass = (rule, value, callback) => {
		// 	if (value === '') {
		// 		callback(new Error('请输入密码'));
		// 	} else {
		// 		if (this.ruleForm.pass !== '') {
		// 			this.$refs.ruleForm.validateField('pass');
		// 		}
		// 		callback();
		// 	}
		// };
		return {
			labelPosition: 'top',
			formLabelAlign: {
				name: '',
				region: '',
				type: ''
			},
			activeName: 'second',
			publicKey:
				'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQChULFF3NMp7zLTPvzeR7jRv5K0MJkVHi/iHMO19zEug5HJjfyEe2wQiSWtYSlKLrSn48DA4S4Q04XVwAZQ4LbZ/cznAqX+O7zj5GMZOntJ9cb4dToZjusFne4kjrxhgFZWpj92L2kxYrSPIHOrKngtIx1zAtHKDW63To3rIunn4QIDAQAB',
			form: {
				//
        //username: 'admin',
				//password: 'x@vte)Pmooe^i',
        username: '',
        password: '',
				checkcode: ''
			},
			dynamicValidateForm: {
				domains: [
					{
						value: ''
					}
				],
				email: ''
			},
			rules: {
				username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
				checkcode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
			},
			ruleForm: {
				pass: ''
			}
			// rules: {
			// 	pass: [{ validator: validatePass, trigger: 'blur' }]
			// }
		};
	},
	computed: {
		loading() {
			return this.$store.state.base.btnLoading;
		}
	},
	mounted() {
		this.keyDown(); // 监听键盘
	},
	methods: {
		keyDown() {
			document.onkeydown = e => {
				//事件对象兼容
				let e1 = e || event || window.event || arguments.callee.caller.arguments[0];
				//console.log('键盘按键==', e1, e1.keyCode);
				//键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
				//左
				if (e1 && e1.keyCode == 13) {
					this.login();
				}
			};
		},
		handleClick(tab, event) {
			console.log(tab, event);
		},
		encryptedData(data) {
			let encryptor = new JSEncrypt();
			encryptor.setPublicKey(this.publicKey);
			return encryptor.encrypt(data);
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					alert('submit!');
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		login() {
			let redirect = this.$route.query.redirect;
			this.$refs['form'].validate(valid => {
				if (valid) {
					let pwd = this.encryptedData(this.form.password);
					//let params = {
					// 	username:this.form.username,
					// 	password:pwd,
					// 	checkcode:'1'
					// };
					// var formData = new FormData();
					// formData.append('username', this.form.username);
					// formData.append('password', pwd);
					// formData.append('checkcode', '1');
					let parms={
						username:this.form.username,
						password:pwd
					}
					apiLogin(parms).then(res => {
						if (res && res.success) {
							this.$store.dispatch('setUserInfo', res.ext);
							let type=res.ext.type;
							if(type=='front'){
								this.$router.push('/index')
							}
							else {
								this.$router.push('/System/index');
							}
						} else {
							//this.changeImg();
						}
					});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		}
	}
};
</script>

<style lang="css" scoped></style>
