import $axios from '@/axios/index'

//留言查询、新增
export const apiGetLeaveMessage = (params)=>{
  return $axios.get('/cbmp/req/getRequirementLeaveMessage', {params});
}
export const apiLeaveMessage = (params,method='post')=>{
  return $axios[method]('/cbmp/rest/RequirementLeaveMessage', params);
}


export default {
  apiGetLeaveMessage,
  apiLeaveMessage
}
