<template>
  <div class="x-main">
    <x-header></x-header>
    <div class="x-content x-mt20">
      <div class="x-left x-mr20">
        <index-left></index-left>
      </div>
      <x-card style="width:100%;padding:0;">
        <div class="x-notice-t x-tc" style="position:relative;">
          <div @click="toPage('/Notice')" style="position:absolute;left:10px;top:16px;">
            <el-button type="text">&lt; {{$t('ndetail1')}}</el-button>
          </div>
          [{{type[details.type]}}]
        </div>
        <div class="x-notice-detail">
          <h3 class="x-tc">{{details.title}}</h3>
          <p>{{$t('ndetail2')}} {{userInfo.surname}}{{userInfo.name}}：</p>
          <div v-html="details.content"></div>
          <div class="x-notice-file" v-show="details.attachment_code">
            <div>
              {{details.attachment}}
            </div>
            <el-button @click="downFile(details.attachment_code)">{{$t('ndetail3')}}</el-button>
          </div>
        </div>
        <div class="x-delete x-tr">
          <el-button type="danger" @click="del">{{$t('notice10')}}</el-button>
        </div>
      </x-card>
    </div>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import IndexLeft from "../IndexLeft";
  import XCard from "../../components/XCard";
  import {apiNotice} from "../../api/Notice";
  import MixinsBase from "../../mixins/base";
  import {downFile} from '../../filters/utils';
  export default {
    components: {
      XHeader,
      IndexLeft,
      XCard
    },
    mixins: [MixinsBase],
    data(){
      return {
        //inside("站内信息"), invite("邀请信息"), interact("互动信息")
        type: {
          inside: this.$t('notice6'),
          invite: this.$t('notice7'),
          interact: this.$t('notice8'),
        }
      }
    },
    computed: {
      details(){
        return this.$store.getters.getNoticeDetails;
      },
      userInfo(){
        return this.$store.getters.getUserInfo;
      }
    },
    mounted() {
      //this.readAxios();
    },
    methods: {
      downFile,
      del(){
        this.$confirm('确定删除这条记录吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          let params = {
            ids: `${this.details.id}`,
            actionType: 'delete'
          }
          apiNotice(params).then(res=>{
            if(res.success){
              this.$store.dispatch('setNoticeSize');
              this.toPage('/Notice');
            }
          })
        }).catch(() => {

        });
      },
      readAxios(){
        apiNotice({
          ids: this.$route.query.id,
          actionType: 'read'
        }).then(res=>{
          if(res.success){
            this.$store.dispatch('setNoticeSize');
          }
        })
      },
    }
  }
</script>

<style scoped>
.x-notice-t{
  padding:20px;background:#F9FBFC;
}
.x-notice-detail{
  padding:20px;min-height:400px;
}
  .x-notice-file{
    display:flex;justify-content: space-between;padding:10px 20px;border:2px solid #F3F6F7;
  }
  .x-delete{
    padding:10px 20px;background:#F9FBFC;
  }
</style>
