<template>
  <x-card style="padding:0;width:100%;">
    <release-step></release-step>
    <div class="step-two-form">
      <el-form ref="form" :model="form" :rules="rules" class="x-form x-form-dir">
        <div class="x-f12 x-bold x-mb20">{{$t('stepTwo1')}}</div>
        <el-row>
          <el-col :span="11">
            <el-form-item prop="name" :label="$t('stepTwo2')">
              <el-input type="text" v-model="form.name" :placeholder="$t('placeholder')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11"  :offset="2">
            <el-form-item prop="regionId" :label="$t('stepTwo3')">
              <el-cascader :key="areaKey" @change="regionChange" ref="regionCascader" style="width:100%;"
                v-model="form.regionId"
                :options="areaOpt"
                :props="{ value: 'id',label: lang==='en'?'nameEn':'name',children: 'children'}" :placeholder="$t('placeholder')"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item prop="demandDate" :label="$t('stepTwo4')">
              <demand-period ikey="demandDate" :value="form.demandDate" @setValue="setValue"></demand-period>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item prop="demandBudget" :label="$t('stepTwo5')">
              <demand-budget ikey="demandBudget" :value="form.demandBudget" width="120px" @setValue="setValue"></demand-budget>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item prop="enterprise" :label="$t('stepTwo6')">
              <el-select style="width:100%;" v-model="form.enterprise"
                         filterable
                         allow-create
                         :placeholder="$t('placeholder')"
                         value-key="id"
                         default-first-option
              @change="enterpriseChange">
                <el-option
                  v-for="item in avoidEnterpriseOpt"
                  :key="item.id"
                  :label="item.name"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item prop="cooperationType" :label="$t('stepTwo7')">
              <el-select :placeholder="$t('placeholder')" :style="`width:${form.cooperationType==='7'?'50%':'100%'};`" v-model="form.cooperationType">
                <el-option v-for="item in cooperationTypeOpt"
                           :key="item.id"
                           :label="lang==='en'?item.nameEn:item.name"
                           :value="item.id"></el-option>
              </el-select>
              <el-form-item style="display:inline-block;margin-left:10px;" v-if="form.cooperationType==='7'" prop="cooperationTypeOther">
                <el-input type="text" v-model="form.cooperationTypeOther"  :placeholder="$t('placeholder')"></el-input>
              </el-form-item>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="linkerFirstName" :label="$t('stepTwo8')">
              <el-row>
                <el-col :span="2">
                  <el-input v-model="form.linkerFirstName" :placeholder="$t('placeholder')"></el-input>
                </el-col>
                <el-col :span="2" style="padding-left:10px;">
                  <el-form-item prop="linkerSecondName">
                    <el-input v-model="form.linkerSecondName" :placeholder="$t('placeholder')"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" style="padding-left:10px;">
                  <el-form-item prop="linkerPhone" :rules="reMobile">
                    <el-input :placeholder="$t('placeholder')" v-model="form.linkerPhone" class="input-with-select i3">
                      <el-select filterable v-model="form.countryMobilePrefixId" slot="prepend" placeholder="请选择" style="width:100px;" @change="countryMobileChange">
                        <el-option v-for="item in mobilePrefixOpt"
                                   :key="item.id"
                                   :label="`+${item.mobilePrefix}(${lang==='en'?item.countryEn:item.country})`"
                                   :value="item.id"></el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4" style="padding-left:10px;">
                  <el-form-item prop="linkerEmail">
                    <el-input v-model="form.linkerEmail" :placeholder="$t('stepTwo25')"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4" style="padding-left:10px;">
                  <el-form-item prop="linkerDetp">
                    <el-input v-model="form.linkerDetp" :placeholder="`${$t('placeholder')}${$t('userinfo23')}`"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4" style="padding-left:10px;">
                  <el-form-item prop="linkerPosition">
                    <el-input v-model="form.linkerPosition" :placeholder="`${$t('placeholder')}${$t('userinfo24')}`"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="industryList" :label="$t('stepTwo9')">
              <demand-industry :key="industryListKey" ikey="industryList" :value="form.industryList" @setForm="setForm"
                               @delEvent="delEvent"
                               :selectOpt="categoryOpt"
                               :tagOpt="tagKeyword"
                               :placeholder="$t('placeholder')">
                <div slot="des" class="x-color666">{{$t('stepTwo10')}}</div>
              </demand-industry>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('userinfo13')" prop="techFieldList">
              <el-cascader :key="techFieldListKey" ref="techFieldListRef" :placeholder="$t('placeholder')" :props="{ value: 'id', label: lang==='en'?'nameEn':'name', children: 'children', multiple: true }"
                           v-model="form.techFieldList"
                           :options="domainOpt"
                           collapse-tags style="width:50%;"></el-cascader><span class="x-color666 x-ml10">{{$t('stepTwo23')}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="x-f12 x-bold x-mb20">{{$t('stepTwo11')}}</div>
        <el-row>
          <el-col :span="11">
            <el-form-item :label="$t('stepTwo12')">
              <el-radio-group v-model="form.hasOwnership">
                <el-radio-button :label="true">{{$t('true')}}</el-radio-button>
                <el-radio-button :label="false">{{$t('false')}}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col  :span="11" :offset="2">
            <el-form-item :label="$t('stepTwo13')">
              <el-radio-group v-model="form.isAllowVisit">
                <el-radio-button :label="true">{{$t('true')}}</el-radio-button>
                <el-radio-button :label="false">{{$t('false')}}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('stepTwo14')" class="form-lh20">
              <el-radio-group v-model="form.isTechFreeze">
                <el-radio-button :label="true">{{$t('true')}}</el-radio-button>
                <el-radio-button :label="false">{{$t('false')}}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item :label="$t('stepTwo15')">
              <el-select :placeholder="$t('placeholder')" style="width:100%;" v-model="form.partnerType">
                <el-option v-for="item in partnerTypeOpt"
                           :key="item.id"
                           :label="lang==='en'?item.nameEn:item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col  :span="11" :offset="2">
            <el-form-item :label="$t('stepTwo16')">
              <el-select :placeholder="$t('placeholder')" style="width:100%;" v-model="form.partnerRegionType">
                <el-option v-for="item in partnerRegionTypeOpt"
                           :key="item.id"
                           :label="lang==='en'?item.nameEn:item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('stepTwo17')">
              <demand-industry-object @setForm="setFormEnterprise"
                                     @delEvent="delEventEnterprise"
                                     :selectOpt="avoidEnterpriseOpt"
                                     :isFilter="true"
                                     :allowCreate="true"
                                     :tagOpt="tagKeywordEnterprise"
                                     :max="3"
                                     ikey="avoidEnterprise" :placeholder="$t('placeholder')">
                <div slot="des" class="x-color666">{{$t('stepTwo21')}}</div>
              </demand-industry-object>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('stepTwo18')">
              <demand-industry-object @setForm="setFormAvoidExpert"
                                      @delEvent="delEventAvoidExpert"
                                      :selectOpt="avoidExpertOpt"
                                      :isFilter="true"
                                      :allowCreate="true"
                                      :tagOpt="tagKeywordAvoidExpert"
                                      :max="5"
                                      ikey="avoidExpert" :placeholder="$t('placeholder')">
                <div slot="des" class="x-color666">{{$t('stepTwo22')}}</div>
              </demand-industry-object>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('stepTwo19')" class="form-lh20">
              <el-input v-model="form.dealContent" type="textarea" :rows="5"  :placeholder="$t('placeholder')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('stepTwo20')" class="form-lh20">
              <el-input v-model="form.supportContent" type="textarea" :rows="5" :placeholder="$t('placeholder')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <step-btn @next="nextEvent" @save="saveEvent"></step-btn>
  </x-card>
</template>

<script>
  import StepBtn from "./StepBtn";
  import XCard from "../../components/XCard";
  import ReleaseStep from "./ReleaseStep";
  import DemandPeriod from "./FormComm/DemandPeriod";
  import DemandBudget from "./FormComm/DemandBudget";
  import DemandIndustry from "./FormComm/DemandIndustry";
  import _ from "lodash";
  import { apiPostEnterprise, apiPostExpert} from "../../api";
  import DemandIndustryObject from "./FormComm/DemandIndustryObject";
  import {reValidateReg} from "../../filters/formValidate";
  import MixinsBase from "../../mixins/base";
  export default {
    components: {
      DemandIndustryObject,
      DemandBudget,
      DemandPeriod,
      XCard,
      StepBtn,
      ReleaseStep,
      DemandIndustry
    },
    mixins: [MixinsBase],
    data() {
      return {
        addressOpt: [],
        form: {
          name: '',
          regionId: '',
          regionName: '',
          regionNameEn: '',
          demandDate: '',//分解为beginDate,endDate,isNoDate 无期限
          beginDate: '',
          endDate: '',
          isNoDate: true,
          demandBudget: '',//分解为beginBudget,endBudget,isFaceBudget 面议
          beginBudget: '',
          endBudget: '',
          isFaceBudget: false,
          enterprise: '',
          enterpriseId: '',
          enterpriseName: '',
          cooperationType: '',
          cooperationTypeOther: '',
          linkerSecondName: '',
          linkerFirstName: '',
          countryMobilePrefix: '86',
          countryMobilePrefixId: 1,
          linkerDetp: '',
          linkerPosition: '',
          linkerPhone: '',
          linkerEmail: '',
          industryList: [],
          //补充信息
          hasOwnership: false,
          isAllowVisit: false,
          isTechFreeze: false,
          partnerType: '',
          partnerRegionType: '',
          //规避企业，规避专家
          avoidEnterprise: [],
          avoidExpert: [],
          dealContent: '',
          supportContent: '',
          techFieldList: ''
        },
        rules: {
          name: {required: true, message: this.$t('placeholder'), trigger: 'blur'},
          regionId: {required: true, message: this.$t('placeholder'), trigger: 'change'},
          techFieldList: {required: true, message: this.$t('placeholder'), trigger: 'change'},
          demandDate: {required: true, message: this.$t('placeholder'), trigger: 'change'},
          demandBudget: {required: true, message: this.$t('placeholder'), trigger: 'blur'},
          enterprise: { required: true, message: this.$t('placeholder'), trigger: 'change' },
          /*enterpriseName: [
            { required: true, message: '请输入', trigger: 'blur' },
            { pattern: reValidateReg('zh-en'), message: '请输入正确的企业名称', trigger: 'change'},
          ],*/
          cooperationType: {required: true, message: this.$t('placeholder'), trigger: 'change'},
          cooperationTypeOther: {required: true, message: this.$t('placeholder'), trigger: 'blur'},
          industryList: {required: true, message: this.$t('placeholder'), trigger: 'blur'},
          linkerSecondName: [
            {required: true, message: this.$t('placeholder'), trigger: 'blur'},
            { pattern: reValidateReg('zh-en'), message: this.$t('placeholder'), trigger: 'blur'},
          ],
          linkerFirstName: [
            {required: true, message: this.$t('placeholder'), trigger: 'blur'},
            { pattern: reValidateReg('zh-en'), message: this.$t('placeholder'), trigger: 'blur'},
          ],
          //linkerPhone: {required: true, message: '请输入', trigger: 'blur'},
          linkerDetp: {required: true, message: this.$t('placeholder'), trigger: 'blur'},
          linkerPosition: {required: true, message: this.$t('placeholder'), trigger: 'blur'},
          linkerEmail: [
            { required: true, message: this.$t('stepTwo25'), trigger: 'blur' },
            { pattern: reValidateReg('email'), message: this.$t('stepTwo25'), trigger: 'blur'},
            ],
        },
        tagKeyword: [],
        tagKeywordEnterprise: [],
        tagKeywordAvoidExpert: [],
        techFieldListKey: 1,
        industryListKey: 3,
        areaKey: 2
      }
    },
    computed: {
      areaOpt() {
        let area = this.$store.getters.getArea;
        return this.getTreeData(area);
      },
      cooperationTypeOpt() {
        return this.$store.getters.getCooperationTypeOpt;
      },
      categoryOpt() {
        return this.getTreeData(this.$store.getters.getCategory);
      },
      avoidEnterpriseOpt() {
        return this.$store.getters.getAvoidEnterprise;
      },
      avoidExpertOpt() {
        return this.$store.getters.getAvoidExpert;
      },
      partnerTypeOpt() {
        return this.getTreeData(this.$store.getters.getPartnerTypeOpt);
      },
      partnerRegionTypeOpt() {
        return this.getTreeData(this.$store.getters.getPartnerRegionTypeOpt);
      },
      mobilePrefixOpt() {
        return this.$store.getters.getMobilePrefix;
      },
      cacheDemand(){
        return this.$store.getters.getCacheDemand;
      },
      reMobile() {
        let rule = [{required: true, message: '请输入', trigger: 'blur'}];
        //为+86时校验
        if(this.form.countryMobilePrefixId===1){
          rule.push({ pattern: this.reValidateReg('mobile'), message: '请输入正确的手机号', trigger: 'blur'})
        }
        return rule;
      },
      domainOpt() {
        return this.getTreeData(this.$store.getters.getDomain);
      },
    },
    watch: {
      cacheDemand: {
        immediate: true,
        handler: function(val){
          this.initForm(val);
        }
      },
      'form.techFieldList'(nval, oval){
        if(nval.length > 3){
          this.$message.error(this.$t('userinfo26'));
          this.$nextTick(()=>{
            this.$set(this.form, 'techFieldList', oval);
          })
        }
      }
    },
    mounted() {
      this.getDict();
    },
    methods: {
      reValidateReg,
      init(){
        this.techFieldListKey = new Date().getTime();
        this.areaKey = new Date().getTime();
        this.industryListKey = new Date().getTime();
      },
      countryMobileChange(val){
        let oo = this.mobilePrefixOpt.filter(i=>{
          return val === i.id;
        });
        this.$set(this.form, 'countryMobilePrefix', oo[0]['mobilePrefix']);
      },
      getTreeData(data = []) {
        for (let i = 0; i < data.length; i++) {
          if (!data[i].children) {
            data[i].children = undefined;
          } else if (data[i].children.length < 1) {
            data[i].children = undefined;
          } else {
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      getDict() {
        if (_.isEmpty(this.areaOpt)) {
          this.$store.dispatch('setArea');
        }
        if (_.isEmpty(this.categoryOpt)) {
          this.$store.dispatch('setCategory');
        }
        if (_.isEmpty(this.avoidEnterpriseOpt)) {
          this.$store.dispatch('setAvoidEnterprise');
        }
        if (_.isEmpty(this.avoidExpertOpt)) {
          this.$store.dispatch('setAvoidExpert');
        }
        if (_.isEmpty(this.mobilePrefixOpt)) {
          this.$store.dispatch('setMobilePrefix');
        }
        if (_.isEmpty(this.domainOpt)) {
          this.$store.dispatch('setDomain');
        }
      },
      setValue(val, key) {
        this.$set(this.form, key, val);
      },
      setForm(opt = {}) {
        let {key, value, formValue} = opt;
        this.tagKeyword.push(value);
        this.$set(this.form, key, formValue);
      },
      delEvent(index) {
        this.tagKeyword.splice(index, 1);
        this.$set(this.form, 'keyword', this.tagKeyword.join('_'));
      },
      setFormEnterprise(opt = {}) {
        let {key, value, isAdd, tagOpt, formValue} = opt;
        if (isAdd) {
          apiPostEnterprise({
            name: value
          }, {
            noMsg: true,
            noLoading: true
          }).then(res=>{
            if(res.success){
              let {id}=res.result[0];
              this.$store.dispatch('setAvoidEnterprise');
              this.tagKeywordEnterprise.push({
                name: value,
                id
              });
              this.$set(this.form, key, [
                ...tagOpt
                ]
              );
            }
          });
        }else{
          this.tagKeywordEnterprise.push(value);
          this.$set(this.form, key, formValue);
        }

      },
      delEventEnterprise(index) {
        this.tagKeywordEnterprise.splice(index, 1);
        this.$set(this.form, 'avoidEnterprise', this.tagKeywordEnterprise);
      },
      setFormAvoidExpert(opt = {}) {
        let {key, value, isAdd, formValue, tagOpt} = opt;
        if (isAdd) {
          apiPostExpert({
            name: value
          }, {
            noMsg: true,
            noLoading: true
          }).then(res=>{
            if(res.success){
              let {id}=res.result[0];
              this.$store.dispatch('setAvoidExpert');
              this.tagKeywordAvoidExpert.push({
                name: value,
                id
              });
              this.$set(this.form, key, [
                ...tagOpt
              ]);
            }
          });
        }else{
          this.tagKeywordAvoidExpert.push(value);
          this.$set(this.form, key, formValue);
        }
      },
      delEventAvoidExpert(index) {
        this.tagKeywordAvoidExpert.splice(index, 1);
        this.$set(this.form, 'avoidExpert', this.tagKeywordAvoidExpert);
      },
      nextEvent(step){
        this.$refs.form.validate(valid=>{
          if(valid){
            let params = this.reParams();
            this.$store.dispatch('setCacheDemand', {
              ...this.cacheDemand,
              ...params
            });
            this.$store.dispatch('setStep', step);
          }
        })
      },
      reParams(){
        let {
          name,
          regionId,
          regionName,
          regionNameEn,
          demandDate,
          demandBudget,
          enterprise,
          enterpriseId,
          enterpriseName,
          cooperationType,
          cooperationTypeOther,
          linkerSecondName,
          linkerFirstName,
          countryMobilePrefix,
          countryMobilePrefixId,
          linkerDetp,
          linkerPosition,
          linkerPhone,
          linkerEmail,
          industryList,
          //补充信息
          hasOwnership,
          isAllowVisit,
          isTechFreeze,
          partnerType,
          partnerRegionType,
          //规避企业，规避专家
          avoidEnterprise,
          avoidExpert,
          dealContent,
          supportContent,
          techFieldList,
        } = this.form;
        let params = {
          name,
          regionId: _.isArray(regionId)?regionId.pop():regionId,
          regionName,
          regionNameEn,
          enterprise,
          enterpriseId,
          enterpriseName,
          cooperationType,
          linkerSecondName,
          linkerFirstName,
          countryMobilePrefix,
          countryMobilePrefixId,
          linkerDetp,
          linkerPosition,
          linkerPhone,
          linkerEmail,
          industryList,
          //补充信息
          hasOwnership,
          isAllowVisit,
          isTechFreeze,
          partnerType,
          partnerRegionType,
          //规避企业，规避专家
          avoidEnterprise,
          avoidExpert,
          dealContent,
          supportContent,
          techFieldList: _.map(techFieldList, i=>{
            return {
              id: i[1],
              parentId: i[0]
            };
          }),
          status: "draft"
        };
        if(cooperationType==="7"){
          params.cooperationTypeOther = cooperationTypeOther;
        }
        if(demandDate===true){
          params.isNoDate = demandDate;
        }else{
          params.isNoDate = false;
          params.beginDate = demandDate[0];
          params.endDate = demandDate[1];
        }
        if(demandBudget===true){
          params.isFaceBudget = demandBudget;
        }else{
          let budget = demandBudget.split('_');
          params.isFaceBudget = false;
          params.beginBudget = budget[0];
          params.endBudget = budget[1];
        }
        return params;
      },
      saveEvent(){
        this.$refs.form.validate(valid=>{
          if(valid){
            let params = this.reParams();
            if(params.enterpriseId === ""){
              setTimeout(()=>{
                this.$store.dispatch('setAvoidEnterprise');
              }, 1000);
            }
            let {langRelReqId} = this.$route.query;
            if(langRelReqId){
              params.langRelReqId = langRelReqId;
            }
            params.lang = this.lang;
            this.$store.dispatch('saveDemand', params);
          }
        })
      },
      initForm(val={}){
        let { isNoDate, beginDate, endDate, isFaceBudget, beginBudget, endBudget, id, industryList, avoidEnterprise, avoidExpert, cooperationType, cooperationTypeOther, enterpriseId, enterpriseName, techFieldList } = val;
        let demandName = val.name;
        if((id === ""||id===undefined)&&!val.enterpriseName){
          let { name, surname, mobile, email, department, post, areaCodeId, enterpriseName } = this.userInfo;
          this.$set(this.form, 'linkerSecondName', name);
          this.$set(this.form, 'linkerFirstName', surname);
          this.$set(this.form, 'linkerPhone', mobile);
          this.$set(this.form, 'linkerEmail', email);
          this.$set(this.form, 'linkerDetp', department);
          this.$set(this.form, 'linkerPosition', post);
          this.$set(this.form, 'countryMobilePrefixId', areaCodeId);
          this.$set(this.form, 'name', demandName);
          this.$set(this.form, 'enterprise', enterpriseName);
          this.$set(this.form, 'enterpriseName', enterpriseName);
          return ;
        }
        let params = {
          ...val,
          techFieldList: techFieldList.map(i=>{
            return [i.parentId, i.id];
          })
        };
        if(isNoDate===true){
          params.demandDate = isNoDate;
        }else if(beginDate&&endDate&&!isNoDate){
          params.demandDate = [beginDate, endDate];
        }else{
          params.demandDate = [];
        }
        if(isFaceBudget===true){
          params.demandBudget = isFaceBudget;
        }else if(beginBudget&&endBudget&&!isFaceBudget){
          params.demandBudget = `${beginBudget}_${endBudget}`;
        }else{
          params.demandBudget = [];
        }
        if(cooperationType === "7"){
          params.cooperationTypeOther = cooperationTypeOther;
        }
        //console.log('...params...', params);
        this.tagKeyword = industryList||[];
        this.tagKeywordEnterprise = avoidEnterprise||[];
        this.tagKeywordAvoidExpert = avoidExpert||[];
        this.form = {
          ...params,
          enterprise: {
            id: enterpriseId,
            name: enterpriseName
          }
        };
      },
      regionChange(val){
        if(_.isEmpty(val)){
          return ;
        }
        let regionNames = [];
        let selectNode = this.$refs.regionCascader.getCheckedNodes()[0];
        let fn = (o, arr=[])=>{
          arr.push(o.label);
          if(o.parent){
            fn(o.parent, arr);
          }
        };
        fn(selectNode, regionNames);
        let regionNamesEn = [];
        let fnEn = (o, arr=[])=>{
          arr.push(o.data.nameEn);
          if(o.parent){
            fnEn(o.parent, arr);
          }
        };
        fnEn(selectNode, regionNamesEn);
        this.$set(this.form, 'regionName', regionNames.reverse().join(" "));
        this.$set(this.form, 'regionNameEn', regionNamesEn.reverse().join(" "));
      },
      enterpriseChange(val){
        if(typeof val === 'string'){
          this.$set(this.form, 'enterpriseName', val);
          this.$set(this.form, 'enterpriseId', '');
        }else{
          this.$set(this.form, 'enterpriseName', val.name);
          this.$set(this.form, 'enterpriseId', val.id);
        }
      }
    },

  }
</script>

<style scoped>
  .step-two-form {
    padding: 26px;
  }
</style>
<style>
  .x-form .form-lh20 .el-form-item__label {
    line-height: 20px;
  }
</style>
