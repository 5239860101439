<template>
  <div class="x-main-box" style="min-width: 1024px;">
    <!--面包屑-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <el-icon class="el-icon-s-home"></el-icon>
        首页
      </el-breadcrumb-item>
      <el-breadcrumb-item>通知管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="background:#fff;margin-top:20px;padding:20px;">
    <search-form :data="searchData" ref="searchForm" @searchFun="searchFun" class="x-fr" style="padding-top:0;"></search-form>

      <el-button type="primary" @click="toSendNotice">发布</el-button>
    <table-page :total="total" :callback="init" :currpage="currPage" :pagesize="pageSize">
      <el-table :data="tableData" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" fixed="left"></el-table-column>
        <el-table-column prop="username" label="发给" show-overflow-tooltip></el-table-column>
        <el-table-column prop="title" label="标题" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="300"
              trigger="hover"
              :content="scope.row.title">
              <span slot="reference">{{scope.row.title.substr(0,50)}}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="attachment" label="附件" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" @click="downFile(scope.row.attachmentCode)">{{scope.row.attachment}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="create_date" label="发布时间" width="130" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.createDate|formatDate('datetime')}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" class="x-red" @click="del([scope.row.id])">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </table-page>
    <div class="x-mt10">
      <el-button @click="delPiliang">批量删除</el-button>
    </div>
    </div>
  </div>
</template>

<script>
  import TablePage from '@/components/comm/TablePage';
  import SearchForm from '@/components/comm/SearchForm';
  import mixins from '@/mixins';
  import {apiGetRestNotice, apiDeleteNotice} from "../../../api/Notice";
  import {downFile} from "../../../filters/utils";
  export default {
    components: {
      TablePage,
      SearchForm
    },
    mixins: [mixins],
    data() {
      return {
        searchData: [{ id: 'title', name: '关键字', type: 'text', ctrl: true }],
      };
    },
    mounted() {
      this.searchParams = {
        title: ''
      };
      this.init();
    },
    methods: {
      downFile,
      init(p = {title: ''}) {
        this.getTableData(p, apiGetRestNotice, {
          type: 'inside',
          exp: 'allLike'
        });
      },
      //多选
      handleSelectionChange(val) {
        this.multipleSelection = val;
        let ids = [];
        this.multipleSelection.map(item => {
          ids.push(item.id);
        });
        this.IDs = ids;
      },
      toSendNotice(){
        this.$router.push('/System/NoticeSend')
      },
      delPiliang(){
        if(_.isEmpty(this.IDs)){
          this.$message.error('请选择');
        }else{
          this.del(this.IDs);
        }

      },
      del(id){
        apiDeleteNotice({
          data: id
        }).then(res=>{
          if(res.success){
            this.init();
          }
        })
      }
    }
  };
</script>
<style></style>
