<template>
  <ul class="step-four x-f12 x-tc">
    <li v-for="(item, index) in stepData" :key="item.id"
        :class="{ 'on': step===index, 'ed': step > index}">
      <span class="step-ggou" v-if="step > index">√</span>
      <span class="step-shu" v-else>{{index+1}}</span>
      {{lang==='en'?item.nameEn:item.name}}
    </li>
  </ul>
</template>

<script>
  import MixinsBase from '../../mixins/base';
  export default {
    mixins: [MixinsBase],
    computed: {
      step(){
        return this.$store.getters.getStep;
      },
      stepData(){
        return this.$store.getters.getStepData;
      }
    }
  }
</script>

<style scoped>
.step-four{border-bottom:1px solid #f5f5f5;}
.step-four li{display:inline-block;margin:0 20px;padding:28px 0;border-bottom:2px solid transparent;}
.step-shu{color:#cfd1db;}
  .step-four .on, .step-four .on .step-shu{color:#0268a1;}
  .step-four .on{border-color:#0268a1;}
  .step-four .ed{color:#02a424;}
</style>
