<template>
  <mother-boadr :formData="form" entity="ExampleOfCase"
                @resetForm="resetForm"
                @edit="edit"
                @formValid="formValid" :title="$t('userinfo33')">
    <el-form :model="form" ref="form5" :rules="rules" label-width="120px">
      <el-form-item :label="$t('haward2')" prop="year">
        <el-date-picker v-model="form.year" type="year" value-format="yyyy" :placeholder="$t('placeholder')"></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('haward3')" prop="name">
        <el-input v-model="form.name" :placeholder="$t('placeholder')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('haward6')" prop="attachmentCode">
        <upload-file v-model="form.attachmentCode" @setAttachment="setAttachment" :filename="form.attachment"></upload-file>
      </el-form-item>
      <el-form-item :label="$t('haward5')" prop="description">
        <wang-editor v-model="form.description"></wang-editor>
      </el-form-item>
    </el-form>
  </mother-boadr>
</template>

<script>
  import MotherBoadr from "./MotherBoadr";
  import UploadImage from "../../../components/comm/UploadImage";
  import WangEditor from "../../../components/comm/WangEditor";
  import UploadFile from "../../../components/comm/UploadFile";
  export default {
    components: {UploadFile, UploadImage, MotherBoadr, WangEditor},
    data(){
      return {
        form: {
          year: '',
          name: '',
          attachment: '',
          attachmentCode: '',
          description: ''
        },
        rules: {
          year: { required: true, message: this.$t('placeholder'), trigger: 'change' },
          name: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
        },
      }
    },
    methods: {
      resetForm(){
        this.$refs.form5.resetFields();
      },
      edit(item){
        let { year, name, attachmentCode, description, attachment } = item;
        this.$set(this.form, 'year', `${year}`);
        this.$set(this.form, 'name', name);
        this.$set(this.form, 'attachment', attachment);
        this.$set(this.form, 'attachmentCode', attachmentCode);
        this.$set(this.form, 'description', description);
      },
      setAttachment(val){
        this.$set(this.form, 'attachment', val);
      },
      formValid(cb){
        this.$refs.form5.validate(valid=>{
          if(valid){
            cb&&cb();
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>
