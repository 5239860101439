<template>
  <ul class="demand-detail-info x-mt20 x-f12">
    <li>{{$t('demandMy9')}}：{{reTypeName(details.type)}}</li>
    <li>{{$t('userinfo10')}}：{{lang==='en'?details.regionNameEn:details.regionName}}</li>
    <li>{{$t('stepTwo7')}}：{{filterCooperation(details.cooperationType)}}{{details.cooperationType=='7'?`-${details.cooperationTypeOther}`:''}}</li>
    <li v-if="details.isNoDate">{{$t('stepTwo4')}}：{{$t('detailbar1')}}</li>
    <li v-else>{{$t('stepTwo4')}}：{{details.beginDate}} {{$t('detailbar2')}} {{details.endDate}}</li>
    <li v-if="details.isFaceBudget">{{$t('stepTwo5')}}：{{$t('detailbar3')}}</li>
    <li v-else>{{$t('stepTwo5')}}：{{details.beginBudget}} {{$t('detailbar2')}} {{details.endBudget}}{{$t('detailbar4')}}</li>
    <li style="width:100%;flex:auto;" v-for="item in details.industryList||[]" :key="item.id">{{$t('stepTwo9')}}：{{lang==="en"?item.pathNameEn:item.pathName}}</li>
  </ul>
</template>
<script>
  import MixinsBase from "../../../mixins/base";
  export default {
    props: {
      details: {
        type: Object,
        default: ()=> {}
      }
    },
    mixins: [MixinsBase],
    computed: {
      cooperationTypeOpt(){
        return this.$store.getters.getCooperationTypeOpt;
      },
      typeObj(){
        return this.$store.getters.getTypeObj;
      }
    },
    methods: {
      reTypeName(type){
        return this.typeObj[type][this.lang==='en'?'nameEn':'name'];
      },
      filterCooperation(id){
        let oo = this.cooperationTypeOpt.filter(i=>{
          return i.id === id;
        });
        return oo[0]&&oo[0][this.lang==='en'?'nameEn':'name'];
      }
    }
  }
</script>

<style scoped>
  .demand-detail-info{padding:10px 20px;background: #eff6fa;border-radius: 8px;display:flex; flex-wrap: wrap;}
  .demand-detail-info li{flex:1;padding-right:10px;}
  .demand-detail-info li:before{margin-right:12px;}
</style>
