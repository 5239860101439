<template>
  <sign-layout>
    <h1 slot="title">{{$t('signUp1')}}</h1>
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane :label="$t('signUp2_1')" name="first">
        <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" :label-position="labelPosition"
                 class="demo-dynamic">
          <el-form-item prop="mobile"
            :rules="{ required: true, message: $t('signIn8'), trigger: 'blur' }"
          >
            <el-input prefix-icon="el-icon-mobile-phone" v-model="dynamicValidateForm.mobile"
                      :placeholder="$t('signIn8')">

              <el-select
                filterable
                v-model="dynamicValidateForm.areaCodeId"
                slot="prepend"
                :placeholder="$t('placeholder')"
                style="width:120px;"
                :rules="{ required: true, message: '', trigger: 'blur' }"
              >
                <el-option
                  v-for="item in mobilePrefixOpt"
                  :key="item.id"
                  :label="`+${item.mobilePrefix}(${lang === 'en' ? item.countryEn : item.country})`"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item prop="checkcode" :rules="{ required: true, message: $t('signIn9'), trigger: 'blur' }">
            <el-col :span="14">
              <el-input :placeholder="$t('signIn9')" v-model="dynamicValidateForm.checkcode"
                        ></el-input>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-button plain style="width:100%" @click="getCheckCode" :disabled="count!==0">
                {{countStr}}
              </el-button>
            </el-col>
          </el-form-item>
          <el-form-item prop="password" :rules="{ required: true, message: $t('signUp13'), trigger: 'blur' }">
            <el-input prefix-icon="el-icon-lock" suffix-icon="el-icon-view" type="password" v-model="dynamicValidateForm.password"
                      autocomplete="off" :placeholder="$t('signUp13')"
                      ></el-input>
          </el-form-item>
          <el-form-item class="x-f12" prop="checked"
                        :rules="{ validator: validChecked, trigger: 'change' }">
            <checked-two v-model="dynamicValidateForm.checked"></checked-two>
          </el-form-item>
          <el-form-item class="x-f12" prop="checked2"
                        :rules="{ validator: validChecked2, trigger: 'change' }">
            <checked-one v-model="dynamicValidateForm.checked2"></checked-one>
          </el-form-item>
          <el-form-item>
            <el-button style="width:100%" type="primary" round @click="phoneSignUp" :loading="btnLoading">{{$t('signUp9')}}</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('signUp3_1')" name="second">
        <el-form :model="emailForm" ref="emailForm" :label-position="labelPosition" class="demo-dynamic">
          <el-form-item
            prop="email"
            :rules="[
                  { required: true, message: $t('signUp12'), trigger: 'blur' },
                  { type: 'email', message: $t('signUp12'), trigger: 'blur' }
                ]"
          >
            <el-input prefix-icon="el-icon-message" v-model="emailForm.email" :placeholder="$t('signUp12')"></el-input>
          </el-form-item>
          <el-form-item prop="password"
                        :rules="[
                  { required: true, message: $t('signUp13'), trigger: 'blur' },
                ]">
            <el-input prefix-icon="el-icon-lock" suffix-icon="el-icon-view" type="password" v-model="emailForm.password" autocomplete="off" :placeholder="$t('signUp13')"></el-input>
          </el-form-item>
          <el-form-item class="x-f12" prop="checked"
                        :rules="{ validator: validChecked, trigger: 'change' }">
            <checked-two v-model="emailForm.checked"></checked-two>
          </el-form-item>
          <el-form-item class="x-f12" prop="checked2"
                        :rules="{ validator: validChecked2, trigger: 'change' }">
            <checked-one v-model="emailForm.checked2"></checked-one>
          </el-form-item>
          <el-form-item>
            <el-button style="width:100%" type="primary" round @click="emailFormSubmit" :loading="btnLoading">{{$t('signUp9')}}</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <div class="login-footer" slot="footer">
      <el-dropdown @command="setLanguage">
        <span class="el-dropdown-link">
          {{$t("name")}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="zh" v-if="lang==='en'">简体中文</el-dropdown-item>
          <el-dropdown-item command="en" v-if="lang==='zh'">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div>
        {{$t('signUp10')}}
        <el-button type="text" @click="toPage('/SignIn')" class="x-f14">{{$t('signUp11')}}</el-button>
      </div>
    </div>
  </sign-layout>
</template>

<script>
  //import "@/assets/style/login.css"
  import SignLayout from "./SignLayout";
  import { JSEncrypt } from "jsencrypt";
  import { apiRegister, apiSendSms } from '../../api/index';
  import MixinsBase from '../../mixins/base';
  import ReadOne from "./ReadOne";
  import ReadTwo from "./ReadTwo";
  import CheckedOne from "./CheckedOne";
  import CheckedTwo from "./CheckedTwo";
  export default {
    components: {ReadTwo, ReadOne, SignLayout, CheckedOne, CheckedTwo},
    mixins: [MixinsBase],
    data() {
      let validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$t('signUp13')));
        } else {
          if (this.ruleForm.pass !== '') {
            this.$refs.ruleForm.validateField('pass');
          }
          callback();
        }
      };
      let validChecked = (rule, value, callback) => {
        if (!value) {
          callback(new Error(this.$t('signUp14')+this.$t('signUp8_2')));
        } else {
          callback();
        }
      };

      let validChecked2 = (rule, value, callback) => {
        if (!value) {
          callback(new Error(this.$t('signUp14')+this.$t('signUp8_5')));
        } else {
          callback();
        }
      };
      return {
        labelPosition: 'top',
        formLabelAlign: {
          name: '',
          region: '',
          type: ''
        },
        activeName: 'first',
        dynamicValidateForm: {
          mobile: '',
          checkcode: '',
          checked: false,
          checked2: false,
          password: '',
          areaCode: '86',
          areaCodeId: 1
        },
        ruleForm: {
          pass: ''
        },
        rules: {
          pass: [
            {validator: validatePass, trigger: 'blur'}
          ],
        },
        emailForm: {
          email: '',
          password: '',
          checked: false,
          checked2: false,
        },
        count: 0,
        speed: 1000,
        validChecked,
        validChecked2
      }
    },
    watch: {
      'dynamicValidateForm.areaCodeId'(areaId){
        let selectItem = this.mobilePrefixOpt.filter(i=>{
          return i.id === areaId;
        });
        this.$set(this.dynamicValidateForm, 'areaCode', selectItem&&selectItem[0]['mobilePrefix']);
      }
    },
    computed: {
      publicKey(){
        return this.$store.getters.getPublicKey;
      },
      btnLoading(){
        return this.$store.getters.getBtnLoading;
      },
      countStr(){
        return this.count === 0?this.$t('signIn10'):`${this.count}s`;
      },
      mobilePrefixOpt(){
        return this.$store.getters.getMobilePrefix;
      }
    },
    mounted() {
      this.$store.dispatch('setMobilePrefix');
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      encryptedData(data) {
        let encryptor = new JSEncrypt();
        encryptor.setPublicKey(this.publicKey);
        return encryptor.encrypt(data);
      },
      emailFormSubmit(){
        this.$refs.emailForm.validate(valid=>{
          if(valid){
            let {email, password} = this.emailForm;
            let encryptionPwd = this.encryptedData(password);
            let params = {
              email,
              password: encryptionPwd,
              lang: this.lang
            };
            //console.log(params);
            apiRegister(params).then(res=>{
              if(res.success){
                //this.$router.push({ name: 'Tips', query: { type: 'SignUp', email } });
                this.toPage('/Tips', { type: 'SignUp', email });
              }
            })
          }
        })
      },
      phoneSignUp(){
        this.$refs.dynamicValidateForm.validate(valid=>{
          if(valid){
            let {mobile, checkcode, password, areaCode, areaCodeId} = this.dynamicValidateForm;
            let encryptionPwd = this.encryptedData(password);
            let params = {
              mobile,
              checkcode,
              areaCode,
              areaCodeId,
              password: encryptionPwd
            };
            apiRegister(params).then(res=>{
              if(res.success){
                this.$router.push({ name: 'SignIn' });
              }
            })
          }
        })
      },
      getCheckCode(){
        let vvv = true;
        this.$refs.dynamicValidateForm.validateField(['mobile', 'checked', 'checked2'], valid=>{
          console.log('valid--', valid);
          if(valid&&vvv){
            vvv = false;
          }
        });
        if(vvv){
          let {speed} = this, timer = null;
          let count = 60;
          let fn = ()=>{
            count -= 1;
            if(count < 0 ){
              clearTimeout(timer);
            }else{
              this.count = count;
              timer = setTimeout(fn, speed);
            }
          };
          let {mobile, areaCode} = this.dynamicValidateForm;
          apiSendSms({
            mobile,
            areaCode,
            type: 'register'
          });
          fn();
        }
      }
    }
  };
</script>

<style lang="css" scoped>

</style>
