<template>
	<div class="x-main">
		<div class="x-content" v-if="visable">
			<el-row>
				<el-col :span="12" style="line-height: 80px;display: flex;">
					<div style="vertical-align: middle;">
						<el-avatar v-if="this.viewData.headimg!=null" size="large" :src="`/cbmp/file/${this.viewData.headimg}`"></el-avatar>
						<el-avatar v-else size="large" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
					</div>
					<div class="x-ml20">
						<p style="line-height: 30px;">
							<span class="x-bold x-f18">{{ (this.viewData.surname==null?'':this.viewData.surname) + (this.viewData.name==null?'':this.viewData.name) }}</span>
							<span class="x-ml20">{{ this.viewData.post }}</span>
						</p>
						<p style="line-height: 20px;">{{ this.viewData.enterprise }}</p>
					</div>
				</el-col>
				<el-col :span="12" style="text-align: right;">
					<span v-if="this.viewData.birthday!=null">
					{{ this.viewData.genderName + '   ' + this.formatDateTime(this.viewData.birthday, 'dateZh') + '出生' }}
					</span>
				</el-col>
			</el-row>
			<el-form :model="viewData" ref="dynamicValidateForm" label-width="100px" class="x-mt20">
				<el-form-item prop="name" label="地区">{{ this.viewData.area }}</el-form-item>
				<el-form-item prop="name" label="行业">{{ this.viewData.category }}</el-form-item>
				<el-form-item prop="name" label="技术领域">{{ this.viewData.domain }}</el-form-item>
				<el-form-item prop="name" label="技术标签">{{ this.viewData.keyword }}</el-form-item>
				<el-form-item prop="name" label="手机号">{{ this.viewData.mobile }}</el-form-item>
				<el-form-item prop="name" label="邮箱">{{ this.viewData.email }}</el-form-item>
				<el-form-item prop="name" label="个人简介">{{ this.viewData.description }}</el-form-item>
				<!-- <el-form-item><el-button @click="resetForm('dynamicValidateForm')">关闭</el-button></el-form-item> -->
			</el-form>
		</div>
		<el-empty v-else description="未查到数据" style="height: 300px;"></el-empty>
	</div>
</template>

<script>
import { apiGetSysUser } from '@/api/UserManage';
import { formatDateTime } from '@/assets/comm';
import mixins from '@/mixins';
export default {
	props: {
		id: {
			type: Number,
			default: 0
		}
	},
	mixins: [mixins],
	components: {},
	data() {
		return {
			details: {},
			viewData: {},
			visable:false
		};
	},

	mounted() {
		this.init();
	},
	methods: {
		formatDateTime,
		init() {
			this.getDetails();
		},
		getDetails() {
			apiGetSysUser({ id: this.id }).then(res => {
				if (res.success) {
					if(res.result.length>0){
						this.visable=true;
						this.viewData = res.result[0];
					}
					else{
						this.visable=false;
					}
					
				}
			});
		}
	}
};
</script>

<style scoped>
.x-content {
	display: block;
	padding-right: 80px;
}
</style>
