<template>
  <mother-boadr :formData="form" entity="ScientificPayoffs" @resetForm="resetForm" @edit="edit" @formValid="formValid" :title="$t('userinfo32')">
    <el-form :model="form" ref="form3" :rules="rules" label-width="120px">
      <el-form-item :label="$t('haward1')" prop="type">
        <el-select v-model="form.type" :placeholder="$t('placeholder')">
          <el-option :label="lang==='en'?'paper':'期刊论文'" value="paper"></el-option>
          <el-option :label="lang==='en'?'achievements':'技术成果'" value="achievements"></el-option>
          <el-option :label="lang==='en'?'patent':'专利'" value="patent"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('haward2')" prop="year">
        <el-date-picker v-model="form.year" type="year" value-format="yyyy" :placeholder="$t('placeholder')"></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('haward3')" prop="name">
        <el-input v-model="form.name" :placeholder="$t('placeholder')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('haward4')" prop="images">
        <upload-image v-model="form.images" :is-temp="true" :btn-delete="true"></upload-image>
      </el-form-item>
      <el-form-item :label="$t('haward5')" prop="description">
        <wang-editor v-model="form.description"></wang-editor>
      </el-form-item>
    </el-form>
  </mother-boadr>
</template>

<script>
  import MotherBoadr from "./MotherBoadr";
  import UploadImage from "../../../components/comm/UploadImage";
  import WangEditor from "../../../components/comm/WangEditor";
  import MixinsBase from "../../../mixins/base";
  export default {
    components: {UploadImage, MotherBoadr, WangEditor},
    mixins: [MixinsBase],
    data(){
      return {
        form: {
          type: '',
          year: '',
          name: '',
          images: '',
          description: ''
        },
        rules: {
          type: { required: true, message: this.$t('placeholder'), trigger: 'change' },
          year: { required: true, message: this.$t('placeholder'), trigger: 'change' },
          name: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
        },
      }
    },
    methods: {
      resetForm(){
        this.$refs.form3.resetFields();
      },
      edit(item){
        let { type, year, name, images, description } = item;
        this.$set(this.form, 'type', type);
        this.$set(this.form, 'year', `${year}`);
        this.$set(this.form, 'name', name);
        this.$set(this.form, 'images', images);
        this.$set(this.form, 'description', description);
      },
      formValid(cb){
        this.$refs.form3.validate(valid=>{
          if(valid){
            cb&&cb();
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>
