<template>
  <div class="d-mark x-tc">
    <div class="d-mark-1" @click="collDemand" :title="$t('xconsulting8')" v-if="duijieId===0">
      <div class="x-f12 el-icon-star-off">{{$t('xconsulting2')}}</div>
    </div>
    <div class="d-mark-1" @click="collDemandCannel" :title="$t('xconsulting8')" v-else>
      <div class="x-f12 el-icon-star-off">{{$t('dc2')}}</div>
    </div>
    <!--<div class="d-mark-2" @click="openDialog" :title="$t('xconsulting9')">
      <div class="x-f12 el-icon-chat-dot-square">{{$t('xconsulting1')}}</div>
    </div>-->
    <el-dialog class="consulting-dialog" :visible.sync="visible" :title="$t('xconsulting1')" :close-on-click-modal="false" append-to-body>
      <div v-if="isResult">
        <div>{{$t('xconsulting3')}}</div>
        <div>{{$t('xconsulting4')}}</div>
        <div class="x-dialog-btn x-tc x-mt20">
          <span class="x-sure x-f16" @click="closeDialog">{{$t('xconsulting5')}}</span>
        </div>
      </div>
      <div v-else>
        <el-input v-model="content" rows="6" class="consulting-area" maxlength="600" type="textarea" :placeholder="$t('xconsulting6')"></el-input>
        <div class="x-dialog-btn x-tc x-mt20">
          <el-button class="x-sure x-f16" @click="sure" :loading="btnLoading">{{$t('xconsulting7')}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {apiPostConsultion, apiPostRequirement, apiGetRequirement, apiDeleteRequirement} from '../api';
  import _ from "lodash";

  export default {
    props:['id', 'userId'],
    data(){
      return {
        visible: false,
        isResult: false,
        content: '',
        duijieId: 0
      }
    },
    computed: {
      btnLoading(){
        return this.$store.getters.getBtnLoading;
      }
    },
    watch: {
      'id'(val){
        this.getIsDuijie(val);
      }
    },
    methods: {
      openDialog(){
        this.content = "";
        this.isResult = false;
        this.visible = true;
      },
      closeDialog(){
        this.visible = false;
      },
      sure(){
        let {content} = this;
        if(_.isEmpty(content)){
          this.$message.error(this.$t('placeholder'));
          return ;
        }
        apiPostConsultion({
          "requirementId": this.$props.id,
          "type": "req",
          content
        }).then(res=>{
          if(res.success){
            this.isResult = true;
          }
        })

      },
      refreshParent(){
        this.$emit('refresh', this.detailsId);
      },
      collDemand(){
        apiPostRequirement({
          requirementId: this.$props.id
        }).then(res=>{
          if(res.success){
            this.getIsDuijie(this.id);
            this.refreshParent();
          }
        })
      },
      getIsDuijie(id){
        let userId = this.userId;
        apiGetRequirement({
          userId,
          requirementId: id
        }).then(res=>{
          if(res.success){
            this.duijieId = res.result.length===0?0:res.result[0]['id'];
          }
        })
      },
      collDemandCannel(){
        apiDeleteRequirement({
          data: [this.duijieId]
        }).then(res=>{
          if(res.success){
            this.getIsDuijie(this.id);
            this.duijieId = 0;
            this.refreshParent();
          }
        })
      },
    }
  }
</script>

<style scoped>
  .d-mark{width:120px;display:flex;color:#fff;}
  .d-mark-2,.d-mark-1{flex:1;padding:10px 0;margin:0 10px;box-sizing:border-box;cursor:pointer;border-radius:10px;}
  .d-mark-2{background:#f37824;}
  .d-mark-1{background:#0369a3;border-bottom:1px solid #fff;}
  .x-sure{padding:12px 38px;display:inline-block;cursor:pointer;border-radius:25px;color:#fff;background:#0268a1;}
  .x-sure:hover{opacity:.8;}
</style>
<style>
  .consulting-dialog .el-dialog{border-radius:20px;}
  .consulting-area .el-textarea__inner{border-radius:8px;border:1px solid #0268A1;background: #F7FCFF;}
</style>
