<template>
	<div class="aaa" style="position:relative;">
		<el-upload
			class="upload-demo"
			:action="url1"
			:file-list="fileList"
			accept=".xls,.xlsx"
			:on-success="handleSuccess"
			:before-upload="beforeUpload"
			:on-error="handleError"
			ref="upload"
		>
			<template>
				<el-button :type="level ? 'primary' : ''">{{ title || '导入' }}</el-button>
			</template>
		</el-upload>
		<el-dialog title="部分表头无法识别" :visible.sync="dialogVisible" width="90%" append-to-body :close-on-click-modal="false">
			<div style="color:#999;">您上传的文件 “{{ fileMessage.filename }}”中共有{{ fileMessage.allTh }}个表头，其中{{ fileMessage.diffTh }}个表头需要您手动匹配系统字段</div>
			<div style="margin:10px 0;">
				<el-checkbox v-model="isAll" label="显示全部表头"></el-checkbox>
				<span style="font-size:12px;color:#999;">(已为您智能匹配{{ fileMessage.sameTh }}个表头)</span>
			</div>
			<el-table :data="filedDataCtrl">
				<el-table-column label="导入的表头字段" prop="a"></el-table-column>
				<el-table-column label="匹配到的系统字段" prop="b"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click="openInner(scope.row)" type="text">选择字段</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer x-tr">
				<el-button type="primary" @click="confirm" :loading="loading">确 定</el-button>
				<el-button @click="closeDialog">取 消</el-button>
			</div>
			<!--选择字段-->
			<el-dialog title="选择字段" :visible.sync="innerVisible" width="90%" :close-on-click-modal="false" append-to-body>
				<div class="x-bbbtn">
					<el-button v-for="(jtem, jndex) in diffData" :key="jndex" @click="peidui(jtem.fieldName, jtem.field)">{{ jtem.fieldName }}</el-button>
				</div>
			</el-dialog>
		</el-dialog>
		<!--导入进度-->
		<el-dialog title="" :visible.sync="importVisible" width="30%" :close-on-click-modal="false" append-to-body :show-close="false" class="nonetitle">
			<div>
				<div class="x-d1">
					<div class="el-icon-document-delete x-d2"></div>
					<div class="x-d3">
						<div class="x-d4">导入数据表</div>
						<div class="x-d5">正在导入...</div>
					</div>
					<div class="x-d6">{{ speed }}%</div>
				</div>
				<el-progress :text-inside="true" :stroke-width="26" :percentage="speed"></el-progress>
			</div>
		</el-dialog>
	</div>
</template>

<script>
/*
  *无分组上传excel文件
  * importTh  系统字段，父组件传
  * url1  上传接口, 返回上传excel表头
  * url2  写入数据接口
  * service 服务名
  * succ  写入完成后的回调，父组件定义
  <import-excel title="导入" :systh="importTh"
                      url1="/readExcel"
                      url2="/importExcel"
                      service="workCalendarImportService"
                      @succ="importSucc"
        >
        </import-excel>
  * */
import { apiPostService } from '@/api';
import _ from 'lodash';
export default {
	props: ['systh', 'url1', 'url2', 'isTemp', 'title', 'type', 'drag', 'level', 'service'],
	emit: ['succ'],
	data() {
		return {
			fileMessage: {
				filename: '',
				allTh: '',
				diffTh: '',
				sameTh: ''
			},
			//对比的表格data
			filedData: [],
			isAll: false,
			dialogVisible: false,
			innerVisible: false,
			fileList: [],
			tableData: [],
			excelth: [],
			tdwdith: 130,
			loading: false,
			files: '',
			sysParams: [],
			diffData: [],
			fieldId: -1,
			importVisible: false,
			speed: 0
		};
	},
	computed: {
		baseUrl: function() {
			return this.$store.state.baseUrl;
		},
		filedDataCtrl() {
			if (this.isAll) {
				return this.filedData;
			} else {
				return this.filedData.filter(i => {
					return !i.ctrl;
				});
			}
		}
	},
	methods: {
		peidui(name, key) {
			//console.log('name---', name, key, this.fieldId);
			_.each(this.filedData, i => {
				if (i.id === this.fieldId) {
					i.b = name;
					i.key = key;
					i.bol = true;
				}
			});
			this.innerVisible = false;
		},
		openInner(row) {
			//console.log(row);
			this.fieldId = row.id;
			this.innerVisible = true;
			let o1 = _.concat([], this.$props.systh);
			let o2 = this.filedData;
			//console.log('@@', o1, o2);
			//this.diffData
			let diff = [];
			_.each(o1, (i, n) => {
				diff[n] = {
					field: '',
					fieldName: ''
				};
				let b2 = true;
				_.each(o2, k => {
					if (i.name === k.b && k.bol) {
						b2 = false;
					}
				});
				if (b2) {
					diff[n]['field'] = i.field;
					diff[n]['fieldName'] = i.name;
				}
			});
			//console.log('diff', diff);
			diff = diff.filter(i => {
				return i.field !== '';
			});
			this.diffData = diff;
		},
		confirm() {
			//this.sysParams, this.filedData
			//console.log(this.sysParams);
			//console.log(this.filedData);
			let _requird = []; //必填项
			let _sysParams = _.concat([], this.sysParams);
			let params = {
				//入参
				fileName: this.files,
				service: this.$props.service,
				mapping: {}
			};
			_.each(_sysParams, i => {
				_.each(this.filedData, j => {
					if (i.field === j.key) {
						i.peiduiName = j.a;
					}
				});
			});
			_.each(_sysParams, i => {
				if (i.required && i.peiduiName === '') {
					_requird.push(i.fieldName);
				}
				if (i.peiduiName !== '') {
					params.mapping[i.field] = i.peiduiName;
				}
			});
			if (!_.isEmpty(_requird)) {
				this.$message.error(_requird.join(',') + '为必填字段');
			} else {
				this.speed = 0;
				this.importVisible = true;
				let _this = this;
				apiPostService(this.$props.url2, params, 'post', {
					noMsg: true
				})
					.then(res => {
						if (res.success) {
							let timer,
								speed = 200;
							let fn = async () => {
								let resSpeed = await apiPostService(
									'/cbmp/rest/SysTaskLog/list',
									{
										params: {
											uuid: res.ext
										}
									},
									'get'
								);
								console.log(resSpeed);
								if (!_.isEmpty(resSpeed.result)) {
									let da = resSpeed.result[0];
									_this.speed = Math.ceil((100 * da.current) / da.size);
									if (da.finish) {
										if (da.success != da.current) {
											_this.$message.error(
												{
													showClose: true,
													message: da.message,
													type: 'warning'
												}
												//`${da.message}`
											);
										}
										_this.$message.info(`共${da.size}条数据，导入成功${da.success}条数据`);
										this.$emit('succ', 1);
										_this.importVisible = false;
										_this.closeDialog();
										clearTimeout(timer);
									} else {
										timer = setTimeout(fn, speed);
									}
								} else {
									timer = setTimeout(fn, speed);
								}
							};
							fn();
						}
					})
					.catch(() => {
						//loading.close();
					});
			}
		},
		closeDialog() {
			this.innerVisible = false;
			this.dialogVisible = false;
		},
		openDialog() {
			let sysParams = [];
			_.each(this.$props.systh, j => {
				sysParams.push({
					field: j.field,
					fieldName: j.name,
					peiduiName: '',
					required: j.required
				});
			});
			this.sysParams = sysParams;
			let aaa = [];
			let _all = 0,
				_same = 0;
			_.each(this.excelth, (i, n) => {
				_all++;
				let oo = {
					id: n,
					a: i,
					b: '',
					key: '',
					bol: false,
					ctrl: false
				};
				_.each(sysParams, j => {
					if (j.fieldName === i) {
						_same++;
						oo.b = j.fieldName;
						oo.key = j.field;
						oo.bol = true;
						oo.ctrl = true;
					}
				});
				aaa.push(oo);
			});
			this.fileMessage.allTh = _all;
			this.fileMessage.sameTh = _same;
			this.fileMessage.diffTh = _all - _same;
			this.filedData = aaa;
			//没有未匹配的字段时，直接导入
			if (this.fileMessage.diffTh === 0) {
				this.confirm();
			} else {
				this.dialogVisible = true;
			}
		},
		handleSuccess(res) {
			this.isAll = false;
			//console.log('success', res);
			//console.log('importSucc', this._events.importSucc);
			let data = res;
			if (this.$props.isTemp && this._events.importSucc) {
				//模板导入成功后处理
				this.$emit('importSucc', data);
			} else {
				this.excelth = data.result[0];
				this.tableData = data.result.slice(1);
				this.files = data.message;
				this.openDialog();
			}
			this.clearFiles();
		},
		clearFiles() {
			this.$refs.upload.clearFiles();
		},
		handleError(err) {
			console.log('error', err);
		},
		beforeUpload(file) {
			let filename = file.name;
			let fileext = filename.substr(filename.lastIndexOf('.'));
			const isExcel = fileext === '.xls' || fileext === '.xlsx';
			const isLt2M = file.size / 1024 / 1024 < 10;
			if (!isExcel) {
				this.$message.error('只能上传excel文件!');
			}
			if (!isLt2M) {
				this.$message.error('上传文件大小不能超过 10MB!');
			}
			this.fileMessage.filename = filename;
			return isExcel;
		}
	}
};
</script>

<style scoped>
.x-excel-flex {
	display: flex;
}
</style>
