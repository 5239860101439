<template>
  <el-form ref="form" :model="form" :rules="rules">
    <el-form-item :label="$t('typeA1')" prop="description">
      <el-input v-model="form.description" type="textarea" :rows="5" :placeholder="$t('placeholder')" />
    </el-form-item>
    <el-form-item :label="$t('typeA2')" prop="scenariosContent">
      <el-input v-model="form.scenariosContent" type="textarea" :rows="5" :placeholder="$t('typeA4')" />
    </el-form-item>
    <el-form-item :label="$t('typeA3')" prop="funcList">
      <loop-zb ref="func" @getFunc="setFunc" :value="form.funcList"></loop-zb>
    </el-form-item>
    <step-btn @next="nextEvent" @save="saveEvent"></step-btn>
  </el-form>
</template>
<script>
  import LoopZb from "./LoopZb";
  import StepBtn from "../StepBtn";
  import _ from "lodash";
  import MixinsBase from "../../../mixins/base";
  export default {
    components: {
      StepBtn,
      LoopZb
    },
    mixins: [MixinsBase],
    data(){
      return {
        form: {
          description: '',
          scenariosContent: '',
          funcList: [],
        },
        rules: {
          description: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
          scenariosContent: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
          funcList: { required: true, message: this.$t('placeholder'), trigger: 'change' },
        }
      }
    },
    computed: {
      cacheDemand(){
        return this.$store.getters.getCacheDemand;
      }
    },
    watch: {
      cacheDemand: {
        immediate: true,
        handler: function(val){
          this.initForm(val);
        }
      }
    },
    methods: {
      initForm(val){
        if(!val){
          return ;
        }
        let { description, scenariosContent, funcList } = val;
        this.form = {
          description,
          scenariosContent,
          funcList
        };
      },
      setFormList(){
        let func = this.$refs.func.reParams();
        if(_.isEmpty(func)){
          return false;
        }else{
          this.setFunc(func);
          return true;
        }

      },
      nextEvent(step){
        if(!this.setFormList()){
          return ;
        }
        this.$refs.form.validate(valid=>{
          if(valid){
            this.$store.dispatch('setCacheDemand', {
              ...this.cacheDemand,
              ...this.form
            });
            this.$store.dispatch('setStep', step);
          }
        })
      },
      saveEvent(){
        if(!this.setFormList()){
          return ;
        }
        this.$refs.form.validate(valid=> {
          if (valid) {
            let params = {
              ...this.form,
              lang: this.lang
            };
            let {langRelReqId} = this.$route.query;
            if(langRelReqId){
              params.langRelReqId = langRelReqId;
            }
            this.$store.dispatch('saveDemand', params);
          }
        })
      },
      setFunc(val){
        this.$set(this.form, 'funcList', val);
      }
    }
  }
</script>

<style scoped>

</style>
