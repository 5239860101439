var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":_vm.$t('typeA1'),"prop":"description"}},[_c('el-input',{attrs:{"type":"textarea","rows":5,"placeholder":_vm.$t('placeholder')},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('typeE1'),"prop":"systemLinkList"}},_vm._l((_vm.form.systemLinkList),function(item,index){return _c('div',{key:index,staticClass:"e-loop-box"},[_c('div',{staticClass:"e-title-box"},[_c('div',[_vm._v(_vm._s(_vm.$t('typeE2'))+" "+_vm._s(index+1))]),_c('div',[(_vm.form.systemLinkList.length>1)?_c('el-button',{attrs:{"circle":"","icon":"el-icon-delete","size":"small","type":"danger"},on:{"click":function($event){return _vm.delParent(index)}}}):_vm._e()],1)]),_c('div',{staticClass:"e-pd20"},[_c('div',{staticClass:"e-name-box x-mb20"},[_c('el-form-item',{staticStyle:{"flex":"1","margin-right":"20px"},attrs:{"prop":'systemLinkList.' + index + '.name',"rules":{
          required: true, message: _vm.$t('placeholder'), trigger: 'blur'
          }}},[_c('el-input',{staticClass:"e-name",attrs:{"type":"text","placeholder":`${_vm.$t('typeE2')} ${index+1} `},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('div',{staticClass:"e-radio"},[_c('span',{staticClass:"x-mr10 x-color666"},[_vm._v(_vm._s(_vm.$t('typeE3')))]),_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"prop":'systemLinkList.' + index + '.status',"rules":{
          required: true, message: _vm.$t('placeholder'), trigger: 'change'
          }}},[_c('el-radio-group',{model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}},_vm._l((_vm.systemLinkListOpt),function(item){return _c('el-radio-button',{key:item.id,attrs:{"label":item.id}},[_vm._v(_vm._s(_vm.lang==='en'?item.nameEn:item.name)+" ")])}),1)],1)],1)],1),_c('el-form-item',{attrs:{"prop":'systemLinkList.' + index + '.content',"rules":{
          required: true, message: _vm.$t('placeholder'), trigger: 'blur'
          }}},[_c('el-input',{attrs:{"type":"textarea","rows":3,"placeholder":_vm.$t('typeE4')},model:{value:(item.content),callback:function ($$v) {_vm.$set(item, "content", $$v)},expression:"item.content"}})],1),_c('div',{staticClass:"e-child-box"},[_c('el-form-item',{attrs:{"label":_vm.$t('typeE5'),"prop":"funcList"}},[_c('loop-zb',{ref:"func",refInFor:true,attrs:{"value":item.funcList}})],1)],1)],1)])}),0),_c('div',{staticClass:"x-tc x-mb20",on:{"click":_vm.addParent}},[_c('el-button',{attrs:{"type":"success","icon":"el-icon-plus","plain":""}})],1),_c('step-btn',{on:{"next":_vm.nextEvent,"save":_vm.saveEvent}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }