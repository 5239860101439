<template>
  <mother-boadr :formData="form" entity="ExpertOrg"
                @resetForm="resetForm"
                @edit="edit"
                @formValid="formValid"
                :title="$t('userinfo30')"
  >
    <el-form :model="form" ref="form10" :rules="rules" label-width="120px">
      <el-form-item :label="$t('eOrg1')" prop="orgName">
        <el-input v-model="form.orgName" :placeholder="$t('placeholder')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('eOrg2')" prop="title">
        <el-input v-model="form.title" :placeholder="$t('placeholder')"></el-input>
      </el-form-item>
    </el-form>
  </mother-boadr>
</template>

<script>
  import MotherBoadr from "./MotherBoadr";
  export default {
    components: {MotherBoadr},
    data(){
      return {
        form: {
          orgName: '',
          title: ''
        },
        rules: {
          orgName: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
          title: { required: true, message: this.$t('placeholder'), trigger: 'blur' },
        },
      }
    },
    methods: {
      resetForm(){
        this.$refs.form10.resetFields();
      },
      edit(item){
        let { orgName, title } = item;
        this.$set(this.form, 'orgName', orgName);
        this.$set(this.form, 'title', title);
      },
      formValid(cb){
        this.$refs.form10.validate(valid=>{
          if(valid){
            cb&&cb();
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>
