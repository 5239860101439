<template>
  <el-pagination
    background
    layout="prev, pager, next"
    @current-change="pageChange"
    :current-page="currpage||1"
	:page-size="pageSize"
    :total="total">
  </el-pagination>
</template>

<script>
  export default {
    props: ['total', 'currpage','pageSize'],
    emits: ['pageChange'],
    methods: {
      pageChange(v){
        this.$emit('pageChange', {
          currPage: v
        });
      },
    }

  }
</script>

<style scoped>

</style>
