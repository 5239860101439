<template>
  <div class="x-main">
    <x-header></x-header>
    <x-breadcrumb></x-breadcrumb>
    <div class="x-content">
      <div class="x-left x-mr20">
        <index-left></index-left>
      </div>
      <div class="x-center">
        <demand-quick></demand-quick>
        <x-card style="width:100%;padding:0;">
          <div class="x-demand-title">{{$t('demand7')}}</div>
          <div class="x-demand-search">
            <demand-search @refreshTable="refreshTable"></demand-search>
          </div>
          <div class="x-demand-list">
            <div v-if="tableData.length === 0">
              <no-data></no-data>
            </div>
            <div v-else>
              <demand-list-temp v-for="item in tableData" :key="item.id" :item="item"></demand-list-temp>
            </div>
            <x-pagination :total="total" @pageChange="refreshTable" :currpage="currPage"></x-pagination>
          </div>
        </x-card>
      </div>
      <div class="x-right x-ml20">
        <index-right></index-right>
      </div>
    </div>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import XBreadcrumb from "../../components/XBreadcrumb";
  import XCard from "../../components/XCard";
  import DemandListTemp from "./DemandComm/DemandListTemp";
  import DemandBudget from "./FormComm/DemandBudget";
  import NoData from "./Comm/NoData";
  import DemandQuick from "./Comm/DemandQuick";
  import Mixins from "../../mixins";
  import MixinsBase from "../../mixins/base";
  import _ from "lodash";
  import {apiDemandList} from "../../api";
  import XPagination from "../../components/comm/XPagination";
  import IndexLeft from "../IndexLeft";
  import IndexRight from "../IndexRight";
  import DemandSearch from "./DemandComm/DemandSearch";
  export default {
    components: {
      IndexRight,
      IndexLeft,
      XPagination,
      NoData,
      DemandBudget,
      DemandListTemp,
      XCard,
      XBreadcrumb,
      XHeader,
      DemandQuick,
      DemandSearch
    },
    mixins: [Mixins, MixinsBase],
    data () {
      return {
        pageSize: 4
      }
    },
    mounted() {
      this.refreshTable({
        isWeightSort: true
      });
    },
    methods: {
      init(){
        this.refreshTable();
      },
      refreshTable(params={}){
        this.searchParams = {
          ...this.searchParams,
          ...params
        };
        this.getTableData(params, apiDemandList, {
          reqStatus: 'published',
          lang: this.lang
        });
      },
    }
  }
</script>

<style scoped>
  .x-demand-search{
    border-bottom:1px solid #e5f0f6;position:relative;
  }
</style>
