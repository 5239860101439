<template>
  <div class="x-main">
    <x-header></x-header>
    <x-breadcrumb></x-breadcrumb>
    <div class="x-content">
      <div class="x-left x-mr20">
        <x-card>
          <ul class="x-demand-left">
            <li class="x-blue">{{$t('baseInfoSet')}}</li>
            <li @click="toPage('/UserPwd')">{{$t('AccountSettings')}}</li>
          </ul>
        </x-card>
      </div>
      <div class="x-center">
        <x-card width="100%">
          <el-form class="x-form-dir" :model="form" ref="form" :rules="rules">
            <div class="u-set-box">

              <!--尺寸240px*240px,jpg/jpeg/gif/png/不超过2M-->
              <!--headimg-->
              <upload-image v-model="form.headimg"
                            style="width:80px;height:80px;background:none;"
                            upStyle="top:56px;height:24px;cursor:pointer;"
              >
                <div class="u-set-pic">
                  <el-image v-if="form.headimg"
                            style="width: 100%; height: 100%"
                            :src="`/cbmp/file/${form.headimg}`"
                            :preview-src-list="[`/cbmp/file/${form.headimg}`]">
                  </el-image>
                  <div class="u-set-pic-change">{{$t('userinfo5')}}</div>
                </div>
                <div class="x-gray">{{$t('userinfo44')}}</div>
              </upload-image>
              <div class="u-set-form x-ml20">
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="$t('userinfo3')" prop="nature">
                      <el-radio v-model="form.nature" v-for="item in natureOpt"
                                :key="item.id" :label="item.id">{{lang==='en'?item.nameEn:item.name}}
                      </el-radio>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item :label="$t('userinfo6')" prop="name">
                      <el-input v-model="form.name" style="width: 100px;margin-right:10px;"
                                :placeholder="$t('userinfo7')"></el-input>
                      <el-form-item prop="surname" style="display:inline-block;margin-bottom:0;">
                        <el-input v-model="form.surname" style="width: 100px;"
                                  :placeholder="$t('userinfo8')"></el-input>
                      </el-form-item>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item :label="$t('userinfo9')" prop="gender">
                      <el-radio-group v-model="form.gender">
                        <el-radio-button v-for="item in genderOpt"
                                         :key="item.id"
                                         :label="item.id">{{lang==='en'?item.nameEn:item.name}}
                        </el-radio-button>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item :label="$t('userinfo11')" prop="birthday">
                      <el-date-picker style="width: 100%;"
                                      v-model="form.birthday"
                                      type="date"
                                      format="yyyy-MM-dd"
                                      :placeholder="$t('placeholder')"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item :label="$t('userinfo10')" prop="area">
                      <el-cascader ref="areaRef" style="width: 100%;"
                                   v-model="form.area"
                                   :options="areaOpt"
                                   :props="{ value: 'name',label: lang==='zh'?'name':'nameEn',children: 'children'}"
                                   :placeholder="$t('placeholder')" @change="areaChange"></el-cascader>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item :label="$t('userinfo12')" prop="category">
                      <el-cascader style="width: 100%;"
                                   v-model="form.category"
                                   :options="categoryOpt"
                                   :props="{ value: 'name', label: lang==='en'?'nameEn':'name', children: 'children'}"></el-cascader>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item :label="$t('userinfo13')" prop="domain">
                      <el-cascader style="width: 100%;" collapse-tags
                                   v-model="form.domain"
                                   :options="domainOpt"
                                   :props="{ value: 'name', label: lang==='en'?'nameEn':'name', children: 'children', multiple: true }"
                                   :placeholder="$t('placeholder')"></el-cascader>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="$t('userinfo14')" prop="keyword">
                      <demand-industry-input @setForm="setForm"
                                             @delEvent="delEvent"
                                             :selectOpt="keywordOpt"
                                             :isFilter="true"
                                             :allowCreate="true"
                                             :tagOpt="tagKeyword" :max="3"
                                             ikey="keyword" :placeholder="$t('userinfo16')">
                        <div slot="des" class="x-color666">{{$t('userinfo15')}}</div>
                      </demand-industry-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item :label="$t('userinfo18')" prop="mobile" :rules="reMobile">
                      <!--<el-input type="text" v-model="form.mobile"></el-input>-->
                      <el-input :placeholder="`${$t('placeholder')}`" :disabled="isMobile" v-model="form.mobile"
                                class="input-with-select i3">
                        <el-select filterable v-model="form.areaCodeId" slot="prepend" :placeholder="$t('placeholder')"
                                   style="width:100px;" @change="countryMobileChange">
                          <el-option v-for="item in mobilePrefixOpt"
                                     :key="item.id"
                                     :label="`+${item.mobilePrefix}(${lang==='en'?item.countryEn:item.country})`"
                                     :value="item.id"></el-option>
                        </el-select>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item :label="$t('userinfo19')" prop="email">
                      <el-input type="text" v-model="form.email" :disabled='!isMobile'></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item :label="$t('userinfo20')" prop="comment">
                      <wang-editor v-model="form.comment"></wang-editor>
                    </el-form-item>
                  </el-col>
                  <el-col :span="16">
                    <el-form-item :label="$t('userinfo23')" prop="department">
                      <el-input type="text" v-model="form.department" :placeholder="`${$t('placeholder')}`"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item :label="$t('userinfo24')" prop="post">
                      <el-input type="text" v-model="form.post" :placeholder="`${$t('placeholder')}`"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <!--企业资料 begin-->
                <div v-if="form.nature === 'enterprise'">
                  <div>{{$t('userinfo35')}}</div>
                  <el-row>
                    <el-col :span="16">
                      <el-form-item :label="$t('userinfo36')" prop="enterpriseName" :rules="{ required: true, message: $t('placeholder'), trigger: 'blur' }">
                        <el-input type="text" v-model="form.enterpriseName"
                                  :placeholder="`${$t('placeholder')}`"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item :label="$t('userinfo37')" prop="enterpriseType" :rules="{ required: true, message: $t('placeholder'), trigger: 'change' }">
                        <el-select style="width:100%;" v-model="form.enterpriseType" :placeholder="`${$t('placeholder')}`">
                          <el-option v-for="item in enterpriseOpt"
                                     :key="item.id"
                                     :label="lang==='en'?item.nameEn:item.name"
                                     :value="item.id"></el-option>
                        </el-select>

                      </el-form-item>
                    </el-col>
                    <el-col :span="16">
                      <el-form-item :label="$t('userinfo38')" prop="unifiedCode">
                        <el-input type="text" v-model="form.unifiedCode"
                                  :placeholder="`${$t('placeholder')}`"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item :label="$t('userinfo39')" prop="insize">
                        <el-select style="width:100%;" v-model="form.insize" :placeholder="`${$t('placeholder')}`">
                          <el-option v-for="item in insizeOpt"
                                     :key="item.id"
                                     :label="lang==='en'?item.nameEn:item.name"
                                     :value="item.id"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="16">
                      <el-form-item :label="$t('userinfo40')" prop="license">
                        <upload-image v-model="form.license" :is-temp="true" :btn-delete="true"></upload-image>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item :label="$t('userinfo41')" prop="logo">
                        <upload-image v-model="form.logo" :is-temp="true" :btn-delete="true"></upload-image>
                        <div class="x-gray">{{$t('userinfo43')}}</div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <div>{{$t('userinfo42')}}</div>
                  <wang-editor v-model="form.enterpriseDesc"></wang-editor>
                </div>
                <!--企业资料 end-->

                <!--专家资料 begin-->
                <div v-if="form.nature === 'expert'">
                  <div class="x-bold">{{$t('userinfo27')}}</div>
                  <!--<div class="x-mt10">{{$t('userinfo28')}}</div>
                  <wang-editor v-model="form.basicInformation"></wang-editor>-->
                  <div class="x-mt10" style="margin-bottom:12px;">{{$t('userinfo29')}}</div>
                  <wang-editor v-model="form.researchDirection"></wang-editor>
                  <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane :label="$t('userinfo30')" name="first">
                      <expert-org></expert-org>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('userinfo31')" name="second">
                      <honor-award></honor-award>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('userinfo32')" name="three">
                      <scientific-payoffs></scientific-payoffs>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('userinfo33')" name="four">
                      <example-of-case></example-of-case>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('userinfo34')" name="five">
                      <media-coverage></media-coverage>
                    </el-tab-pane>
                  </el-tabs>
                </div>
                <!--专家资料 end-->

                <el-row class="x-mt20">
                  <el-col :span="24">
                    <el-button class="x-btn x-btn-save" :style="`margin-left:${labelWidth};`" @click="btnSave"
                               :loading="btnLoading">{{$t('resetPwd4')}}
                    </el-button>
                  </el-col>
                </el-row>

              </div>
            </div>
          </el-form>
        </x-card>

      </div>
    </div>
    <el-drawer size="420"
               :with-header="false"
               :visible.sync="drawer"
               :close-on-press-escape="false"
               :wrapperClosable="false"
               direction="ttb">
      <div style="padding:20px;line-height:24px;height:400px;overflow-y:auto;">
        <h3 class="x-tc">{{$t('baomixieyi1')}}</h3>
        <pre style="white-space: pre-wrap;">
          {{$t('baomixieyi2')}}
          </pre>
        <div style="text-align:right;">
          {{$t('baomixieyi3')}}<br/>
          20220901
        </div>
        <div class="x-tc x-mt20">
          <el-button type="primary" class="x-btn" @click="readAgree">{{$t('signUp8_1')}}</el-button>
          <span style="cursor:pointer;" @click="logout">{{$t('yinsizhengce1')}}</span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
  import XHeader from "../../components/XHeader";
  import XBreadcrumb from "../../components/XBreadcrumb";
  import XCard from "../../components/XCard";
  import DemandIndustryInput from "./FormComm/DemandIndustryInput";
  import {apiTechnologyKeyword, apiPostTechnologyKeyword, apiPutAppUser, apiExit} from "../../api/index";
  import _ from 'lodash';
  import UploadImage from "../../components/comm/UploadImage";
  import WangEditor from "../../components/comm/WangEditor";
  import {reValidateReg} from "../../filters/formValidate";
  import mixinsBase from '../../mixins/base';
  import ExpertOrg from './PersonalData/ExpertOrg';
  import HonorAward from "./PersonalData/HonorAward";
  import ScientificPayoffs from "./PersonalData/ScientificPayoffs";
  import ExampleOfCase from "./PersonalData/ExampleOfCase";
  import MediaCoverage from "./PersonalData/MediaCoverage";
  export default {
    components: {
      MediaCoverage,
      ExampleOfCase,
      ScientificPayoffs,
      HonorAward,
      ExpertOrg,
      UploadImage,
      DemandIndustryInput,
      XCard,
      XBreadcrumb,
      XHeader,
      WangEditor
    },
    mixins: [mixinsBase],
    data() {
      return {
        form: {
          nature: '',
          surname: '',
          name: '',
          gender: 'nvl',
          area: [],
          birthday: '',
          category: [],
          domain: [],
          comment: '',
          email: '',
          mobile: '',
          headimg: '',
          keyword: '',
          areaCodeId: 1,
          areaCode: '86',
          enterprise: '',
          department: '',
          post: '',
          basicInformation: '',
          researchDirection: '',
          enterpriseType: '',
          enterpriseName: '',
          unifiedCode: '',
          insize: '',
          license: '',
          logo: '',
          enterpriseDesc: '',

        },
        isMobile: true,
        rules: {
          surname: [
            {required: true, message: this.$t('placeholder'), trigger: 'blur'},
            {pattern: reValidateReg('zh-en'), message: this.$t('placeholder'), trigger: 'blur'},
          ],
          name: [
            {required: true, message: this.$t('placeholder'), trigger: 'blur'},
            {pattern: reValidateReg('zh-en'), message: this.$t('placeholder'), trigger: 'blur'},
          ],
          //gender: {required: true, message: '请选择性别', trigger: 'change'},
          area: {required: true, message: this.$t('placeholder'), trigger: 'change'},
          nature: {required: true, message: this.$t('placeholder'), trigger: 'change'},
          //birthday: {required: true, message: '请选择出生', trigger: 'change'},
          category: {required: true, message: this.$t('placeholder'), trigger: 'change'},
          domain: {required: true, message: this.$t('placeholder'), trigger: 'change'},
          keyword: {required: true, message: this.$t('placeholder'), trigger: 'blur'},
          /*areaCodeId: {required: true, message: '请选择区号', trigger: 'change'},
          mobile: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { pattern: reValidateReg('mobile'), message: '请输入正确的手机号', trigger: 'blur' },
          ],*/
          // mobile: [
          //   { required: true, message: '请输入手机号', trigger: 'blur' },
          //   { pattern: reValidateReg('mobile'), message: '请输入正确的手机号', trigger: 'blur' },
          // ],
          email: [
            {required: false, message: this.$t('placeholder'), trigger: 'blur'},
            {pattern: reValidateReg('email'), message: this.$t('placeholder'), trigger: 'blur'}
          ],
          enterprise: {required: true, message: this.$t('placeholder'), trigger: 'blur'},
          department: {required: true, message: this.$t('placeholder'), trigger: 'blur'},
          post: {required: true, message: this.$t('placeholder'), trigger: 'blur'},
        },
        addressOpt: [],
        labelWidth: '120px',
        keywordOpt: [],
        tagKeyword: [],
        areaId: null,
        drawer: false,
        activeName: 'first'
      }
    },
    computed: {
      enterpriseOpt() {
        return this.$store.getters.getEnterprise;
      },
      insizeOpt() {
        return this.$store.getters.getInsize;
      },
      initUserInfo() {
        return this.$store.getters.getUserInfo;
      },
      btnLoading() {
        return this.$store.getters.getBtnLoading;
      },
      genderOpt() {
        return this.$store.getters.getGender;
      },
      areaOpt() {
        let area = this.$store.getters.getArea;
        return this.getTreeData(area);
      },
      categoryOpt() {
        return this.getTreeData(this.$store.getters.getCategory);
      },
      domainOpt() {
        return this.getTreeData(this.$store.getters.getDomain);
      },
      mobilePrefixOpt() {
        return this.$store.getters.getMobilePrefix;
      },
      reMobile() {
        let rule = [{required: false, message: '请输入', trigger: 'blur'}];
        //为+86时校验
        if (this.form.areaCodeId === 1) {
          rule.push({pattern: this.reValidateReg('mobile'), message: '请输入正确的手机号', trigger: 'blur'})
        }
        return rule;
      },
      natureOpt() {
        return this.$store.getters.getNature;
      }
    },
    watch: {
      'userInfo': {
        immediate: true,
        handler: function (val) {
          this.initForm(val);
        }
      },
      'form.domain'(nval, oval) {
        if (nval.length > 3) {
          this.$message.error(this.$t('userinfo26'));
          this.$nextTick(() => {
            this.$set(this.form, 'domain', oval);
          })
        }
      }
    },
    created() {
      this.setUserType();
    },
    mounted() {
      this.getDict();
    },
    methods: {
      init() {
        this.$router.go(0);
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      setUserType() {
        let registerType = this.initUserInfo.registerType;
        this.isMobile = (registerType == 'mobile');
        if (!this.isMobile) {
          this.rules.mobile = [
            {required: false, message: '请输入手机号', trigger: 'blur'},
            {pattern: this.reValidateReg('mobile'), message: '请输入正确的手机号', trigger: 'blur'},
          ];
          delete this.rules.email;
        }
      },
      reValidateReg,
      countryMobileChange(val) {
        let oo = this.mobilePrefixOpt.filter(i => {
          return val === i.id;
        });
        this.$set(this.form, 'areaCode', oo[0]['mobilePrefix']);
      },
      getDict() {
        if (_.isEmpty(this.areaOpt)) {
          this.$store.dispatch('setArea');
        }
        if (_.isEmpty(this.categoryOpt)) {
          this.$store.dispatch('setCategory');
        }
        if (_.isEmpty(this.domainOpt)) {
          this.$store.dispatch('setDomain');
        }
        if (_.isEmpty(this.mobilePrefixOpt)) {
          this.$store.dispatch('setMobilePrefix');
        }
        apiTechnologyKeyword().then(res => {
          this.keywordOpt = res.result;
        })
      },
      getTreeData(data = []) {
        for (let i = 0; i < data.length; i++) {
          if (!data[i].children) {
            data[i].children = undefined;
          } else if (data[i].children.length < 1) {
            data[i].children = undefined;
          } else {
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      reDomainStr() {
        let {domain} = this.form;
        let str = '';
        if (!_.isEmpty(domain)) {
          _.each(domain, i => {
            str += `${i.join('_')};`;
          })
          str = str.substr(0, str.length - 1);
        }
        return str;
      },
      reDomainArr(domain = '') {
        let arr = [];
        let domainArr = domain.split(';');
        _.each(domainArr, i => {
          arr.push(i.split('_'));
        });
        return arr;
      },
      btnSave() {
        this.$refs.form.validate(valid => {
          if (valid) {
            let {
              nature,
              surname,
              name,
              gender,
              area,
              birthday,
              category,
              //domain,
              comment,
              email,
              mobile,
              headimg,
              keyword,
              areaCode,
              areaCodeId,
              enterprise,
              department,
              post,
              basicInformation,
              researchDirection,
              enterpriseType,
              enterpriseName,
              unifiedCode,
              insize,
              license,
              logo,
              enterpriseDesc
            } = this.form;
            let params = {
              id: this.userInfo.id,
              nature,
              surname,
              name,
              gender,
              birthday,
              area: area.join('_'),
              category: category.join('_'),
              domain: this.reDomainStr(),
              comment,
              email,
              mobile,
              headimg,
              keyword,
              areaCode,
              areaCodeId,
              enterprise,
              department,
              post,
              basicInformation,
              researchDirection,
              enterpriseType,
              enterpriseName,
              unifiedCode,
              insize,
              license,
              logo,
              enterpriseDesc
            };
            if (this.areaId) {
              params.areaId = this.areaId;
            }
            apiPutAppUser(params).then(res => {
              if (res.success) {
                this.$store.dispatch('setUserInfo');
                this.toPage('/index')
              }
            })
          }
        })
      },
      readAgree() {
        this.drawer = false;
        apiPutAppUser({
          id: this.userInfo.id,
          signProtocol: true
        }, {
          noLoading: false
        }).then(res => {
          if (res.success) {
            this.$store.dispatch('setUserInfo');
          }
        })
      },
      setForm(opt = {}) {
        let {key, value, isAdd, formValue} = opt;
        if (isAdd) {
          apiPostTechnologyKeyword({
            keyword: value
          }, {
            noMsg: true,
            noLoading: true
          });
          this.keywordOpt.push({
            keyword: value
          })
        }
        this.tagKeyword.push(value);
        this.$set(this.form, key, formValue);
      },
      delEvent(index) {
        this.tagKeyword.splice(index, 1);
        this.$set(this.form, 'keyword', this.tagKeyword.join('_'));
      },
      initForm(val) {
        if (!_.isEmpty(val)) {
          let {
            nature,
            surname,
            name,
            gender,
            area,
            areaCodeId,
            birthday,
            category,
            domain,
            comment,
            email,
            mobile,
            headimg,
            keyword,
            signProtocol,
            enterprise,
            department,
            post,
            basicInformation,
            researchDirection,
            enterpriseType,
            enterpriseName,
            unifiedCode,
            insize,
            license,
            logo,
            enterpriseDesc
          } = val;
          this.$set(this.form, 'nature', nature);
          this.$set(this.form, 'surname', surname);
          this.$set(this.form, 'name', name);
          this.$set(this.form, 'gender', gender);
          this.$set(this.form, 'birthday', birthday);
          this.$set(this.form, 'area', area ? area.split('_') : []);
          this.$set(this.form, 'areaCodeId', areaCodeId);
          this.$set(this.form, 'category', category ? category.split('_') : []);
          this.$set(this.form, 'domain', domain ? this.reDomainArr(domain) : []);
          this.$set(this.form, 'comment', comment);
          this.$set(this.form, 'email', email);
          this.$set(this.form, 'mobile', mobile);
          this.$set(this.form, 'headimg', headimg);
          this.$set(this.form, 'keyword', keyword);
          this.$set(this.form, 'enterprise', enterprise);
          this.$set(this.form, 'department', department);
          this.$set(this.form, 'post', post);
          this.$set(this.form, 'basicInformation', basicInformation);
          this.$set(this.form, 'researchDirection', researchDirection);
          this.$set(this.form, 'enterpriseType', enterpriseType);
          this.$set(this.form, 'enterpriseName', enterpriseName);
          this.$set(this.form, 'unifiedCode', unifiedCode);
          this.$set(this.form, 'insize', insize);
          this.$set(this.form, 'license', license);
          this.$set(this.form, 'logo', logo);
          this.$set(this.form, 'enterpriseDesc', enterpriseDesc);
          this.tagKeyword = keyword ? keyword.split('_') : [];
          if (!signProtocol) {
            this.drawer = true;
          }
        }
      },
      areaChange() {
        let {id} = this.$refs.areaRef.getCheckedNodes()[0]['data'];
        this.areaId = id;
      },
      logout() {
        apiExit();
        this.toPage('/SignIn');
      }
    }
  }
</script>

<style scoped>
  .x-demand-left li {
    margin-bottom: 20px;
    cursor: pointer;
  }

  .x-demand-left li:last-child {
    margin-bottom: 0;
  }

  .u-set-box {
    display: flex;
  }

  .u-set-pic {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    position: relative;
    background: #f4f4f4;
    overflow: hidden;
  }

  .u-set-pic-change {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    background: rgba(2, 104, 161, 0.50);
  }

  .u-set-form {
    flex: 1;
  }
</style>
