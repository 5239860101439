<template>
	<div class="x-main-box">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">
				<el-icon class="el-icon-s-home"></el-icon>
				首页
			</el-breadcrumb-item>
			<el-breadcrumb-item>留言管理</el-breadcrumb-item>
		</el-breadcrumb>
		<el-tabs v-model="activeName" class="x-mt20" type="border-card" @tab-click="handleClick">
			<el-tab-pane label="需求留言" name="first">
        <ReqMessage v-if="activeName === 'first'" type="req"></ReqMessage>
      </el-tab-pane>
			<el-tab-pane label="专家留言" name="second">
        <ReqMessage v-if="activeName === 'second'" type="expert"></ReqMessage>
      </el-tab-pane>
			<el-tab-pane label="回收站" name="three">
        <ReqMessage v-if="activeName === 'three'" type="recycle"></ReqMessage>
      </el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import ReqMessage from '@/views/System/MessageManage/Message';
export default {
	components: {
		ReqMessage
	},
	data() {
		return {
			activeName: 'first'
		};
	},
	methods: {
		handleClick(tab, event) {
			console.log(tab, event);
		}
	}
};
</script>

<style>
.tabs-box .el-tabs__header {
	background-color: #ffffff;
	padding: 10px;
	margin-bottom: 0;
}
.el-tabs__nav-wrap::after {
	background-color: rgba(0, 0, 0, 0.05);
}
</style>
