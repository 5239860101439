<template>
  <x-card style="width:100%;padding:0;">
    <div>
      <el-input :rows="2" class="x-dq-area" style="padding-top: 16px;font-size: 20px;" placeholder="输入活动标题，发布一条新活动" maxlength="70" type="textarea" v-model="txt"></el-input>
    </div>
    <div class="x-dq-box">
      <div style="margin-left:10px;flex:1;">
        活动日期：
        <el-date-picker
        v-model="adate"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      </div>
      <div @click="sendActivity" class="el-icon-s-promotion x-dq-submit x-blue">发活动</div>
    </div>
  </x-card>
</template>

<script>
  import XCard from "../../components/XCard";
  import MixinsBase from "../../mixins/base";
  import _ from 'lodash';
  export default {
    components: {XCard},
    mixins: [MixinsBase],
    data(){
      return {
        txt: '',
        adate: [],
      }
    },
    computed: {
    },
    methods: {
      sendActivity(){
        if(_.trim(this.txt)===''){
          this.$message.error('请输入活动标题');
        }else if(_.isEmpty(this.adate)){
          this.$message.error('请选择活动日期');
        }else{
          this.$store.dispatch('setActivity', {
            title: this.txt,
            adate: this.adate
          });
          this.toPage('/PublishingActivities', { type: 'quick' });
        }
      }
    }
  }
</script>

<style scoped>
.x-dq-box{width:100%;height:42px;display:flex;align-items:center;background:#f6fcff;border-bottom-left-radius:20px;}
  .x-dq-submit{height:42px;line-height:42px;padding:0 20px;background:#e6f6ff;border-bottom-right-radius: 20px;border:1px solid #cfe8e6;box-sizing: border-box;}
</style>
<style>
  .x-dq-area textarea{border-top-left-radius:20px;border-top-right-radius: 20px;border:none;}
  .x-dq-box .el-select .el-input__inner{height:42px;line-height:42px;border:none;box-sizing:border-box;background:#f6fcff;}
  .x-dq-box .el-select .el-input__inner::-webkit-input-placeholder{color:#333;font-size:14px;}
  .x-dq-area .el-textarea__inner::-webkit-input-placeholder{font-size:20px!important;}
</style>
